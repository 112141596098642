import { unionBy } from "lodash";
import EventEmitter from "../../utils/EventEmitter";
import { UIAmenityPinCard, UIPanel, UIRow } from "../ui.lib";
import { detectMobile } from "../../../_utils/detectBrowser";

class FloatingbarAmenityCards extends EventEmitter {
    constructor(editor) {
        super();

        this.editor = editor;
        this.amenityTypes = [];

        this.hiddenInit = false;

        this.editor.resources.on("ready", this.setAmenityTypes);
        this.editor.on("hasFeaturedPins", this.onHasFeaturedPins);

        this.initUI();
    }

    initUI = () => {
        const uiContainer = new UIPanel().addClass(
            "wsInteractiveFloatingbar__amenityContainer"
        );

        this.uiAmenityList = new UIRow().addClass(
            "wsInteractiveFloatingbar__amenityList"
        );
        uiContainer.addChild(this.uiAmenityList);

        this.dom = uiContainer;
    };

    emptyContent = () => {
        var child = this.uiAmenityList.dom.lastChild;
        while (child) {
            this.uiAmenityList.dom.removeChild(child);
            child = this.uiAmenityList.dom.lastChild;
        }
    };

    renderAmenityList = () => {
        this.emptyContent();

        if (this.amenityTypes.length === 0) {
            this.dom.setDisplay("none");
            const node = document.getElementById("topLocationContainer");
            if (node) {
                node.classList.add(
                    "wsInteractiveFloatingbar__topLocationContainer--paddedTop"
                );
            }
        } else {
            this.dom.setDisplay("");
            const node = document.getElementById("topLocationContainer");
            if (node) {
                node.classList.remove(
                    "wsInteractiveFloatingbar__topLocationContainer--paddedTop"
                );
            }
            this.amenityTypes.forEach((amenityType, index) => {
                const uiCont = new UIPanel().addClass(
                    "wsInteractiveFloatingbar__amenityCard"
                );
                const uiContainer = new UIAmenityPinCard(
                    amenityType,
                    this.handleAmenityClick,
                    index
                );
                uiCont.addChild(uiContainer);
                this.uiAmenityList.addChild(uiCont);
            });
        }
    };

    handleAmenityClick = (amenityType) => {
        this.editor.trigger("amenityTypeSelected", [amenityType.name]);
    };

    updateUI = () => {
        this.emptyContent();
        this.renderAmenityList();
    };

    onHasFeaturedPins = (hasFeaturedPins) => {
        const browser = detectMobile();
        if (!browser) {
            this.hiddenInit = hasFeaturedPins;
        }
    };

    setAmenityTypes = () => {
        this.editor.jsonLocationPins.forEach((pin) => {
            if (pin.pinType === "amenity" && pin.amenityPinTypes.length > 0) {
                this.amenityTypes.push(pin.amenityPinTypes[0]);
            }
        });
        this.amenityTypes = unionBy(this.amenityTypes, "id");

        // console.log("this.amenityTypes", this.amenityTypes);
        this.updateUI();
        this.hiddenInit && this.dom.setDisplay("none");
    };
}

export { FloatingbarAmenityCards };
