import { has, isEmpty } from "lodash";
import EventEmitter from "../../utils/EventEmitter";
import {
    UIButton,
    UIImage,
    UIPanel,
    UIRow,
    UIText,
    UITextHeader,
} from "../ui.lib";

import DefFileIcon from "../../../../assets/pngs/studio/locationPin/defaultPDF.png";
import ChevronIcon from "../../../../assets/svgs/scenes/chevron_dark.svg";

import { getPinThumbIcons, getTexNameFromId } from "../../threeUtils/LocationPins/PinUtils";

class SidebarPinInfo extends EventEmitter {
    constructor(editor) {
        super();
        this.editor = editor;
        this.selectedLocationPin = null;

        this.dom = null;

        this.initUI();
    }

    initUI = () => {
        this.uiContainer = new UIPanel().addClass(
            "wsInteractiveSidebar__sidebarPinInfo"
        );

        this.pinInfoContainer = new UIPanel().addClass(
            "wsInteractiveSidebar__pinInfoContainer"
        );

        const uiBackRow = new UIRow();
        uiBackRow.dom.style.width = "max-content";
        uiBackRow.addChild(new UIImage(ChevronIcon, "28px", "28px").addClass("wsInteractiveSidebar__backIcon"));
        uiBackRow.addChild(new UIText("", "Back").addClass("wsInteractiveSidebar__backIconText"));
        uiBackRow.onClick(() => {
            this.editor.trigger("toggleInteractiveSidebar", [null]);
            this.editor.trigger("clearPathBetweenPins");
            this.editor.select(null);
        });

        this.uiContainer.addChild(uiBackRow);
        this.uiContainer.addChild(this.pinInfoContainer);
        // this.uiContainer.addChild(this.uiDirectionBtn);
        this.dom = this.uiContainer;
    };

    emptyContent = () => {
        let child = this.pinInfoContainer.dom.lastChild;
        while (child) {
            this.pinInfoContainer.dom.removeChild(child);
            child = this.pinInfoContainer.dom.lastChild;
        }
    };

    updatePinInfo = () => {
        this.emptyContent();
        const scope = this;

        if (this.selectedLocationPin) {
            const { pinType, name, description } = this.selectedLocationPin;

            // pin Name
            const uiNameRow = new UIRow().addClass(
                "wsInteractiveSidebar__pinInfoRow"
            );
            let uiNameLogo = null
            if (
                pinType === "regular" &&
                has(this.selectedLocationPin.advanced, "logo") &&
                !isEmpty(this.selectedLocationPin.advanced.logo)
            ) {
                uiNameLogo = new UIImage(this.selectedLocationPin.advanced.logo?.link, "40px", "40px");
                uiNameLogo.dom.style.border = `2px solid ${this.selectedLocationPin.pinColor}`;
            } else {
                uiNameLogo = new UIImage(getPinThumbIcons(
                    this.selectedLocationPin.pinType === "amenity"
                        ? getTexNameFromId(this.selectedLocationPin.amenityPinTypes[0].id)
                        : "location"
                    , this.selectedLocationPin.pinColor), "40px", "40px");
            }
            uiNameLogo.alt = "iconName";
            uiNameLogo.addClass("wsInteractiveSidebar__pinInfoLogoImg");
            
            const uiNameHeader = new UITextHeader("", name).addClass(
                "wsInteractiveSidebar__pinInfoHeader"
            );
            uiNameRow.addChild(uiNameLogo);
            uiNameRow.addChild(uiNameHeader);
            this.pinInfoContainer.addChild(uiNameRow);

            // images!!
            if (
                has(this.selectedLocationPin, "images") &&
                this.selectedLocationPin.images.length
            ) {
                const uiImageRow = new UIRow().addClass(
                    "wsInteractiveSidebar__pinInfoImagesRow"
                );
                this.selectedLocationPin.images.forEach((image, index) => {
                    const uiImageCont = new UIPanel().addClass("wsInteractiveSidebar__pinInfoImageCont");
                    const uiImage = new UIImage(image.link, "", "").addClass(
                        "wsInteractiveSidebar__pinInfoImage"
                    );
                    uiImageCont.addChild(uiImage);
                    uiImageRow.addChild(uiImageCont);
                });
                this.pinInfoContainer.addChild(uiImageRow);
            }

            this.uiDirectionBtn = new UIButton("Get Directions").onClick(() => {
                scope.editor.trigger("updateSidebarMode", ["DIRECTIONS_SELECTOR"]);
            });
            this.uiDirectionBtn.setStyle("margin", "10px 0px");
            this.pinInfoContainer.addChild(this.uiDirectionBtn);

            // description
            const uiDescriptionCont = new UIPanel().addClass(
                "wsInteractiveSidebar__pinInfoDescription"
            );
            if (description) {
                const uiDescription = new UIText("", description).addClass(
                    "wsInteractiveSidebar__pinInfoDescriptionText"
                );
                uiDescriptionCont.addChild(uiDescription);
            }

            if (
                has(this.selectedLocationPin, "advanced") &&
                has(this.selectedLocationPin.advanced, "note") &&
                !isEmpty(this.selectedLocationPin.advanced.note)
            ) {
                const uiNoteCont = new UIPanel().addClass(
                    "wsInteractiveSidebar__pinInfoNoteCont"
                );
                const uiNoteHeader = new UIText("", "Note:").addClass(
                    "wsInteractiveSidebar__pinInfoNoteHeader"
                );
                const uiNote = new UIText(
                    "",
                    this.selectedLocationPin.advanced.note
                ).addClass("wsInteractiveSidebar__pinInfoNote");
                uiNoteCont.addChild(uiNoteHeader);
                uiNoteCont.addChild(uiNote);
                uiDescriptionCont.addChild(uiNoteCont);
            }

            if (
                has(this.selectedLocationPin, "advanced") &&
                has(this.selectedLocationPin.advanced, "file") &&
                !isEmpty(this.selectedLocationPin.advanced.file)
            ) {
                const uiFileCont = new UIPanel().addClass(
                    "wsInteractiveSidebar__pinInfoFileCont"
                );
                const uiFileHeader = new UIImage(DefFileIcon, "", "").addClass(
                    "wsInteractiveSidebar__pinInfoFileIcon"
                );
                const uiFile = new UIText(
                    "",
                    this.selectedLocationPin.advanced.file.name
                )
                    .addClass("wsInteractiveSidebar__pinInfoFile")
                    .onClick(async () => {
                        //create anchor tag and download file
                        const url = this.selectedLocationPin.advanced.file.link;

                        async function toDataURL(url) {
                            const blob = await fetch(url).then((res) =>
                                res.blob()
                            );
                            return URL.createObjectURL(blob);
                        }

                        const a = document.createElement("a");
                        a.href = await toDataURL(url);
                        a.download = "myImage.png";
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    });
                uiFileCont.addChild(uiFileHeader);
                uiFileCont.addChild(uiFile);
                uiDescriptionCont.addChild(uiFileCont);
            }

            uiDescriptionCont.dom.hasChildNodes() && 
                this.pinInfoContainer.addChild(uiDescriptionCont);

            // categories
            if (
                has(this.selectedLocationPin, "categories") &&
                this.selectedLocationPin.categories.length
            ) {
                const uiCategoriesCont = new UIPanel().addClass(
                    "wsInteractiveSidebar__pinInfoCategories"
                );
                this.selectedLocationPin.categories.forEach(
                    (category, index) => {
                        const uiCategory = new UIText(
                            "",
                            category.name
                        ).addClass("wsInteractiveSidebar__pinInfoCategory");
                        uiCategoriesCont.addChild(uiCategory);
                    }
                );
                uiDescriptionCont.addChild(uiCategoriesCont);
            }

            // advanced
            const uiAdvancedCont = new UIPanel().addClass(
                "wsInteractiveSidebar__pinInfoAdvanced"
            );
            if (has(this.selectedLocationPin, "advanced")) {
                const { mobile, email, websiteLabel, websiteLink } =
                    this.selectedLocationPin.advanced;
                if (mobile || email || websiteLabel || websiteLink) {
                    const uiAdvancedHeader = new UITextHeader(
                        "",
                        "Contact Information"
                    ).addClass("wsInteractiveSidebar__pinInfoAdvancedHeader");
                    uiAdvancedCont.addChild(uiAdvancedHeader);
                }
                if (mobile) {
                    const uiMobileCont = new UIPanel().addClass(
                        "wsInteractiveSidebar__pinContactInfoCont"
                    );
                    const uiMobileHeader = new UIText("", "Mobile:").addClass(
                        "wsInteractiveSidebar__pinInfoContactHeader"
                    );
                    const uiMobile = new UIText("", mobile).addClass(
                        "wsInteractiveSidebar__pinContactInfoText"
                    );
                    uiMobileCont.addChild(uiMobileHeader);
                    uiMobileCont.addChild(uiMobile);
                    uiAdvancedCont.addChild(uiMobileCont);
                }
                if (email) {
                    const uiEmailCont = new UIPanel().addClass(
                        "wsInteractiveSidebar__pinContactInfoCont"
                    );
                    const uiEmailHeader = new UIText("", "Email:").addClass(
                        "wsInteractiveSidebar__pinInfoContactHeader"
                    );
                    const uiEmail = new UIText("", email).addClass(
                        "wsInteractiveSidebar__pinContactInfoText"
                    );
                    uiEmailCont.addChild(uiEmailHeader);
                    uiEmailCont.addChild(uiEmail);
                    uiAdvancedCont.addChild(uiEmailCont);
                }
                if (websiteLabel && websiteLink) {
                    const uiWebsiteCont = new UIPanel().addClass(
                        "wsInteractiveSidebar__pinContactInfoCont"
                    );
                    const uiWebsiteHeader = new UIText("", "Website:").addClass(
                        "wsInteractiveSidebar__pinInfoContactHeader"
                    );
                    const uiWebsite = new UIText("", `${websiteLabel}`)
                        .addClass("wsInteractiveSidebar__pinWebsiteInfoText")
                        .onClick(() => {
                            window.open(websiteLink, "_blank");
                        });
                    uiWebsiteCont.addChild(uiWebsiteHeader);
                    uiWebsiteCont.addChild(uiWebsite);
                    uiAdvancedCont.addChild(uiWebsiteCont);
                }
            }
            uiAdvancedCont.dom.hasChildNodes() &&
                this.pinInfoContainer.addChild(uiAdvancedCont);
        }
    };

    refreshUI = (locationPin) => {
        this.selectedLocationPin = locationPin;
        this.updatePinInfo();
    };
}

export { SidebarPinInfo };
