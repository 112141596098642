import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from "../../api";
import ServerThreeDy from "../../api/threedyServer";
import { HandleExceptionWithSecuredCatch } from "../CombineCatch";

// ThreeDy!
import axios from "axios";
import { threeDyBlob, threeDyPutBlob } from "../../values/threedyBlob";
import { REACT_APP_THREEDY_AUTH_URL } from "../../values/constants";

const uploadAsset = createAsyncThunk(
  "common/uploadAsset",
  async ({ reqObj }, { dispatch }) => {
    try {
      console.log(reqObj);
      const response = await Server.post("/v1/asset/upload", reqObj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const deleteAsset = createAsyncThunk(
  "common/deleteAsset",
  async (params, { dispatch }) => {
    try {
      const response = await Server.post("/v1/asset/delete", params);
      return response.data;
    } catch (e) {
      return e.response.data;
    }
  }
);

const getThreeDyAccessToken = createAsyncThunk(
  "common/getThreeDyAccessToken",
  async (params, { dispatch }) => {
    try {
      const axiosInstance = axios.create({
        baseURL: REACT_APP_THREEDY_AUTH_URL,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const response = await axiosInstance.post("oauth/token/", threeDyBlob);
      localStorage.setItem("threeDyToken", response.data.access_token);
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getThreeDyModelAssets = createAsyncThunk(
  "common/getThreeDyModelAssets",
  async (params, { dispatch }) => {
    try {
      const response = await ServerThreeDy.put(
        "/full_jobs/list_via_put/",
        threeDyPutBlob
      );
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getPlansList = createAsyncThunk(
  "adminanalytics/getPlansList",
  async (_, { dispatch }) => {
    try {
      const response = await Server.get("/v1/plan");
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getSampleFloors = createAsyncThunk(
  "common/getSampleFloors",
  async (_, { dispatch }) => {
    try {
      const response = await Server.get(
        "/v1/location-pins/amenity-types?type=floorplan"
      );
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const generateQRFile = createAsyncThunk(
  "common/generateQRFile",
  async (params, { dispatch }) => {
    try {
      const response = await Server.get("/v1/utils/generateQRFile", { params: params, responseType: "arraybuffer"} );
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }

);

const initialState = {
  commonLog: null,
  uploadData: null,
  isThumbnailUploading: false,
  thumbnailData: null,
  isFloorplanUploading: false,
  floorplanData: null,
  isOverviewFileUploading: false,
  overviewData: null,
  activeNavIndex: 0,
  threeDyAssets: [],
  plansList: null,
  sampleFloors: null,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    resetState: () => {
      return { ...initialState };
    },
    retainNavState: (state, action) => {
      return { ...state, activeNavIndex: action.payload };
    },
    resetCommonLog: (state, action) => {
      return { ...state, commonLog: null, uploadData: null };
    },
    resetMapDetailsTab: (state, action) => {
      return {
        ...state,
        isThumbnailUploading: false,
        thumbnailData: null,
        isFloorplanUploading: false,
        floorplanData: null,
        isOverviewFileUploading: false,
        overviewData: null,
      };
    },
    resetGeneratedBlob: (state, action) => {
      return {
        ...state,
        generatedBlob: null,
      };
    },
  },
  extraReducers: {
    [uploadAsset.fulfilled]: (state, action) => {
      const args = action.meta.arg;
      const { assetType } = args;
      return {
        ...state,
        ...(assetType === "file"
          ? {
              isOverviewFileUploading: false,
              overviewData: action.payload.data,
            }
          : assetType === "thumbnail"
          ? { isThumbnailUploading: false, thumbnailData: action.payload.data }
          : {
              isFloorplanUploading: false,
              floorplanData: {
                ...action.payload.data,
                name: args.reqObj.get("file").name,
              },
            }),
        commonLog: action.payload.status,
      };
    },
    [uploadAsset.pending]: (state, { meta }) => {
      let { reqObj, ...uploadFlag } = meta.arg;
      return {
        ...state,
        ...uploadFlag,
      };
    },
    [deleteAsset.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        uploadData: null,
        commonLog: payload.status,
      };
    },
    [deleteAsset.rejected]: (state, { payload }) => {
      console.log(payload);
      return state;
    },
    [getThreeDyAccessToken.fulfilled]: (state, { payload }) => {
      return state;
    },
    [getThreeDyModelAssets.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        threeDyAssets: payload.results,
      };
    },
    [getPlansList.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        plansList: payload.data,
        analyticsLog: null,
      };
    },
    [getSampleFloors.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        sampleFloors: payload.data,
      };
    },
    [generateQRFile.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        generatedBlob: payload,
      };
    },
  },
});

export const {
  resetState,
  retainNavState,
  resetCommonLog,
  resetMapDetailsTab,
  resetGeneratedBlob,
} = commonSlice.actions;

export const isThumbnailUploading = (state) =>
  state?.common?.isThumbnailUploading;
export const isFloorplanUploading = (state) =>
  state?.common?.isFloorplanUploading;
export const isOverviewFileUploading = (state) =>
  state?.common?.isOverviewFileUploading; 

export const thumbnailData = (state) => state?.common?.thumbnailData;
export const floorplanData = (state) => state?.common?.floorplanData;
export const overviewData = (state) => state?.common?.overviewData;
export const commonLog = (state) => state?.common?.commonLog;

export {
  uploadAsset,
  deleteAsset,
  getThreeDyAccessToken,
  getThreeDyModelAssets,
  getPlansList,
  getSampleFloors,
  generateQRFile,
};

export default commonSlice.reducer;
