import EventEmitter from "../../utils/EventEmitter";
import { UIPanel, UIImage, UIText, UICheckbox } from "../ui.lib";

import chevronIcon from "../../../../assets/svgs/scenes/chevron.svg";
import { detectMobile } from "../../../_utils/detectBrowser";

class LevelSelector extends EventEmitter {
    constructor(editor) {
        super();

        this.editor = editor;
        this.dom = null;

        this.venueMaps = [];
        this.mapsData = null;
        this.selectedLevel = null;
        this.checkList = [];

        this.names = {
            floors: "Floor",
            levels: "Level",
            zones: "Zone",
            areas: "Area",
            sections: "Section",
            parking: "Parking",
        };

        this.initUI();

        this.editor.on("updateLevelsVisibility", this.onUpdateLevelsVisibility);
        this.editor.on("venueDataFetched", this.onVenueDataFetched);
        this.editor.resources.on("ready", this.detectBrowserandAddEvents);
        this.editor.on("requestSceneSwitch", this.handleLevelChange);
        this.editor.on("updateLevelSelector", this.handleLevelChange);
    }

    initUI = () => {
        this.uiContainer = new UIPanel().addClass("wsInteractiveLevelSelector");

        this.levelSelector = new UIPanel().setClass(
            "wsInteractiveLevelSelector__levelSelector"
        );
        const innerSelectorLayer = new UIPanel().setClass(
            "wsInteractiveLevelSelector__levelSelectorInner"
        );
        this.levelText = new UIText("", "Level 1").setClass(
            "wsInteractiveLevelSelector__levelText"
        );
        innerSelectorLayer.addChild(this.levelText);
        this.levelSelectorIcon = new UIImage(
            chevronIcon,
            "18px",
            "18px"
        ).setClass("wsInteractiveLevelSelector__levelIcon");
        innerSelectorLayer.addChild(this.levelSelectorIcon);

        this.levelSelector.addChild(innerSelectorLayer);

        this.levelSelector.onClick(() => {
            if (this.levelList.dom.style.display === "none") {
                this.levelList.setDisplay("");
                this.levelSelectorIcon.dom.style.transform = "rotate(180deg)";
            } else {
                this.levelList.setDisplay("none");
                this.levelSelectorIcon.dom.style.transform = "rotate(0deg)";
            }
        });

        this.uiContainer.addChild(this.levelSelector);

        // level drop list
        this.levelList = new UIPanel().setClass(
            "wsInteractiveLevelSelector__levelListContainer"
        );
        this.levelList.setDisplay("none");

        this.uiContainer.addChild(this.levelList);

        this.dom = this.uiContainer;
    };

    emptyContent = () => {
        var child = this.levelList.dom.lastChild;
        while (child) {
            this.levelList.dom.removeChild(child);
            child = this.levelList.dom.lastChild;
        }
    };

    renderLevelList = () => {
        this.emptyContent();

        if (this.venueMaps.length) {
            this.venueMaps.forEach((level, index) => {
                if (level.mapId === this.editor.activeMap) {
                    this.levelText.setTextContent(
                        `${
                            detectMobile()
                                ? this.names[level.mapNamingType][0]
                                : `${this.names[level.mapNamingType]} `
                        }${level.identifier}`
                    );
                }

                const levelItem = new UIPanel().setClass(
                    "wsInteractiveLevelSelector__levelItem"
                );
                var uiCheckCont = new UIPanel().addClass(
                    "wsInteractiveCheckCont"
                );
                const checbox = new UICheckbox(
                    this.editor.activeMap === level.mapId
                ).onChange((checked) => {
                    if (checked) {
                        this.handleLevelChange(level.mapId);
                    }
                });
                uiCheckCont.addChild(checbox);
                const levelText = new UIText(
                    "",
                    `${level.mapNamingType[0].toUpperCase()}${
                        level.identifier
                    } - ${level.mapName}`
                ).setClass("wsInteractiveLevelSelector__levelItemText");
                levelItem.addChild(uiCheckCont);
                levelItem.addChild(levelText);

                this.checkList.push({
                    id: level.mapId,
                    checkbox: checbox,
                });
                levelItem.onClick( () => {
                    if(this.editor.navigationTracking) {
                        this.editor.trigger("navigationPreview", [false]);
                    }
                    this.handleLevelChange(level.mapId);
                }
                );

                this.levelList.addChild(levelItem);
            });
        }
    };

    onUpdateLevelsVisibility = (isVenue) => {
        if (!isVenue) {
            this.uiContainer.setDisplay("none");
        } else {
            this.uiContainer.setDisplay("");
        }
    };

    onVenueDataFetched = (mapsData) => {
        if (this.editor.venueDetails) {
            this.venueMaps = this.editor.venueDetails.maps;
            this.mapsData = mapsData;
            this.renderLevelList();
        }
    };

    handleLevelChange = (mapId) => {
        this.checkList.forEach((level) => {
            if (level.id === mapId) {
                level.checkbox.setValue(true);
            } else {
                level.checkbox.setValue(false);
            }
        });

        this.editor.activeMap = mapId;
        const level = this.venueMaps.find((map) => map.mapId === mapId);
        this.levelText.setTextContent(
            `${
                detectMobile()
                    ? this.names[level.mapNamingType][0]
                    : `${this.names[level.mapNamingType]} `
            }${level.identifier}`
        );

        this.editor.trigger("updateMapVisibility", [mapId]);
        setTimeout(() => {
            this.levelList.setDisplay("none");
            this.levelSelectorIcon.dom.style.transform = "rotate(0deg)";
        }, 200);
    };

    detectBrowserandAddEvents = () => {
        if (detectMobile()) {
            if (this.venueMaps.length) {
                const level = this.venueMaps.find(
                    (map) => map.mapId === this.editor.activeMap
                );
                this.levelText.setTextContent(
                    `${this.names[level.mapNamingType][0]} ${level.identifier}`
                );
            } else {
                this.levelText.setTextContent(`L1`);
            }
        }
    };
}

export { LevelSelector };
