import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    getBuildingDetails,
    getLocationPins,
    getMapDetails,
    getVenueMapsDetails,
    resetStore,
    searchDirectionBetweenPins,
} from "../../features/interactivemap/interactivemapSlice";
import InteractiveExperience from "./InteractiveExperience";
import { isEqual, size } from "lodash";

import { Floatingbar } from "./ui/floatingbar/Floatingbar.js";
import { Sidebar } from "./ui/sidebar/Sidebar.js";
import { ActionButtons } from "./ui/buttons/Buttons.js";
import ARModal from "./interactivecommon/ARModal.js";

export default function InteractiveMapsHome() {
    const params = useParams();
    const dispatch = useDispatch();
    const mapCode = params.mapCode;

    const [fetching, setFetching] = useState(true);
    const [mapDetails, setMapDetails] = useState(null);
    const [venueDetails, setVenueDetails] = useState(null);
    const [mapsPins, setMapsPins] = useState(null);
    const [venueMaps, setVenueMaps] = useState([]);

    const [showARModal, setShowARModal] = useState(false);
    const [modalData, setModalData] = useState({});

    const mapDetailsSelector = useSelector(
        (state) => state.interactivemap.mapDetails
    );
    const locationSelector = useSelector(
        (state) => state.interactivemap.locationPins
    );

    const venueDetailsSelector = useSelector(
        (state) => state.interactivemap.venueDetails
    );

    const venueMapListSelector = useSelector(
        (state) => state.interactivemap.venueMapList
    );

    const venueMapsDetailsSelector = useSelector(
        (state) => state.interactivemap.venueMapsDetails
    );

    const pathRoute = useSelector((state) => state.interactivemap.pathRoute);

    const editor = useRef(null);
    const floatingbar = useRef(null);
    const sidebar = useRef(null);
    const actionButtons = useRef(null);

    const mapMeta = useRef({
        mapId: null,
        mapCode: null,
        buildingId: null,
        maps: [],
        mapsData: [],
    });

    useEffect(() => {
        mapMeta.current.mapCode = mapCode;
        editor.current !== null && editor.current.clearAndReset();
        editor.current = null;

        dispatch(resetStore());
        fetchMapDetails();
        initiateEditor();

        return () => {
            dispatch(resetStore());
            editor.current?.clearAndReset();
            editor.current = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initiateEditor = () => {
        const CanvasElement = document.getElementById("interactive-canvas");
        const LabelsElement = document.getElementById("interactiveLabels");
        editor.current = new InteractiveExperience(
            mapDetails,
            mapCode,
            "ARway-Map",
            CanvasElement,
            {
                loadingComplete: onEditorLoadingComplete,
                onRequestPathBetweenPins: fetchPathBetweenPins,
                onToggleARModal: toggleARModal,
            },
            LabelsElement
        );

        floatingbar.current === null &&
            (floatingbar.current = new Floatingbar({
                dom: document.getElementById("interactiveStudioFloatingbar"),
            }));

        sidebar.current === null &&
            (sidebar.current = new Sidebar({
                dom: document.getElementById("interactiveStudioSidebar"),
            }));

        actionButtons.current === null &&
            (actionButtons.current = new ActionButtons({
                dom: document.getElementById("interactiveStudioActionButtons"),
            }));
    };

    const fetchMapDetails = () => {
        dispatch(getMapDetails(mapCode));
    };

    const onEditorLoadingComplete = () => setFetching(false);

    const fetchPathBetweenPins = (destinationId, sourcePosition, mapId) => {
        // console.log("fetchPathBetweenPins", destinationId, sourcePosition);
        dispatch(
            searchDirectionBetweenPins({
                mapId,
                reqObj: {
                    locationId: destinationId,
                    startPosition: sourcePosition,
                    pathType: "",
                },
            })
        );
    };

    const toggleARModal = (data) => {
        setModalData(data);
        setShowARModal(true);
    };

    //useEffects
    useEffect(() => {
        if (mapDetailsSelector && !isEqual(mapDetailsSelector, mapDetails)) {
            setMapDetails(mapDetailsSelector);
            mapMeta.current.mapId = mapDetailsSelector.metadata.mapId;
            if (
                mapDetailsSelector.metadata.buildingId &&
                size(mapDetailsSelector.metadata.buildingId) > 0
            ) {
                mapMeta.current.buildingId =
                    mapDetailsSelector.metadata.buildingId;
                dispatch(getBuildingDetails(mapMeta.current.buildingId));
            } else {
                mapMeta.current.buildingId = null;
                dispatch(
                    getLocationPins({
                        mapId: mapMeta.current.mapId,
                        type: "map",
                    })
                );
            }
            editor.current !== null &&
                editor.current.initResourceLoading(
                    mapDetailsSelector,
                    mapMeta.current.buildingId
                );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapDetailsSelector]);

    useEffect(() => {
        if (pathRoute) {
            editor.current !== null &&
                editor.current.onPathBetweenPinsResponse(pathRoute);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathRoute]);

    useEffect(
        () => {
            if (
                venueDetailsSelector &&
                !isEqual(venueDetailsSelector, venueDetails)
            ) {
                // console.log("venueDetailsSelector", venueDetailsSelector, venueMapListSelector);
                setVenueDetails(venueDetailsSelector);
                mapMeta.current.maps = venueMapListSelector;
                editor.current !== null &&
                    editor.current.setVenueDetails(venueDetailsSelector);
                if (venueMapListSelector.length) {
                    dispatch(
                        getVenueMapsDetails(
                            venueMapListSelector.map((map) => map.mapCode)
                        )
                    );
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [venueDetailsSelector]
    );

    useEffect(
        () => {
            if (
                venueMapsDetailsSelector &&
                venueMapsDetailsSelector.length &&
                !isEqual(venueMapsDetailsSelector, venueMaps)
            ) {
                setVenueMaps(venueMapsDetailsSelector);
                mapMeta.current.mapsData = venueMapsDetailsSelector.map(
                    (map) => ({ metadata: map })
                );
                dispatch(
                    getLocationPins({
                        mapId: mapMeta.current.buildingId,
                        type: "building",
                    })
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [venueMapsDetailsSelector]
    );

    useEffect(() => {
        if (locationSelector && !isEqual(locationSelector, mapsPins)) {
            if (size(mapMeta.current.buildingId)) {
                mapMeta.current.mapsData = mapMeta.current.mapsData.map(
                    (map) => ({
                        ...map,
                        pins:
                            locationSelector?.locationPins.filter(
                                (pin) => pin.mapId === map.metadata.mapId
                            ) || [],
                    })
                );
                editor.current !== null &&
                    editor.current.loadExtraResources(
                        locationSelector.locationPins.filter(
                            (pin) => pin.mapId === mapMeta.current.mapId
                        ) || []
                    );
                editor.current !== null &&
                    editor.current.setLocationPins(
                        locationSelector.locationPins
                    );
                editor.current !== null &&
                    editor.current.loadVenueMaps(mapMeta.current.mapsData);
            } else {
                editor.current !== null &&
                    editor.current.loadExtraResources(
                        locationSelector.locationPins || []
                    );
            }
            setMapsPins(locationSelector);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationSelector]);

    return (
        <React.Fragment>
            <div
                className="interactiveMaps__loaderContainer"
                style={fetching ? { display: "flex" } : { display: "none" }}
            >
                <div className="interactiveMaps__loaderIconsContainer">
                    <span className="interactiveMaps__loaderLogo"></span>
                    <span className="interactiveMaps__loaderCircle"></span>
                </div>
                <span className="interactiveMaps__loaderText">
                    Opening Interactive Map . . .
                </span>
            </div>
            <div style={fetching ? { display: "none" } : { display: "flex" }}>
                <div
                    className="interactiveMaps"
                    style={fetching ? { display: "none" } : { display: "flex" }}
                >
                    <div className="interactiveMaps__wsSecondRow">
                        <div className="interactiveMaps__wsCanvasContainer">
                            <canvas id="interactive-canvas"></canvas>
                        </div>
                    </div>
                    <div id="interactiveStudioFloatingbar"></div>
                    <div id="interactiveStudioSidebar"></div>
                    <div id="interactiveStudioActionButtons"></div>
                    <div id="interactiveLabels"></div>
                </div>
            </div>

            <ARModal
                openModal={showARModal}
                onCloseModal={() => setShowARModal(false)}
                modalData={modalData}
            />
        </React.Fragment>
    );
}
