import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from "../../api/noAuthServer";
import { HandleExceptionWithSecuredCatch } from "../CombineCatch";

const getMapDetails = createAsyncThunk(
    "interactiveMaps/getMapDetails",
    async (mapCode, { dispatch }) => {
        try {
            const response = await Server.get(`v1/public/map/${mapCode}`);
            return response.data;
        } catch (e) {
            return dispatch(HandleExceptionWithSecuredCatch(e));
        }
    }
);

const getBuildingDetails = createAsyncThunk(
    "interactiveMaps/getBuildingDetails",
    async (buildingId, { dispatch }) => {
        try {
            const response = await Server.get(`v1/building/${buildingId}`);
            return response.data;
        } catch (e) {
            return dispatch(HandleExceptionWithSecuredCatch(e));
        }
    }
);

const getLocationPins = createAsyncThunk(
    "interactiveMaps/getLocationPins",
    async ({mapId, type}, { dispatch }) => {
        try {
            const response = await Server.get(`/v1/map/locationpin/${mapId}?type=${type}`);
            return response.data;
        } catch (e) {
            return dispatch(HandleExceptionWithSecuredCatch(e));
        }
    }
);

const searchLocationpins = createAsyncThunk(
    "interactiveMaps/searchLocationpins",
    async ({ mapId, pageNumber, searchText, mapType }, { dispatch }) => {
        const url = `/v1/map/locationpin/${mapId}?limit=10&page=${pageNumber}&q=${encodeURIComponent(
            searchText
        )}&type=${mapType}`;
        try {
            const response = await Server.get(url);
            return response.data;
        } catch (e) {
            return dispatch(HandleExceptionWithSecuredCatch(e));
        }
    }
);

const searchDirectionBetweenPins = createAsyncThunk(
    "interactiveMaps/searchDirectionBetweenPins",
    async ({ mapId, reqObj }, { dispatch }) => {
        try {
            const response = await Server.post(
                `/v2/map/locationpin/path/${mapId}/search`,
                reqObj
            );
            return response.data;
        } catch (e) {
            return dispatch(HandleExceptionWithSecuredCatch(e));
        }
    }
);

const getVenueMapsDetails = createAsyncThunk(
    "interactiveMaps/getVenueMapsDetails",
    async (mapCodes, { dispatch }) => {
        try {
            const responses = await Promise.all(
                mapCodes.map((id) => Server.get(`v1/public/map/${id}`))
            );
            return responses.map((res) => res.data.data.metadata);
        } catch (e) {
            return dispatch(HandleExceptionWithSecuredCatch(e));
        }
    }
);

const initialState = {
    isLoading: false,
    locationPins: null,
    searchedLocationPins: [],
    mapDetails: null,
    venueMapsDetails: [],
    pathRoute: null,
    venueDetails: null,
    venueMapList: [],
};

const locationSlice = createSlice({
    name: "building",
    initialState,
    reducers: {
        resetStore: () => {
            return { ...initialState, locationPins: null };
        },
        resetLocationPins: () => {
            return { ...initialState };
        },
        clearSearchedLocationPins: (state) => {
            return { ...state, searchedLocationPins: null };
        },
    },
    extraReducers: {
        "common/resetState": () => {
            return { ...initialState };
        },
        [getMapDetails.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                mapDetails: payload.data,
                isLoading: false,
            };
        },
        [getLocationPins.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                locationPins: payload.data,
                isLoading: false,
            };
        },
        [searchLocationpins.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                searchedLocationPins: payload.data,
                isLoading: false,
            };
        },
        [searchDirectionBetweenPins.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                pathRoute: payload.data,
                isLoading: false,
            };
        },
        [getBuildingDetails.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                venueDetails: payload.data,
                venueMapList: payload.data.maps,
                isLoading: false,
            };
        },
        [getVenueMapsDetails.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                venueMapsDetails: payload,
            };
        },
    },
});

export {
    getMapDetails,
    getBuildingDetails,
    getLocationPins,
    searchLocationpins,
    searchDirectionBetweenPins,
    getVenueMapsDetails,
};

export const { resetStore, resetLocationPins, clearSearchedLocationPins } =
    locationSlice.actions;

export default locationSlice.reducer;
