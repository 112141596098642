import * as THREE from "three";
//Comp
import EditorExperience from "../EditorExperience";
import GSAP from "gsap";
import { OrbitControls } from "./OrbitControls";

export default class Camera {
    constructor() {
        this.editor = new EditorExperience();
        this.sizes = this.editor.sizes;
        this.canvas = this.editor.canvas;
        this.scene = this.editor.scene;

        // !Orbit Modes: [select, pan, rotate]. select and rotate are basically same thing
        this.orbitMode = "select";

        this.animTimer = 2;

        this.initInstance();
        this.initOrbitControls();

        this.editor.on("orbitInteractionModeChanged", this.onOrbitModeChanged);
        this.editor.on(
            "cameraInteractionModeChanged",
            this.onCameraModeChanged
        );
    }

    initInstance = () => {
        this.instance = new THREE.PerspectiveCamera(
            35,
            this.sizes.width / this.sizes.height,
            0.1,
            2000
        );
        this.instance.position.set(8, 6, 8);
        this.instance.name = "ARway-Camera";
        this.instance.lookAt(this.scene.position);
        this.scene.add(this.instance);
    };

    initOrbitControls = () => {
        this.controls = new OrbitControls(this.instance, this.canvas);
        this.controls.enableDamping = true;
        this.controls.rotateSpeed = 0.8;
        this.controls.zoomSpeed = 0.8;
        this.controls.panSpeed = 0.8;
        this.controls.dampingFactor = 0.1;
        this.controls.addEventListener("change", this.handleControlsChanged);
    };

    resize = () => {
        this.instance.aspect = this.sizes.width / this.sizes.height;
        this.instance.updateProjectionMatrix();
    };

    relocateCamera = (target) => {
        // Calculate Bounds!
        const box = new THREE.Box3().setFromObject(target);
        const center = box.getCenter(new THREE.Vector3());
        const size = box.getSize(new THREE.Vector3());

        this.controls.enabled = false;

        let distance = center.z;
        if (distance < 0) {
            distance -= size.z;
            distance -= 2;
        } else {
            distance += size.z;
            distance += 2;
        }

        GSAP.to(this.instance.position, {
            duration: this.animTimer,
            x: center.x,
            y: center.y,
            z: distance,
            onUpdate: () => {
                this.instance.lookAt(target.position);
            },
        });

        GSAP.to(this.controls.target, {
            duration: this.animTimer,
            x: center.x,
            y: center.y,
            z: center.z,
            onComplete: () => {
                this.controls.enabled = true;
            },
        });
    };

    setZoomLimits = (box3) => {
        const bSize = box3.getSize(new THREE.Vector3());

        const maxSize = Math.max(bSize.x, bSize.y, bSize.z);
        const fitHeightDistance =
            maxSize / (2 * Math.atan((Math.PI * this.instance.fov) / 360));
        const fitWidthDistance = fitHeightDistance / this.instance.aspect;

        let maxDistance = 2 * Math.max(fitHeightDistance, fitWidthDistance);

        // for smaller make the max distance 10 units
        if(maxDistance < 20) {
            maxDistance = 20
        }

        this.editor.controlBounds.maxDistance = maxDistance;
        this.controls.maxDistance = maxDistance;
        this.controls.minDistance = 0;

        this.update();
    };

    handleControlsChanged = () => {
        if (
            this.controls.getDistance().toFixed(2) !==
            this.editor.controlBounds.currentDistance
        ) {
            this.editor.controlBounds.currentDistance = this.controls
                .getDistance()
                .toFixed(2);
            this.editor.trigger("orbitControlsChanged");
        }
    };

    update = () => {
        this.controls.update();
    };

    onOrbitModeChanged = (mode) => {
        if (mode === "pan") {
            this.controls.mouseButtons = {
                LEFT: THREE.MOUSE.PAN,
                MIDDLE: THREE.MOUSE.DOLLY,
                RIGHT: THREE.MOUSE.ROTATE,
            };
        } else if (mode === "navTracking") {
            this.controls.mouseButtons = {
                LEFT: undefined,
                MIDDLE: THREE.MOUSE.DOLLY,
                RIGHT: undefined,
            };
        } else if (mode === "navPaning") {
            this.controls.mouseButtons = {
                LEFT: THREE.MOUSE.PAN,
                MIDDLE: THREE.MOUSE.DOLLY,
                RIGHT: undefined,
            };
        } else {
            this.controls.mouseButtons = {
                LEFT: THREE.MOUSE.ROTATE,
                MIDDLE: THREE.MOUSE.DOLLY,
                RIGHT: THREE.MOUSE.PAN,
            };
        }
        this.update();
    };

    onCameraModeChanged = (mode) => {
        if (mode === "zoomSelect") {
            this.controls.enabled = false;
        } else {
            this.controls.enabled = true;
        }
    };
}
