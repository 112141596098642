import ComputeDirections from "./ComputeDirections";


export default class ComputeMultiDirections {

    constructor(paths, scenes) {
        this.paths = paths.map((path, index) => ({...path, level: index})) || [];
        this.scenes = scenes || [];
        this.directions = [];
        this.levels = paths.length || 0;

        this.connectorTypes = {
            "stairs": "STAIRS",
            "elevator": "ELEVATOR",
            "escalator": "ESCALATOR",
            "bridge": "BRIDGE",
        }

        this.computeDirections();

    }

    computeDirections = () => {
        for (var i = 0; i < this.levels; i++) {
            const direction = new ComputeDirections(this.paths[i].path);
            this.directions.push({
                mapId: this.paths[i].mapId,
                directions: direction,
                sourcePin: this.paths[i].sourcePin,
                destinationPin: this.paths[i].destinationPin,
            });
        }

        // combine steps and add coreect data
        this.combineSteps();
        this.calculateNavigationDuration();
    }

    combineSteps = () => {
        let combinedSteps = [];
        let totalDistance = 0

        this.directions.forEach((direction, index) => {
            // first one!
            if(direction.sourcePin === null && direction.destinationPin !== null) {
                combinedSteps.push(...direction.directions.directionList);
                totalDistance += direction.directions.totalDistance;
                
                if(direction.destinationPin && index < this.levels - 1) {
                    const connector = this.scenes[direction.mapId].connectors.find(connector => connector.id === direction.destinationPin);
                    if(connector) {
                        combinedSteps.push({
                            turnText: `${connector.connectorType === "stairs" ? "Take" : "Enter"} ${connector.connectorType}  ${connector.name}`,
                            turnAngle: 0,
                            position: null,
                            directionType: this.connectorTypes[connector.connectorType],
                            turnIndex: null,
                        });
                    }
                }
            } else if( direction.sourcePin !== null && direction.destinationPin !== null && index < this.levels - 1 ) { // middle ones
                const connectorSource = this.scenes[direction.mapId].connectors.find(connector => connector.id === direction.sourcePin);
                if(connectorSource) {
                    combinedSteps.push({
                        turnText: `Exit ${connectorSource.connectorType} & scan access point`,
                        turnAngle: 0,
                        position: null,
                        directionType: this.connectorTypes[connectorSource.connectorType],
                        turnIndex: null,
                    });
                }
                combinedSteps.push(...direction.directions.directionList);
                totalDistance += direction.directions.totalDistance;

                const connectorDestination = this.scenes[direction.mapId].connectors.find(connector => connector.id === direction.destinationPin);
                if(connectorDestination) {
                    combinedSteps.push({
                        turnText: `${connectorDestination.connectorType === "stairs" ? "Take" : "Enter"} ${connectorDestination.connectorType}  ${connectorDestination.name}`,
                        turnAngle: 0,
                        position: null,
                        directionType: this.connectorTypes[connectorDestination.connectorType],
                        turnIndex: null,
                    });
                }
            } else if( direction.sourcePin !== null && direction.destinationPin !== null ) { // last one
                const connector = this.scenes[direction.mapId].connectors.find(connector => connector.id === direction.sourcePin);
                if(connector) {
                    combinedSteps.push({
                        turnText: `Exit ${connector.connectorType} & scan access point`,
                        turnAngle: 0,
                        position: null,
                        directionType: this.connectorTypes[connector.connectorType],
                        turnIndex: null,
                    });
                }
                combinedSteps.push(...direction.directions.directionList);
                totalDistance += direction.directions.totalDistance;
            }
        })

        this.directionList = combinedSteps;
        this.totalDistance = totalDistance;
    }

    calculateNavigationDuration = () => {
        let durationInSeconds = this.totalDistance / 0.7;
        this.navigationDuration =
            this.convertSecondsToMinutes(durationInSeconds);
    };

    convertSecondsToMinutes = (seconds) => {
        const mins = Math.ceil(seconds / 60);
        return `${mins} ${mins === 1 ? "min" : "mins"}`;
    };
}