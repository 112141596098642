const prod = process.env.REACT_APP_NODE_ENV === "production";
const stg = process.env.REACT_APP_NODE_ENV === "staging";

export const REACT_APP_MAP_APIS_BASEURL = prod
    ? "https://maps-api.nextechar.com/"
    : stg ? "https://stg-maps-api.nextechar.com/"
        : "https://dev-maps-api.nextechar.com/";

export const REACT_APP_MAPBOX_ACCESS = prod 
    ? "pk.eyJ1IjoibmlraGlsLW5leHRlY2giLCJhIjoiY2t6d2JwYzJsMXR0bTJ3bzlsMHlvdnp1NyJ9.H6wu_XoljU9VtzX_lSzkNA"
    : stg ? "pk.eyJ1IjoibmlraGlsLW5leHRlY2giLCJhIjoiY2t6czMxeG96MHBwbjJ1b2h5cnk3MzZleCJ9._KPzZms-KX8zNPTcku9QtA"
    : "pk.eyJ1IjoibmlraGlsLW5leHRlY2giLCJhIjoiY2t6czMxeG96MHBwbjJ1b2h5cnk3MzZleCJ9._KPzZms-KX8zNPTcku9QtA"


export const REACT_APP_THREEDY_BASEURL = 'https://portal.threedy.ai/api/';
export const REACT_APP_THREEDY_AUTH_URL = 'https://auth.threedy.ai/';