import * as THREE from "three";
import { isEmpty } from "lodash";
import { TransformControls } from "./utils/TransformControls.js";


import EventEmitter from "./utils/EventEmitter";
import WorldMain from "./components/World.Main";
import Camera from "./utils/Camera";
import Renderer from "./utils/Renderer";
import Resizer from "./utils/Resizer";
import Time from "./utils/Time";
import KeyEvents from "./utils/KeyEvents";
import ObjectsLoader from "./utils/ObjectsLoader";
import ZoomSelector from "./utils/ZoomSelector";
import { AutoSave } from "./utils/AutoSave";
import { Strings } from "./utils/Strings";
import { Loader } from "./utils/Loader";
import { History } from "./utils/History";
import { NetworkServices } from "./utils/Network.Utils";
import LocationPinHelper from "./threeUtils/LocationPinHelper";


//Transform Helpers
import {
  fromVec3PosToPosObject,
  fromVec3ScaleToScaleObject,
  fromQuaternionToQuaternionObject,
  getRandomIdCode,
  fromPosObjectToVec3Pos,
  getObjectByUserDataProperty,
} from "./threeUtils/TransformConversions";

import { Line2 } from "three/examples/jsm/lines/Line2";

//2d Editor
import EditorExperience2D from "./2d-editor/2DEditorExperience";
import { Menu2D } from "./2d-editor/ui/menu/2DMenu";
import { Toolbar2D } from "./2d-editor/ui/toolbar/2DToolbar";
import GuidedTourController from "./threeUtils/GuidedTour/GuidedTourController";

import { Sidebar2D } from "./2d-editor/ui/sidebar/Sidebar2D";

// singleton object
let instance = null;
export default class EditorExperience extends EventEmitter {
  constructor(
    mapDetails,
    buildingDetails,
    mapCode,
    mapId,
    amenityTypes,
    categories,
    planDetails,
    sceneName,
    canvas,
    callbacks
  ) {
    super();
    if (instance) return instance;

    instance = this;

    this.mapData = mapDetails;
    this.mapCode = mapCode;
    this.buildingData = buildingDetails;
    this.venueDetails = null;
    this.mapId = mapId;
    this.activeMap = mapId;
    this.amenityTypes = amenityTypes;
    this.categories = categories;
    this.planDetails = planDetails;
    this.isReady = false;
    this.prevMapArea = 0;
    this.isFloorMap = false;

    this.floorVector = new THREE.Vector3(0, -0.5, 0);
    this.floorY = null;
    this.canvas = canvas;
    this.callbacks = callbacks;
    this.mapPinObject = {};
    this.anchorPinObject = {};
    this.navPinStart = {};
    this.navPinEnd = {};
    this.qrPin = {};
    this.qrMainPin = {};
    this.markerPin = {};
    this.imgAccessPin = {};
    this.imgAccessMainPin = {};
    this.floorplanPeg = {};
    this.toAutoSave = false; // TRUE: After all existing objects are loaded!
    this.inSavingState = false;
    this.sizes = new Resizer();
    this.keyEvents = new KeyEvents();
    this.time = new Time();

    this.scene = new THREE.Scene();
    this.scene.name = sceneName;
    this.camera = new Camera();
    this.renderer = new Renderer();
    this.resources = new ObjectsLoader();
    this.world = new WorldMain();
    this.strings = new Strings();
    this.loader = new Loader(this);
    this.history = new History(this);
    this.autoSaveHelper = new AutoSave();
    this.networkService = new NetworkServices(this);
    this.locPinHelper = new LocationPinHelper();
    /* Guided Tour */
    this.guidedTourController = new GuidedTourController();
    this.zoomSelector = new ZoomSelector();

    // Hotspots & AudioNote Array
    this.hotspots = [];
    this.destinations = [];
    this.waypointPins = [];
    this.raycasterObjects = [];
    this.nonInteractiveObjects = [];
    this.markers = [];
    this.uiObjects = [];

    this.jsonWayProps = [];
    this.jsonQRAnchors = [];
    this.jsonMapMarkers = [];
    this.jsonFloors = [];
    this.jsonNavPaths = [];
    this.jsonNavPins = [];

    this.selectedObject = null;
    this.previousSelected = null;

    this.isPrimaryAccessEnabled = false;
    this.toAdjustValues = false;
    this.toAdjustAccess = false;

    this.editor2D = null;
    this.floorWorldMatrix = null;
    this.passCBTo2d = false;
    this.editorTransitioning = false;
    this.switchOn2D = false;
    this.processingImportMaps = false;
    this.floorplanVersion = 2.7;

    this.floorData = {};
    this.manualAligned = "no_change";
    this.pinReadjustObj = [];
    this.pathReadjustObj = [];
    this.apiFor2dEditor = false;
    //movedPins
    this.pinMovedObj = [];
    this.movedPinsCount = 0;

    this.controlBounds = {
      maxDistance: 0,
      minDistance: 0,
      currentDistance: 0
    }

    this.sizes.on("resize", this.resize);
    this.time.on("tick", this.update);
    this.resources.on("ready", this.makeHTMLVisible);
    this.keyEvents.on("keydownEvent", this.onKeydownEvent);
    this.on("toggleAutoSaveSceneFlag", this.onToggleAutoSaveSceneFlag);
    this.on("objectChanged", this.onObjectChanged);
    this.on("sidebarSceneGraphChanged", this.onSidebarSceneGraphChanged);
    this.on("2DEditorSceneGraphChanged", this.on2DEditorSceneGraphChanged);
    this.on("fetchLibraryContentsRequest", this.onFetchLibraryContentsRequest);
    this.on(
      "fetch3DyLibraryContentsRequest",
      this.onFetch3DyLibraryContentsRequest
    );
    this.on("fetchMapVersionHistory", this.onFetchMapVersionHistory);
    this.on("toggleMenuActive", this.onToggleMenuActive);
    this.on("loadMapVersionFromHistory", this.onLoadMapVersionFromHistory);
    this.on("mountObjectLoader", this.onMountObjectLoader);
    this.on("adjustGridPosition", (posVec3) => (this.floorVector = posVec3));
    this.on("sceneAreaChanged", this.calculateSceneArea);
    this.on("initImportMapsModal", this.onInitImportMapsModal);

    // switch
    this.on("switchBetweenMaps", this.onSwitchBetweenMaps);

    this.on("requestPinPathReadjustment", this.onRequestPinPathReadjustment);
    this.on("requestUpdateMovedPins", this.onRequestUpdateMovedPins);

    // global instance
    window.experience = this;
  }

  select = (object) => {
    if (object !== null && this.selectedObject === object) return;

    this.previousSelected = this.selectedObject;
    this.selectedObject = object;

    this.trigger("objectSelected", [object]);
    // this.trigger("sidebarSceneGraphChanged", [object]);
  };

  deselect = () => {
    this.select(null);
  };

  onCommand = (command, optName) => {
    // command.execute();
    this.history.execute(command, optName);
  };

  onUndo = () => {
    this.history.undo();
  };

  onRedo = () => {
    this.history.redo();
  };

  addObjects = (object, invokeAutoSave = true) => {
    /* object.traverse((child) => {
      if (child.geometry !== undefined); // TODO: Add Geometry
      if (child.material !== undefined); // TODO: Add Material

      // TODO:Add Camera & Helper for them
    }); */
    this.scene.add(object);
    this.trigger("objectAdded", [object]);

    this.toAutoSave && this.calculateSceneArea();

    invokeAutoSave &&
      this.toAutoSave &&
      this.trigger("autoSaveSceneGraphState");
  };

  removeObject = (object, delayAutosave, isVersionSwitch = false) => {
    if (object?.parent === null) return;

    this.scene.remove(object);

    this.trigger("objectRemoved", [object, delayAutosave, isVersionSwitch]);
    !delayAutosave &&
      this.toAutoSave &&
      this.trigger("autoSaveSceneGraphState");

    if(object?.userData?.type === "floorplan") {
      this.setIsometricCamera(2, true, true);
      this.trigger("orbitControlsChanged");
    }

    if (object.userData?.type === "mapMarker") {
      for (let i = 0; i < this.markers.length; i++) {
        if (this.markers[i].mesh === object) {
          this.markers.splice(i, 1);
        }
      }
    }
  };

  initResourceLoading = (mapDetails, isFloorMap) => {
    if (!this.isReady && mapDetails) {
      const data = mapDetails.mapdata;
      console.log("REsource", mapDetails);
      this.mapData = mapDetails;
      this.mapId = this.activeMap = mapDetails.metadata.mapId;
      this.mapCode = mapDetails.metadata.mapCode;
      this.prevMapArea = this.mapData?.metadata?.mapArea || 0;
      this.isFloorMap = isFloorMap;
      this.trigger("refreshUI");
      this.trigger("refreshBuildingMapsList");
      this.guidedTourController.clearTours();
      const obj = !isEmpty(data)
        ? this.getMapContent(
            { ...data.mapContent },
            mapDetails.metadata,
            mapDetails.pins,
            mapDetails.paths
          )
        : this.getMapContent(
            {},
            mapDetails.metadata,
            mapDetails.pins,
            mapDetails.paths
          );
      this.resources.initiateLoading(obj);
    }
  };

  sortByOrder(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  setBuildingMaps = (bMaps, venueDetails) => {
    this.buildingData =
      bMaps && bMaps.length ? this.sortByOrder([...bMaps], "order") : bMaps;
    this.venueDetails = venueDetails;
    if (this.buildingData?.length) {
      this.trigger("refreshBuildingMapsList");
    }
  };

  onFetchLibraryContentsRequest = (pageNumber, contentType) => {
    this.callbacks.fetchLibraryContentsRequest(pageNumber, contentType);
  };

  onFetch3DyLibraryContentsRequest = () => {
    this.callbacks.fetch3DyLibraryContentRequest();
  };

  onFetchMapVersionHistory = () => {
    this.callbacks.fetchMapVersions();
  };

  onFetchLibraryContentsResponse = (data, contentType) => {
    this.trigger("fetchLibraryContentsResponse", [data, contentType]);
  };

  onFetch3DyLibraryContentsResponse = (data) => {
    this.trigger("fetch3DyLibraryContentsResponse", [data]);
  };

  onFetchMapVersionResponse = (versionHistory) => {
    this.trigger("fetchMapVersionHistoryResponse", [versionHistory]);
  };

  onplanDetailsFetched = (planDetails) => (this.planDetails = planDetails);

  onFetchAmenityCategoriesResponse = (amenityTypes) =>
    (this.amenityTypes = amenityTypes);

  onFetchCategoriesResponse = (categories) => {
    this.categories = categories;
    this.editor2D?.trigger("sidebarCategoryGraphChanged", [categories]);
  };

  onLoadMapVersionFromHistory = (version) => {
    if (version.id === "CURRENT_VERSION") {
      this.callbacks.loadCurrentVersion();
    } else {
      this.callbacks.loadSpecifiedVersion(version.id);
    }
    // this.removeEditor2D();
    this.onToggleAutoSaveSceneFlag(false);
    this.clearScene();
    this.isReady = false;
    this.trigger("initiateVersionSwitch");
  };

  onSuccessContinueConnector = (object) => {
    this.editor2D.trigger("continueConnectorPlacement", [object]);
  };

  onToggleEscalatorDirectionModal = () => {
    this.editor2D.trigger("toggleEscalatorDirectionModal");
  };

  onCancelConnectorPlacement = () => {
    this.editor2D.trigger("cancelConnectorPlacement");
  };

  onEscalatorDirectionSuccess = (type) => {
    this.editor2D.trigger("setConnectorDirection", [type]);
  };

  onSwitchBetweenMaps = (selectedMap, switchOn2D = false) => {
    this.activeMap = selectedMap.mapId;
    this.switchOn2D = switchOn2D;

    this.callbacks.loadSwitchedMapContent(selectedMap);
    this.onToggleAutoSaveSceneFlag(false);
    this.clearScene(true);
    this.setInitState();
    this.isReady = false;
    this.trigger("initiateMapSwitch");
  };

  onDeleteSelectedAssetSuccess = () => {
    if (this.passCBTo2d) {
      this.passCBTo2d = false;
      this.editor2D.trigger("deleteSelectedAsset");
    } else this.trigger("deleteSelectedAsset");
  };

  onFloorModalSuccess = (data) => {
    if (data.type === "delete") {
      this.trigger("deleteSelectedAsset");
    } else if (data.type === "update") {
      this.trigger("MetaObjectChanged", [
        data.object,
        data.property,
        data.value,
      ]);
    }
  };

  onSwitchMapVersionSuccess = (bSwitchFlag) => {
    if (bSwitchFlag) {
      this.trigger("handlePublishAndVersionSwitch");
    } else {
      this.trigger("handleVersionSwitch");
    }
  };

  onPublishSuccess = () => {
    this.trigger("invokeSaveMapState", ["publish"]);
  };

  onToggleInfoModals = (idx, bFlag, type) => {
    this.callbacks.onToggleInfoModals(idx, bFlag, type);
  };

  onToggleIAPModal = (bFlag, infoObj) => {
    this.callbacks.onToggleIAPModal(bFlag, infoObj);
  };

  onToggleDeleteAssetModal = (bFlag, delParams, from2D = false) => {
    this.passCBTo2d = from2D;
    this.callbacks.onToggleDeleteModal(bFlag, delParams);
  };

  onToggleFloorplanInfoModal = (bFlag, floorModalData) => {
    this.callbacks.onToggleFloorplanInfoModal(bFlag, floorModalData);
  };

  onToggleMapSwitchModal = (bFlag) => {
    this.callbacks.onToggleMapSwitchModal(bFlag, this.history.updated);
  };

  onToggleConnectorInfoModals = (idx, flag, modalData) => {
    this.callbacks.onToggleConnectorInfoModals(idx, flag, modalData);
  };

  onCancelVersionSwitch = () => {
    this.trigger("cancelVersionSwitch");
  };

  onUpdateVenueDetails = (venueDetails) => {
    this.callbacks.onUpdateVenueDetails(venueDetails);
  };

  onSampleFloorplanSuccess = (object) => {
    if (object) {
      if (object.type === "file") this.loader.loadFloorplans(object.data);
      else if (object.type === "url")
        this.loader.createFloorplan(object.data.image, object.data.name);
    }
  };

  getMapContent = (mapContent, metadata, pins = [], paths = []) => {
    if (
      metadata &&
      metadata.anchorType &&
      metadata.anchorType === "azure" &&
      metadata.mapVisual &&
      metadata.mapVisual.length > 0
    ) {
      let mapVisual = [
        {
          id: "mapVisual_001",
          name: `${metadata.mapName} vis`,
          mapName: metadata.mapName,
          anchors: metadata.anchors,
          link: metadata.mapVisual,
          position: { posX: 0, posY: 0, posZ: 0 },
          rotation: { rotX: 0, rotY: 0, rotZ: 0, rotW: 0 },
          scale: { scaX: 1, scaY: 1, scaZ: 1 },
        },
      ];
      mapContent = { ...mapContent, mapVisual };
    } else if (
      metadata &&
      metadata.anchorType &&
      metadata.anchorType === "qrAnchors" &&
      metadata.anchors &&
      metadata.anchors.length > 0
    ) {
      let qrAnchors = [
        {
          id: "qrAnchors",
          anchors: metadata.anchors,
        },
      ];
      mapContent = { ...mapContent, qrAnchors };
    }

    if (metadata.floors) {
      // console.log(metadata.floors);
      let floors = [
        {
          id: "metadata_floorplans",
          floorplans: metadata.floors,
        },
      ];
      mapContent = { ...mapContent, floors };
    }

    if (pins.length)
      pins = pins.map((p) => ({ ...p, wsType: `mapPin_${p.id}` }));
    if (paths.length)
      paths = paths.map((p) => ({ ...p, wsType: `mapPath_${p.id}` }));

    return { ...mapContent, pins, paths };
  };

  resize = () => {
    this.camera.resize();
    this.renderer.resize();
  };

  makeHTMLVisible = () => {
    console.log("READY");
    this.isReady = true;
    this.fetchExtraResources();
    this.onToggleAutoSaveSceneFlag(true, true);
    this.calculateSceneArea();
    this.trigger("historyChanged");
    this.floorY && this.trigger("repositionYOfObjects", [this.floorY]);
    !this.switchOn2D && this.callbacks.loadingComplete();

    this.setIsometricCamera(1, true);

    // load 2D Editor things
    this.load2DEditor();
    this.switchOn2D && this.on2DEditorSceneGraphChanged(this.switchOn2D);
  };

  on2DEditorReady = () => {
    if (this.switchOn2D) {
      this.switchOn2D = false;
      this.callbacks.loadingComplete();
    }
  };

  fetchExtraResources = () => {
    // get add on resources
    const extraResources = this.callbacks.getExtraResources();
    this.onFetchConnectorGroupsResponse(extraResources.groups);
    this.onFetchAmenityCategoriesResponse(extraResources.amenityCategories);
  };

  load2DEditor = () => {
    if (!this.editor2D) {
      this.editor2D = new EditorExperience2D(
        "ARway-2DEditor",
        document.getElementById("webgl-2deditor-canvas"),
        this
      );
      //UI Components
      this.menubar2d = new Menu2D({
        dom: document.getElementById("web2DEditorMenuBar"),
        amenityTypes: this.amenityTypes,
        isFloorMap: this.isFloorMap,
      });
      this.sidebar2d = new Sidebar2D({
        dom: document.getElementById("web2dEditorSideBar"),
        categories: this.categories,
      });
      this.toolbar2d = new Toolbar2D({
        dom: document.getElementById("web2dEditorBottomBar"),
      });
    }
  };

  set2DEditor = (action) => {
    const floor = this.scene.children.filter(
      (child) => child.userData.type === "floorplan"
    );
    if (floor.length && this.floorWorldMatrix) {
      const idx = this.getIndex("jsonFloors", floor[0].userData.id);
      let mat4inv = this.floorWorldMatrix.clone();
      mat4inv = mat4inv.invert();
      this.editor2D.setInitMode(action);
      this.editor2D.setFloorplanVersion(this.floorplanVersion);
      this.editor2D.loader.loadFloorplan({
        floorJson: this.jsonFloors[idx],
        worldMatrix: this.floorWorldMatrix.clone(),
        invWorldMatrix: mat4inv,
      });
      this.editor2D.initResourceLoading({
        paths: this.jsonNavPaths,
        pins: this.jsonNavPins,
        qrAnchors: this.jsonQRAnchors.filter((qr) => qr.isQrCode),
      });
    } else {
      this.callbacks.generateAlert({
        msg: "Add floor plan in map to edit location pins & paths.",
        alertType: "information",
      });
    }
  };

  exit2DEditor = (paths, pins, isSwitch) => {
    //paths setup!
    this.editorTransitioning = true;
    // console.log(paths, pins, isSwitch);
    if (isSwitch) {
      const objects = [...this.scene.children];
      objects.forEach((child) => {
        if ("interactive2D" in child.userData) {
          this.removeObject(child, true);
        }
      });
      this.jsonNavPaths = [];
      this.jsonNavPins = [];

      paths?.length &&
        paths.forEach((path) => {
          this.loader.loadNavPaths(path);
        });

      pins?.length &&
        pins.forEach((pin) => {
          this.loader.loadNavPins(pin);
        });
    }
    this.trigger("toggleMenuActive", [null]);
    this.on2DEditorSceneGraphChanged(null);
    this.editorTransitioning = false;
    this.apiFor2dEditor = false;
  };

  removeEditor2D = () => {
    const uiMenu = document.getElementById("web2DEditorMenuBar");
    const uiSide = document.getElementById("web2dEditorSideBar");
    const uiBottom = document.getElementById("web2dEditorBottomBar");

    uiMenu.removeChild(uiMenu.lastChild);
    uiSide.removeChild(uiSide.lastChild);
    uiBottom.removeChild(uiBottom.lastChild);

    // this.editor2D?.clearAndReset();
  };

  setIsometricCamera = (offset = 1, setZoomDist = false, setCamera = true) => {
    const sceneGroup = new THREE.Group();
    for (var i = 0; i < this.scene.children.length; i++) {
      const obj = this.scene.children[i];
      if (this.toClear(obj)) sceneGroup.add(obj.clone());
    }
    const box3 = new THREE.Box3().setFromObject(sceneGroup);
    setZoomDist && this.camera.setZoomLimits(box3);
    setCamera && this.trigger("setCameraAroundBox3", [box3, offset]);
  
  }

  updateFloorplanAlignedState = (flag) => {
    this.manualAligned = flag;
    this.trigger("updateFloorplanAlignedState", [flag]);
  };

  // 3D readjustment changes
  invokePathsAndPinsReAdjustment = () => {
    if (
      this.manualAligned === "3d_change" &&
      (this.jsonNavPaths.length > 0 || this.jsonNavPins.length > 0)
    ) {
      // call transformed aligned for all!
      // trigger loader
      this.trigger("mountObjectLoader", [true]);
      this.trigger("toggleLoaderProgress", ["", "Readjusting Pins & Paths..."]);

      this.pinReadjustObj = [];
      this.pathReadjustObj = [];

      this.trigger("updateObject3DPositionsWithNewFloorMatrix");
    } else if (
      this.manualAligned !== "no_change" &&
      this.jsonNavPaths.length === 0 &&
      this.jsonNavPins.length === 0
    ) {
      // nothing to change!
      this.updateFloorplanAlignedState("no_change");
    }
  };

  onRequestPinPathReadjustment = () => {
    if (
      this.pinReadjustObj.length === this.jsonNavPins.length &&
      this.pathReadjustObj.length === this.jsonNavPaths.length
    ) {
      // console.log("CALL API NOW PINS & PATHS 3D", this.pinReadjustObj, this.pathReadjustObj);
      this.callbacks.onBulkUpdatePinPathsAPIRequest({
        locationPins: this.pinReadjustObj,
        locationPaths: this.pathReadjustObj,
      });
    }
  };

  onRequestUpdateMovedPins = () => {
    if(this.pinMovedObj.length === this.movedPinsCount) {
      let delay = 0;
      const delayIncrement = 1000;
      const promises =  this.pinMovedObj.map((reqObj) => {
        delay += delayIncrement;
        return new Promise((resolve) => setTimeout(resolve, delay)).then(() => this.onPinAPICalls(reqObj, 'UPDATE'))
      });

      Promise.all(promises).then((res) => {
        this.pinMovedObj = [];
        this.movedPinsCount = 0;
      });
    }
  }

  onBulkUpdateAPIResponse = (object) => {
    // trigger loader
    if (this.manualAligned === "3d_change") {
      this.trigger("mountObjectLoader", [false]);
      this.trigger("toggleLoaderProgress", ["none"]);
      this.pinReadjustObj = [];
      this.pathReadjustObj = [];
      this.updateFloorplanAlignedState("no_change");
    } else {
      this.editor2D?.onBulkUpdateAPIResponse(object);
    }
    this.callbacks.onResetBulkUpdateStates();
  };

  calculateSceneArea = () => {
    const sceneGroup = new THREE.Group();
    for (var i = 0; i < this.scene.children.length; i++) {
      const obj = this.scene.children[i];
      if (this.toAdd(obj)) sceneGroup.add(obj.clone());
    }
    const box3 = new THREE.Box3().setFromObject(sceneGroup);
    const basePositions = [
      new THREE.Vector3(box3.min.x, box3.min.y, box3.min.z),
      new THREE.Vector3(box3.max.x, box3.min.y, box3.min.z),
      new THREE.Vector3(box3.max.x, box3.min.y, box3.max.z),
      new THREE.Vector3(box3.min.x, box3.min.y, box3.max.z),
    ];

    const d12 = basePositions[0].distanceTo(basePositions[1]);
    const d23 = basePositions[1].distanceTo(basePositions[2]);
    const newArea = d12 * d23;

    if (!newArea) return;
    if (newArea !== this.prevMapArea) {
      this.prevMapArea = newArea;
      this.callbacks.onUpdateMapArea({ mapArea: newArea });
    }

    this.setIsometricCamera(1, true, false);
    this.trigger("orbitControlsChanged");
    this.gridReposition();
  };

  gridReposition = () => {
    const sceneGroup = new THREE.Group();
    for (let i = 0; i < this.scene.children.length; i++) {
      const obj = this.scene.children[i];
      if (this.toClear(obj)) {
        sceneGroup.add(obj.clone());
      }
    }
    const box3 = new THREE.Box3().setFromObject(sceneGroup);
    this.trigger("adjustGridPosition", [box3.min]);
  };

  onKeydownEvent = (event) => {
    if (event === this.keyEvents.events.z) {
      this.onUndo();
    } else if (event === this.keyEvents.events.y) {
      this.onRedo();
    }
  };

  storageExceeds = () => {
    let bFlag = false;
    if (this.planDetails && this.planDetails?.planStorageLeft === false) bFlag = true;
    return bFlag;
  };

  onToggleAutoSaveSceneFlag = (bFlag, isInit = false) => {
    this.toAutoSave = bFlag;
    bFlag && !isInit && this.trigger("autoSaveSceneGraphState");
  };

  toggleProcessingImportMaps = (bFlag) => {
    this.processingImportMaps = bFlag;
  };

  onObjectChanged = (object, invokeAutoSave = true, c, isView = false) => {
    if (!object) return;
    !isView &&
      invokeAutoSave &&
      this.toAutoSave &&
      this.trigger("autoSaveSceneGraphState");
  };

  onSidebarSceneGraphChanged = (action) => {
    if (action === null) {
      this.callbacks.onSidebarGraphChanged("showSidebar", false);
    } else {
      this.callbacks.onSidebarGraphChanged("showSidebar", true);
    }
  };

  on2DEditorSceneGraphChanged = (action) => {
    if (action === null) {
      this.callbacks.on2DEditorSceneGraphChanged(false);
    } else {
      this.callbacks.on2DEditorSceneGraphChanged(true);
      this.set2DEditor(action);
      this.apiFor2dEditor = true;
    }
  };

  // Bulk Path Delete
  onRequestPathBulkDelete = () => {
    if (this.jsonNavPaths.length > 0) {
      const arrIds = this.jsonNavPaths.map((path) => path.id);
      this.callbacks.onBulkDeletePathsAPIRequest({ ids: arrIds });
    }
  };

  onPathBulkDeleteAPIResponse = () => {
    this.trigger("removePathsFromScene");
    this.callbacks.onResetBulkUpdateStates();
  };

  onFloorplanAPICalls = (object, apiType) => {
    this.callbacks.onFloorplanAPICalls(object, apiType);
  };

  onPathAPICalls = (object, apiType) => {
    this.callbacks.onPathAPICalls(object, apiType);
  };

  onPinAPICalls = (object, apiType) => {
    this.callbacks.onPinAPICalls(object, apiType);
  };

  onToggleRenameVenueMapModal = (flag, connectorData) => {
    this.callbacks.onToggleRenameVenueMapModal(flag, {
      content: connectorData,
    });
  };

  resetFloorplanStates = () => this.callbacks.onResetFloorStates();

  onFloorplanAPIResponse = (object, apiType) => {
    if (apiType === "DELETE") {
      this.onRequestPathBulkDelete();
      this.onToggleAutoSaveSceneFlag(true);
      this.resetFloorplanStates();
    } else if (apiType === "UPDATE") {
      this.trigger("floorplanUpdateSuccess", [this.selectedObject, object]);
    }
  };

  onAnchorAPICalls = (object, apiType) => {
    this.callbacks.onAnchorAPICalls(object, apiType);
  };

  onAnchorAPIResponse = (object, apiType) => {
    if (apiType === "UPDATE") {
      this.callbacks.generateAlert({
        msg: "Access Point Updated Successfully",
        alertType: "information",
      });
    }
    this.callbacks.onResetAnchorStates();
  };

  onPinsAPIResponse = (object, apiType) => {
    if (this.apiFor2dEditor) {
      this.editor2D?.onPinsAPIResponse(object, apiType);
    }
    this.callbacks.onResetPinStates();
  };

  onPathsAPIResponse = (object, type) => {
    this.editor2D?.onPathsAPIResponse(object, type);
    this.callbacks.onResetPathStates();
  };

  onFetchConnectorGroupsResponse = (groups) => {
    this.connectorGroups = groups;
    this.editor2D?.onFetchConnectorGroupsResponse(groups);
  };

  onToggleMenuActive = (name) => {
    if (name !== null && this.selectedObject) {
      this.deselect();
    }
  };

  onInitImportMapsModal = (bFlag, data) => {
    // call things from callback here!
    this.toggleProcessingImportMaps(true);
    this.callbacks.onImportMapsModal(bFlag, data);
  };

  onMountObjectLoader = (bFlag) => {
    if (bFlag) {
      this.canvas.classList.add("noEvents");
    } else {
      this.canvas.classList.remove("noEvents");
    }
  };

  update = () => {
    this.camera.update();
    this.renderer.update();
    this.world.update();

    //Update Marker Scale
    this.markers.forEach((marker) => {
      var scaleVector = new THREE.Vector3();
      var scaleFactor = 20;
      var scale =
        scaleVector
          .subVectors(marker.mesh.position, this.camera.instance.position)
          .length() / scaleFactor;
      scale = scale <= 1 ? 1 : scale;
      marker.mesh.scale.set(scale, scale, scale);
    });

    //Update Position for UI Object annotations
    for (const uiObj of this.uiObjects) {
      const screenPos = uiObj.mesh.position.clone();
      screenPos.project(this.camera.instance);
      const transX = screenPos.x * this.sizes.width * 0.5;
      const transY = -(screenPos.y * this.sizes.height * 0.5);
      uiObj.uiCont.dom.style.transform = `translate(${transX}px, ${transY}px)`;
    }
  };

  toClear(object, isSwitch = false) {
    if (object && object.type.includes("Camera")) return false;
    if (object && object.type.includes("Light")) return false;
    if (object && object.type.includes("GridHelper")) return false;
    if (object && object.type.includes("BoxHelper")) return false;
    if (object && object instanceof TransformControls) return false;
    if (object && "TourHelper" in object.userData) return false;
  
    // if (!isSwitch && object && 'interactive2D' in object.userData) return false;

    return true;
  }

  toAdd(object) {
    if (object && object.type.includes("Camera")) return false;
    if (object && object.type.includes("Light")) return false;
    if (object && object.type.includes("GridHelper")) return false;
    if (object && object.type.includes("BoxHelper")) return false;
    if (object && object instanceof TransformControls) return false;
    if (object && "excludeArea" in object.userData) return false;

    return true;
  }

  setInitState = () => {
    this.hotspots = [];
    this.destinations = [];
    this.waypointPins = [];
    this.raycasterObjects = [];
    this.nonInteractiveObjects = [];
    this.markers = [];
    this.uiObjects = [];
    this.pinReadjustObj = [];
    this.pathReadjustObj = [];

    this.jsonWayProps = [];
    this.jsonQRAnchors = [];
    this.jsonMapMarkers = [];
    this.jsonFloors = [];
    this.jsonNavPaths = [];
    this.jsonNavPins = [];
  };

  clearScene = (isSwitch = false) => {
    const size = this.scene.children.length;
    const children = [...this.scene.children];
    this.deselect();
    for (var i = 0; i < size; i++) {
      const child = getObjectByUserDataProperty(
        this.scene,
        "id",
        children[i].userData.id
      );
      if (this.toClear(child, isSwitch)) {
        this.removeObject(child, true, true);
      }
    }
  };

  clearAndReset = () => {
    this.trigger("clearAndReset");
    this.editor2D?.clearAndReset();
    this.onToggleAutoSaveSceneFlag(false);
    this.setInitState();
    while (this.scene?.children.length) {
      this.scene.remove(this.scene.children[0]);
    }
    delete this;
    instance = null;
  };

  checkDefaultIAP = () => {
    this.isPrimaryAccessEnabled =
      this.jsonQRAnchors.filter((el) => el?.isPrimaryAccess === true).length >
      0;
    return this.isPrimaryAccessEnabled;
  };

  getIndex = (name, objectId) => {
    let index = -1;
    this[name].forEach((obj, idx) => {
      if (obj.id === objectId) {
        index = idx;
      }
    });
    return index;
  };

  //For Waypoint distancing
  calculateDistance = (
    arrLength,
    index,
    ptStart,
    ptEnd,
    pathName,
    isVisible,
    name,
    description,
    pathColor
  ) => {
    const vec1 = ptStart.position.clone();
    const vec2 = ptEnd.position.clone();
    let lineDistance = vec1.distanceTo(vec2);
    let points = [];
    const ptRot = fromQuaternionToQuaternionObject(ptStart.rotation.clone());
    const ptSca = fromVec3ScaleToScaleObject(ptStart.scale.clone());
    points.push({
      id: ptStart.waypointName,
      name: name,
      description: description,
      pathName,
      position: fromVec3PosToPosObject(ptStart.position.clone()),
      scale: ptSca,
      rotation: ptRot,
      visible: isVisible,
      pathColor,
    });
    while (lineDistance.toFixed(2) > 1.3) {
      let subV = new THREE.Vector3();
      const randId = getRandomIdCode();
      let vecPrev = fromPosObjectToVec3Pos(points[points.length - 1].position);
      subV.subVectors(vec2, vecPrev).setLength(1).add(vecPrev);
      points.push({
        id: `wayPoint_${randId}`,
        name: name,
        description: description,
        pathName,
        position: fromVec3PosToPosObject(subV.clone()),
        scale: ptSca,
        rotation: ptRot,
        visible: isVisible,
        pathColor,
      });
      lineDistance = subV.distanceTo(vec2);
    }
    arrLength - 2 === index &&
      points.push({
        id: ptEnd.waypointName,
        name: name,
        description: description,
        pathName,
        position: fromVec3PosToPosObject(vec2.clone()),
        scale: fromVec3ScaleToScaleObject(ptEnd.scale.clone()),
        rotation: fromQuaternionToQuaternionObject(ptEnd.rotation.clone()),
        visible: isVisible,
        pathColor,
      });

    return [...points];
  };

  getWayPointObject = (groupId, visible, pathColor) => {
    let retObj = {};
    for (const wayObj of this.jsonWayProps) {
      if (wayObj.id === groupId) {
        retObj = wayObj;
        break;
      }
    }
    return this.addVisibleValues({ ...retObj }, visible, pathColor);
  };

  addVisibleValues = (wayGroup, visible, pathColor) => {
    if (wayGroup.type === "waypointAndDestination") {
      let wayObj = { ...wayGroup.navObj };
      wayObj.visible = visible;

      let wayArray = [...wayObj.waypoints];
      for (var i = 0; i < wayArray.length; i++) {
        wayArray[i] = { ...wayArray[i], visible };
      }

      wayObj = {
        ...wayObj,
        waypoints: wayArray,
      };

      wayGroup = {
        ...wayGroup,
        navObj: wayObj,
      };
    } else if (wayGroup.type === "waypoints") {
      let wayArray = [...wayGroup.navObj];

      for (var j = 0; j < wayArray.length; j++) {
        wayArray[j] = { ...wayArray[j], visible, pathColor };
      }

      wayGroup = {
        ...wayGroup,
        navObj: wayArray,
      };
    } else if (wayGroup.type === "destination") {
      let wayObj = { ...wayGroup.navObj };
      wayObj.visible = visible;

      wayGroup = {
        ...wayGroup,
        navObj: wayObj,
      };
    }

    return wayGroup;
  };

  getFloorplanObject = (floorId) => {
    let retObj = {};
    for (const floor of this.jsonFloors) {
      if (floor.id === floorId) {
        retObj = floor;
        break;
      }
    }
    return retObj;
  };

  toJSON = () => {
    let mapContent = {
      hotspots: [],
      images: [],
      models: [],
      navigation3Ds: [],
      // textNote3Ds: this.mapData.mapdata ? this.mapData?.mapdata?.mapContent?.textNote3Ds : [],
      texts: [],
      voiceMemos: [],
      destination3ds: [],
      waypoints3ds: [],
      // floors: [],
      videos: [],
      marker: [],
      // nftItems: this.mapData.mapdata && this.mapData.mapdata.mapContent.nftItems ? this.mapData.mapdata.mapContent.nftItems : []
    };

    this.scene.children.forEach((child) => {
      if (
        child.userData &&
        child.userData.type &&
        child.userData.type === "audio"
      ) {
        mapContent.voiceMemos.push({
          autoplay: child.userData.autoplay,
          id: child.userData.id,
          link: child.userData.audioLink,
          name: child.name,
          position: fromVec3PosToPosObject(child.position.clone()),
          scale: fromVec3ScaleToScaleObject(child.scale.clone()),
          rotation: fromQuaternionToQuaternionObject(child.quaternion.clone()),
          visible: child.userData.visible,
          downloadProximity: child.userData.downloadProximity,
        });
        mapContent.voiceMemos = [
          ...new Map(
            mapContent.voiceMemos.map((item) => [item["id"], item])
          ).values(),
        ];
      }
      if (
        child.userData &&
        child.userData.type &&
        child.userData.type === "image"
      ) {
        mapContent.images.push({
          id: child.userData.id,
          link: child.userData.link,
          name: child.name,
          position: fromVec3PosToPosObject(child.position.clone()),
          scale: fromVec3ScaleToScaleObject(child.scale.clone()),
          rotation: fromQuaternionToQuaternionObject(child.quaternion.clone()),
          visible: child.userData.visible,
          showAsIcon: child.userData.showAsIcon,
          autoRotate: child.userData.autoRotate,
          canDownload: child.userData.canDownload,
          downloadProximity: child.userData.downloadProximity,
        });
        mapContent.images = [
          ...new Map(
            mapContent.images.map((item) => [item["id"], item])
          ).values(),
        ];
      }
      /* if(child.userData && child.userData.type && child.userData.type === 'floorplan') {
                mapContent.floors.push(this.getFloorplanObject(child.userData.id));
                mapContent.floors = [...new Map(mapContent.floors.map((item) => [item['id'], item])).values()];
            } */
      if (
        child.userData &&
        child.userData.type &&
        child.userData.type === "model"
      ) {
        mapContent.models.push({
          id: child.userData.id,
          link: child.userData.link,
          name: child.name,
          position: fromVec3PosToPosObject(child.position.clone()),
          scale: fromVec3ScaleToScaleObject(child.scale.clone()),
          rotation: fromQuaternionToQuaternionObject(child.quaternion.clone()),
          visible: child.userData.visible,
          downloadProximity: child.userData.downloadProximity,
        });
        mapContent.models = [
          ...new Map(
            mapContent.models.map((item) => [item["id"], item])
          ).values(),
        ];
      }
      if (
        child.userData &&
        child.userData.type &&
        child.userData.type === "text"
      ) {
        mapContent.texts.push({
          id: child.userData.id,
          name: child.userData.id,
          textValue: child.name,
          color: `#${child.children[0].material.color
            .getHexString()
            .toUpperCase()}FF`,
          position: fromVec3PosToPosObject(child.position.clone()),
          scale: fromVec3ScaleToScaleObject(child.scale.clone()),
          rotation: fromQuaternionToQuaternionObject(child.quaternion.clone()),
          visible: child.userData.visible,
          autoRotate: child.userData.autoRotate,
          downloadProximity: child.userData.downloadProximity,
        });
        mapContent.texts = [
          ...new Map(
            mapContent.texts.map((item) => [item["id"], item])
          ).values(),
        ];
      }
      if (
        child.userData &&
        child.userData.type &&
        child.userData.type === "hotspot"
      ) {
        mapContent.hotspots.push({
          id: child.userData.id,
          name: child.name,
          description: child.userData.description,
          hyperlink: child.userData.hyperlink,
          hyperlinkName: child.userData.hyperlinkName,
          position: fromVec3PosToPosObject(child.position.clone()),
          scale: fromVec3ScaleToScaleObject(child.scale.clone()),
          rotation: fromQuaternionToQuaternionObject(child.quaternion.clone()),
          visible: child.userData.visible,
          downloadProximity: child.userData.downloadProximity,
        });
        mapContent.hotspots = [
          ...new Map(
            mapContent.hotspots.map((item) => [item["id"], item])
          ).values(),
        ];
      }
      if (
        child.userData &&
        child.userData.type &&
        child.userData.type === "video"
      ) {
        mapContent.videos.push({
          autoplay: child.userData.autoplay,
          id: child.userData.id,
          link: child.userData.videoLink,
          name: child.name,
          description: child.userData.description,
          position: fromVec3PosToPosObject(child.position.clone()),
          scale: fromVec3ScaleToScaleObject(child.scale.clone()),
          rotation: fromQuaternionToQuaternionObject(child.quaternion.clone()),
          visible: child.userData.visible,
          showAsIcon: child.userData.showAsIcon,
          autoRotate: child.userData.autoRotate,
          downloadProximity: child.userData.downloadProximity,
        });
        mapContent.images = [
          ...new Map(
            mapContent.images.map((item) => [item["id"], item])
          ).values(),
        ];
      }
      if (
        child.userData &&
        child.userData.type &&
        child.userData.type === "mapMarker"
      ) {
        mapContent.marker.push({
          id: child.userData.id,
          name: child.name,
          description: child.userData.description,
          position: fromVec3PosToPosObject(child.position.clone()),
          scale: fromVec3ScaleToScaleObject(child.scale.clone()),
          rotation: fromQuaternionToQuaternionObject(child.quaternion.clone()),
          visible: child.userData.visible,
          pathColor: child.userData.pathColor,
        });
        mapContent.marker = [
          ...new Map(
            mapContent.marker.map((item) => [item["id"], item])
          ).values(),
        ];
      }
      if (
        child.userData &&
        child.userData.type &&
        child.userData.type === "wayPointGroup"
      ) {
        const tourObject = this.guidedTourController.toJSON(child.userData.id);
        
        if (tourObject) mapContent.waypoints3ds.push(tourObject);
      }
      // Nav:
      if (
        child.userData &&
        child.userData.navigationMode &&
        child.userData.navigationMode === "waypointAndDestination" &&
        !(child instanceof Line2)
      ) {
        // deprecated now
        const wayObj = this.getWayPointObject(
          child.userData.id,
          child.userData.visible
        );
        mapContent.navigation3Ds.push({ ...wayObj.navObj });
        mapContent.navigation3Ds = [
          ...new Map(
            mapContent.navigation3Ds.map((item) => [item["id"], item])
          ).values(),
        ];
      }
      if (
        child.userData &&
        child.userData.navigationMode &&
        child.userData.navigationMode === "destination"
      ) {
        const wayObj = this.getWayPointObject(
          child.userData.id,
          child.userData.visible
        );
        mapContent.destination3ds.push({ ...wayObj.navObj });
        mapContent.destination3ds = [
          ...new Map(
            mapContent.destination3ds.map((item) => [item["id"], item])
          ).values(),
        ];
      }
      if (
        child.userData &&
        child.userData.navigationMode &&
        child.userData.navigationMode === "waypoints" &&
        child.userData.type !== "wayPointGroup"
      ) {
        const wayObj = this.getWayPointObject(
          child.userData.id,
          child.userData.visible,
          child.userData.pathColor
        );
        mapContent.waypoints3ds.push({
          waypoints: wayObj.navObj,
        });
        mapContent.waypoints3ds = [
          ...new Map(
            mapContent.waypoints3ds.map((item) => [
              item["waypoints"][0]["id"],
              item,
            ])
          ).values(),
        ];
      }
    });

    return mapContent;
  };
}
