import * as THREE from 'three';
import { TransformControls } from "three/addons/controls/TransformControls.js";


import EventEmitter from '../utils/EventEmitter';

import Environment from './utils/Environment';
import WorldMain from './world/World.Main'; 
import Camera from './utils/Camera';
import Renderer from './utils/Renderer';
import ObjectsLoader from './utils/ObjectLoader';
import { History } from './utils/History';
import { Loader } from './utils/Loader';

//common
import Resizer from '../utils/Resizer';
import Time from '../utils/Time';
import LocationPinHelper from './threeUtils/Pins/PinHelper';
import PathController from './threeUtils/Paths/PathController';
import ConnectorPinHelper from './threeUtils/Connectors/Connector.Helper';
import { sortConnectorGroupDropDownOptions } from './threeUtils/Connectors/ConnectorUtils';
// import KeyEvents from '../utils/KeyEvents';


// singleton object
let instance = null;
export default class EditorExperience2D extends EventEmitter {
    constructor(sceneName, canvas, editor3d) {
        super();

        if(instance) return instance;

        instance = this;
        this.canvas = canvas;
        this.editor3d = editor3d;
        this.floorData = {};
        this.nonInteractiveObjects = [];

        this.activeMap = null;
        this.activeFloor = null;
        this.venueFloorList = [];
        this.preloadConnector = {};
        this.continueConnectorTracking = false;
        this.navigationTracking = false;

        this.jsonLocationPins = [];
        this.jsonNavPaths = [];
        this.jsonQRAnchors = [];

        this.amenityTypes = [];
        this.isFloorMap = false;

        this.triggerDeselect = false;

        this.manualAligned = 'no_change';
        this.zoomObjects = [];

        this.sizes = new Resizer();
        // this.keyEvents = new KeyEvents();
        this.time = new Time();

        this.scene = new THREE.Scene();
        this.scene.name = sceneName;
        this.camera = new Camera();
        this.renderer = new Renderer();
        this.resources = new ObjectsLoader();
        this.worenvld = new Environment();
        this.world = new WorldMain();
        this.history = new History(this);
        this.loader = new Loader(this);

        // helpers
        this.locScales = {
            scaleY: 47,
            regScaleXZ: 41,
            amScaleXZ: 35
        }
        // DEFAULT, PINS, CONNECTORS, PATHS
        this.menuConnectorsMode = 'DEFAULT';
        this.initMode = null;
        this.floorplanVersion = 2.7;

        this.locPinHelper = new LocationPinHelper();
        this.pathController = new PathController();
        this.conPinHelper = new ConnectorPinHelper();

        this.sizes.on('resize', this.resize);
        this.time.on('tick', this.update);
        this.resources.on('ready', this.onReady);
        this.on('2DSidebarSceneGraphChanged', this.onSidebarSceneGraphChanged);
        this.on('openCategoryModal',this.onOpenCategoryModal);
        this.on('initiateLoading', this.onInitiateLoding);
        this.on('requestPinPathReadjustment', this.onrequestPinPathReadjustment);
        // this.on('objectReadjustSuccessful', this.onObjectReadjustSuccessful);
        this.on('continueConnectorPlacement', this.onContinueConnectorPlacement);
        this.on('toggleMenuActive', this.onToggleMenuActive);
        this.on('toggleConnectorMenuMode', this.onToggleConnectorMenuMode);
        this.on('navigationTracking', this.onNavigationTracking);

        this.on('setFloorplan', (object) => this.addObjects(object));
        this.on('saveAndExit', this.onSaveAndExit);

        window.experience2d = this;
    }

    select = (object) => {
        if(object !== null && this.selectedObject === object) return;

        this.previousSelected = this.selectedObject;
        this.selectedObject = object;
        
        this.trigger('objectSelected', [object]);
        (!object || (object && (object.userData.type === 'Location Pin' || object.userData.type === 'Connector Pin'))) 
            && this.trigger('2DSidebarSceneGraphChanged', [object]);
    }

    deselect = () => {
        this.select(null);
    }

    onCommand = (command, optName) => {
        // command.execute();
        this.history.execute(command, optName);
    }

    onUndo = () => {
        this.history.undo();
    }

    onRedo = () => {
        this.history.redo();
    }

    addObjects = (object, _, fromHoitory) => {
        this.scene.add(object);
        this.trigger('objectAdded', [object, fromHoitory]);
    }

    removeObject = ( object, delayAutosave, isVersionSwitch = false ) => {
        if(object?.parent === null) return;

        this.scene.remove(object);

        this.trigger('objectRemoved', [object, delayAutosave, isVersionSwitch]);
    }

    onInitiateLoding = () => this.pathController.setUpCylnderObject();

    onReady = () => {
        console.log('2D READY', this.manualAligned);
        this.activeMap = this.editor3d.activeMap;
        this.connectorGroups = this.editor3d.connectorGroups;
        this.editor3d.on2DEditorReady();

        if(this.initMode === 'Connector Pins') {
            this.onToggleConnectorMenuMode('CONNECTORS');
        }

        if(this.manualAligned === '2d_change' && (this.pathController.getPathCount() > 0 || this.jsonLocationPins.length > 0)) {
            // call transformed aligned for all!
            // trigger loader 
            this.trigger('mount2DLoader', [true]);
            this.trigger('toggle2DLoaderProgress', ['', 'Readjusting Pins & Paths...']);

            this.pinReadjustObj = [];
            this.pathReadjustObj = [];

            this.trigger('updateObject3DPositionsWithNewFloorMatrix');
            this.pathController.onUpdateObject3DPositions();
        } else if(this.manualAligned !== 'no_change' && this.pathController.getPathCount() === 0 
            && this.jsonLocationPins.length === 0) {
            // nothing to change!
            this.manualAligned = 'no_change';
            this.editor3d.updateFloorplanAlignedState('no_change');
        }
    }

    onrequestPinPathReadjustment = () => {
        if(this.pinReadjustObj.length === this.jsonLocationPins.length &&
            this.pathReadjustObj.length === this.pathController.getPathCount()) {
            // console.log("CALL 2D API NOW PINS & PATHS", this.pinReadjustObj, this.pathReadjustObj);
            this.editor3d.callbacks.onBulkUpdatePinPathsAPIRequest({locationPins: this.pinReadjustObj, locationPaths: this.pathReadjustObj})
        }
    }

    onBulkUpdateAPIResponse = () => {
        // trigger loader 
        this.trigger('mount2DLoader', [false]);
        this.trigger('toggle2DLoaderProgress', ['none']);
        this.manualAligned = 'no_change';
        this.pinReadjustObj = [];
        this.pathReadjustObj = [];
        this.editor3d.updateFloorplanAlignedState('no_change');
    }

    onFetchConnectorGroupsResponse = (groups) => {
        this.connectorGroups = groups;
        this.trigger('connectorGroupOptionsChanged', [ groups ]);
    }

    invokeConnectorCreate = (object) => {
        const connectorType = object.userData.connectorType;
        this.editor3d.callbacks.onToggleConnectorGroupModal(true, {connectorType})
    }

    initResourceLoading = (data) => {
        //reset arrays
        this.jsonLocationPins = [];
        this.jsonQRAnchors = [];
        this.pathController.clearGraphs();
        this.jsonNavPaths = [];
        this.zoomObjects = [];
        this.resources.initiateLoading(data);
    }

    setInitMode = action => this.initMode = action;

    setFloorplanVersion = version => this.floorplanVersion = version;

    resize = () => {
        this.camera.resize();
        this.renderer.resize();
    }

    update = () => {
        this.camera.update();
        this.renderer.update();
    }

    onToggleMenuActive = (name, isInit = false) => {
        if(name !== null && !isInit && this.selectedObject) {
            this.deselect();
        }
    }

    onNavigationTracking = (bFlag) => {
        this.navigationTracking = bFlag;
    }

    onToggleConnectorMenuMode = (mode) => {
        if(mode) {
            this.menuConnectorsMode = mode;
        } else {
            this.menuConnectorsMode = 'DEFAULT';
        }
        this.trigger('menuModeChanged', [this.menuConnectorsMode]);
    }

    getIndex = (name, objectId) => {
        let index = -1;
        this[name].forEach((obj, idx) => {
            if(obj.id === objectId){
                index = idx;
            } 
        });
        return index;
    }

    toggleContinueConnectorTracking = (bFlag = undefined) => {
        if(bFlag !== undefined) {
            this.continueConnectorTracking = bFlag;
        } else {
            this.continueConnectorTracking = !this.continueConnectorTracking;
        }
        if(!this.continueConnectorTracking) this.preloadConnector = {};
    }

    onContinueConnectorPlacement = data => this.toggleContinueConnectorTracking(data ? true : false);

    onToggleInfoModals = (idx, flag, type) => this.editor3d.onToggleInfoModals(idx, flag, type);

    onToggleContinueConnectorModal = (flag, activeId, activeType, pairStep) => {
        // console.log(flag, activeId, activeType);
        const group = this.connectorGroups.find( cg => cg.id === activeId);
        this.editor3d.callbacks.onToggleContinueConnectorModal(flag, {
            activeFloor: this.activeFloor, 
            selectedGroup: group, 
            floorList: this.venueFloorList,
            groupList: sortConnectorGroupDropDownOptions(this.connectorGroups, activeType),
            connectorType: activeType,
            pairStep,
        })
    }

    onToggleConnectorManagerModal = (flag) => {
        this.editor3d.callbacks.onToggleConnectorManagerModal(flag)
    }

    onToggleConnectorInfoModals = (idx, flag, modalData) => {
        this.editor3d.callbacks.onToggleConnectorInfoModals(idx, flag, modalData);
    }

    onOpenCategoryModal = (flag,data) =>{
        // console.log(flag,data)
        // console.log(this.editor3d.callbacks)
        if(data.isEdit){
            this.editor3d.callbacks.onToggleCategoryModal(flag,data)
        } else{
            this.editor3d.callbacks.onToggleCategoryModal(flag,data)
        }
    }

    onPinAPICalls = (object, apiType, toDeselect = false) => {
        this.triggerDeselect = toDeselect;
        this.editor3d.callbacks.onPinAPICalls(object, apiType);
    }

    onPathAPICalls = (object, apiType) => {
        this.editor3d.callbacks.onPathAPICalls(object, apiType);
    }

    onPathsAPIResponse = (object, apiType) => {
        if(apiType === 'DELETE') {
            this.pathController?.removePath(object);
        } 
    }

    onPinsAPIResponse = (object, apiType) => {
        if(apiType === 'UPDATE') {
            this.editor3d.callbacks.generateAlert({
                msg: "Pin Updated Successfully",
                alertType: "information"
            });
        }
        if(apiType === 'UPDATE' && this.triggerDeselect) {
            this.deselect();
            // trigger loader 
            this.trigger('mount2DLoader', [false]);
            this.trigger('toggle2DLoaderProgress', ['none']);
        }
        this.triggerDeselect = false;
    }

    toClear(object) {
		if (object && object.type.includes('Camera')) return false;
		if (object && object.type.includes('Light')) return false;
        if (object && object.type.includes('BoxHelper')) return  false;
        if (object && object instanceof TransformControls) return false;
        return true;
	}

    clearScene = () => {
        const size = this.scene.children.length;
        const children = [...this.scene.children];
        this.deselect();
        for(var i = 0; i < size; i++) {
            const child = children[i]
            if(this.toClear(child)) {
                this.removeObject(child, true, true);
            }
        }
    }

    clearAndReset = () => {
        this.trigger('clearAndReset');
        while(this.scene.children.length) {
            this.scene.remove(this.scene.children[0]);
        }
        delete this;
        instance = null
    }

    onSaveAndExit = (isSwitch = false) => {
        this.clearScene();
        this.resources.onClearAndReset();
        this.world?.onClearAndReset();
        delete this.world;
        this.world = null;
        this.jsonNavPaths = this.pathController.toJSON();
        this.editor3d.exit2DEditor(this.jsonNavPaths, this.jsonLocationPins, isSwitch);
    }

    onSidebarSceneGraphChanged = (action) => {
        if(action === null) {
            this.editor3d.callbacks.onSidebarGraphChanged('show2DSidebar',false);
        } else {
            this.editor3d.callbacks.onSidebarGraphChanged('show2DSidebar',true);
        }
    }
    

    toJSON = () => {
    
    }
}