import * as THREE from "three";
import EditorExperience from "../../MinEditorExperience";
import EventEmitter from "../../../utils/EventEmitter";

import {
    fromPosObjectToVec3Pos,
    fromQuaternionObjectToQuaternionFP,
    fromScaleObjectToVec3Scale,
} from "../../../threeUtils/TransformConversions";

export default class Floorplan extends EventEmitter {
    constructor(floorData, type) {
        super();
        this.editor = new EditorExperience();

        this.position = floorData.position
            ? fromPosObjectToVec3Pos(floorData.position)
            : new THREE.Vector3(0, 0, 0);
        this.rotation = floorData.rotation
            ? fromQuaternionObjectToQuaternionFP(floorData.rotation)
            : new THREE.Quaternion(-0.7071068, 0, 0, 0.7071068);
        this.scale = floorData.scale
            ? fromScaleObjectToVec3Scale(floorData.scale)
            : new THREE.Vector3(0.1, 0.1, 0.1);
        this.imageMesh = null;

        this.name = floorData.name ? floorData.name.substring(0, 20) : "";
        this.id = floorData.id;
        this.assetLink = floorData.link;
        this.alignmentStatus =
            "alignmentStatus" in floorData ? floorData.alignmentStatus : false;

        this.pegs = "pegs" in floorData ? floorData.pegs : [];

        this.texLoader = new THREE.TextureLoader();

        this.setupObject();
    }

    setupObject = () => {
        this.texLoader.load(
            this.assetLink,
            (imgTex) => {
                imgTex.minFilter = imgTex.magFilter = THREE.LinearFilter;
                imgTex.colorSpace = THREE.SRGBColorSpace;
                imgTex.anisotropy = 4;

                let imgGeo = new THREE.PlaneGeometry(1, 1, 64, 64);
                this.imgMat = new THREE.MeshBasicMaterial({
                    map: imgTex,
                    side: THREE.DoubleSide,
                    transparent: false,
                });
                this.imgMesh = new THREE.Mesh(imgGeo, this.imgMat);
                this.imgMesh.rotateY(THREE.MathUtils.DEG2RAD * 180);

                const newRot = new THREE.Euler().setFromQuaternion(
                    this.rotation
                );

                this.imageMesh = new THREE.Group();
                this.imageMesh.add(this.imgMesh);
                this.imageMesh.name = this.name.substring(0, 20);
                this.imageMesh.renderOrder = 999;
                this.imageMesh.userData.type = "floorplan";
                this.imageMesh.position.copy(this.position);
                this.imageMesh.scale.set(
                    this.scale.x * imgTex.image.width,
                    this.scale.y * imgTex.image.height,
                    this.scale.z
                );
                this.imageMesh.rotation.set(newRot.x, 0, newRot.z * -1);

                let min = 0;
                let bFlag = false;

                this.pegs.forEach((peg) => {
                    let fPegPos = fromPosObjectToVec3Pos(peg.position);

                    if (fPegPos.y < min) {
                        min = fPegPos.y;
                        bFlag = true;
                    }
                });

                if (bFlag && this.imageMesh.position.y === -1) {
                    this.imageMesh.position.y = min;
                }
                this.editor.trigger("asyncObjectLoaded", [this, "floorplan"]);
            },
            undefined,
            () => {
                this.editor.trigger("asyncObjectLoaded", [
                    this,
                    "SKIPPED_OBJECT",
                ]);
            }
        );
    };
}
