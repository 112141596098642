import EventEmitter from "../../utils/EventEmitter";
import InteractiveExperience from "../../InteractiveExperience";
import { fromPosObjectToVec3Pos } from "../TransformConversions";

import NavigationLine from "./Navigation.Line";

class Navigation extends EventEmitter {
    constructor() {
        super();

        this.editor = new InteractiveExperience();
        this.waypoints = [];
        this.line = null;
        this.multiLines = [];

        this.editor.on("clearPathBetweenPins", this.clearPath);
        this.editor.on("swapPinsForNavigation", this.clearPath);
    }

    setWaypoints = (waypoints) => {
        this.clearPath(false);

        this.waypoints = waypoints.map((waypoint) =>
            fromPosObjectToVec3Pos(waypoint.position)
        );
        this.generateLine();
    };

    generateLine = () => {
        this.line = new NavigationLine(this.waypoints);
        this.editor.scene.add(this.line.line);
        this.editor.camera.relocateCamera(this.line.line);
    };

    setMultiWaypoints = (multiData) => {
        this.clearPath(false);
        for (const data of multiData) {
            const waypoints = data.path.map((waypoint) =>
                fromPosObjectToVec3Pos(waypoint.position)
            );
            const line = new NavigationLine(waypoints);
            this.multiLines.push(line);
            const scene = this.editor.sceneObjectData[data.mapId].scene;
            scene.add(line.line);
        }
        this.editor.camera.relocateCamera(this.multiLines[0].line);
    }

    clearPath = (trigger = true) => {
        if(this.multiLines.length > 0) {
            trigger && this.editor.trigger("navigationPreview", [false]);
            for(const line of this.multiLines) {
                for (const sceneId in this.editor.sceneObjectData) {
                    const scene = this.editor.sceneObjectData[sceneId].scene;
                    if (scene.children.includes(line.line)) {
                        scene.remove(line.line);
                        break;
                    }
                }
            }
            this.multiLines = [];
        }

        if (this.line) {
            trigger && this.editor.trigger("navigationPreview", [false]);
            let bDone = false;
            for (const sceneId in this.editor.sceneObjectData) {
                const scene = this.editor.sceneObjectData[sceneId].scene;
                if (!bDone && scene.children.includes(this.line.line)) {
                    scene.remove(this.line.line);
                    bDone = true;
                    break;
                }
            }
            delete this.line;
            this.line = null;
        }
    };
}

export default Navigation;
