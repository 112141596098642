import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stepperMapDetails } from "../../../../../../features/user/studio/studioSlice";
import UploadComponent from "./UploadComponent";
import { isEmpty, isEqual } from "lodash";

import { Grid } from "@mui/material";

import {
  // uploadingData,
  mapDetails,
  createMap,
  updateUserMap,
  getUserMapDetails,
  isMapDetailsExist,
  setStepperMapDetails,
  putFloorplan,
} from "../../../../../../features/user/studio/studioSlice";

import {
  uploadAsset,
  thumbnailData,
  floorplanData,
  resetMapDetailsTab,
  deleteAsset,
} from "../../../../../../features/common/commonSlice";

import { v4 } from "uuid";

const StepperMapDetails = ({
  setNext,
  setUpload,
  setActiveStep,
  createData,
  updateObj,
  setIsModifiedObj,
}) => {
  const dispatch = useDispatch();
  const contentType = "maps";

  const mapData = useSelector(stepperMapDetails);
  const isMapDetailsExists = useSelector(isMapDetailsExist);
  const [mapName, setMapName] = useState("");
  const [description, setDescription] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [floorplanName, setFloorplanName] = useState("");
  const [floorplan, setFloorplan] = useState(null);
  const mapDetailsData = useSelector(mapDetails);
  const [modifiedFields, setModifiedFields] = useState({
    mapName: false,
    onFocusMapname: false,
    description: false,
    thumbnail: false,
    floorplan: false,
    floorplanName: false,
  });
  const thumbnailFileData = useSelector(thumbnailData);
  const floorplanFileData = useSelector(floorplanData);

  useEffect(() => {
    if (!isEmpty(mapData)) {
      if (isEqual(mapName, mapData.mapName)) {
        setModifiedFields((prev) => ({ ...prev, mapName: false }));
        setIsModifiedObj.setIsModified(false);
      }
      if (isEqual(description, mapData.description)) {
        setModifiedFields((prev) => ({ ...prev, description: false }));
        setIsModifiedObj.setIsModified(false);
      }
      if (thumbnail !== null) {
        if (
          decodeURIComponent(mapDetailsData?.metadata?.thumbnailImage).includes(
            thumbnail.name
          )
        ) {
          setModifiedFields((prev) => ({ ...prev, thumbnail: false }));
          setIsModifiedObj.setIsModified(false);
        }
      }
      if (floorplan !== null) {
        if (mapDetailsData?.metadata?.floors?.length > 0) {
          if (
            decodeURIComponent(
              mapDetailsData?.metadata?.floors[0].link
            ).includes(floorplan.name)
          ) {
            setModifiedFields((prev) => ({ ...prev, floorplan: false }));
            setIsModifiedObj.setIsModified(false);
          }
        } else {
          setModifiedFields((prev) => ({ ...prev, floorplan: false }));
          setIsModifiedObj.setIsModified(false);
        }
      }
      if (isEqual(floorplanName, mapData.floorplanName)) {
        setModifiedFields((prev) => ({ ...prev, floorplanName: false }));
        setIsModifiedObj.setIsModified(false);
      }
      if (!isEqual(mapName, mapData.mapName)) {
        setModifiedFields((prev) => ({ ...prev, mapName: true }));
        setIsModifiedObj.setIsModified(true);
      }
      if (!isEqual(description, mapData.description)) {
        setModifiedFields((prev) => ({ ...prev, description: true }));
        setIsModifiedObj.setIsModified(true);
      }
      if (thumbnail !== null) {
        if (
          !decodeURIComponent(mapDetailsData?.metadata?.thumbnailImage).includes(
            thumbnail.name
          )
        ) {
          setModifiedFields((prev) => ({ ...prev, thumbnail: true }));
          setIsModifiedObj.setIsModified(true);
        }
      }
      if (floorplan !== null) {
        if (mapDetailsData?.metadata?.floors?.length > 0) {
          if (
            !decodeURIComponent(
              mapDetailsData?.metadata?.floors[0].link
            ).includes(floorplan.name)
          ) {
            setIsModifiedObj.setIsModified(true);
            setModifiedFields((prev) => ({ ...prev, floorplan: true }));
          }
        } else {
          setIsModifiedObj.setIsModified(true);
          setModifiedFields((prev) => ({ ...prev, floorplan: true }));
        }
      }
      if (!isEqual(floorplanName, mapData.floorplanName)) {
        setModifiedFields((prev) => ({ ...prev, floorplanName: true }));
        setIsModifiedObj.setIsModified(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mapName,
    description,
    thumbnail,
    floorplan,
    floorplanName,
    mapDetailsData,
  ]);

  useEffect(() => {
    if (isMapDetailsExists)
      dispatch(getUserMapDetails(mapDetailsData?.metadata?.mapId));
    return () => {
      dispatch(resetMapDetailsTab());
      setIsModifiedObj.setIsModified(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isEmpty(mapName) ||
      mapName?.length < 3 ||
      (isEmpty(floorplanName) && floorplan)
    )
      setNext(true);
    else {
      setNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapName, floorplan, floorplanName]);

  useEffect(() => {
    if (isMapDetailsExists) {
      setMapName(mapData.mapName);
      setDescription(mapData.description);
      setThumbnail(mapData.thumbnail);
      setFloorplan(mapData.floorplan);
      setFloorplanName(mapData.floorplanName);
      setNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapDetailsExists]);

  useEffect(() => {
    if (thumbnailFileData) {
      let metadata = {
        ...mapDetailsData?.metadata,
        ...(modifiedFields.thumbnail
          ? { thumbnailImage: thumbnailFileData.file }
          : ""),
      };
      dispatch(
        updateUserMap({
          mapId: mapDetailsData?.metadata.mapId,
          mapDetails: {
            metadata,
          },
        })
      ).then(() => {
        setNext(false);
        setUpload(false);
        if (modifiedFields.thumbnail && modifiedFields.floorplan) {
          createAsset("floorplan");
        } else {
          setActiveStep();
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnailFileData]);

  useEffect(() => {
    if (floorplanFileData) {
      // build put req for floorplan
      dispatch(
        putFloorplan({
          mapId: mapDetailsData?.metadata.mapId,
          mapDetails: mapDetailsData,
          floorplanData: {
            link: floorplanFileData?.compressedFile || floorplanFileData.file,
            name: modifiedFields.floorplanName
              ? floorplanName
              : floorplan?.name?.replace(/\.[^/.]+$/, ""),
          },
          currFloorplan: mapDetailsData?.metadata?.floors[0]
        })
      );
    }
    if (floorplanFileData) {
      setNext(false);
      setUpload(false);
      setActiveStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorplanFileData]);

  useEffect(() => {
    if (createData.create) {
      dispatch(
        setStepperMapDetails({
          mapName,
          description,
          thumbnail,
          floorplan,
          floorplanName,
        })
      );
      handleUploadAndCreate();
      createData.setCreate(false);
    }
    if (updateObj.update) {
      dispatch(
        setStepperMapDetails({
          mapName,
          description,
          thumbnail,
          floorplan,
          floorplanName,
        })
      );
      handleUpdate();
      updateObj.setUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData, updateObj]);

  const removeAsset = (url) => {
    dispatch(deleteAsset({ assetUrl: url }));
  };

  const createAsset = (assetType) => {
    let isThumbnail = assetType === "thumbnail";
    if (isThumbnail && mapDetailsData?.metadata.thumbnailImage) {
      removeAsset(mapDetailsData?.metadata.thumbnailImage);
    }
    /* if (!isThumbnail && mapDetailsData?.metadata?.floors?.length > 0) {
      removeAsset(mapDetailsData?.metadata?.floors[0].link);
    } */
    let reqObj = new FormData();
    reqObj.append("file", isThumbnail ? thumbnail : floorplan);
    reqObj.append("contentType", isThumbnail ? "maps" : "floorplans");
    reqObj.append("mapId", mapDetailsData?.metadata?.mapId);
    reqObj.append('compressFile', true);
    let data = {
      reqObj,
      ...(isThumbnail
        ? { isThumbnailUploading: true }
        : { isFloorplanUploading: true }),
      assetType,
    };
    dispatch(uploadAsset(data));
  };

  const handleUpdate = () => {
    setUpload(true);

    if (modifiedFields.thumbnail) {
      createAsset("thumbnail");
    }

    if(modifiedFields.floorplan && !floorplan.isUrl){
      createAsset('floorplan')
    } else if(modifiedFields.floorplan && floorplan.isUrl){
      dispatch(
        putFloorplan({
          mapId: mapDetailsData?.metadata.mapId,
          mapDetails: mapDetailsData,
          floorplanData: {
            link: floorplan.image,
            name: modifiedFields.floorplanName
              ? floorplanName
              : floorplan?.name?.replace(/\.[^/.]+$/, ""),
          },
          currFloorplan: mapDetailsData?.metadata?.floors[0]
        })
      ).then(() => {
        setNext(false);
        setUpload(false);
        setActiveStep();
      })
    }

    // build the put req without floorplanName
    if (modifiedFields.floorplanName && !modifiedFields.floorplan) {
      dispatch(
        putFloorplan({
          mapId: mapDetailsData?.metadata?.mapId,
          mapDetails: mapDetailsData,
          floorplanData: {
            link: mapDetailsData?.metadata?.floors[0].link,
            name: floorplanName,
          },
          currFloorplan: mapDetailsData?.metadata?.floors[0]
        })
      ).then(() => {
        setNext(false);
        setUpload(false);
        setActiveStep();
      });
    }

    if (modifiedFields.mapName || modifiedFields.description) {
      let metadata = {
        ...mapDetailsData?.metadata,
        ...(modifiedFields.mapName ? { mapName } : ""),
        ...(modifiedFields.description ? { description } : ""),
      };
      dispatch(
        updateUserMap({
          mapId: mapDetailsData?.metadata.mapId,
          mapDetails: {
            metadata,
          },
        })
      ).then(() => {
        setNext(false);
        setUpload(false);
        if (!(modifiedFields.thumbnail && modifiedFields.floorplan)) {
          setActiveStep();
        }
      });
    }
  };

  const handleUploadAndCreate = () => {
    const thumbnailData = new FormData();
    if (thumbnail) {
      thumbnailData.append("name", thumbnail.name);
      thumbnailData.append("contentType", contentType);
      thumbnailData.append("file", thumbnail);
      thumbnailData.append("thumbnail", "");
      thumbnailData.append('compressFile', true);

    }

    const floorplanData = new FormData();
    if (floorplan) {
      floorplanData.append("name", `${floorplanName}.png`);
      floorplanData.append("contentType", "floorplans");
      floorplanData.append("file", floorplan.isUrl ? floorplan.image : floorplan);
      floorplanData.append("thumbnail", "");
      floorplanData.append('compressFile', true);
    }

    let primaryAnchorId = v4();

    const mapData = {
      mapName,
      description,
      isPublicMap: false,
      anchorType: "qrAnchors",
      anchorIds: [primaryAnchorId],
      anchors: [
        {
          id: primaryAnchorId,
          sessiongroup: 0,
          name: "",
          description: "",
          anchorIndex: "primary",
          isAssociated: false,
          isQrCode: true,
          isPrimaryAccess: false,
          anchorthumbnail: null,
          texture: null,
        },
      ],
    };
    setUpload(true);

    dispatch(
      createMap({
        data: {
          thumbnailData,
          floorplanData,
          mapData,
          hasThumbnail: thumbnail !== null,
          hasFloorplan: floorplan !== null,
          isSampleFloor: floorplan !== null && floorplan.isUrl,
        },
        onSuccess: () => {
          setNext(false);
          setUpload(false);
          setActiveStep();
        },
      })
    );
  };

  return (
    <div className="stepper">
      <div className="stepper__Header">
        Ensure you have the ARway app installed. You will need the app to
        activate your map.
      </div>
      <div className="stepper__Card">
        <Grid container={true}>
          <Grid item xs={12} className="mapinfo__separator">
            <div className="stepper__SectionHeader">Enter Map Details</div>
            <label className="content-type-select mt-4">
              Map details will be visible to viewers.
              <input
                type="text"
                id="input-map-title"
                className="form-control"
                placeholder="Map Title*"
                onChange={(e) => setMapName(e.target.value)}
                onBlur={() => {
                  if (mapName?.length < 3) {
                    setModifiedFields((prev) => ({ ...prev, onFocusMapname: true }));
                  } else {
                    setModifiedFields((prev) => ({ ...prev, onFocusMapname: false }));
                  }
                }}
                value={mapName}
                maxLength={30}
              />
              {mapName?.length < 3 && modifiedFields.onFocusMapname ? (
                <span className="p-1" style={{ color: "red" }}>
                  Minimum three character required!
                </span>
              ) : null}
            </label>
            <label className="content-type-select">
              <textarea
                rows={5}
                type="text"
                id="input-map-description"
                className="form-control"
                placeholder="Description (Optional)"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                maxLength={500}
              />
            </label>
          </Grid>
        </Grid>
      </div>
      <Grid container={true} spacing={3}>
        <Grid item xs={6}>
          <UploadComponent
            header={"Upload Thumbnail (optional)"}
            subHeader={"Your thumbnail will be visible to viewers."}
            setFilecb={setThumbnail}
          />
        </Grid>
        <Grid item xs={6}>
          <UploadComponent
            isFloorplan={false}
            header={"Upload Floor plan"}
            subHeader={
              "Align the floor plan to your physical space using the ARway app. View the floor plan in the studio and use it for more accurate content placement."
            }
            setFilecb={setFloorplan}
            floorplanNamecb={setFloorplanName}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default StepperMapDetails;
