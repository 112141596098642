import * as THREE from 'three';
import EditorExperience from '../InteractiveExperience';


export default class WorldEnvironment {
    constructor(scene) {
        this.editor = new EditorExperience();
        this.scene = scene;

        this.initSimulatedAreaLight();
    }

    initSimulatedAreaLight = () => {
        this.rayLight1 = new THREE.DirectionalLight('#ffffff', 2.5);
        this.rayLight1.position.set(0, 100, 0);
        this.scene.add(this.rayLight1);

        this.rayLight2 = new THREE.DirectionalLight('#ffffff', 2.5);
        this.rayLight2.position.set(0, -100, 0);
        this.scene.add(this.rayLight2);

        this.rayLight3 = new THREE.DirectionalLight('#ffffff', 2.5);
        this.rayLight3.position.set(100, 0, 0);
        this.scene.add(this.rayLight3);

        this.rayLight4 = new THREE.DirectionalLight('#ffffff', 2.5);
        this.rayLight4.position.set(-100, 0, 0);
        this.scene.add(this.rayLight4);

        this.rayLight5 = new THREE.DirectionalLight('#ffffff', 2.5);
        this.rayLight5.position.set(0, 0, 100);
        this.scene.add(this.rayLight5);

        this.rayLight6 = new THREE.DirectionalLight('#ffffff', 2.5);
        this.rayLight6.position.set(0, 0, -100);
        this.scene.add(this.rayLight6);

    }
}