//For QR
import Server from "../../../api";
import { BrowserQRCodeSvgWriter } from "@zxing/browser";
import EncodeHintType from "@zxing/library/esm/core/EncodeHintType";
import ErrorCorrectionLevel from "@zxing/library/esm/core/qrcode/decoder/ErrorCorrectionLevel";
import { isEmpty, isObject } from 'lodash';
import { saveAs } from "file-saver";

//DEFAULT IMAGE ASSET
import defImg from '../../../assets/pngs/studioImageIcon.png';
import defVidImg from '../../../assets/pngs/studioVideoIcon.png';
import defModelIcon from '../../../assets/pngs/studioModelIcon.png';
import playIcon from '../../../assets/icons/scene/play.png';
import pauseIcon from '../../../assets/icons/scene/pause.png';
import playErrorIcon from '../../../assets/icons/scene/playerror.png';
import eyeOpenIcon from '../../../assets/svgs/scenes/eye_open_white.svg';
import eyeClosedIcon from '../../../assets/svgs/scenes/eye_closed_white.svg';
import tourIcon from '../../../assets/svgs/scenes/menu/path.svg';
import editIcon from '../../../assets/svgs/scenes/edit.svg';
import pinIcon from '../../../assets/svgs/scenes/menu/navigation.svg';
import connectorIcon from '../../../assets/svgs/scenes/menu/connector_pin.svg';
import qrIcon from '../../../assets/svgs/scenes/menu/qrcode.svg';
import markerIcon from '../../../assets/svgs/scenes/menu/mapmarker.svg';
import imgAccessIcon from '../../../assets/svgs/scenes/menu/imageaccess.svg';
import floorplanIcon from '../../../assets/svgs/scenes/menu/floorplan.svg';
import ARWayQRIconBG from '../../../assets/pngs/ARWayQR_BG.png';
import ARWayQRIconFG from '../../../assets/pngs/ARWayQR_FG.png';
import alertIcon from '../../../assets/svgs/scenes/alert.svg';
import floorAlignedIcon from '../../../assets/svgs/scenes/floor_aligned.svg';
import addImageButtonIcon from '../../../assets/pngs/studio/locationPin/addImageButtonIcon.png';
import DefaultNV from '../../../assets/pngs/studio/locationPin/default.png';
import GlowingNV from '../../../assets/pngs/studio/locationPin/glowing.png';
// import ChevronsNV from '../../../assets/pngs/studio/locationPin/chevrons.png';
import SpheresNV from '../../../assets/pngs/studio/locationPin/spheres.png';
import JumboChevronNV from '../../../assets/pngs/studio/locationPin/jumbo_chevron.png';
import FlatPathNV from '../../../assets/pngs/studio/locationPin/flat_path.png';
import CheckboxIcon from '../../../assets/pngs/studio/locationPin/checkbox.png';
import AddImgIcon from '../../../assets/pngs/studio/locationPin/addImageButtonIcon.png';
import DefImgIcon from '../../../assets/pngs/studio/locationPin/defSrc.png';
import DefFileIcon from '../../../assets/pngs/studio/locationPin/defaultPDF.png';
// import MoreVerti from '../../../assets/svgs/studio/locationPin/more-vertical.svg'
// import editImageIcon from '../../../assets/svgs/studio/locationPin/edit.svg'
import FeaturePinIcon from '../../../assets/pngs/studio/locationPin/featured_pin.png';
// import MoreHoriz from '../../../assets/pngs/studio/locationPin/moreHorizontal.png';
import checkIcon from '../../../assets/svgs/scenes/ar_check.svg';
import moreOptnsIcon from '../../../assets/svgs/scenes/more_options.svg';
import DropdownIcon from '../../../assets/pngs/studio/locationPin/chevron_drop.png';
import MoveIcon from '../../../assets/svgs/scenes/move_arrow.svg';
import EditPencon from '../../../assets/svgs/scenes/edit_pen.svg';
import SearchIcon from '../../../assets/svgs/scenes/search_icon.svg';


import { getRandomIdCode } from '../threeUtils/TransformConversions';
import { validateExt, validateSize } from "../../common/utils";
import { getQRURL, shouldAddQRMargins } from "../../_utils/QRURLUtils";
import { getNameType } from "../2d-editor/threeUtils/Connectors/ConnectorUtils";
import { getPlaceholderThumbnail } from "../../_utils/AssignPlaceholder";

//ICON Object
const icons = {
    'QR': qrIcon,
    'TOUR': tourIcon,
    'LOCATION': pinIcon,
    'CONNECTOR': connectorIcon,
    'MARKER': markerIcon,
    'IMAGE_ACCESS': imgAccessIcon,
    'FLOORPLAN': floorplanIcon
}

// UI Prototype
function UI(dom) {
    this.dom = dom;
}

UI.prototype = {

    addChild: function () {
        for (var i = 0; i < arguments.length; i++) {
            var argument = arguments[i]
            if (argument instanceof UI) {
                this.dom.appendChild(argument.dom)
            } else {
                console.log("UI:", argument, "is not an instantce of UI");
            }
        }
        return this;
    },

    deleteChild: function () {
        for (var i = 0; i < arguments.length; i++) {
            var argument = arguments[i]

            if (argument instanceof UI) {
                this.dom.removeChild(argument.dom)
            } else {
                console.log("UI:", argument, "is not an instantce of UI");
            }
        }
        return this;
    },

    clearChildren: function () {
        while (this.dom.children.length) {
            this.dom.removeChild(this.dom.lastChild)
        }
    },

    setDisabled: function (value) {
        this.dom.disabled = value;
        return this;
    },

    setDisplay: function (value) {
        this.dom.style.display = value;
    },

    setStyle: function (key, value) {
        this.dom.style[key] = value;
        return this;
    },

    setValue: function (value) {
        this.dom.value = value;
        return this;
    },

    setTextContent: function (value) {
        this.dom.textContent = value;
        return this;
    },

    addAttribute: function (key, value) {
        this.dom.setAttribute(key, value);
        return this;
    },

    setClass: function (name) {
        this.dom.className = name;
        return this;
    },

    addClass: function (name) {
        this.dom.classList.add(name);
        return this;
    },

    setId: function (id) {
        this.dom.id = id
        return this;
    },

    removeClass: function (name) {
        this.dom.classList.remove(name);
        return this;
    },
}

var events = ['KeyUp', 'KeyDown', 'MouseOver', 'MouseOut', 'Click', 'DblClick', 'Change', 'Mouseover', 'Mouseout', 'ImgChange', 'AutoSave'];
events.forEach(function (event) {
    var method = 'on' + event;
    UI.prototype[method] = function (callback) {
        this.dom.addEventListener(event.toLowerCase(), callback.bind(this), false);
        return this;
    };
});

// UIPanel
function UIPanel() {
    UI.call(this);
    var dom = document.createElement('div');
    this.dom = dom;
    return this;
}
UIPanel.prototype = Object.create(UI.prototype);
UIPanel.prototype.constructor = UIPanel;

function UIRow() {

    UI.call(this);

    const row = document.createElement('div');
    row.classList.add('ws_uiRow');
    this.dom = row;

    return this;
}

UIRow.prototype = Object.create(UI.prototype);
UIRow.prototype.constructor = UIRow;

function UICol() {

    UI.call(this);

    const row = document.createElement('div');
    row.classList.add('ws_uiCol');
    this.dom = row;

    return this;
}

UICol.prototype = Object.create(UI.prototype);
UICol.prototype.constructor = UICol;

function UISeparator(custClass) {
    UI.call(this);
    var dom = document.createElement('div');
    dom.classList.add(custClass || 'ws_uiSeparator');
    this.dom = dom;
    return this;
}
UISeparator.prototype = Object.create(UI.prototype);
UISeparator.prototype.constructor = UISeparator;

//UITooltip
function UITooltip(dataVal, custClass = null) {
    UI.call(this);

    var dom = document.createElement('span');
    dom.setAttribute('data-text', dataVal);
    dom.className = (custClass ? custClass : 'ws_tooltip');
    this.dom = dom;

    return this;
}
UITooltip.prototype = Object.create(UI.prototype);
UITooltip.prototype.constructor = UITooltip;

function UIText(percentW, innerHtml, noClass = false) {

    UI.call(this);

    const textSpan = document.createElement('span');
    !noClass && textSpan.classList.add('ws_uiText');

    this.dom = textSpan;
    percentW && this.setStyle('width', percentW);
    this.setTextContent(innerHtml);
    return this;
}

UIText.prototype = Object.create(UI.prototype);
UIText.prototype.constructor = UIText;

//UI Text Header
function UITextHeader(percentW, innerHtml) {

    UI.call(this);

    const textSpan = document.createElement('h4');
    textSpan.classList.add('ws_uiTextHeader');

    this.dom = textSpan;
    percentW && this.setStyle('width', percentW);
    this.setTextContent(innerHtml);
    return this;
}

UITextHeader.prototype = Object.create(UI.prototype);
UITextHeader.prototype.constructor = UITextHeader;

//UI Image
function UIImage(src, width, height, title, handleError = false) {

    UI.call(this);

    this.handleError = handleError;

    const uiImageEle = document.createElement('img');
    uiImageEle.src = src;

    uiImageEle.classList.add('ws_uiImage');
    uiImageEle.draggable = false;
    uiImageEle.crossOrigin = 'anonymous';
    if(this.handleError) {
        uiImageEle.onerror = () => {
            uiImageEle.src = getPlaceholderThumbnail();
        }
    }
    uiImageEle.style.width = width;
    uiImageEle.style.height = height;
    title && (uiImageEle.title = title);

    this.dom = uiImageEle;
    return this;
}

UIImage.prototype = Object.create(UI.prototype);
UIImage.prototype.constructor = UIImage;

UIImage.prototype.setSrc = function (src) {
    this.dom.src = src;
    if(this.handleError) {
        this.dom.onerror = () => {
            this.dom.src = getPlaceholderThumbnail();
        }
    }
    return this;
}

function UIInputText(id, percentW, defValue, maxLength, placeholder, extraClass = null) {

    UI.call(this);

    const textInput = document.createElement('input');
    textInput.classList.add('ws_uiTextInput');
    extraClass !== null && textInput.classList.add(`ws_uiTextInput--${extraClass}`)
    textInput.style.width = percentW;
    textInput.defaultValue = defValue || '';
    textInput.placeholder = placeholder || '';
    textInput.autocomplete = 'off';
    maxLength && (textInput.maxLength = maxLength);
    textInput.id = id;

    this.dom = textInput;

    return this;
}

UIInputText.prototype = Object.create(UI.prototype);
UIInputText.prototype.constructor = UIInputText;

UIInputText.prototype.getValue = () => {
    return this.dom.value;
}

UIInputText.prototype.setReadOnly = function (value) {
    if (value !== undefined) {
        this.dom.readOnly = value;
        this.dom.style.pointerEvents = value ? 'none' : 'all';
    }

    return this;
}

function UIInputTextArea(id, percentW, defValue, maxLength, placeholder, rows = '2', extraClass = null) {

    UI.call(this);

    const textInput = document.createElement('textarea');
    textInput.rows = rows;
    textInput.classList.add('ws_uiTextAreaInput');
    extraClass !== null && textInput.classList.add(`ws_uiTextAreaInput--${extraClass}`)
    textInput.style.width = percentW;
    textInput.defaultValue = defValue || '';
    textInput.placeholder = placeholder || '';
    maxLength && (textInput.maxLength = maxLength);
    textInput.id = id;

    this.dom = textInput;

    return this;
}

UIInputTextArea.prototype = Object.create(UI.prototype);
UIInputTextArea.prototype.constructor = UIInputTextArea;

UIInputTextArea.prototype.getValue = () => {
    return this.dom.value;
}

UIInputTextArea.prototype.setReadOnly = function (value) {
    if (value !== undefined) {
        this.dom.readOnly = value;
        this.dom.style.pointerEvents = value ? 'none' : 'all';
    }

    return this;
}


function UINumber(number) {
    UI.call(this);

    var scope = this;

    const numContainer = document.createElement('div');
    numContainer.classList.add('ws_uiNumberCont');

    this.uiHelper = document.createElement('div');
    this.uiHelper.classList.add('ws_uiNumberHelper');

    this.numInput = document.createElement('input');
    this.numInput.style.cursor = 'ns-resize';
    this.numInput.classList.add('ws_uiNumber');
    this.numInput.value = '0.00';

    numContainer.appendChild(this.uiHelper);
    numContainer.appendChild(this.numInput);

    this.value = 0;
    this.prevValidValue = 0;
    this.min = -Infinity;
    this.max = Infinity;
    this.precision = 2;
    this.step = 1;
    this.unit = '';
    this.helper = '';
    this.nudge = 0.01;

    this.dom = numContainer;

    this.setValue(number);

    var changeEvent = document.createEvent('HTMLEvents');
    changeEvent.initEvent('change', true, true);

    var autoSaveEvent = document.createEvent('HTMLEvents');
    autoSaveEvent.initEvent('autosave', true, true);

    var distance = 0;
    var mouseDownVal = 0;

    var pointer = [0, 0];
    var prevPointer = [0, 0];

    function onMouseDown(e) {
        e.preventDefault();

        distance = 0;

        mouseDownVal = scope.value;

        prevPointer = [e.clientX, e.clientY];

        scope.numInput.dispatchEvent(autoSaveEvent);

        document.addEventListener('mousemove', onMouseMove, false);
        document.addEventListener('mouseup', onMouseUp, false);
    }

    function onMouseMove(e) {
        var currentVal = scope.value;

        pointer = [e.clientX, e.clientY];
        distance += (pointer[0] - prevPointer[0]) - (pointer[1] - prevPointer[1]);

        var value = mouseDownVal + (distance / (e.shiftKey ? 5 : 50)) * scope.step;
        value = Math.min(scope.max, Math.max(scope.min, value));

        if (currentVal !== value) {
            scope.setValue(value);
            scope.numInput.dispatchEvent(changeEvent);
        }

        prevPointer = [e.clientX, e.clientY];
    }

    function onMouseUp(e) {

        document.removeEventListener('mousemove', onMouseMove, false);
        document.removeEventListener('mouseup', onMouseUp, false);

        scope.numInput.dispatchEvent(autoSaveEvent);

        if (Math.abs(distance) < 2) {
            scope.numInput.focus();
            scope.numInput.select();
        }
    }

    function onTouchStart(e) {
        if (e.touches.length === 1) {
            distance = 0;

            mouseDownVal = scope.value;

            prevPointer = [e.touches[0].pageX, e.touches[0].pageY];

            scope.numInput.dispatchEvent(autoSaveEvent);
            
            document.addEventListener('touchmove', onTouchMove, false);
            document.addEventListener('touchend', onTouchEnd, false);
        }
    }

    function onTouchMove(e) {

        var currentVal = scope.value;

        pointer = [e.touches[0].pageX, e.touches[0].pageY];

        distance += (pointer[0] - prevPointer[0]) - (pointer[1] - prevPointer[1]);

        var value = mouseDownVal + (distance / (e.shiftKey ? 5 : 50)) * scope.step;
        value = Math.min(scope.max, Math.max(scope.min, value));

        if (currentVal !== value) {
            scope.setValue(value);
            scope.numInput.dispatchEvent(changeEvent);
        }

        prevPointer = [e.touches[0].pageX, e.touches[0].pageY];
    }

    function onTouchEnd(e) {
        if (e.touches.length === 0) {
            scope.numInput.dispatchEvent(autoSaveEvent);
            document.removeEventListener('touchmove', onTouchMove, false);
            document.removeEventListener('touchend', onTouchEnd, false);
        }
    }

    function onChange() {
        if (containsOnlyDigits(scope.numInput.value)) {
            scope.setValue(scope.numInput.value ? scope.numInput.value : '0');
        } else {
            scope.setValue(scope.prevValidValue);
        }
    }

    function containsOnlyDigits(str) {
        let lStr = "";
        if (str.includes('°')) {
            lStr = str.replace('°', '');
        } else {
            lStr = str;
        }
        return !isNaN(lStr) && !isNaN(parseFloat(lStr));
    }

    function onFocus() {
        if (containsOnlyDigits(scope.numInput.value)) {
            scope.prevValidValue = scope.numInput.value;
        }

        scope.numInput.style.backgroundColor = '';
        scope.numInput.style.cursor = '';
        scope.uiHelper.style.display = 'none';
    }

    function onBlur() {
        scope.numInput.style.backgroundColor = 'transparent';
        scope.numInput.style.cursor = 'ns-resize';
        scope.uiHelper.style.display = '';
    }

    function onKeyDown(e) {
        e.stopPropagation();

        switch (e.keyCode) {

            case 13: // Enter
                scope.numInput.blur();
                break;

            case 38: // key up
                e.preventDefault();
                scope.setValue(scope.getValue() + scope.nudge);
                scope.numInput.dispatchEvent(changeEvent);
                break;

            case 40: // key down
                e.preventDefault();
                scope.setValue(scope.getValue() - scope.nudge);
                scope.numInput.dispatchEvent(changeEvent);
                break;

            default:
                break;
        }
    }

    onBlur();

    this.numInput.addEventListener('keydown', onKeyDown, false);
    this.numInput.addEventListener('mousedown', onMouseDown, false);
    this.numInput.addEventListener('touchstart', onTouchStart, false);
    this.numInput.addEventListener('change', onChange, false);
    this.numInput.addEventListener('focus', onFocus, false);
    this.numInput.addEventListener('blur', onBlur, false);

    return this;
}

UINumber.prototype = Object.create(UI.prototype);
UINumber.prototype.constructor = UINumber;

UINumber.prototype.getValue = function () {
    return this.value;
}

UINumber.prototype.setValue = function (value) {
    if (value !== undefined) {
        value = parseFloat(value);

        if (value < this.min) value = this.min;
        if (value > this.max) value = this.max;

        this.value = value;
        this.numInput.value = value.toFixed(this.precision);

        if (this.unit !== '') this.numInput.value += ' ' + this.unit;
    }

    return this;
}

UINumber.prototype.setReadOnly = function (value) {
    if (value !== undefined) {
        this.numInput.readOnly = value;
        this.numInput.style.pointerEvents = value ? 'none' : 'all';
    }

    return this;
}

UINumber.prototype.setPrecision = function (precision) {

    this.precision = precision;
    return this;
}

UINumber.prototype.setStep = function (step) {

    this.step = step;
    return this;
}

UINumber.prototype.setNudge = function (nudge) {

    this.nudge = nudge;
    return this;
}

UINumber.prototype.setRange = function (min, max) {

    this.min = min;
    this.max = max;
    return this;
}

UINumber.prototype.setUnit = function (unit) {

    this.unit = unit;
    return this;
}

UINumber.prototype.setHelper = function (helper) {
    this.helper = helper;
    this.uiHelper.textContent = this.helper;
    return this;
}

// Color
function UIColor() {
    UI.call(this);

    var colorPicker = document.createElement('input');
    colorPicker.classList.add('ws_uiColor');
    colorPicker.id = "ws_uiColorPicker";

    try {
        colorPicker.type = 'color';
        colorPicker.value = '#ffffff';
    } catch (e) { console.log("EXCEPTION: Couldn't setup color picker!!") }

    this.dom = colorPicker;

    return this;
}

UIColor.prototype = Object.create(UI.prototype);
UIColor.prototype.constructor = UIColor;

UIColor.prototype.getValue = function () {
    return this.dom.value;
}

UIColor.prototype.setValue = function (value) {
    this.dom.value = value;
    return this;
}

UIColor.prototype.getHexValue = function () {
    return parseInt(this.dom.value.substring(1), 16);
}

UIColor.prototype.setHexValue = function (hex) {
    this.dom.value = `#${('000000' + hex.toString(16)).slice(-6)}`;
    return this;
}

//UI Color Card
function UIColorPalette(defColorHex, customColors = []) {
    UI.call(this);

    // this.defPalette = customColors.length > 0 ? customColors : ['#3A64F5', '#04D9FF', '#FFDC26', '#FD7A02', '#E92B01', '#E90155', '#FF08BA', '#E908FF', '#AB43FC', '#FFFFFF', '#ADFE03', '#07D91E'];
    this.defPalette = customColors.length > 0 ? customColors : ['#36CCE7', '#FFE768', '#4A4A4A', '#6689FF', '#BC67FF', '#FF6CA2', '#F166FF', '#CFFF69', '#6BFF7B', '#FF8165', '#FFB16A', '#FF68D5', '#803D00', '#17181C'];

    this.activeColor = defColorHex || this.defPalette[0];
    this.uiPaletteVariants = [];

    const colorCardCont = document.createElement('div');
    colorCardCont.classList.add('ws_uiColorPalette');

    this.dom = colorCardCont;

    this.setPaletteVariants();

    return this;
}

UIColorPalette.prototype = Object.create(UI.prototype);
UIColorPalette.prototype.constructor = UIColorPalette;

UIColorPalette.prototype.setPaletteVariants = function () {

    const scope = this;

    function onClick(e) {
        scope.setValue(e.target.id);
        let changeEvent = document.createEvent('HTMLEvents');
        changeEvent.initEvent('change', true, true);
        scope.dom.dispatchEvent(changeEvent);
    }

    for (var i = 0; i < this.defPalette.length; i++) {
        const colorDiv = document.createElement('div');
        colorDiv.classList.add('ws_uiColorPalette--colorOptn');
        colorDiv.id = this.defPalette[i];
        colorDiv.style.backgroundColor = this.defPalette[i];
        this.defPalette[i] === this.activeColor && colorDiv.classList.add('ws_uiColorPalette--colorActive');

        colorDiv.addEventListener('click', onClick);

        this.dom.appendChild(colorDiv);
        this.uiPaletteVariants.push(colorDiv);
    }

    return this;
}

UIColorPalette.prototype.getValue = function () {
    return this.activeColor;
}

UIColorPalette.prototype.updatePaletteToNoWhite = function (visibility) {
    this.uiPaletteVariants.forEach(ele => ele.id === '#FFFFFF' && (ele.style.display = visibility ? 'none' : ''))
}

UIColorPalette.prototype.setValue = function (value) {
    for (var i = 0; i < this.uiPaletteVariants.length; i++) {
        var element = this.uiPaletteVariants[i];
        if (element.id.toLowerCase() === value.toLowerCase()) {
            element.classList.add('ws_uiColorPalette--colorActive');
            this.activeColor = value;
        } else {
            element.classList.remove('ws_uiColorPalette--colorActive');
        }
    }
    return this;
}

// Radio
function UIRadioButton(bool, name) {
    UI.call(this);

    var radio = document.createElement('input');
    radio.type = 'radio';
    radio.name = name;
    radio.classList.add('ws_uiCheckbox');

    this.dom = radio;
    this.setValue(bool)

    return this;
}

UIRadioButton.prototype = Object.create(UI.prototype);
UIRadioButton.prototype.constructor = UIRadioButton;

UIRadioButton.prototype.getValue = function () {
    return this.dom.checked;
}

UIRadioButton.prototype.setValue = function (bool) {
    if (bool !== undefined) {
        this.dom.checked = bool;
        this.dom.value = bool;
    }

    return this;
}

// checkbox
function UICheckbox(bool) {
    UI.call(this);

    var checkBox = document.createElement('input');
    checkBox.type = 'checkbox';
    checkBox.classList.add('ws_uiCheckbox');

    this.dom = checkBox;
    this.setValue(bool)

    return this;
}

UICheckbox.prototype = Object.create(UI.prototype);
UICheckbox.prototype.constructor = UICheckbox;

UICheckbox.prototype.getValue = function () {
    return this.dom.checked;
}

UICheckbox.prototype.setValue = function (bool) {
    if (bool !== undefined) {
        this.dom.checked = bool;
        this.dom.value = bool;
    }

    return this;
}

function UIArrowCheckbox(bool) {
    UI.call(this);

    var checkBoxContainer = document.createElement('label');
    checkBoxContainer.classList.add('ws_uiArrowCheckbox');

    this.checkBox = document.createElement('input');
    this.checkBox.type = 'checkbox';
    this.checkBox.classList.add('ws_uiCheckbox');
    checkBoxContainer.appendChild(this.checkBox);

    var arrowSpan = document.createElement('span');
    arrowSpan.classList.add('ws_uiArrowMark');
    checkBoxContainer.appendChild(arrowSpan);

    this.dom = checkBoxContainer;
    this.setValue(bool)

    return this;
}

UIArrowCheckbox.prototype = Object.create(UI.prototype);
UIArrowCheckbox.prototype.constructor = UIArrowCheckbox;

UIArrowCheckbox.prototype.getValue = function () {
    return this.checkBox.checked;
}

UIArrowCheckbox.prototype.setValue = function (bool) {
    if (bool !== undefined) {
        this.checkBox.checked = bool;
        this.checkBox.value = bool;
    }

    return this;
}

//UI Auido
function UIAudio() {
    UI.call(this);

    this.error = false;

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiLibraryCardAudio');
    uiCont.id = 'ws_uiAudioCard';

    this.uiAudCont = document.createElement('div');
    this.uiAudCont.classList.add('ws_uiAudioContainer');
    this.uiAudCont.classList.add('ws_uiAudioContainer--cardExtended');

    const uiAudioBtn = document.createElement('img');
    uiAudioBtn.draggable = false;
    uiAudioBtn.classList.add('ws_uiAudioBtn');
    uiAudioBtn.src = playIcon;
    uiAudioBtn.id = "playIcon";
    this.uiAudCont.appendChild(uiAudioBtn);

    //Audio Player Progress
    const uiCircularTrack = document.createElement('div');
    uiCircularTrack.classList.add('ws_uiCircularTrack');

    this.uiAudCont.appendChild(uiCircularTrack);

    this.uiAudio = document.createElement('audio');
    this.uiAudio.classList.add('ws_uiAudio')
    this.uiAudio.id = "ws_Audio"
    this.uiAudio.canPlayType('audio/*');
    this.uiAudio.setAttribute("controls", "controls");
    this.uiAudio.setAttribute("controlsList", "nodownload");
    this.uiAudio.crossOrigin = 'anonymous';
    this.uiAudCont.appendChild(this.uiAudio);
    //Audio Event Handlers!
    this.uiAudio.addEventListener('loadedmetadata', () => {
        uiAudioBtn.src = playIcon;
        this.error = false
    })
    this.uiAudio.addEventListener('error', () => {
        uiAudioBtn.src = playErrorIcon;
        this.error = true;
        uiCircularTrack.style.background = '#353E5A';
    });
    this.uiAudio.ontimeupdate = () => {
        uiCircularTrack.style.background = `conic-gradient(
            #FFFFFF ${((this.uiAudio.currentTime / this.uiAudio.duration) * 100) * 3.6}deg,
            #353E5A ${((this.uiAudio.currentTime / this.uiAudio.duration) * 100) * 3.6}deg
        )`;
    }
    this.uiAudio.onended = () => {
        uiAudioBtn.src = playIcon;
        uiCircularTrack.style.background = '#353E5A';
    }

    const toggleAudio = () => {
        if (!this.error) {
            if (this.uiAudio.paused) {
                this.uiAudio.play();
                uiAudioBtn.src = pauseIcon;
            } else {
                this.uiAudio.pause();
                uiAudioBtn.src = playIcon;
            }
        }
    }
    this.uiAudCont.addEventListener('click', toggleAudio);

    uiCont.appendChild(this.uiAudCont);

    this.dom = uiCont;

    return this;
}

UIAudio.prototype = Object.create(UI.prototype);
UIAudio.prototype.constructor = UIAudio;

UIAudio.prototype.setSrcAttribute = function (audioLink) {
    if (audioLink !== undefined) {
        this.uiAudio.setAttribute('src', audioLink);
    }
    return this;
}

// UI Video
function UIVideo() {
    UI.call(this);

    this.error = false;

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiVideoCont');
    uiCont.id = 'ws_uiVideoCard';

    this.uiVideo = document.createElement('video');
    this.uiVideo.classList.add('ws_uiVideo')
    this.uiVideo.id = "ws_Video"
    this.uiVideo.canPlayType('video/*');
    this.uiVideo.muted = false;
    this.uiVideo.setAttribute("controls", "controls");
    this.uiVideo.setAttribute("controlsList", "nodownload");
    this.uiVideo.setAttribute("crossOrigin", "anonymous");
    uiCont.appendChild(this.uiVideo);
    //Audio Event Handlers!
    this.uiVideo.addEventListener('loadedmetadata', () => {
        this.error = false
    })
    this.uiVideo.addEventListener('error', () => {
        this.error = true;
    });

    this.dom = uiCont;

    return this;

}

UIVideo.prototype = Object.create(UI.prototype);
UIVideo.prototype.constructor = UIVideo;

UIVideo.prototype.setSrcAttribute = function (videoLink) {
    if (videoLink !== undefined) {
        this.uiVideo.setAttribute('src', videoLink);
    }
    return this;
}

//UI Outliner
function UIOutliner(editor) {
    UI.call(this);

    var scope = this;

    var domOutliner = document.createElement('div');
    domOutliner.classList.add('ws_uiOutliner');
    domOutliner.tabIndex = 0;

    this.scene = editor.scene;

    domOutliner.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
            case 38:
            case 40:
                e.preventDefault();
                e.stopPropagation();

                break;

            default:
                break;
        }
    }, false);

    domOutliner.addEventListener('keyup', function (e) {
        switch (e.keyCode) {

            case 38:
                scope.setIndex(scope.selectedIndex - 1);
                break;

            case 40:
                scope.setIndex(scope.selectedIndex + 1);
                break;

            default:
                break;
        }
    }, false);

    this.dom = domOutliner;
    this.editor = editor;

    this.options = [];
    this.selectedIndex = -1;
    this.selectedValue = null;

    return this;
}

UIOutliner.prototype = Object.create(UI.prototype);
UIOutliner.prototype.constructor = UIOutliner;

UIOutliner.prototype.setIndex = function (index) {
    if (index >= 0 && index < this.options.length) {
        this.setValue(this.options[index].value);

        let changeEvent = document.createEvent('HTMLEvents');
        changeEvent.initEvent('change', true, true);
        this.dom.dispatchEvent(changeEvent);

    }
}

UIOutliner.prototype.setOptions = function (options) {
    var scope = this;

    while (scope.dom.children.length > 0) {
        scope.dom.removeChild(scope.dom.firstChild);
    }

    function onClick(e) {
        scope.setValue(this.value);
        let changeEvent = document.createEvent('HTMLEvents');
        changeEvent.initEvent('change', true, true);
        scope.dom.dispatchEvent(changeEvent);
    }

    function onDblClick() {
        scope.setValue(this.value);
    }

    scope.options = [];

    for (var i = 0; i < options.length; i++) {
        var div = options[i].dom;
        div.classList.add("ws_uiOutliner__option");
        scope.dom.appendChild(div);

        scope.options.push(div);

        !options[i].noClick && div.addEventListener('click', onClick);
        !options[i].noClick && div.addEventListener('dblclick', onDblClick);
    }

    return scope;
}

UIOutliner.prototype.getValue = function () {
    return this.selectedValue;
}

UIOutliner.prototype.setValue = function (value) {
    for (var i = 0; i < this.options.length; i++) {

        var element = this.options[i];

        if (element.value === value) {
            element.classList.add('ws_uiOutliner__option--activeItem');

            //Scroll

            let y = element.offsetTop - this.dom.offsetTop;
            let botY = y + element.offsetHeight;
            let minScroll = botY - this.dom.offsetHeight;

            if (this.dom.scrollTop > y) {
                this.dom.scrollTop = y;
            } else if (this.dom.scrollTop < minScroll) {
                this.dom.scrollTop = minScroll;
            }

            this.selectedIndex = i;
        } else {
            element.classList.remove('ws_uiOutliner__option--activeItem');
        }
    }

    this.selectedValue = value;

    return this;
}

//UITabs
function UITabs(tabKeys, tabNames, defActive) {

    UI.call(this);

    this.active = defActive;

    const tabContainer = document.createElement('div');
    tabContainer.classList.add('wsTabContainer');

    this.tabHeaderRow = document.createElement('div');
    this.tabHeaderRow.classList.add('wsTabRow');

    this.tabContents = document.createElement('div');
    this.tabContents.classList.add('wsTabContent');


    for (var i = 0; i < tabKeys.length; i++) {
        let tabSpan = document.createElement('div');
        tabSpan.classList.add('wsTab')
        tabSpan.innerHTML = tabNames[i];
        tabSpan.id = tabKeys[i];
        tabSpan.addEventListener('click', (e) => this.handleTabChange(e), false);
        tabSpan.classList.add(tabKeys[i] === defActive ? 'wsTab--active' : null);
        this.tabHeaderRow.appendChild(tabSpan);
    }

    tabContainer.appendChild(this.tabHeaderRow);
    tabContainer.appendChild(this.tabContents);



    this.handleTabChange = (e, defTab = undefined) => {
        this.active = e ? parseInt(e.target.id) : defTab;
        for (let j = 0; j < tabKeys.length; j++) {
            if (j === this.active) {
                this.tabHeaderRow.children[j].classList.add('wsTab--active');
                this.tabContents.children[j]?.classList.add('wsTab--visible');
                this.tabContents.children[j]?.classList.remove('wsTab--hidden');
            } else {
                this.tabHeaderRow.children[j].classList.remove('wsTab--active');
                this.tabContents.children[j]?.classList.add('wsTab--hidden');
                this.tabContents.children[j]?.classList.remove('wsTab--visible');
            }
        }
    }
    this.dom = tabContainer;
}

UITabs.prototype = Object.create(UI.prototype);
UITabs.prototype.constructor = UITabs;

UITabs.prototype.setComponents = function (components) {
    for (var i = 0; i < components.length; i++) {
        this.tabContents.appendChild(components[i]);
    }
    this.handleTabChange(undefined, this.active);
    return this;
}

//UI Button
function UIButton(btnText, className = undefined, noClass = false) {
    UI.call(this);

    const uiBtn = document.createElement('button');
    !noClass && uiBtn.classList.add('ws_uiButton');

    className && (uiBtn.className = className);

    uiBtn.textContent = btnText;
    uiBtn.id = `ws_uiButton${btnText.replaceAll(/\s/g, '')}`;

    this.dom = uiBtn;
}

UIButton.prototype = Object.create(UI.prototype);
UIButton.prototype.constructor = UIButton;

//UI Text Button
function UITextButton(btnText) {
    UI.call(this);

    const uiBtn = document.createElement('div');
    uiBtn.classList.add('ws_uiTextButton');

    uiBtn.textContent = btnText;

    this.dom = uiBtn;
}

UITextButton.prototype = Object.create(UI.prototype);
UITextButton.prototype.constructor = UITextButton;

//UI Switch
function UISwitch(value, enable = true, id = "ws_uiSlider") {
    UI.call(this);

    this.value = value;

    const uiLabel = document.createElement('label');
    uiLabel.classList.add('ws_uiSwitch');

    this.uiInput = document.createElement('input');
    this.uiInput.classList.add('ws_uiSwitchInput');
    this.uiInput.type = 'checkbox';
    this.uiInput.checked = this.value;
    this.uiInput.value = this.value
    this.uiInput.disabled = !enable;
    this.uiInput.id = id;

    const uiSlider = document.createElement('span');
    uiSlider.classList.add('ws_uiSlider');
    uiSlider.classList.add('ws_uiSliderRound');

    uiLabel.appendChild(this.uiInput);
    uiLabel.appendChild(uiSlider);

    this.dom = uiLabel;
}


UISwitch.prototype = Object.create(UI.prototype);
UISwitch.prototype.constructor = UISwitch;

UISwitch.prototype.setValue = function (value) {
    this.value = value;
    this.uiInput.checked = value;
    this.uiInput.value = value;
    return this;
}

UISwitch.prototype.setEnable = function (value) {
    this.uiInput.disabled = !value;
    return this;
}

// UI ImageInput
function UIImageInput(label) {
    UI.call(this);

    this.src = '';
    this.file = null;
    const scope = this;

    //Invisible UI Input
    const form = document.createElement('form');
    form.style.display = 'none';
    document.body.appendChild(form);
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.id = "AccessPointThumbnailUploader";
    fileInput.accept = "image/jpeg, image/png";
    fileInput.addEventListener('change', () => {
        //Invoke Things!
        var fr = new FileReader();
        fr.onload = function () {
            scope.setSrc(fr.result)
        }
        fr.readAsDataURL(fileInput.files[0]);

        scope.file = fileInput.files[0];
        let changeEvent = document.createEvent('HTMLEvents');
        changeEvent.initEvent('change', true, true);
        scope.dom.dispatchEvent(changeEvent);

        form.reset();
    });
    form.appendChild(fileInput);

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiImageInputCont');

    this.uiImg = document.createElement('img');
    this.uiImg.src = this.src;
    this.uiImg.classList.add('ws_uiImageInputCont--image');
    this.uiImg.draggable = false;
    this.uiImg.crossOrigin = 'anonymous';
    uiCont.appendChild(this.uiImg);

    const uiLabel = document.createElement('div');
    uiLabel.classList.add('ws_uiImageInputCont--label');
    uiLabel.textContent = `Choose a different file`;
    uiLabel.id = `button-Change${label}`;
    uiLabel.addEventListener('click', () => fileInput.click());
    uiCont.appendChild(uiLabel);

    this.dom = uiCont;
}

UIImageInput.prototype = Object.create(UI.prototype);
UIImageInput.prototype.constructor = UIImageInput;

UIImageInput.prototype.getFile = function () {
    return this.file;
}

UIImageInput.prototype.setSrc = function (src) {
    this.src = src;
    this.uiImg.src = this.src;

    return this;
}

// floorplan
function UIFloorplanImgInput(label) {
    UI.call(this);

    this.src = '';
    this.aligned = false;
    this.file = null;
    this.error = null;
    const scope = this;

    //Invisible UI Input
    const form = document.createElement('form');
    form.style.display = 'none';
    document.body.appendChild(form);
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.id = "FloorplanThumbnailUploader";
    fileInput.accept = "image/jpeg, image/png";
    fileInput.addEventListener('change', () => {
        // handle it with event
        // check file size
        if(validateSize(fileInput.files[0], 'floorplans')) {
            scope.file = fileInput.files[0];
        } else {
            scope.error = 'MORE SIZE';
        }
        let changeEvent = document.createEvent('HTMLEvents');
        changeEvent.initEvent('change', true, true);
        scope.dom.dispatchEvent(changeEvent);
        form.reset();
    });
    form.appendChild(fileInput);

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiImageInputCont');

    const uiHeader = document.createElement('span');
    uiHeader.classList.add('ws_uiImageInputCont--header');
    uiHeader.textContent = "My Uploads";
    uiCont.appendChild(uiHeader);

    const uiImgCont = document.createElement('div');
    uiImgCont.classList.add('ws_uiImageInputCont--imgCont');

    this.uiImg = document.createElement('img');
    this.uiImg.src = this.src;
    this.uiImg.classList.add('ws_uiImageInputCont--image');
    this.uiImg.draggable = false;
    this.uiImg.crossOrigin = 'anonymous';
    uiImgCont.appendChild(this.uiImg);

    this.uiAlignCont = document.createElement('div');
    this.uiAlignCont.classList.add('ws_uiFPAlignCont');
    this.uiAlignCont.style.display = this.aligned ? 'flex' : 'none';
    const checkImg = document.createElement('img');
    checkImg.setAttribute("src", floorAlignedIcon);
    const checkText = document.createElement('span');
    checkText.textContent = "Floorplan is Aligned"

    this.uiAlignCont.appendChild(checkImg);
    this.uiAlignCont.appendChild(checkText);

    uiImgCont.appendChild(this.uiAlignCont);
    uiCont.appendChild(uiImgCont);

    const uiLabel = document.createElement('div');
    uiLabel.classList.add('ws_uiImageInputCont--label');
    uiLabel.textContent = `Choose a different file`;
    uiLabel.id = `button-Change${label}`;
    uiLabel.addEventListener('click', () => fileInput.click());
    uiCont.appendChild(uiLabel);

    this.dom = uiCont;
}

UIFloorplanImgInput.prototype = Object.create(UI.prototype);
UIFloorplanImgInput.prototype.constructor = UIFloorplanImgInput;

UIFloorplanImgInput.prototype.setSrc = function (src) {
    this.src = src;
    this.file = null;
    this.uiImg.src = this.src;

    return this;
}

UIFloorplanImgInput.prototype.getFile = function () {
    return this.file;
}

UIFloorplanImgInput.prototype.getError = function () {
    return this.error;
}

UIFloorplanImgInput.prototype.setAligned = function (value) {
    this.aligned = value;
    this.uiAlignCont.style.display = value ? 'flex' : 'none';

    return this;
}

UIFloorplanImgInput.prototype.resetError = function () {
    this.error = null;
    return this;
}

// Image
function UILibraryCardImage(content, link, title, callBack) {
    UI.call(this);

    this.content = content;

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiLibraryCard');
    uiCont.id = "ws_uiImageCard";

    const uiCardImg = document.createElement('img');
    uiCardImg.classList.add('ws_uiLibraryImg');
    uiCardImg.src = link;
    uiCardImg.alt = uiCardImg.title = title;
    uiCardImg.crossOrigin = 'anonymous'
    uiCardImg.onerror = () => {
        uiCardImg.src = defImg;
        uiCardImg.alt = uiCardImg.title = 'DELETED ASSET';
        this.dom.removeEventListener('click', handleClick, false)
    }
    uiCardImg.draggable = false;
    uiCardImg.id = link;

    const uiText = document.createElement('span');
    uiText.classList.add('ws_uiLibraryTitle')
    uiText.textContent = title;
    uiText.id = "ws_uiImageName";


    uiCont.appendChild(uiCardImg);
    uiCont.appendChild(uiText);
    this.dom = uiCont;

    const handleClick = (e) => {
        callBack(this.content);
    }

    this.dom.addEventListener('click', handleClick, false)
}

UILibraryCardImage.prototype = Object.create(UI.prototype);
UILibraryCardImage.prototype.constructor = UILibraryCardImage;

function UILibraryCardAudio(editor, content, link, title, callBack) {
    UI.call(this);

    this.content = content;
    this.editor = editor;
    this.duration = 0.0;
    this.error = false;
    this.uuid = getRandomIdCode();

    //Event Handlers
    const pauseAudio = (uuid) => {
        this.uuid !== uuid && !uiAudio.paused && toggleAudio(false);
    }

    // PLAY PASUE!
    this.editor.on('audioPauseRest', pauseAudio);

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiLibraryCardAudio');
    uiCont.id = "ws_uiAudioCard"

    this.uiAudCont = document.createElement('div');
    this.uiAudCont.classList.add('ws_uiAudioContainer');

    const uiAudioBtn = document.createElement('img');
    uiAudioBtn.draggable = false;
    uiAudioBtn.classList.add('ws_uiAudioBtn');
    uiAudioBtn.src = playIcon;
    uiAudioBtn.crossOrigin = 'anonymous'
    this.uiAudCont.appendChild(uiAudioBtn);

    //Audio Player Progress
    const uiCircularTrack = document.createElement('div');
    uiCircularTrack.classList.add('ws_uiCircularTrack');

    this.uiAudCont.appendChild(uiCircularTrack);

    const uiAudio = document.createElement('audio');
    uiAudio.classList.add('ws_uiAudio')
    uiAudio.canPlayType('audio/*');
    uiAudio.src = link;
    uiAudio.setAttribute("controls", "controls");
    uiAudio.setAttribute("controlsList", "nodownload");
    uiAudio.setAttribute("crossOrigin", "anonymous");
    this.uiAudCont.appendChild(uiAudio);
    //Audio Event Handlers!
    uiAudio.addEventListener('loadedmetadata', () => {
        this.duration = uiAudio.duration;
        this.uiDurnText.textContent = this.duration.toFixed(2);
    });
    uiAudio.addEventListener('error', () => {
        this.uiAudioInfo.removeEventListener('click', handleClick, false);
        uiAudioBtn.src = playErrorIcon;
        this.error = true
    });
    uiAudio.ontimeupdate = () => {
        uiCircularTrack.style.background = `conic-gradient(
            #FFFFFF ${((uiAudio.currentTime / uiAudio.duration) * 100) * 3.6}deg,
            #353E5A ${((uiAudio.currentTime / uiAudio.duration) * 100) * 3.6}deg
        )`;
    }
    uiAudio.onended = () => {
        uiAudioBtn.src = playIcon;
        uiCircularTrack.style.background = '#353E5A';
    }

    const toggleAudio = (trigger = true) => {
        if (!this.error) {
            if (uiAudio.paused) {
                uiAudio.play();
                uiAudioBtn.src = pauseIcon;
                trigger && this.editor.trigger('audioPauseRest', [this.uuid])
            } else {
                uiAudio.pause();
                uiAudioBtn.src = playIcon;
                this.uiDurnText.textContent = this.duration.toFixed(2);
            }
        }
    }
    this.uiAudCont.addEventListener('click', toggleAudio);

    this.uiAudioInfo = document.createElement('div');
    this.uiAudioInfo.classList.add('ws_uiAudioInfo')

    const uiText = document.createElement('span');
    uiText.classList.add('ws_uiAudioTitle')
    uiText.textContent = title;
    this.uiAudioInfo.appendChild(uiText);

    this.uiDurnText = document.createElement('span');
    this.uiDurnText.classList.add('ws_uiAudioText')
    this.uiDurnText.textContent = this.duration;
    this.uiAudioInfo.appendChild(this.uiDurnText);

    uiCont.appendChild(this.uiAudCont);
    uiCont.appendChild(this.uiAudioInfo);

    this.dom = uiCont;

    const handleClick = (e) => {
        callBack(this.content);
    }

    this.uiAudioInfo.addEventListener('click', handleClick, false);

}

UILibraryCardAudio.prototype = Object.create(UI.prototype);
UILibraryCardAudio.prototype.constructor = UILibraryCardAudio;

//Videos
function UILibraryCardVideo(content, link, title, callBack) {
    UI.call(this);

    this.content = content;

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiLibraryCard');
    uiCont.id = 'ws_uiVideoCard';

    const uiCardImg = document.createElement('img');
    uiCardImg.classList.add('ws_uiLibraryImg');
    uiCardImg.src = link;
    uiCardImg.alt = uiCardImg.title = title;
    uiCardImg.crossOrigin = 'anonymous'
    uiCardImg.onerror = () => {
        uiCardImg.src = defVidImg;
    }
    uiCardImg.draggable = false;
    uiCardImg.id = link;

    const uiText = document.createElement('span');
    uiText.classList.add('ws_uiLibraryTitle')
    uiText.textContent = title;
    uiText.id = 'ws_uiVideoName';

    uiCont.appendChild(uiCardImg);
    uiCont.appendChild(uiText);
    this.dom = uiCont;

    const handleClick = (e) => {
        callBack(this.content);
    }

    this.dom.addEventListener('click', handleClick, false)
}

UILibraryCardVideo.prototype = Object.create(UI.prototype);
UILibraryCardVideo.prototype.constructor = UILibraryCardVideo;

// Models
function UILibraryCardModel(content, link, title, callBack) {
    UI.call(this);

    this.content = content;

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiLibraryCard');
    uiCont.id = 'ws_uiModelCard';

    const uiCardImg = document.createElement('img');
    uiCardImg.classList.add('ws_uiLibraryImg');
    uiCardImg.src = link.length > 0 ? link : defModelIcon;
    uiCardImg.alt = uiCardImg.title = title;
    uiCardImg.crossOrigin = 'anonymous'
    link.length <= 0 && (uiCardImg.onerror = (e) => {
        uiCardImg.src = defImg;
        uiCardImg.alt = uiCardImg.title = 'DELETED ASSET';
        this.dom.removeEventListener('click', handleClick, false)
    })
    uiCardImg.draggable = false;
    uiCardImg.id = link;

    const uiText = document.createElement('span');
    uiText.classList.add('ws_uiLibraryTitle')
    uiText.textContent = title;
    uiText.id = "ws_uiModelCardName";

    uiCont.appendChild(uiCardImg);
    uiCont.appendChild(uiText);
    this.dom = uiCont;

    const handleClick = (e) => {
        callBack(this.content);
    }

    this.dom.addEventListener('click', handleClick, false)
}

UILibraryCardModel.prototype = Object.create(UI.prototype);
UILibraryCardModel.prototype.constructor = UILibraryCardModel;

// Tours
function UIObjectInfoCard(editor, content, object, callBack, iconName, index = '') {
    UI.call(this);

    this.content = content;
    this.object = object;
    this.editor = editor;

    this.editor.on('sidebarVisibleChanged', (object) => {
        if (object.userData.id === this.object.userData.id) {
            uiEyeIcon.src = object?.visible ? eyeOpenIcon : eyeClosedIcon;
        }
    })

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiLibraryCardTour');
    this.editor.selectedObject === this.object && uiCont.classList.add('ws_uiLibraryCardTour--active');
    uiCont.id = `ws_uiObjectCard${index}`;

    const uiTourIcon = document.createElement('img');
    uiTourIcon.classList.add('ws_uiLibraryIcon');
    uiTourIcon.src = icons[iconName];
    uiTourIcon.alt = 'iconName';
    uiTourIcon.draggable = false;
    uiTourIcon.crossOrigin = 'anonymous';

    if(iconName === 'LOCATION' &&  this.object?.userData?.featuredPin === true) {
        const uiTourIconFeat = document.createElement('img');
        uiTourIconFeat.classList.add('ws_uiLibraryFeatureIcon');
        uiTourIconFeat.src = FeaturePinIcon;
        uiTourIconFeat.alt = 'FeaturePinIcon';
        uiTourIconFeat.draggable = false;
        uiTourIconFeat.crossOrigin = 'anonymous';
        uiCont.appendChild(uiTourIconFeat);
    }

    const uiText = document.createElement('span');
    uiText.classList.add('ws_uiTourCardTitle')
    uiText.textContent = this.content?.name || this.object?.name || '';

    const uiEditIcon = document.createElement('img');
    uiEditIcon.classList.add('ws_uiLibraryIcon');
    uiEditIcon.classList.add('ws_uiLibraryIcon--w70');
    uiEditIcon.src = editIcon;
    uiEditIcon.alt = uiEditIcon.title = 'edit';
    uiEditIcon.draggable = false;
    uiEditIcon.crossOrigin = 'anonymous';
    uiEditIcon.id = `ws_uiObjectEdit${index}`;

    const uiEyeIcon = document.createElement('img');
    uiEyeIcon.classList.add('ws_uiLibraryIcon');
    uiEyeIcon.src = object?.visible ? eyeOpenIcon : eyeClosedIcon;
    uiEyeIcon.alt = uiEyeIcon.title = 'visible';
    uiEyeIcon.draggable = false;
    uiEyeIcon.crossOrigin = 'anonymous';
    uiEyeIcon.id = `ws_uiObject${object?.visible ? 'Hide' : 'Visible'}Icon${index}`;

    uiCont.appendChild(uiTourIcon);
    uiCont.appendChild(uiText);
    uiCont.appendChild(uiEditIcon);
    uiCont.appendChild(uiEyeIcon);

    this.dom = uiCont;

    const handleClick = (clickKey) => {
        callBack(this.content, clickKey);
    }
    uiEditIcon.addEventListener('click', (e) => { e.stopPropagation(); handleClick(1) }, false)
    uiEyeIcon.addEventListener('click', (e) => { e.stopPropagation(); handleClick(2) }, false)
    this.dom.addEventListener('click', (e) => { e.stopPropagation(); handleClick(3) }, false)
}

UIObjectInfoCard.prototype = Object.create(UI.prototype);
UIObjectInfoCard.prototype.constructor = UIObjectInfoCard;


function UIAnimations(editor) {
    UI.call(this);

    this.editor = editor;

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiAnimation');

    this.uiAnimations = [];
    this.object = null;
    this.activeTrigger = null;

    this.dom = uiCont;
}

UIAnimations.prototype = Object.create(UI.prototype);
UIAnimations.prototype.constructor = UIAnimations;

UIAnimations.prototype.setAnimations = function (object, animations) {
    if (animations && animations.length > 0 && object) {
        this.object = object;

        const scope = this;

        function onClick(e) {
            const value = e.target.id;
            scope.setValue(value);
            if (scope.activeTrigger === 'Play') {
                scope.editor.trigger('startAnimation', [scope.object, value]);
            } else {
                scope.editor.trigger('stopAnimation', [scope.object, value]);
            }
        }

        while (this.dom.children.length > 0) {
            this.dom.firstChild.removeEventListener('click', onClick);
            this.dom.removeChild(this.dom.firstChild);
        }

        for (var i = 0; i < animations.length; i++) {
            const animationDiv = document.createElement('div');
            animationDiv.classList.add('ws_uiAnimation--animationDiv');
            animationDiv.id = "ws_uiAnimationCard";
            const textDiv = document.createElement('div');
            textDiv.classList.add('ws_uiAnimation--aText');
            textDiv.textContent = animations[i];
            const iconSPan = document.createElement('span');
            textDiv.id = iconSPan.id = animationDiv.id = animations[i];
            animationDiv.appendChild(iconSPan);
            animationDiv.appendChild(textDiv);
            animations[i] === this.activeAnimation && animationDiv.classList.add('ws_uiColorPalette--aPlaying');

            animationDiv.addEventListener('click', onClick);

            this.dom.appendChild(animationDiv);
            this.uiAnimations.push(animationDiv);
        }
    }
    return this;
}

UIAnimations.prototype.setValue = function (value) {
    const className = 'ws_uiAnimation--aPlaying';
    for (var i = 0; i < this.uiAnimations.length; i++) {
        var element = this.uiAnimations[i];
        if (element.id.toLowerCase() === value.toLowerCase()) {
            if (element.classList.contains(className)) {
                element.classList.remove(className);
                this.activeTrigger = 'Pause';
            } else {
                element.classList.add(className);
                this.activeTrigger = 'Play';
            }
            this.activeAnimation = value;
        } else {
            element.classList.remove(className);
        }
    }
    return this;
}

UIAnimations.prototype.triggerStop = function () {
    this.editor.trigger('stopAnimation', [this.object]);
    return this;
}

function UIQRCode(mapCode, mapData) {
    UI.call(this);

    this.mapCode = mapCode;
    this.mapName = mapData?.metadata?.mapName || 'Test';
    this.qrId = null;

    this.size = 256;
    this.isRendered = false;
    const scope = this;

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiQRCont');
    uiCont.id = 'ws_uiQRCard';

    const uiQrCodeId = document.createElement('div');
    uiQrCodeId.classList.add('ws_uiQRCont--qrIdDiv');
    uiQrCodeId.innerHTML = "QR Code ID:";
    this.uiQrIdSpan = document.createElement('span');
    this.uiQrIdSpan.innerHTML = this.qrId;
    uiQrCodeId.appendChild(this.uiQrIdSpan);
    uiCont.appendChild(uiQrCodeId);

    //QR Things
    const uiParentCont = document.createElement('div');
    uiParentCont.classList.add('ws_uiQRCont--parentContDiv')
    const uiQRParent = document.createElement('div');
    uiQRParent.classList.add('ws_uiQRParent');
    uiQRParent.id = 'UIQRParent'
    this.uiQRCode = document.createElement('div');
    this.uiQRCode.id = 'arwayQRCode';
    uiQRParent.appendChild(this.uiQRCode);
    const uiQRLogo = document.createElement('div');
    uiQRLogo.classList.add('ws_uiQRLogosCont');

    this.uiLogoImg = document.createElement('img');
    this.uiLogoImg.classList.add('ws_uiQRLogo');
    this.uiLogoImg.src = ARWayQRIconBG;
    this.uiLogoImg.crossOrigin = 'anonymous';
    this.uiLogoImg.draggable = false;

    this.uiLogoImgFG = document.createElement('img');
    this.uiLogoImgFG.className = 'ws_uiQRLogo ws_uiQRLogo--foreground';
    this.uiLogoImgFG.src = ARWayQRIconFG;
    this.uiLogoImgFG.crossOrigin = 'anonymous';
    this.uiLogoImgFG.draggable = false;

    uiQRLogo.appendChild(this.uiLogoImg);
    uiQRLogo.appendChild(this.uiLogoImgFG);
    uiQRParent.appendChild(uiQRLogo);
    this.uiQRDigits = document.createElement('h4');
    this.uiQRDigits.className = "ws_uiQRCont--lastdigits";
    uiQRParent.appendChild(this.uiQRDigits);
    uiParentCont.appendChild(uiQRParent);
    uiCont.appendChild(uiParentCont);

    const uiBtn = document.createElement('div');
    uiBtn.innerHTML = 'Download QR Code';
    uiBtn.id = 'ws_uiQRDownloadButton';
    uiBtn.classList.add('ws_uiQRCont--btn');
    uiBtn.addEventListener('click', async () => {
       // //Download QR
        // const node = document.getElementById('UIQRParent')
        // htmlToImage.toBlob(node, {
        //     canvasHeight: 1024,
        //     canvasWidth: 1024,
        //     cacheBust: true
        // }).then(function (dataUrl) {
        //     var link = document.createElement('a');
        //     link.download = `${scope.mapName}'.png`;
        //     link.href = window.URL.createObjectURL(dataUrl);
        //     link.click();
        // });

        const params = {
            data: getQRURL(scope.qrId),
            fileType: "PDF",
            outputFileName: scope.mapName,
        }

        try {
            const response = await Server.get("/v1/utils/generateQRFile", { params: params, responseType: "arraybuffer" });            
            const data = response.data;
            let blob = new Blob([data], { type: "application/zip" });
            saveAs(blob, scope.mapName + "_QRs.zip");
        } catch (e) {
            console.log("error", e.message);
        }
    });
    uiCont.appendChild(uiBtn);

    //QR classes Init
    this.qrCodeWriter = new BrowserQRCodeSvgWriter();
    this.qrHints = new Map().set(EncodeHintType.ERROR_CORRECTION, ErrorCorrectionLevel.M);
    this.qrHints.set(EncodeHintType.MARGIN, 2);

    this.dom = uiCont;

}

UIQRCode.prototype = Object.create(UI.prototype);
UIQRCode.prototype.constructor = UIQRCode;


UIQRCode.prototype.setValue = function (value, metadata) {
    this.qrId = value;
    this.mapName = metadata.mapName
    this.isRendered = true;
    //Write QR
    const qrLastDigits = this.qrId.slice(-4).toUpperCase();
    this.uiQrIdSpan.innerHTML = qrLastDigits;
    this.uiQRDigits.innerHTML = qrLastDigits;

    const url = getQRURL(this.qrId);
    if(shouldAddQRMargins()) {
        this.uiLogoImg.classList.add('ws_s240');
        this.uiLogoImgFG.classList.add('ws_s240');
        this.uiQRDigits.classList.add('ws_b2per');
    } else {
        this.uiLogoImg.classList.remove('ws_s240');
        this.uiLogoImgFG.classList.remove('ws_s240');
        this.uiQRDigits.classList.remove('ws_b2per');
    }
    this.qrCodeWriter.writeToDom(
        "#arwayQRCode",
        url,
        this.size,
        this.size,
        this.qrHints
    );
}

UIQRCode.prototype.triggerClear = function () {
    this.isRendered = false;
    this.uiQRCode?.lastChild && this.uiQRCode?.removeChild(this.uiQRCode.lastChild);
}

// View Option Row
function UIViewOptionRow(editor, option, callBack) {
    UI.call(this);

    this.editor = editor;
    this.option = option;
    this.state = option.state;
    this.id = option.id;
    this.enable = option.enable;
    this.type = option.type;

    const scope = this;

    this.editor.on('viewGraphChanged', (id) => {
        if (scope.id === id) {
            const lEnable = !scope.enable;
            if(lEnable) {
                scope.dom.classList.remove('ws_uiViewRow--disabled');
            } else {
                scope.dom.classList.add('ws_uiViewRow--disabled');
            }
        }
    });

    this.editor.on('disableViewGraph', () => scope.dom.classList.add('ws_uiViewRow--disabled'));

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiViewRow');
    uiCont.classList.add('ws_uiViewRow--disabled');

    const uiImage = document.createElement('img');
    uiImage.src = this.option.icon;
    uiImage.classList.add('ws_uiViewImg')
    uiImage.draggable = false;

    const uiText = document.createElement('span');
    uiText.classList.add('ws_uiViewTitle')
    uiText.textContent = this.option.name;

    const handleClick = (e) => {
        e.stopPropagation();
        scope.state = !scope.state;
        scope.viewBtn.src = scope.state ? eyeOpenIcon : eyeClosedIcon;
        callBack(scope.id, scope.state);
    }

    this.viewBtn = document.createElement('img');
    this.viewBtn.classList.add('ws_uiViewEyeIcon');
    this.viewBtn.draggable = false;
    this.viewBtn.src = this.state ? eyeOpenIcon : eyeClosedIcon;
    this.viewBtn.addEventListener('click', handleClick, false);

    const uiHead = document.createElement('div');
    uiHead.classList.add('ws_uiViewHeadRow')

    uiHead.appendChild(uiImage);
    uiHead.appendChild(uiText);
    uiCont.appendChild(uiHead);
    uiCont.appendChild(this.viewBtn);

    this.dom = uiCont;
}

UIViewOptionRow.prototype = Object.create(UI.prototype);
UIViewOptionRow.prototype.constructor = UIViewOptionRow;

// Warning Card
function UIUserWarningCard(uiText) {
    UI.call(this);

    const uiCont = new UICol().addClass('ws_uiReadInfoCard');
    const uiIcon = new UIImage(alertIcon, '32px', '32px').addClass('ws_uiReadInfoCard--icon');
    const uiTextMsg = new UIText(undefined, uiText).addClass('ws_uiReadInfoCard--textSpan');
    uiCont.addChild(uiIcon);
    uiCont.addChild(uiTextMsg);

    this.dom = uiCont.dom;
}

UIUserWarningCard.prototype = Object.create(UI.prototype);
UIUserWarningCard.prototype.constructor = UIUserWarningCard;

//UI LocationPinImageContainer

function UILocationPinImageContainer() {
    UI.call(this);
    this.images = []
    this.imageFiles = []
    this.order = 1
    this.imgActions = []
    const imageContainer = document.createElement("div");
    imageContainer.classList.add("ws_imageContainer")
    imageContainer.id = 'img-cont'
    this.addImageCard = function () {
        return new UILocationPinImageCard('', '', 0, '40px', '40px').onClick(() => {
            const input = document.createElement("input");
            input.type = "file";
            input.accept = "image/jpeg, image/png";
            input.addEventListener("change", () => {
                const file = input.files[0]
                if ((this.images.length + this.imageFiles.length - this.imgActions.filter(img=>img.action==='delete').length) < 6) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        this.imageFiles.push(file)
                        let order = this.images.length + this.imageFiles.length
                        imageContainer.appendChild(new UILocationPinImageCard(reader.result, file.name, order, this).dom)
                        this.order = order
                        file.order = order
                        let changeEvent = document.createEvent('HTMLEvents');
                        changeEvent.initEvent('change', true, true);
                        imageContainer.dispatchEvent(changeEvent);
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert("You can only add up to six images.");
                }
            });
            input.click();
        });
    }
    this.dom = imageContainer
    return this
}

UILocationPinImageContainer.prototype = Object.create(UI.prototype);
UILocationPinImageContainer.prototype.constructor = UILocationPinImageContainer;

UILocationPinImageContainer.prototype.getImageFiles = function () {
    return this.imageFiles
}

UILocationPinImageContainer.prototype.setImageFiles = function () {
    this.imageFiles = [];
    return this;
}

UILocationPinImageContainer.prototype.getImageFiles = function () {
    return this.imageFiles
}

UILocationPinImageContainer.prototype.setImages = function (images) {
    var imageCont = document.getElementById('img-cont')
    imageCont.innerText = ''
    imageCont.appendChild(this.addImageCard().dom)
    this.images = images
    this.images.forEach((imgObj) => {
        imageCont.appendChild(new UILocationPinImageCard(imgObj.link ? imgObj.link : imgObj.url, imgObj.name, imgObj.order, this).dom)
    })
    this.imageFiles = [];
    return this;
}
UILocationPinImageContainer.prototype.setImagesInContainer = () => {
    return this;
}

//UI LocationPinImage
function UILocationPinImageCard(src, title, order, scope, height, width, others = false) {
    UI.call(this);
    this.imageCard = document.createElement("div");
    this.imageCard.id = `img-card-${order}`
    this.imageCard.setAttribute ("actionid",order)
    this.imageCard.classList.add("ws_imageCard");
    this.imageCard.classList.toggle("ws_imageCardDefault", isEmpty(src));
    this.imageCard.classList.toggle("ws_imageCardOthers", others);
    this.imageCard.addEventListener('mouseenter',()=>{
        let menu = document.getElementById(`img-menu-${order}`)
        menu && (menu.style.display = 'block');
    })
    this.imageCard.addEventListener('mouseleave',()=>{
        let menu = document.getElementById(`img-menu-${order}`)
        menu && (menu.style.display = 'none');
    })
    this.image = document.createElement("img");
    this.image.id = `img-id-${order}`
    this.image.src = isEmpty(src) ? addImageButtonIcon : src;
    this.image.style.height = height;
    this.image.style.width = width;
    this.image.crossOrigin = 'anonymous';
    

    // const moreOptions = document.createElement('img');
    // moreOptions.classList.add('images-more')
    // moreOptions.src = MoreHoriz;
    var moreCont = document.createElement('span');
    moreCont.classList.add('moreOptions')

    var moreOptioncheck = false
    const toggleEditMenu = (isMouseleave = false) => {
        moreOptioncheck = isMouseleave ? false : !moreOptioncheck
        var menu = document.getElementById(`img-menu-${order}`);
        if (moreOptioncheck) {
            menu.classList.add('dropdown-menu-show')
        } else {
            menu.classList.remove('dropdown-menu-show')
        }
    }

    const replaceImage = () => {
        // let image = document.get
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/jpeg, image/png";
        input.addEventListener("change", () => {
            const file = input.files[0]
            const reader = new FileReader();
            reader.onload = () => {
                this.image.src = reader.result
                this.uiText.textContent = file.name
                file.order = order
                let index = scope.imageFiles.indexOf(scope.imageFiles.find(imgObj => imgObj.order === order))
                if (index > -1) {
                    scope.imageFiles[index] = file
                } else {
                    scope.imageFiles.push(file)
                }
                let changeEvent = document.createEvent('HTMLEvents');
                changeEvent.initEvent('change', true, true);
                this.dom.dispatchEvent(changeEvent);
            };
            reader.readAsDataURL(file);
        });
        input.click();
    }

    var menu = document.createElement('div')
    menu.id = `img-menu-${order}`
    menu.classList.add('dropdown-menu')
    menu.classList.add('image-dropdown-menu')
    // menu.style.display = 'block'
    var menuItemReplace = document.createElement('a')
    menuItemReplace.classList.add('dropdown-item')
    menuItemReplace.href = "#"
    menuItemReplace.innerText = "Replace"
    menuItemReplace.addEventListener('click', () => {
        toggleEditMenu()
        replaceImage()
    })
    this.menuItemMove = document.createElement('a')
    this.menuItemMove.classList.add('dropdown-item')
    this.menuItemMove.href = "#"
    this.menuItemMove.innerText = "Move to front"
    this.menuItemMove.id = `move-${order}`
    this.menuItemMove.addEventListener('click', (e) => {
        toggleEditMenu()
        let parentNode=  document.getElementById(`img-card-${order}`).parentNode
        let childNodes =  Array.from(parentNode.childNodes)
        let currId = e.target.id.slice(-1)
        let currIndex = Array.from(parentNode.childNodes).indexOf(document.getElementById(`img-card-${currId}`))
        scope.imgActions.push({selected:this.imageCard.getAttribute("actionid"),action:'reorder'})
        let reorderNodes = [childNodes[currIndex],...[...childNodes.slice(1,currIndex),...childNodes.slice(currIndex+1,childNodes.length)]]
        this.menuItemMove.style.display='none'
        reorderNodes[1].childNodes[1].childNodes[1].style.display='block'
        reorderNodes.forEach((e,i)=>{
            e.setAttribute("actionid",i+1)
            parentNode.appendChild(e)
        }
        )
        document.getElementById(`img-menu-${order}`).style.display='none'
        let changeEvent = document.createEvent('HTMLEvents');
        changeEvent.initEvent('change', true, true);
        this.dom.dispatchEvent(changeEvent);
    })
    var menuItemDelete = document.createElement('a')
    menuItemDelete.classList.add('dropdown-item')
    menuItemDelete.href = "#"
    menuItemDelete.innerText = "Delete"
    menuItemDelete.addEventListener('click', (e) => {
        toggleEditMenu()
        scope.imgActions.push({selected:this.imageCard.getAttribute("actionid"),action:'delete'})
        let changeEvent = document.createEvent('HTMLEvents');
        changeEvent.initEvent('change', true, true);
        this.dom.dispatchEvent(changeEvent);
        this.imageCard.remove()
        let parentNode =  document.getElementById('img-cont')
        let childNodes =  Array.from(parentNode.childNodes)
        let reorderNodes = childNodes.slice(1,childNodes.length)
        reorderNodes.forEach((e,i)=>{
            e.setAttribute("actionid",i+1)
            parentNode.appendChild(e)
        })
    })
    menuItemDelete.id = order
    menu.appendChild(menuItemReplace)
    menu.appendChild(this.menuItemMove)
    menu.appendChild(menuItemDelete)
    if(order===2){
        document.getElementById(`move-${order-1}`).style.display='none'
    }

    this.uiText = document.createElement('span');
    this.uiText.classList.add('ws_uiLibraryTitle')
    this.uiText.textContent = isEmpty(title) ? '' : title.split('.')[0];
    this.uiText.id = "ws_uiImageName";

    this.imageCard.appendChild(this.image);
    if (!isEmpty(src))
        this.imageCard.appendChild(menu)
    // imageCard.appendChild(moreCont)
    this.imageCard.appendChild(this.uiText)

    this.dom = this.imageCard;
    return this;
}

UILocationPinImageCard.prototype = Object.create(UI.prototype);
UILocationPinImageCard.prototype.constructor = UILocationPinImageCard;

UILocationPinImageCard.prototype.setSrc = function (src) {
    this.src = src;
    this.image.src = null;
    this.image.src = this.src;

    return this;
}

// category

function UIMultiselect(editor) {
    UI.call(this);
    var scope = this;
    scope.subexpanded = false
    scope.expanded = false
    this.editor = editor
    this.selectedCategories = []
    this.data = {}

    const showCategories = () => {
        var checkboxes = scope.categoriesDiv
        if (!scope.expanded) {
            checkboxes.style.display = "block";
            scope.expanded = true;
        } else {
            checkboxes.style.display = "none";
            scope.expanded = false;
        }
        return scope
    }
    const showSubCategories = (id) => {
        var subcheckboxes = document.getElementById("sub-categories-" + id);
        if (!this.subexpanded) {
            subcheckboxes.style.display = "block";
            document.getElementById("sub-select-" + id).classList.remove('multiselectItem')
            document.getElementById('subcatDiv-' + id).classList.remove('multiselectItem')
            document.getElementById('subDiv'+id).style.width='98%'
            this.subexpanded = true;
        } 
        else {
            subcheckboxes.style.display = "none";
            document.getElementById("sub-select-" + id).classList.add('multiselectItem')
            document.getElementById('subcatDiv-' + id).classList.add('multiselectItem')
            document.getElementById('subDiv'+id).style.width='100%'
            this.subexpanded = false;
        }
    }

    this.initCategories = () => {
        let catMultiselect = document.createElement('div')
        catMultiselect.classList.add('multiselect')
        catMultiselect.id = 'catmulti'
        let catSelectDiv = document.createElement('div')
        catSelectDiv.classList.add('parent-selectBox')
        catSelectDiv.id = 'selectBoxParent'
        catSelectDiv.addEventListener('click', showCategories)
        let catSelect = document.createElement('select')
        let catOption = document.createElement('option')
        catSelect.appendChild(catOption)
        let catOverselect = document.createElement('div')
        catOverselect.classList.add('overSelect')
        catSelectDiv.appendChild(catSelect)
        catSelectDiv.appendChild(catOverselect)
        catMultiselect.appendChild(catSelectDiv)
        this.dom = catMultiselect
        this.parentOption = catOption
        this.showSubCategories = showSubCategories
    }

    this.initCategoreisDD = (expanded) => {
        let catDiv = document.createElement('div')
        let addCatLabel = document.createElement('label')
        addCatLabel.innerHTML = '+ Add a Category'
        addCatLabel.classList.add('item-extra')
        addCatLabel.style.cursor = 'pointer'
        addCatLabel.addEventListener('click', () => {
            this.data['isAdd'] = true
            this.data['isAddSub'] = false
            this.data['isEdit'] = false
            this.data['isDelete'] = false
            this.data['data'] = null
            scope.editor.trigger('openCategoryModal', [true, this.data])
        })
        catDiv.id = 'categories'
        catDiv.appendChild(addCatLabel)
        if (expanded) {
            catDiv.style.display = 'block'
            this.subexpanded = false
        }
        this.dom.appendChild(catDiv)
        this.categoriesDiv = catDiv
    }
    this.initCategories()
    return this;

}

UIMultiselect.prototype = Object.create(UI.prototype);
UIMultiselect.prototype.constructor = UIMultiselect;

UIMultiselect.prototype.getCategoryValues = function () {
    return this.selectedCategories
};

UIMultiselect.prototype.setCategoriesValue = function (categoriesIds) {
    if (isObject(categoriesIds[0])) {
        categoriesIds = categoriesIds.map(Obj => Obj.id)
    }

    var values = []
    var categories = []

    var checkboxes = this.categoriesDiv.querySelectorAll(
        "input[type=checkbox]"
    );
    for (const item of checkboxes) {
        if (categoriesIds.some(cat => cat === item.getAttribute('id'))) {
            var checkboxValue = item.getAttribute("value");
            values.push(checkboxValue);
            categories.push(item.getAttribute('id'))
            item.checked = true
        } else {
            item.checked = false

        }
    }
    var dropdownValue = "Nothing is selected";
    if (values.length > 0) {
        dropdownValue = values.join(", ");
    }
    this.selectedCategories = categories

    this.parentOption.innerText = dropdownValue
}


// UIMultiselect.prototype.checkboxStatusChange = 

// UIMultiselect.prototype.
UIMultiselect.prototype.reset = function () {
    // document.getElementById('catmulti').remove()
    // this.initCategories()
}

UIMultiselect.prototype.loadOptions = function (data, expanded = false) {
    if (document.getElementById('categories')) {
        document.getElementById('categories').remove()
    }
    var scope = this
    scope.initCategoreisDD(expanded)
    let categoriesEle = this.categoriesDiv
    function checkboxStatusChange(e, intial = false) {
        var multiselectOption = scope.parentOption
        var values = [];
        var categories = []
        var checkboxes = categoriesEle;
        var checkedCheckboxes = checkboxes.querySelectorAll(
            "input[type=checkbox]:checked"
        );

        for (const item of checkedCheckboxes) {

            var checkboxValue = item.getAttribute("value");
            values.push(checkboxValue);
            categories.push(item.getAttribute('id'))
        }
        if (!intial) {
            scope.selectedCategories = categories
        }

        var dropdownValue = "Nothing is selected";
        if (values.length > 0) {
            dropdownValue = values.join(", ");
        }

        multiselectOption.innerText = dropdownValue;
        if (dropdownValue.length > 30) {
            let el = document.getElementById('overflowtxt')
            if(el) {
                el.innerText = dropdownValue;
            } else {
                let showExtra = document.createElement('span')
                showExtra.id = 'overflowtxt'
                showExtra.innerText = dropdownValue
                showExtra.classList.add('spanExtra');
                scope.dom.insertBefore(showExtra, scope.categoriesDiv)
            }
        } else {
            var getExtraEle = document.getElementById('overflowtxt')
            if (getExtraEle) {
                getExtraEle.remove()
                getExtraEle.classList.add('spanExtra')
            }
        }
        return scope
    }

    function createCategory(data) {
        var cat = document.createElement('div')
        var label = document.createElement("label");
        var input = document.createElement("input");
        var abbr = document.createElement('abbr');
        input.classList.add('cat-checkbox')
        input.setAttribute("type", "checkbox");
        input.addEventListener('click', checkboxStatusChange)
        input.setAttribute("value", data.name);
        input.setAttribute('id', data.id)
        label.insertBefore(input, label.lastChild)
        label.classList.add('overflowText')
        // abbr
        abbr.title = data.name;
        abbr.textContent = data.name;
        label.appendChild(abbr);
        
        cat.appendChild(label);
        cat.style.paddingTop='0px'
        cat.style.position = 'relative'
        cat.classList.add('subCategoryDiv')
        cat.classList.add('test')
        addMenu(cat, {...data,isCategory:true}, scope)
        return cat;
    }

    const addMenu = (labelElem, data, scope) => {
        var moreOptioncheck = false
        const toggleEditMenu = (isMouseleave = false) => {
            moreOptioncheck = isMouseleave ? false : !moreOptioncheck
            var menu = document.getElementById(`menu-${data.id}`);
            if (moreOptioncheck) {
                menu.classList.add('dropdown-menu-show')
            } else {
                menu.classList.remove('dropdown-menu-show')
            }
        }

        var menu = document.createElement('div')
        menu.id = `menu-${data.id}`
        menu.classList.add('dropdown-menu')
        var menuItemEdit = document.createElement('a')
        menuItemEdit.classList.add('dropdown-item')
        if (moreOptioncheck) {
            menuItemEdit.classList.add('dropdown-item-show')
        }
        menuItemEdit.href = "#"
        menuItemEdit.innerText = "Edit"
        menuItemEdit.addEventListener('click', () => {
            scope.data['isAddSub'] = false
            scope.data['isAdd'] = false
            scope.data['isEdit'] = true
            scope.data['isDelete'] = false
            scope.data['data'] = data
            scope.editor.trigger('openCategoryModal', [true, scope.data])
            toggleEditMenu()
        })
        var menuItemDelete = document.createElement('a')
        menuItemDelete.classList.add('dropdown-item')
        menuItemDelete.href = "#"
        menuItemDelete.innerText = "Delete"
        menuItemDelete.addEventListener('click', () => {
            scope.data['isAddSub'] = false
            scope.data['isAdd'] = false
            scope.data['isDelete'] = true
            scope.data['isEdit'] = false
            scope.data['data'] = data
            scope.editor.trigger('openCategoryModal', [true, scope.data])
            toggleEditMenu()
        })
        
        menu.appendChild(menuItemEdit)
        menu.appendChild(menuItemDelete)
        var moreCont = document.createElement('span');
        moreCont.classList.add('moreOptions')
        moreCont.style.cursor='pointer'
        moreCont.addEventListener('click', ((e) => {
            e.stopPropagation()
            toggleEditMenu()
        }))
        labelElem.appendChild(menu)
        labelElem.appendChild(moreCont)
        labelElem.addEventListener('mouseleave', (e) => {
            toggleEditMenu(true)
        })
    }

    function createSubCategory(data,i) {

        var subDiv = document.createElement("div");
        subDiv.classList.add('multiselect')
        subDiv.id = 'subDiv'+data.id
        if(i!==0){
            subDiv.style.marginTop='0px'
            subDiv.style.position = 'relative'
        }
        var catDiv = document.createElement('div')
        catDiv.className = "subCategoryDiv"
        catDiv.id = 'subcatDiv-' + data.id
        if (!scope.subexpanded) {
            catDiv.classList.add('multiselectItem')
        }
        var label = document.createElement("label");
        var input = document.createElement("input");
        input.classList.add('cat-checkbox')
        input.setAttribute("type", "checkbox");
        input.addEventListener('click', checkboxStatusChange)
        input.setAttribute("value", data.name);
        input.setAttribute('id', data.id)
        label.insertBefore(input, label.lastChild)
        label.style.display = 'flex'

        catDiv.appendChild(label)

        var selectDiv = document.createElement("div");
        selectDiv.classList.add('selectBox')
        selectDiv.style.display = 'inline-block'
        selectDiv.style.cursor = 'pointer'
        selectDiv.addEventListener('click', () => scope.showSubCategories(data.id))
        var selectEle = document.createElement("select");
        selectEle.id = 'sub-select-' + data.id
        if (!scope.subexpanded) {
            selectEle.classList.add('multiselectItem')
        }
        var optionEle = document.createElement("option");
        optionEle.innerHTML = data.name
        selectEle.appendChild(optionEle)
        var overselectDiv = document.createElement("div");
        overselectDiv.classList.add("overSelect")
        selectDiv.appendChild(selectEle)
        selectDiv.appendChild(overselectDiv)
        catDiv.appendChild(selectDiv)
        addMenu(catDiv, {...data,isCategory:true}, scope)

        subDiv.appendChild(catDiv);

        var subCatDiv = document.createElement('div')
        subCatDiv.setAttribute('id', 'sub-categories-' + data.id)
        subCatDiv.style.display = 'none'
        subCatDiv.classList.add('sub-categories')
        var addSubCat = document.createElement('label')
        addSubCat.classList.add('item-extra')
        addSubCat.innerHTML = '+ Add a Subcategory'
        addSubCat.style.cursor = 'pointer'
        addSubCat.addEventListener('click', () => {
            scope.data['isAdd'] = false
            scope.data['isAddSub'] = true
            scope.data['isEdit'] = false
            scope.data['isDelete'] = false
            scope.data['data'] = data.id
            scope.editor.trigger('openCategoryModal', [true, scope.data])
        })
        const hrDiv1 = document.createElement('hr')
        hrDiv1.classList.add('subcat')
        subCatDiv.appendChild(hrDiv1)
        subCatDiv.appendChild(addSubCat)

        data.children.forEach(child => {
            var subcat = document.createElement('div')
            var label = document.createElement("label");
            var input = document.createElement("input");
            input.classList.add('cat-checkbox')
            input.setAttribute("type", "checkbox");
            input.addEventListener('click', checkboxStatusChange)
            input.setAttribute("value", child.name);
            input.setAttribute('id', child.id)
            label.innerHTML = child.name;
            label.classList.add('overflowText')
            label.insertBefore(input, label.lastChild)
            subcat.appendChild(label)
            subcat.classList.add('subCategoryDiv');
            addMenu(subcat, {...child,isCategory:false,categoryId:data.id}, scope)
            subCatDiv.insertBefore(subcat, subCatDiv.childNodes[0])
        })

        subDiv.appendChild(subCatDiv)
        return subDiv
    }

    checkboxStatusChange({}, true)

    let lastItemCat = categoriesEle.lastElementChild
    data.forEach((element,i) => {
        if (element.hasOwnProperty("children") && element.children.length > 0) {
            categoriesEle.insertBefore(createSubCategory(element,i), lastItemCat)
        } else {
            categoriesEle.insertBefore(createCategory(element), lastItemCat);
        }
    });

    scope.setCategoriesValue(scope.selectedCategories)

    return this
}

// UI for Pin Nav style!
function UIPinNavSelector(type = "pin") {
    UI.call(this);

    this.navType = type;

    this.defNavStyles = [
        { name: 'Default', icon: DefaultNV, value: 'Default', type: "pin"},
        // { name: 'Chevrons', icon: ChevronsNV, value: 'Chevrons', type: "pin" },
        { name: 'Spheres', icon: SpheresNV, value: 'Spheres', type: "pin" },
        { name: 'Glowing Stripes', icon: GlowingNV, value: 'GlowingStripes', type: "pin" },
        { name: 'Jumbo Chevron', icon: JumboChevronNV, value: 'JumboChevrons', type: "all" },
        { name: 'Flat Path', icon: FlatPathNV, value: 'GuidedPath', type: "all" },
    ]
    this.activeStyle = this.navType === 'pin' ? 'Default' : 'JumboChevrons';
    this.uiStyleVariants = [];

    const navStyleCont = document.createElement('div');
    navStyleCont.classList.add('ws_uiNavStyles');

    this.dom = navStyleCont;

    this.setStyleVariants();
}

UIPinNavSelector.prototype = Object.create(UI.prototype);
UIPinNavSelector.prototype.constructor = UIPinNavSelector;

UIPinNavSelector.prototype.setStyleVariants = function () {

    const scope = this;

    function onClick(e) {
        scope.setValue(e.target.id);
        let changeEvent = document.createEvent('HTMLEvents');
        changeEvent.initEvent('change', true, true);
        scope.dom.dispatchEvent(changeEvent);
    }

    for (var i = 0; i < this.defNavStyles.length; i++) {
        if(this.defNavStyles[i].type !== 'all' && this.defNavStyles[i].type !== this.navType) {
            continue;
        }

        const navDiv = document.createElement('div');
        navDiv.classList.add('ws_uiNavStyles__uiNavCard');
        navDiv.id = this.defNavStyles[i].name;

        //image
        const navImg = document.createElement('img');
        navImg.className = 'ws_uiNavStyles__uiNavCard--navImg'
        navImg.src = this.defNavStyles[i].icon;
        navImg.alt = this.defNavStyles[i].name;
        navImg.id = this.defNavStyles[i].value;
        this.defNavStyles[i].name === this.activeStyle && navImg.classList.add('ws_uiNavStyles__uiNavCard--activeStyle');

        const navCheck = document.createElement('img');
        navCheck.className = 'ws_uiNavStyles__uiNavCard--navCheck'
        navCheck.src = CheckboxIcon;
        navCheck.alt = this.defNavStyles[i].name + 'navCheck';

        const navName = document.createElement('span');
        navName.className = 'ws_uiNavStyles__uiNavCard--navName'
        navName.textContent = this.defNavStyles[i].name;

        navDiv.appendChild(navImg);
        navDiv.appendChild(navCheck);
        navDiv.appendChild(navName);

        navDiv.addEventListener('click', onClick);

        this.dom.appendChild(navDiv);
        this.uiStyleVariants.push(navImg);
    }

    return this;
}

UIPinNavSelector.prototype.getValue = function () {
    return this.activeStyle;
}

UIPinNavSelector.prototype.setValue = function (value) {
    if(value === 'Chevrons') value = 'Default';
    for (var i = 0; i < this.uiStyleVariants.length; i++) {
        var element = this.uiStyleVariants[i];
        if (element.id.toLowerCase() === value.toLowerCase()) {
            element.classList.add('ws_uiNavStyles__uiNavCard--activeStyle');
            this.activeStyle = value;
        } else {
            element.classList.remove('ws_uiNavStyles__uiNavCard--activeStyle');
        }
    }
    return this;
}

function UIFileInput(acceptType, contentType, custClass) {
    UI.call(this);

    this.accepts = {
        image: 'image/jpeg, image/png',
        file: 'application/pdf'
    }

    this.acceptType = acceptType;
    this.iconId = acceptType + getRandomIdCode();
    this.contentType = contentType || 'images';
    this.error = false;

    this.src = DefImgIcon;
    this.title = '';
    this.file = null;
    const scope = this;

    //Invisible UI Input
    const form = document.createElement('form');
    form.style.display = 'none';
    document.body.appendChild(form);
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.id = `${acceptType}AdvancedFileInput`;
    fileInput.accept = this.accepts["image"];
    fileInput.addEventListener('change', () => {
        //Invoke Things!
        const name = fileInput.files[0].name;
        if(validateExt(fileInput.files[0], this.contentType)) {
            if (scope.acceptType === 'image' || 'file') {
                var fr = new FileReader();
                fr.onload = function () {
                    scope.setSrc(fr.result)
                    scope.setTitle(name)
                }
                fr.readAsDataURL(fileInput.files[0]);
            } else {
                scope.setSrc(DefFileIcon)
                scope.setTitle(name)
            }
            document.getElementById(`${scope.iconId}Close`).style.display='block'
            document.getElementById(`${scope.iconId}Add`).style.display='none'
            scope.file = fileInput.files[0];
            let changeEvent = document.createEvent('HTMLEvents');
            changeEvent.initEvent('change', true, true);
            scope.dom.dispatchEvent(changeEvent);
        } else {
            scope.error = true;
            let changeEvent = document.createEvent('HTMLEvents');
            changeEvent.initEvent('change', true, true);
            scope.dom.dispatchEvent(changeEvent);
        }

        form.reset();
    });
    form.appendChild(fileInput);

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiFileInputCont');

    const uiContImg = document.createElement('div');
    uiContImg.classList.add(custClass || 'ws_uiFileInputCont--imgCont');

    this.uiImg = document.createElement('img');
    this.uiImg.src = this.src;
    this.uiImg.classList.add('ws_uiFileInputCont--image');
    this.uiImg.draggable = false;
    this.uiImg.crossOrigin = 'anonymous';
    uiContImg.appendChild(this.uiImg);

    this.uiTitle = document.createElement('span');
    this.uiTitle.textContent = this.title;
    this.uiTitle.classList.add('ws_uiFileInputCont--title');

    const uiChangeCont = document.createElement('div');
    uiChangeCont.classList.add('ws_uiFileInputCont--imageOverCont');

    const uiChange = document.createElement('img');
    uiChange.src = AddImgIcon;
    uiChange.classList.add('ws_uiFileInputCont--imageOver');
    uiChange.id = `${this.iconId}Add`
    uiChange.draggable = false;
    uiChange.addEventListener('click', () => fileInput.click());
    uiChangeCont.appendChild(uiChange);
    // close
    const uiClose = document.createElement('img');
    uiClose.src = AddImgIcon;
    uiClose.id = `${this.iconId}Close`
    uiClose.style.display='none'
    uiClose.classList.add('ws_uiFileInputCont--imageOverClose');
    uiClose.draggable = false;
    uiClose.addEventListener('click', () => {
        this.setSrc(DefImgIcon)
        this.setContent(false)
        let changeEvent = document.createEvent('HTMLEvents');
        changeEvent.initEvent('change', true, true);
        scope.dom.dispatchEvent(changeEvent);
        document.getElementById(`${this.iconId}Close`).style.display='none'
        document.getElementById(`${this.iconId}Add`).style.display='flex'
    });
    uiChangeCont.appendChild(uiClose);
    uiContImg.appendChild(uiChangeCont);

    uiCont.appendChild(uiContImg);
    uiCont.appendChild(this.uiTitle);

    this.dom = uiCont;
}

UIFileInput.prototype = Object.create(UI.prototype);
UIFileInput.prototype.constructor = UIFileInput;

UIFileInput.prototype.getFile = function () {
    return this.file;
}

UIFileInput.prototype.setSrc = function (src) {
    // this.src = this.acceptType === 'file' && src ? DefFileIcon : src || DefImgIcon;
    this.src = src || DefImgIcon
    this.uiImg.src = this.src;
    if(src){
        document.getElementById(`${this.iconId}Close`).style.display='block'
        document.getElementById(`${this.iconId}Add`).style.display='none'
    }
    return this;
}

UIFileInput.prototype.setTitle = function (name) {
    this.title = name || '';
    this.uiTitle.textContent = this.title

    return this;
}

UIFileInput.prototype.setContent = function (content) {
    if (!content) {
        this.src = DefImgIcon;
        this.title = '';
        this.file = null
    } else if (typeof content === 'object') {
        // this.src = this.acceptType === 'file' ? DefFileIcon : content.link;
        this.src = content.link
        this.title = content.name
        document.getElementById(`${this.iconId}Close`).style.display='block'
        document.getElementById(`${this.iconId}Add`).style.display='none'
    } else {
        // local content!!
        // this.src = this.acceptType === 'file' ? DefFileIcon : content;
        this.src = content
        this.title = this.file.name;
        document.getElementById(`${this.iconId}Close`).style.display='block'
        document.getElementById(`${this.iconId}Add`).style.display='none'
    }

    this.uiImg.src = this.src;
    this.uiTitle.textContent = this.title;

    return this;
}

UIFileInput.prototype.getError = function () {
    return this.error;
}

UIFileInput.prototype.resetError = function () {
    this.error = false;
    return this;
}

// UI for amenity type radio btns
function UIRadioSelector() {
    UI.call(this);

    this.options = []
    this.active = 'Default';
    this.uiOptions = [];

    const amenityTypeCont = document.createElement('div');
    amenityTypeCont.classList.add('ws_uiRadiosCont');

    this.dom = amenityTypeCont;
}

UIRadioSelector.prototype = Object.create(UI.prototype);
UIRadioSelector.prototype.constructor = UIRadioSelector;

UIRadioSelector.prototype.setOptions = function (options) {

    var scope = this;

    // clear old option
    while (scope.dom.children.length > 0) {
        scope.dom.removeChild(scope.dom.firstChild);
    }

    const onChange = (e) => {
        scope.setValue(e.target.id);
        let changeEvent = document.createEvent('HTMLEvents');
        changeEvent.initEvent('change', true, true);
        scope.dom.dispatchEvent(changeEvent);
    }

    this.options = options.children;

    for (var i = 0; i < this.options.length; i++) {

        let opt = this.options[i];

        const uiRadioRow = document.createElement('div');
        uiRadioRow.classList.add('ws_uiRadiosCont__row');

        // radio btn
        const uiradioCont = document.createElement('div');
        uiradioCont.classList.add('ws_uiRadiosCont__radioCont');
        uiradioCont.id = opt.id;

        const uiRadioBtn = document.createElement('input');
        uiRadioBtn.classList.add('ws_uiRadiosCont__radio');
        uiRadioBtn.type = 'radio';
        uiRadioBtn.id = opt.id;
        uiRadioBtn.name = options.name;

        uiradioCont.addEventListener('change', (e) => onChange(e));
        uiradioCont.appendChild(uiRadioBtn);

        const uiImg = document.createElement('img');
        uiImg.classList.add('ws_uiRadiosCont__img');
        uiImg.src = opt.image;
        uiImg.draggable = false;
        uiImg.crossOrigin = 'anonymous';

        const uiTitle = document.createElement('span');
        uiTitle.classList.add('ws_uiRadiosCont__title');
        const uiAbbr = document.createElement('abbr');
        uiAbbr.title = opt.name;
        uiAbbr.textContent = opt.name;
        uiTitle.appendChild(uiAbbr);

        uiRadioRow.appendChild(uiradioCont);
        uiRadioRow.appendChild(uiImg);
        uiRadioRow.appendChild(uiTitle);

        this.dom.appendChild(uiRadioRow);
        this.uiOptions.push(uiRadioBtn);
    }

    return scope;
}

UIRadioSelector.prototype.getValue = function () {
    return this.active;
}

UIRadioSelector.prototype.setValue = function (value) {
    for (var i = 0; i < this.uiOptions.length; i++) {
        var element = this.uiOptions[i];
        if (element.id === value) {
            element.checked = true;
            this.active = value;
        } else {
            element.checked = false;
        }
    }
    return this;
}

function UI2dMenuViewCard(editor, option, callBack) {
    UI.call(this);

    this.editor = editor;
    this.option = option;
    this.state = option.state;
    this.id = option.id;
    this.enable = option.enable;

    this.editor.on('2dViewGraphChanged', (id) => {
        if (scope.id === id) {
            const lEnable = !scope.enable;
            if(lEnable) {
                scope.dom.classList.remove('ws_ui2dViewRow--disabled');
            } else {
                scope.dom.classList.add('ws_ui2dViewRow--disabled');
            }
        }
    });

    this.editor.on('2dDisableViewGraph', () => scope.dom.classList.add('ws_ui2dViewRow--disabled'));

    const scope = this;

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_ui2dViewRow');
    uiCont.classList.add('ws_ui2dViewRow--disabled');

    const uisubRow = document.createElement('div');

    const uiIcon = document.createElement('img');
    uiIcon.classList.add('ws_ui2dViewIcon')
    uiIcon.src = this.option.icon;

    const uiText = document.createElement('span');
    uiText.classList.add('ws_ui2dViewTitle')
    uiText.textContent = this.option.name;

    uisubRow.appendChild(uiIcon);
    uisubRow.appendChild(uiText);

    const handleClick = (e) => {
        e.stopPropagation();
        scope.state = !scope.state;
        scope.viewBtn.src = scope.state ? eyeOpenIcon : eyeClosedIcon;
        callBack(scope.id, scope.state);
    }

    this.viewBtn = document.createElement('img');
    this.viewBtn.classList.add('ws_ui2dViewEyeIcon');
    this.viewBtn.draggable = false;
    this.viewBtn.src = this.state ? eyeOpenIcon : eyeClosedIcon;
    this.viewBtn.addEventListener('click', handleClick, false);

    uiCont.appendChild(uisubRow);
    uiCont.appendChild(this.viewBtn);

    this.dom = uiCont;
}

UI2dMenuViewCard.prototype = Object.create(UI.prototype);
UI2dMenuViewCard.prototype.constructor = UI2dMenuViewCard;

// Maps Card
function UIMapInfoCard(editor, content, callBack, index, isFloor, position) {
    UI.call(this);

    this.content = content;
    this.editor = editor;
    this.mapId = content.mapId;
    this.callBack = callBack;
    this.isFloor = isFloor;

    this.menuOptions = [ {
            name: 'Rename',
            icon: EditPencon,
            action: 'edit',
            transform: 'rotate(0deg)',
            disabled: false,
        }, {
            name: 'Move Up',
            icon: MoveIcon,
            action: 'moveUp',
            transform: 'rotate(0deg)',
            disabled: position === 'first',
        }, {
            name: 'Move Down',
            icon: MoveIcon,
            action: 'moveDown',
            transform: 'rotate(180deg)',
            disabled: position === 'last',
        }];

    this.editor.on('toggleSceneMapsMenu', (mapId) => {
        if(mapId !== this.mapId && this.menuOptions) {
            this.menuOptions = false;
            this.uiMenu.classList.remove('ws_uiMapsCardTour__menuCont--active');
        }
    })

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiMapsCardTour');
    this.editor.activeMap === this.mapId && uiCont.classList.add('ws_uiMapsCardTour--active');
    uiCont.id = `ws_uiObjectCard${index}`;

    const uiText = document.createElement('span');
    uiText.classList.add('ws_uiMapsCardTour__title')
    uiText.textContent = `${getNameType(this.content.mapNamingType)} ${this.content.identifier || 1} - ${this.content.mapName}`;

    const uiCheckIcon = document.createElement('img');
    uiCheckIcon.classList.add('ws_uiMapsCardTour__icon');
    uiCheckIcon.src = checkIcon;
    uiCheckIcon.draggable = false;
    uiCheckIcon.crossOrigin = 'anonymous';
    uiCheckIcon.id = `ws_uiObjectEdit${index}`;

    const uiMoreIcon = document.createElement('img');
    uiMoreIcon.classList.add('ws_uiMapsCardTour__moreIcon');
    uiMoreIcon.classList.add('ws_uiMapsCardTour__moreIcon--hide');
    uiMoreIcon.src = moreOptnsIcon;
    uiMoreIcon.draggable = false;
    uiMoreIcon.crossOrigin = 'anonymous';
    uiMoreIcon.id = `ws_uiObjectMore${index}`;
    uiMoreIcon.addEventListener('click', (e) => {
        e.stopPropagation();
        this.toggleMenuOptions()
    })

    uiCont.appendChild(uiCheckIcon);
    uiCont.appendChild(uiText);
    !this.isFloor && uiCont.appendChild(uiMoreIcon);

    this.uiMenu = document.createElement('div');
    this.uiMenu.classList.add('ws_uiMapsCardTour__menuCont');

    this.setMenuOptions();

    uiCont.appendChild(this.uiMenu);

    this.dom = uiCont;

    const handleClick = (clickKey) => {
        this.callBack(this.content, clickKey);
    }

    this.dom.addEventListener('click', (e) => { e.stopPropagation(); handleClick(3) }, false);
}

UIMapInfoCard.prototype = Object.create(UI.prototype);
UIMapInfoCard.prototype.constructor = UIMapInfoCard;

UIMapInfoCard.prototype.setMenuOptions = function () {

    const scope = this;

    function onClick(e, op) {
        e.stopPropagation();
        scope.callBack(scope.content, 4, op.action);
        scope.toggleMenuOptions();
    }

    for (var i = 0; i < this.menuOptions.length; i++) {
        const optn = this.menuOptions[i];
        
        const uiMenuOption = document.createElement('div');
        uiMenuOption.classList.add('ws_uiMapsCardTour__menuItem');
        optn.disabled && uiMenuOption.classList.add('ws_uiMapsCardTour__menuItem--disabled');

        const uiIcon = document.createElement('img');
        uiIcon.classList.add('ws_uiMapsCardTour__menuItem--menuIcon');
        uiIcon.src = optn.icon;
        uiIcon.draggable = false;
        uiIcon.crossOrigin = 'anonymous';
        uiIcon.style.transform = optn.transform;

        const uiText = document.createElement('span');
        uiText.classList.add('ws_uiMapsCardTour__menuItem--menuText');
        uiText.textContent = optn.name;

        uiMenuOption.appendChild(uiIcon);
        uiMenuOption.appendChild(uiText);
        
        uiMenuOption.addEventListener('click', (e) => onClick(e, optn));
        this.uiMenu.appendChild(uiMenuOption);
    }

    return this;
}

UIMapInfoCard.prototype.toggleMenuOptions = function () {
    this.menuOptions = !this.menuOptions;
    this.editor.trigger('toggleSceneMapsMenu', [ this.mapId ])
    if( this.menuOptions) {
        this.uiMenu.classList.add('ws_uiMapsCardTour__menuCont--active');
    } else {
        this.uiMenu.classList.remove('ws_uiMapsCardTour__menuCont--active');
    }
    return this;
}

// Maps Card
function UIFloorDropDownCard(editor, content, callBack, index) {
    UI.call(this);

    this.content = content;
    this.editor = editor;
    this.mapId = content.mapId;
    this.isAligned = content.isFloorAligned ?? true;

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiFloorDropCard');
    this.editor.activeMap === this.mapId && uiCont.classList.add('ws_uiFloorDropCard--active');
    !this.isAligned && uiCont.classList.add('ws_uiFloorDropCard--disabled');
    uiCont.id = `ws_uiObjectCard${index}`;

    const uiText = document.createElement('span');
    uiText.classList.add('ws_uiFloorDropCard__title')
    uiText.textContent = `${getNameType(this.content.mapNamingType)} - ${this.content.mapName}`;

    const uiFloorIcon = document.createElement('img');
    uiFloorIcon.classList.add('ws_uiFloorDropCard__icon');
    uiFloorIcon.src = floorplanIcon;
    uiFloorIcon.draggable = false;
    uiFloorIcon.crossOrigin = 'anonymous';
    uiFloorIcon.id = `ws_uiObjectEdit${index}`;

    const uiCheckIcon = document.createElement('img');
    uiCheckIcon.classList.add('ws_uiFloorDropCard__checkIcon');
    uiCheckIcon.src = checkIcon;
    uiCheckIcon.draggable = false;
    uiCheckIcon.crossOrigin = 'anonymous';
    uiCheckIcon.id = `ws_uiObjectEdit${index}`;


    uiCont.appendChild(uiFloorIcon);
    uiCont.appendChild(uiText);
    uiCont.appendChild(uiCheckIcon);

    this.dom = uiCont;

    const handleClick = (clickKey) => {
        callBack(this.content, clickKey);
    }

    this.dom.addEventListener('click', (e) => { e.stopPropagation(); handleClick(3) }, false);
}

UIFloorDropDownCard.prototype = Object.create(UI.prototype);
UIFloorDropDownCard.prototype.constructor = UIFloorDropDownCard;


// Maps Card
function UINameIdenfierInput(prename, length, value, width) {
    UI.call(this);

    this.prename = prename;
    this.value = new Array(length);
    this.indices = length;
    this.uiTextInputs = [];
    this.inputWidth = width;

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiNameIdentCont');

    this.uiPrename = document.createElement('span');
    this.uiPrename.classList.add('ws_uiNameIdentCont--prenameSpan');
    this.uiPrename.textContent = prename;
    uiCont.appendChild(this.uiPrename);

    this.dom = uiCont;

    this.setInputVarients();
}

UINameIdenfierInput.prototype = Object.create(UI.prototype);
UINameIdenfierInput.prototype.constructor = UINameIdenfierInput;

UINameIdenfierInput.prototype.setInputVarients = function () {

    const scope = this;

    function onChange(e) {
        scope.setIdxValue(e.target.id, e.target.value);
        let changeEvent = document.createEvent('HTMLEvents');
        changeEvent.initEvent('change', true, true);
        scope.dom.dispatchEvent(changeEvent);
    }

    for (var i = 0; i < this.indices; i++) {
        const uiInput = document.createElement('input');
        uiInput.classList.add('ws_uiNameIdentCont--textInput');
        uiInput.style.width = this.inputWidth;
        uiInput.type = 'text';
        uiInput.id = i;
        uiInput.maxLength = 1;
        uiInput.autocomplete = 'off';
        uiInput.value = this.value[i] ?? ""

        uiInput.addEventListener('change', onChange);

        this.dom.appendChild(uiInput);
        this.uiTextInputs.push(uiInput);
    }

    return this;
}

UINameIdenfierInput.prototype.getValue = function () {
    return this.value.join('').toLocaleUpperCase();
}

UINameIdenfierInput.prototype.setPrename = function (prename) {
    this.prename = prename;
    this.uiPrename.textContent = prename;

    return this;
}

UINameIdenfierInput.prototype.setIdxValue = function (idx, value) {
    if(idx) {
        this.uiTextInputs[idx].value = value;
        this.value[idx] = value;
    }
    return this;
}

UINameIdenfierInput.prototype.setValue = function (value) {
    for (var i = 0; i < this.uiTextInputs.length; i++) {
        var element = this.uiTextInputs[i];
        element.value = value.at(i) ?? "";
        this.value[i] = value.at(i) ?? "";
    }
    return this;
}

UINameIdenfierInput.prototype.setInputWidth = function (wdth) {
    for (var i = 0; i < this.uiTextInputs.length; i++) {
        var element = this.uiTextInputs[i];
        element.style.width = wdth;
    }
    return this;
}

// ConnectorDropdown
function UIDropDown(options = [], activeOptn = null, extraOptns = {}) {
    UI.call(this);

    this.options = options;
    this.activeOption = activeOptn;
    this.menuOpen = false;
    this.modes = 'select';
    this.placeholder = extraOptns.placeholder || 'Select a Connector Group';
    this.showAdd = 'toAdd' in extraOptns ? extraOptns.toAdd : true;
    this.showBorder = extraOptns.showBorder || false;

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiDropDownCont');

    const uiOptnRow = document.createElement('div');
    uiOptnRow.classList.add('ws_uiDropDownCont--optnRowCont');
    this.showBorder && uiOptnRow.classList.add('ws_uiDropDownCont--optnRowCont--grayBorder');

    this.uiActiveOptn = document.createElement('span');
    this.uiActiveOptn.classList.add('ws_uiDropDownCont--activeOptnSpan');
    this.uiActiveOptn.textContent = activeOptn?.name ?? this.placeholder;
    uiOptnRow.appendChild(this.uiActiveOptn);

    this.uiMoreIcon = document.createElement('img');
    this.uiMoreIcon.classList.add('ws_uiDropDownCont--chevronIcon');
    this.uiMoreIcon.src = DropdownIcon;
    this.uiMoreIcon.crossOrigin = 'anonymous';
    this.uiMoreIcon.draggable = false;
    this.uiMoreIcon.addEventListener('click', () => this.toggleDropList())
    uiOptnRow.appendChild(this.uiMoreIcon);

    uiCont.appendChild(uiOptnRow);

    this.uiDropList = document.createElement('div');
    this.uiDropList.classList.add('ws_uiDropDownCont--dropListCont')
    this.showBorder && this.uiDropList.classList.add('ws_uiDropDownCont--dropListCont--grayBorder');

    uiCont.appendChild(this.uiDropList);

    this.dom = uiCont;

    this.setDropDownOptions();
}

UIDropDown.prototype = Object.create(UI.prototype);
UIDropDown.prototype.constructor = UIDropDown;

UIDropDown.prototype.setDropDownOptions = function () {

    const scope = this;

    while (this.uiDropList.children.length > 0) {
        this.uiDropList.removeChild(this.uiDropList.firstChild);
    }

    function onClick(e) {
        scope.modes = 'select';
        scope.setActive(e.target.id);
        let changeEvent = document.createEvent('HTMLEvents');
        changeEvent.initEvent('change', true, true);
        scope.dom.dispatchEvent(changeEvent);
    }

    function onClick2(e) {
        scope.modes = 'create';
        let changeEvent = document.createEvent('HTMLEvents');
        changeEvent.initEvent('change', true, true);
        scope.dom.dispatchEvent(changeEvent);
    }

    const length = this.showAdd ? this.options.length + 1 : this.options.length;

    for (var i = 0; i < length; i++) {
        const optn = this.options[i];
        if(i < this.options.length) {
            const uiDropOptn = document.createElement('div');
            uiDropOptn.classList.add('ws_uiDropDownCont--dropItem');
            uiDropOptn.id = optn.id;
            uiDropOptn.textContent = optn.name || i
    
            uiDropOptn.addEventListener('click', onClick);
            this.uiDropList.appendChild(uiDropOptn);
        } else if(this.showAdd) {
            // last option
            const uiDropOptn = document.createElement('div');
            uiDropOptn.className = 'ws_uiDropDownCont--dropItem ws_uiDropDownCont--dropItemCreate';
            uiDropOptn.id = 'new';
            uiDropOptn.textContent = '+ Add a Connector Group'
    
            uiDropOptn.addEventListener('click', onClick2);
            this.uiDropList.appendChild(uiDropOptn);
        }
    }

    return this;
}

UIDropDown.prototype.toggleDropList = function() {
    this.menuOpen = !this.menuOpen;
    if(this.menuOpen) {
        this.uiMoreIcon.style.transform = 'rotate(90deg)';
        this.uiDropList.classList.add('ws_uiDropDownCont--dropListCont--show')
    } else {
        this.uiMoreIcon.style.transform = 'rotate(0deg)';
        this.uiDropList.classList.remove('ws_uiDropDownCont--dropListCont--show')
    }
}

UIDropDown.prototype.setOptions = function (options) {
    const sorted = options && options.length ? options : [];
    this.options = sorted;
    this.activeOption = null;
    this.uiActiveOptn.textContent = this.placeholder;
    this.setDropDownOptions();
    return this;
}

UIDropDown.prototype.setActive = function (option) {
    const optn = this.options.find(op => op.id === option);
    if(optn) {
        this.activeOption = optn;
        this.uiActiveOptn.textContent = optn.name;
    }
    this.menuOpen && this.toggleDropList();
    return this;
}

UIDropDown.prototype.getActive = function () {
    return this.activeOption;
}

UIDropDown.prototype.getMode = function () {
    return this.modes;
}

UIDropDown.prototype.disableDrop = function (flag) {
    if(flag) {
        this.uiMoreIcon.style.display = 'none';
    } else {
        this.uiMoreIcon.style.display = '';
    }
    return this;
}


// ConnectorDropdown
function UIDropDownMini(options = [], activeOptn = null) {
    UI.call(this);

    this.options = options;
    this.activeOption = activeOptn;
    this.menuOpen = false;

    const uiCont = document.createElement('div');
    uiCont.classList.add('ws_uiDropDownCont');

    const uiOptnRow = document.createElement('div');
    uiOptnRow.classList.add('ws_uiDropDownCont--optnRowCont');
    uiOptnRow.classList.add('ws_uiDropDownCont--noMT');
    this.showBorder && uiOptnRow.classList.add('ws_uiDropDownCont--optnRowCont--grayBorder');

    this.uiActiveOptn = document.createElement('span');
    this.uiActiveOptn.classList.add('ws_uiDropDownCont--activeOptnSpan');
    this.uiActiveOptn.textContent = activeOptn?.name ?? this.placeholder;
    uiOptnRow.appendChild(this.uiActiveOptn);

    this.uiMoreIcon = document.createElement('img');
    this.uiMoreIcon.classList.add('ws_uiDropDownCont--chevronIcon');
    this.uiMoreIcon.src = DropdownIcon;
    this.uiMoreIcon.crossOrigin = 'anonymous';
    this.uiMoreIcon.draggable = false;
    uiOptnRow.appendChild(this.uiMoreIcon);

    this.uiMoreIcon.addEventListener('click', () => this.toggleDropList()); 

    uiCont.appendChild(uiOptnRow);

    this.uiDropList = document.createElement('div');
    this.uiDropList.classList.add('ws_uiDropDownCont--dropListContAbs')

    uiCont.appendChild(this.uiDropList);

    this.dom = uiCont;

    this.setDropDownOptions();
}

UIDropDownMini.prototype = Object.create(UI.prototype);
UIDropDownMini.prototype.constructor = UIDropDownMini;

UIDropDownMini.prototype.setDropDownOptions = function () {

    const scope = this;

    while (this.uiDropList.children.length > 0) {
        this.uiDropList.removeChild(this.uiDropList.firstChild);
    }

    function onClick(e) {
        scope.setActive(e.target.id);
        let changeEvent = document.createEvent('HTMLEvents');
        changeEvent.initEvent('change', true, true);
        scope.dom.dispatchEvent(changeEvent);
    }

    for (var i = 0; i < this.options.length; i++) {
        const optn = this.options[i];
        const uiDropOptn = document.createElement('div');
        uiDropOptn.classList.add('ws_uiDropDownCont--dropItem');
        uiDropOptn.id = optn.id;
        uiDropOptn.textContent = optn.name || i

        uiDropOptn.addEventListener('click', onClick);
        this.uiDropList.appendChild(uiDropOptn);
    }

    return this;
}

UIDropDownMini.prototype.toggleDropList = function() {
    this.menuOpen = !this.menuOpen;
    if(this.menuOpen) {
        this.uiMoreIcon.style.transform = 'rotate(90deg)';
        this.uiDropList.classList.add('ws_uiDropDownCont--dropListContAbs--show')
    } else {
        this.uiMoreIcon.style.transform = 'rotate(0deg)';
        this.uiDropList.classList.remove('ws_uiDropDownCont--dropListContAbs--show')
    }
}

UIDropDownMini.prototype.setOptions = function (options) {
    this.options = options;
    this.activeOption = null;
    this.setDropDownOptions();
    return this;
}

UIDropDownMini.prototype.setActive = function (option) {
    const optn = this.options.find(op => op.id === option);
    if(optn) {
        this.activeOption = optn;
        this.uiActiveOptn.textContent = optn.name;
    }
    this.menuOpen && this.toggleDropList();
    return this;
}

UIDropDownMini.prototype.getActive = function () {
    return this.activeOption;
}

UIDropDownMini.prototype.disableDrop = function (flag) {
    if(flag) {
        this.uiMoreIcon.style.display = 'none';
    } else {
        this.uiMoreIcon.style.display = '';
    }
    return this;
}

function UISearchbar(placeholder, defValue = "") {
    UI.call(this);

    this.dom = null;
    
    this.value = ""

    const scope = this;

    var changeEvent = document.createEvent('HTMLEvents');
    changeEvent.initEvent('change', true, true);

    function onChange(e){
        scope.setValue(e.target.value);
        // scope.dom.dispatchEvent(changeEvent);
    }

    function onFocus() {
        document.addEventListener('keyup', onKeyDown, false);
    }

    function onBlur() {
        document.removeEventListener('keyup', onKeyDown, false);
    }

    function onKeyDown(e) {
        scope.setValue(e.target.value);
        scope.dom.dispatchEvent(changeEvent);
    }

    const searchbarDiv = document.createElement('div');
    searchbarDiv.classList.add('wsSearchbar');

    const searchCont = document.createElement('div');
    searchCont.classList.add('wsSearchbar__container');

    const searchbarIcon = document.createElement('img');
    searchbarIcon.classList.add('wsSearchbar__icon');
    searchbarIcon.src = SearchIcon;
    searchbarIcon.draggable = false;
    searchCont.appendChild(searchbarIcon);

    const searchInput = document.createElement('input');
    searchInput.type = 'text';
    searchInput.placeholder = placeholder;
    searchInput.value = defValue;
    searchInput.autocomplete = 'off';
    searchInput.addEventListener('change', onChange, false);
    searchInput.addEventListener('focus', onFocus, false);
    searchInput.addEventListener('blur', onBlur, false);
    searchCont.appendChild(searchInput);

    searchbarDiv.appendChild(searchCont);

    this.dom = searchbarDiv;

    return this;
}

UISearchbar.prototype = Object.create(UI.prototype);
UISearchbar.prototype.constructor = UISearchbar;

UISearchbar.prototype.setValue = function (value) {
    return this.value = value;
}

UISearchbar.prototype.getValue = function () {
    return this.value;
}

function UIRangeSlider(id, minValue, maxValue, defValue, percentW = undefined) {

    UI.call(this);

    this.dom = null;

    const rangeInput = document.createElement('input');
    rangeInput.classList.add('ws_uiSliderInput');
    percentW && (rangeInput.style.width = percentW);
    rangeInput.type = "range"
    rangeInput.min = minValue || 0;
    rangeInput.max = maxValue || 100;
    rangeInput.defaultValue = defValue || 0;
    rangeInput.autocomplete = 'off';
    rangeInput.id = id;

    this.dom = rangeInput;

    return this;
}

UIRangeSlider.prototype = Object.create(UI.prototype);
UIRangeSlider.prototype.constructor = UIRangeSlider;

UIRangeSlider.prototype.getValue = function () {
    return this.dom.value;
}

UIRangeSlider.prototype.setValue = function (value) {
    this.dom.value = value;
    return this;
}

export {
    UIPanel,
    UIRow,
    UICol,
    UISeparator,
    UITooltip,
    UIText,
    UIInputText,
    UIInputTextArea,
    UINumber,
    UIColor,
    UIColorPalette,
    UICheckbox,
    UIArrowCheckbox,
    UIAudio,
    UIVideo,
    UIOutliner,
    UIImage,
    UITextHeader,
    UITabs,
    UIButton,
    UILibraryCardImage,
    UILibraryCardAudio,
    UILibraryCardModel,
    UILibraryCardVideo,
    UIObjectInfoCard,
    UITextButton,
    UISwitch,
    UIRadioButton,
    UIAnimations,
    UIQRCode,
    UIViewOptionRow,
    UIImageInput,
    UIFloorplanImgInput,
    UIUserWarningCard,
    UILocationPinImageContainer,
    UILocationPinImageCard,
    UIMultiselect,
    UIFileInput,
    UIPinNavSelector,
    UIRadioSelector,
    UI2dMenuViewCard,
    UIMapInfoCard,
    UIFloorDropDownCard,
    UINameIdenfierInput,
    UIDropDown,
    UIDropDownMini,
    UISearchbar,
    UIRangeSlider
}