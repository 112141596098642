import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from 'react-router-dom';
// to be removed after doing rewrite on azure
import HttpsRedirect from 'react-https-redirect';
/* Components */
import App from './components/App'
import { dataStore } from "./features/store";

/* Styles */
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/main.css";

if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(
  // <HttpsRedirect>
    <Provider store={dataStore}>
      <Router>
        <App />
      </Router>
    </Provider>,
  // </HttpsRedirect>,
  document.getElementById("root")
);
