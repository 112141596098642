import * as THREE from "three";
import EditorExperience from "../InteractiveExperience";

import { CSS2DRenderer } from "three/examples/jsm/renderers/CSS2DRenderer.js"

export default class Renderer {
    constructor() {
        this.experience = new EditorExperience();
        this.sizes = this.experience.sizes;
        this.canvas = this.experience.canvas;
        this.scene = this.experience.scene;
        this.viewPort = new THREE.Vector4();

        this.raycaster = new THREE.Raycaster();

        this.initInstance();

        this.experience.on("swapScene", this.updateScene);
        this.experience.on("renderUpdatedScene", this.onRenderUpdatedScene);
    }

    initInstance = () => {
        const scope = this;
        this.instance = new THREE.WebGLRenderer({
            antialias: this.sizes.pixelRatio > 1 ? false : true,
            canvas: this.canvas,
            powerPreference: "high-performance",
        });

        this.instance.physicallyCorrectLights = true;
        this.instance.outputColorSpace = THREE.SRGBColorSpace;
        this.instance.setClearColor("#AAAAAA");
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(this.sizes.pixelRatio);

        this.instance.getContext().canvas.addEventListener(
            "webglcontextlost",
            (e) => {
                e.preventDefault();
                console.log("CONTEXT LOST");
                scope.experience?.time.cancelAnimationFrame();
                window.location.reload();
            },
            false
        );

        this.cssRenderer = new CSS2DRenderer()
        this.cssRenderer.setSize(window.innerWidth, window.innerHeight)
        this.cssRenderer.domElement.style.position = 'absolute'
        this.cssRenderer.domElement.style.top = '0px'
        this.cssRenderer.domElement.style.pointerEvents = 'none'
        document.body.appendChild(this.cssRenderer.domElement);

        this.cssRenderer.setSize(this.sizes.width, this.sizes.height);

        this.instance.getViewport(this.viewPort);
    };

    onMouseMove = (e) => {
        const mouse = { x: 0, y: 0 };
        mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
    };

    resize = () => {
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.cssRenderer.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(this.sizes.pixelRatio);
        this.instance.getViewport(this.viewPort);

    };

    onRenderUpdatedScene = () => {
        this.scene = this.experience.scene;
        this.update();
        this.experience.trigger("sceneSwitched");
    }

    update = () => {
        if(this.experience.camera) {
            this.instance.render(this.scene, this.experience.camera.instance);
            this.cssRenderer.render(this.scene, this.experience.camera.instance);
        }
    };
}
