import EventEmitter from "../../utils/EventEmitter";
import { TransformControls } from "../../utils/TransformControls.js";
import { v4 } from "uuid";

import { SceneTypes } from "../../utils/Strings";

import { UIPanel, UIOutliner, UIText } from "../ui.lib";

class SceneSidebarScene extends EventEmitter {
  constructor(editor) {
    super();
    this.editor = editor;
    this.strings = this.editor.strings;

    this.toggleSceneGraph = false;

    this.camera = this.editor.camera.instance;
    this.scene = this.editor.scene;
    this.dom = null;
    this.domScene = null;
    this.options = [];
    this.prevSelected = null;

    this.sceneTypes = new SceneTypes();

    this.nodeStates = new WeakMap();
    this.nodeGroups = new Map();
    this.setUpOutliner();

    this.initUI();
    this.editor.on("objectAdded", this.refreshUI);
    this.editor.on("objectChanged", this.refreshUI);
    this.editor.on("objectRemoved", this.onObjectRemoved);
    this.editor.on("objectSelected", this.onObjectSelected);
    this.editor.on("switchBetweenMaps", this.onSwitchBetweenMaps);
    this.editor.on("initiateVersionSwitch", this.onSwitchBetweenMaps);

    // this.editor.on('mountObjectLoader', this.onMountObjectLoader);
  }

  initUI = () => {
    this.uiCont = new UIPanel();
    this.uiCont.addClass("wsSceneSidebarContentContainer");

    this.uiCont.addChild(
      new UIText("100%", "Content").addClass("wsSceneContentTitle")
    );

    //Now Init Scene
    this.initSceneUI();

    this.uiContScene = new UIPanel();
    this.uiContScene.addClass("wsSceneContainer");

    this.uiContScene.onClick((e) => e.stopPropagation());
    this.uiContScene.dom.appendChild(this.domScene);

    // this.uiContScene.setDisplay('none');

    this.uiCont.dom.appendChild(this.uiContScene.dom);

    this.dom = this.uiCont;
  };

  onObjectSelected = (object) => {
    if (object !== null) {
      let needsRefresh = false;
      let parent = object.parent;

      while (parent !== this.editor.scene) {
        if (
          this.nodeStates.get(parent) !== true &&
          !("FloorPegsGroup" in parent?.userData)
        ) {
          this.nodeStates.set(parent, true);
          needsRefresh = true;
        }
        parent = parent.parent;
      }

      if (needsRefresh) this.initUI();

      this.uiOutliner.setValue(object.uuid);
    } else {
      this.uiOutliner.setValue(null);
    }
  };

  onObjectRemoved = () => {
    this.nodeStates = new WeakMap();
    this.nodeGroups = new Map();
    this.refreshUI();
  };

  refreshUI = () => {
    this.initSceneUI();
    this.editor.trigger("sceneHierarchyChanged");
    if (this.editor.selectedObject)
      this.onObjectSelected(this.editor.selectedObject);
  };

  isNodeGroup = (uuid) => {
    let idType = "three";
    Array.from(this.nodeGroups.values()).forEach((grp) => {
      if (grp.uuid === uuid) {
        idType = "node";
      } else if (this.sceneTypes.isSubType(grp.name)) {
        grp.children.forEach((childGrp) => {
          if (childGrp.uuid === uuid) {
            idType = "subNode";
          }
        });
      }
    });
    return idType;
  };

  setUpOutliner = () => {
    this.uiOutliner = new UIOutliner(this.editor);
    this.uiOutliner.onChange(() => {
      let objId = this.uiOutliner.getValue();
      if (objId !== null) {
        const idType = this.isNodeGroup(objId);
        if (idType === "node") {
          this.uiOutliner.setValue(objId);
          const obj = Array.from(this.nodeGroups.values()).find(
            (o) => o.uuid === objId
          );
          if (obj) {
            this.nodeStates.set(obj, this.nodeStates.get(obj) === false);
            this.initSceneUI();
          }
        } else if (idType === "subNode") {
          this.uiOutliner.setValue(objId);
          let obj = null;
          Array.from(this.nodeGroups.values()).forEach((o) => {
            if (this.sceneTypes.isSubType(o.name)) {
              obj = o.children.find((oo) => oo.uuid === objId);
            }
          });
          if (obj) {
            this.nodeStates.set(obj, this.nodeStates.get(obj) === false);
            this.initSceneUI();
          }
        } else {
          var object = this.editor.scene.getObjectByProperty("uuid", objId);
          if(object.userData.type === "wayPointGroup") {
            this.editor.guidedTourController.selectGuidedTour(object.userData.id);
          } else {
            this.editor.select(object);
          }
        }
      }
    });

    this.uiOutliner.onDblClick(() => {
      let objId = this.uiOutliner.getValue();
      var object = this.editor.scene.getObjectByProperty("uuid", objId);
      object && this.editor.camera.relocateCamera(object);
    });
  };

  isNonInteractiveObject = (uuid) => {
    const index = this.editor.nonInteractiveObjects.indexOf(uuid);
    return index !== -1;
  };

  isGrouped = (children, id) => {
    let bFlag = false;
    children.forEach((c) => {
      if (c.uuid === id) bFlag = true;
    });

    return bFlag;
  };

  getNodeObj = (type) => {
    const newGrp = {};
    newGrp.name = type;
    newGrp.uuid = v4();
    newGrp.type = "Node_Group";
    newGrp.children = [];

    newGrp.userData = {};
    newGrp.userData.skipSelect = true;
    newGrp.userData.type = "Node_Group";

    return { ...newGrp };
  };

  generateGroupNodes = () => {
    this.scene.children.forEach((child) => {
      const { toAdd } = this.getObjectType(child);
      if (toAdd) {
        const type = this.sceneTypes.getType(child.userData.type);
        if (this.nodeGroups.has(type)) {
          const typeGrp = this.nodeGroups.get(type);
          if (this.sceneTypes.isSubType(type)) {
            const childType = this.sceneTypes.getSubType(child.userData.type);
            let childTypeGrp = typeGrp.children.find(
              (p) => p.name === childType
            );
            let childTypeIdx = typeGrp.children.findIndex(
              (p) => p.name === childType
            );
            if (childTypeGrp && childTypeIdx > -1) {
              if (this.isGrouped(childTypeGrp.children, child.uuid) === false)
                childTypeGrp.children.push({
                  uuid: child.uuid,
                  name: child.name,
                });
              typeGrp.children[childTypeIdx] = childTypeGrp;
            } else {
              childTypeGrp = this.getNodeObj(childType);
              childTypeGrp.children.push({
                uuid: child.uuid,
                name: child.name,
              });
              typeGrp.children.push(childTypeGrp);
            }
          } else {
            if (this.isGrouped(typeGrp.children, child.uuid) === false)
              typeGrp.children.push({ uuid: child.uuid, name: child.name });
          }
          this.nodeGroups.set(type, typeGrp);
        } else {
          const newGrp = this.getNodeObj(type);

          if (this.sceneTypes.isSubType(type)) {
            const childType = this.sceneTypes.getSubType(child.userData.type);
            const childNewGrp = this.getNodeObj(childType);
            childNewGrp.children.push({ uuid: child.uuid, name: child.name });
            newGrp.children.push(childNewGrp);
          } else {
            newGrp.children.push({ uuid: child.uuid, name: child.name });
          }
          this.nodeGroups.set(type, newGrp);
        }
      }
    });
  };

  initSceneUI = () => {
    this.options = [];
    var scope = this;
    this.generateGroupNodes();
    (function addObjects(objects, pad) {
      for (var i = 0; i < objects.length; i++) {
        let obj = objects[i];
        obj =
          "type" in obj && obj.type === "Node_Group"
            ? obj
            : scope.editor.scene.getObjectByProperty("uuid", obj.uuid);
        if (obj) {
          let { toAdd } = scope.getObjectType(obj);
          if (toAdd && !scope.isNonInteractiveObject(obj.uuid)) {
            if (scope.nodeStates.has(obj) === false) {
              scope.nodeStates.set(obj, false);
            }

            var option = scope.generateOptions(obj);
            option.style.paddingLeft = pad * 3 + "px";
            scope.options.push({ dom: option });

            if (
              scope.nodeStates.get(obj) === true &&
              obj.userData["skipChild"] === undefined
            ) {
              addObjects(obj.children, pad + 4);
            }
          }
        }
      }
    })(Array.from(this.nodeGroups.values()), 1);
    this.uiOutliner.setOptions(this.options);
    if (this.editor.selectedObject !== null) {
      this.uiOutliner.setValue(this.editor.selectedObject.uuid);
    }
    this.domScene = this.uiOutliner.dom;
  };

  generateOptions = (obj) => {
    let option = document.createElement("div");
    option.className = "wsOptionContainer";
    const object =
      "type" in obj && obj.type === "Node_Group"
        ? obj
        : this.editor.scene.getObjectByProperty("uuid", obj.uuid);
    option.innerHTML = this.generateHTML(object);
    option.value = object.uuid;
    option.key = option.uuid;
    option.addEventListener(
      "click",
      (e) => {
        e.preventDefault();
        e.stopPropagation();
      },
      false
    );
    //Opener
    if (this.nodeStates.has(object)) {
      var state = this.nodeStates.get(object);
      var opener = document.createElement("span");
      opener.classList.add("opener");

      if (
        object.children.length > 0 &&
        object.userData["skipChild"] === undefined
      ) {
        opener.classList.add(state ? "open" : "closed");
      }

      opener.addEventListener(
        "click",
        (e) => {
          e.stopPropagation();
          this.nodeStates.set(object, this.nodeStates.get(object) === false);
          this.initSceneUI();
        },
        false
      );

      option.insertBefore(opener, option.firstChild);
    }
    return option;
  };

  generateHTML = (object) => {
    let html = null;
    let { toAdd } = this.getObjectType(object);
    const type = (object.userData?.type || "object3d")
      .replace(/\s+/g, "")
      .toLowerCase();
    if (toAdd) {
      html = `<span id="${type}_${
        object.uuid
      }" class="wScene__wsListItem"><span class="wScene__wsObjectImg wScene__wsObjectImg--${type}"></span><span class="wScene__wsListItemText"><abbr title="${this.escapeHTML(
        object.name
      )}">${this.escapeHTML(object.name)}</abbr></span></span>`;
    }
    return html;
  };

  getMaterialName(material) {
    if (Array.isArray(material)) {
      var array = [];
      for (var i = 0; i < material.length; i++) {
        array.push(material[i].name);
      }
      return array.join(",");
    }
    return material.name;
  }

  escapeHTML(html) {
    return html
      .replace(/&/g, "&amp;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#39;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  getObjectType(object) {
    if (object && object.type.includes("Scene"))
      return { objType: "Scene", toAdd: true };
    if (object && object.type.includes("Camera"))
      return { objType: "Camera", toAdd: false };
    if (object && object.type.includes("Light"))
      return { objType: "Light", toAdd: false };
    if (object && object.type.includes("Mesh"))
      return { objType: "Mesh", toAdd: true };
    if (object && object.type.includes("Line"))
      return { objType: "Line", toAdd: true };
    if (object && object.type.includes("Points"))
      return { objType: "Points", toAdd: true };
    if (object && object.type.includes("GridHelper"))
      return { objType: "GridHelper", toAdd: false };
    if (object && object.type.includes("BoxHelper"))
      return { objType: "BoxHelper", toAdd: false };
    if (object && object.type.includes("Node_Group"))
      return { objType: "Node_Group", toAdd: true };
    if (object && object instanceof TransformControls)
      return { objType: "TransformControls", toAdd: false };
    return { objType: "Object3D", toAdd: true };
  }

  onMountObjectLoader = (bFlag) => {
    if (bFlag) {
      this.uiCont.addClass("wsBottomBarContainer--noEvnts");
    } else {
      this.uiCont.removeClass("wsBottomBarContainer--noEvnts");
    }
  };

  onSwitchBetweenMaps = () => {
    this.nodeStates = new WeakMap();
    this.nodeGroups = new Map();
  };
}

export { SceneSidebarScene };
