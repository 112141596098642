import * as THREE from 'three';
import EditorExperience from '../../MinEditorExperience.js';

//shaders
import { heatVertex, heatFragment } from '../../miniUtils/shaders/heatShaders.js';

import { getRandomIdCode } from '../../../threeUtils/TransformConversions.js';

export default class HeatMap2 {
    constructor(heatData, heatType, sizes = null) {

        this.editor = new EditorExperience();
        
        this.heatData = heatData;
        this.heatType = heatType;

        this.heatPlaneData = null;

        this.sizes = {
            start: sizes?.start || 0,
            end: sizes?.end || 0,
        };

        this.mesh = null;

        this.computeHeatPlaneData();
        this.setupHeatMap();

        if(this.heatType === 'end') {
            this.initHTML();
        }
        this.editor.on('hotspotHovered', this.showAnnotation); 
    }
    
    setupHeatMap = () => {
        var planeGeometry = new THREE.PlaneGeometry(this.heatPlaneData.width, this.heatPlaneData.height, 100, 100);
        planeGeometry.rotateX(-Math.PI * 0.5);

        const heightMap = this.createHeightMap();
        
        this.mesh = new THREE.Mesh(planeGeometry, new THREE.ShaderMaterial({
        uniforms: {
            heightMap: {value: heightMap},
            heightRatio: {value: 0},
            uHeatType: {value: this.getHeatType()}
        },
            vertexShader: heatVertex,
            fragmentShader: heatFragment,
            transparent: true,
            side: THREE.DoubleSide,
        }));
        this.mesh.name = `heatMap_${getRandomIdCode()}`
        const { boundingSphere, boundingBox } = this.heatPlaneData;
        this.mesh.position.set(boundingSphere.center.x, boundingBox.min.y + 0.02, boundingSphere.center.z);
    }

    getHeatType = () => {
        if(this.heatType === 'average') return 1;
        else if(this.heatType === 'start') return 2;
        else if(this.heatType === 'end') return 3;
        else return -1;
    }

    createHeightMap = () => {
        var canvas = document.createElement("canvas");
        canvas.width = 512;
        canvas.height = 512;
        var ctx = canvas.getContext("2d");
        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, 512, 512);

        this.heatData.forEach(heatDist => {
            const { position, weight} = heatDist;

            const { boundingBox } = this.heatPlaneData;

            var x = THREE.MathUtils.mapLinear(position.x, boundingBox.min.x, boundingBox.max.x, 0, canvas.width);
            var y = THREE.MathUtils.mapLinear(position.z, boundingBox.min.z, boundingBox.max.z, 0, canvas.height);

            var radius, grd, h8;
            if(weight === 1) {
                radius = 10;
                grd = ctx.createRadialGradient(x, y, 1, x, y, radius);
                h8 = 340;
            } else {
                radius = 25 * weight;
                grd = ctx.createRadialGradient(x, y, 1, x, y, radius);
                h8 = 340 * weight;
            }

            grd.addColorStop(0, "rgb("+ h8 + "," + h8 + "," + h8 +")");
            grd.addColorStop(1, "transparent");
            ctx.fillStyle = grd;
            ctx.fillRect(0, 0, 512, 512);
        });

        return new THREE.CanvasTexture(canvas);
    }

    computeHeatPlaneData = () => {

        const floor = this.editor.scene.children.find(child => child.userData.type === 'floorplan');

        const boundingBox = new THREE.Box3();
        const boundingSphere = new THREE.Sphere();
        if(floor) {
            boundingBox.setFromObject(floor);
            boundingBox.getBoundingSphere(boundingSphere);
        } else {
            const sceneGroup = new THREE.Group();
            this.editor.scene.children.forEach(child => {
                if(this.toAdd(child)) sceneGroup.add(child.clone());
            });
            boundingBox.setFromObject(sceneGroup);
            boundingBox.getBoundingSphere(boundingSphere);
        }

        this.heatPlaneData = {
            boundingBox,
            boundingSphere,
            width: Math.abs(boundingBox.max.x - boundingBox.min.x),
            height: Math.abs(boundingBox.max.z - boundingBox.min.z),
        }
    }

    toAdd(object) {
        if (object && object.type.includes("Camera")) return false;
        if (object && object.type.includes("Light")) return false;
        if (object && object.type.includes("GridHelper")) return false;
        if (object && object.type.includes("BoxHelper")) return false;
        return true;
    }

    initHTML = () => {
        this.annotation = document.createElement('div');
        this.annotation.classList.add('annotationHeat');
        this.annotation.id = `start_${this.mesh.name}`;

        this.pDes = document.createElement('div');
        this.pDes.classList.add('annotationHeat--text')
        this.pDes.textContent = 'Average App Opens:';
        this.annotation.appendChild(this.pDes);

        this.pDes2 = document.createElement('span');
        this.pDes2.classList.add('annotationHeat--textSm')
        this.pDes2.textContent = ` ${this.sizes.start}`;
        this.pDes.appendChild(this.pDes2);

        this.pDes3 = document.createElement('div');
        this.pDes3.classList.add('annotationHeat--text')
        this.pDes3.textContent = 'Average App Exits:';
        this.annotation.appendChild(this.pDes3);
        
        this.pDes4 = document.createElement('span');
        this.pDes4.classList.add('annotationHeat--textSm')
        this.pDes4.textContent = ` ${this.sizes.end}`;
        this.pDes3.appendChild(this.pDes4);

        this.annotation.style.visibility = 'hidden';
        document.body.appendChild(this.annotation)
    }

    showAnnotation = (object) => {
        if(this.annotation) {
            if(object === this.mesh) {
                this.annotation.style.visibility = 'visible';
            } else {
                this.annotation.style.visibility = 'hidden';
            }
        }
    }


    
} 