import * as THREE from "three";
import { fromPosObjectToVec3Pos } from "./TransformConversions";

// ComputeDirections.js
class ComputeDirections {
    constructor(waypoints) {
        this.waypoints = waypoints;
        this.totalWaypoints = waypoints.length;

        this.slightTurnAngle = 30.0;
        this.sharpTurnAngle = 60.0;
        this.maxSegmentLength = 2.0;
        this.maxSegmentDistance = 4.0;

        this.directionList = [];
        this.navigationDuration = "";
        this.totalDistance = 0;

        // console.log("waypoints", this.waypoints);

        if (this.waypoints.length > 0) {
            if (this.totalWaypoints > 2) {
                this.getTurns();
                // this.splitLongerTurns();
                this.combineDuplicateTurns();
                this.calculateDistancesInTurns();

                this.calculateNavigationDuration();
            } else {
                this.directionList.push({
                    turnText: "Go Straight",
                    turnAngle: 0,
                    position: fromPosObjectToVec3Pos(
                        this.waypoints[0].position
                    ),
                    directionType: "STRAIGHT",
                    turnIndex: 0,
                });
                this.directionList.push({
                    turnText: "Go Straight",
                    turnAngle: 0,
                    position: fromPosObjectToVec3Pos(
                        this.waypoints[1].position
                    ),
                    directionType: "STRAIGHT",
                    turnIndex: 1,
                });
                this.calculateNavigationDuration();
                this.combineDuplicateTurns();
            }
        }

        // console.log("directionList", this.directionList);
    }

    getTurns = () => {
        // start from 1st waypoint as 0th and last waypoint would be a node by default
        for (var i = 0; i < this.totalWaypoints - 2; i++) {
            this.computeTurn(
                fromPosObjectToVec3Pos(this.waypoints[i].position),
                fromPosObjectToVec3Pos(this.waypoints[i + 1].position),
                fromPosObjectToVec3Pos(this.waypoints[i + 2].position),
                i
            );
        }
    };

    computeTurn = (currentWaypoint, nextWaypoint, nextNextWaypoint, index) => {
        let cp = new THREE.Vector3().subVectors(nextWaypoint, currentWaypoint);
        let cn = new THREE.Vector3().subVectors(nextNextWaypoint, nextWaypoint);

        var crossProduct = new THREE.Vector3().crossVectors(cp, cn);
        var dotProduct = cp.dot(cn);
        var angleRad = Math.atan2(crossProduct.length(), dotProduct);
        var orientation = crossProduct.dot(currentWaypoint);
        var signedAngleRad = orientation >= 0 ? angleRad : -angleRad;
        var signedAngleDeg = THREE.MathUtils.radToDeg(signedAngleRad);

        if (Math.abs(signedAngleDeg) >= this.slightTurnAngle) {
            if (signedAngleDeg >= this.sharpTurnAngle) {
                this.directionList.push({
                    turnText: "Turn Right",
                    turnAngle: signedAngleDeg,
                    position: currentWaypoint,
                    directionType: "RIGHT",
                    turnIndex: index,
                });
            } else if (signedAngleDeg <= -this.sharpTurnAngle) {
                this.directionList.push({
                    turnText: "Turn Left",
                    turnAngle: signedAngleDeg,
                    position: currentWaypoint,
                    directionType: "LEFT",
                    turnIndex: index,
                });
            } else if (signedAngleDeg >= 0) {
                this.directionList.push({
                    turnText: "Slight Right",
                    turnAngle: signedAngleDeg,
                    position: currentWaypoint,
                    directionType: "SLIGHT_RIGHT",
                    turnIndex: index,
                });
            } else {
                this.directionList.push({
                    turnText: "Slight Left",
                    turnAngle: signedAngleDeg,
                    position: currentWaypoint,
                    directionType: "SLIGHT_LEFT",
                    turnIndex: index,
                });
            }
        } else {
            this.directionList.push({
                turnText: "Go Straight",
                turnAngle: 0,
                position: currentWaypoint,
                directionType: "STRAIGHT",
                turnIndex: index,
            });
        }
    };

    combineDuplicateTurns = () => {
        for (var i = this.directionList.length - 1; i > 0; i--) {
            if (
                i - 1 >= 0 &&
                this.directionList[i].directionType ===
                    this.directionList[i - 1].directionType
            ) {
                this.directionList.splice(i, 1);
            }
        }
    };

    calculateNavigationDuration = () => {
        for (var i = 0; i < this.totalWaypoints - 1; i++) {
            this.totalDistance += fromPosObjectToVec3Pos(
                this.waypoints[i].position
            ).distanceTo(
                fromPosObjectToVec3Pos(this.waypoints[i + 1].position)
            );
        }
        let durationInSeconds = this.totalDistance / 0.7;
        this.navigationDuration =
            this.convertSecondsToMinutes(durationInSeconds);
    };

    convertSecondsToHMS = (seconds) => {
        seconds = Number(seconds);
        var h = Math.floor(seconds / 3600);
        var m = Math.floor((seconds % 3600) / 60);
        var s = Math.floor((seconds % 3600) % 60);
        if (h > 0) {
            return h + " hours " + m + " minutes " + s + " seconds";
        } else if (m > 0) {
            return m + " minutes " + s + " seconds";
        } else {
            return s + " seconds";
        }
    };

    convertSecondsToMinutes = (seconds) => {
        const mins = Math.ceil(seconds / 60);
        return `${mins} ${mins === 1 ? "min" : "mins"}`;
    };

    calculateDistancesInTurns = () => {
        for (var i = 0; i < this.directionList.length; i++) {
            if (i === this.directionList.length - 1) {
                this.directionList[i].distanceToNextTurn =
                    this.calculateDistance(
                        this.waypoints,
                        this.directionList[i].turnIndex,
                        this.totalWaypoints - 1
                    );
            } else {
                this.directionList[i].distanceToNextTurn =
                    this.calculateDistance(
                        this.waypoints,
                        this.directionList[i].turnIndex,
                        this.directionList[i + 1].turnIndex
                    );
            }
        }
    };

    calculateDistance = (waypoints, startIndex, endIndex) => {
        let distance = 0;
        for (var i = startIndex; i < endIndex; i++) {
            distance += fromPosObjectToVec3Pos(
                waypoints[i].position
            ).distanceTo(fromPosObjectToVec3Pos(waypoints[i + 1].position));
        }
        return distance < 1 ? 1 : distance;
    };

    splitLongerTurns = () => {
        for (var i = 0; i < this.directionList.length; i++) {
            if (
                this.directionList[i] &&
                this.directionList[i].distanceToNextTurn >
                    this.maxSegmentDistance
            ) {
                const maxDistance = parseInt(this.maxSegmentLength);
                if (
                    this.directionList[i].distanceToNextTurn >
                    maxDistance + 1
                ) {
                    const increasedBy = maxDistance;
                    const segmentIndex =
                        this.directionList[i].turnIndex + increasedBy;
                    let newWaypoint;
                    if (
                        this.totalWaypoints > 0 &&
                        segmentIndex <= this.totalWaypoints - 1
                    ) {
                        newWaypoint = fromPosObjectToVec3Pos(
                            this.waypoints[
                                this.directionList[i].turnIndex + increasedBy
                            ].position
                        );
                        this.directionList.splice(i + 1, 0, {
                            turnText: "Go Straight",
                            turnAngle: 0,
                            position: newWaypoint,
                            directionType: "STRAIGHT",
                        });
                    }
                }
            }
        }
    };
}

export default ComputeDirections;
