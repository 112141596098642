import { MoreVertical } from "react-feather";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as DefaultIAPIcon } from "../../../../../assets/svgs/dash-landing/mapdetails/iap/defaultiap.svg";
import { ReactComponent as RenameIcon } from "../../../../../assets/svgs/dash-landing/mapdetails/rename.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/svgs/dash-landing/mapdetails/iap/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/svgs/dash-landing/mapdetails/iap/delete.svg";
import { ReactComponent as LArrow } from "../../../../../assets/svgs/dash-landing/mapdetails/iap/arrowleft.svg";
import { ReactComponent as RArrow } from "../../../../../assets/svgs/dash-landing/mapdetails/iap/arrowright.svg";
import alertIcon from "../../../../../assets/svgs/scenes/alert.svg";

import DefaultIAP from "../../../../../assets/pngs/mapdetails/defaultImageAccessPoint.png";
import { mapDetails } from "../../../../../features/user/studio/studioSlice";
import CustomModal from "../common/CustomModal";
import {
  isThumbnailUploading,
  resetMapDetailsTab,
} from "../../../../../features/common/commonSlice";
import { createAsset } from "../common/AssertUtils";
import { setNewAlert } from "../../../../../features/common/alertSlice";
import ARWayImage from "../../../../../components/common/CO/ARWayImage";
import {
  deleteAnchor,
  updateAnchor,
} from "../../../../../features/user/studio/anchorSlice";
import { omit, isNull } from "lodash";
import { Edit2 } from "react-feather";

const IAPCard = ({ anchorIAPObj, isChooseDIAP, checkSetter, id }) => {
  const dispatch = useDispatch();

  const [renameModal, setRenameModal] = useState(false);
  const [editThumbnailModal, setEditThumbnailModal] = useState(false);
  const [replaceModal, setReplaceModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [thumbnailFileURL, setThumbnailFileURL] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [anchorName, setAnchorName] = useState(anchorIAPObj.name);
  const [anchorDescription, setAnchorDescription] = useState(
    anchorIAPObj.description
  );
  const selectedMapDetails = useSelector(mapDetails);
  const anchorIds = selectedMapDetails?.metadata?.anchors
    .slice(1)
    .filter((IAPAnchorId) => !IAPAnchorId.isQrCode);
  const mapId = selectedMapDetails?.metadata?.mapId;
  const primaryIAP = anchorIds.filter(
    (anchorId) => anchorId.isPrimaryAccess
  )[0];

  const isPrimaryAccess = primaryIAP?.isPrimaryAccess;
  const [showCheckbox, setShowCheckbox] = useState(false);
  const thumbnailStatus = useSelector(isThumbnailUploading);
  const renameIAP = () => {
    let anchors = [...selectedMapDetails.metadata.anchors];
    let selectedAnchor = anchors.find(
      (anchor) => anchor.id === anchorIAPObj.id
    );
    selectedAnchor = omit(selectedAnchor, ["modifiedOn", "createdOn"]);
    let modifiedAnchor = {
      ...selectedAnchor,
      name: anchorName,
    };
    dispatch(
      updateAnchor({ mapId: selectedMapDetails.id, modifiedAnchor })
    ).then(() => {
      if (renameModal) setRenameModal(false);
      dispatch(
        setNewAlert({
          msg: "IAP Renamed Successfully!",
          alertType: "success",
        })
      );
    });
  };

  const editIAP = () => {
    let anchors = [...selectedMapDetails.metadata.anchors];
    let selectedAnchor = anchors.find(
      (anchor) => anchor.id === anchorIAPObj.id
    );
    selectedAnchor = omit(selectedAnchor, ["modifiedOn", "createdOn"]);
    let modifiedAnchor = {
      ...selectedAnchor,
      name: anchorName,
      description: anchorDescription,
    };
    dispatch(
      updateAnchor({ mapId: selectedMapDetails.id, modifiedAnchor })
    ).then(() => {
      if (editModal) setEditModal(false);
      dispatch(
        setNewAlert({
          msg: "IAP Updated Successfully!",
          alertType: "success",
        })
      );
    });
  };

  const saveDefaultIAP = (isReplace) => {
    let anchors = [...selectedMapDetails.metadata.anchors].filter(
      (anchorIAPObj) => !anchorIAPObj.isQrCode
    );
    let mAnchors = [];
    anchors.forEach((anchor) => {
      let modifiedAnchor = anchor;
      if (isReplace && anchor.isPrimaryAccess) {
        mAnchors.push({
          ...omit(modifiedAnchor, ["modifiedOn", "createdOn"]),
          isPrimaryAccess: false,
        });
      }
      if (anchor.id === anchorIAPObj.id) {
        mAnchors.push({
          ...omit(modifiedAnchor, ["modifiedOn", "createdOn"]),
          isPrimaryAccess: true,
        });
      }
    });
    // create delayed tasks
    let delay = 0;
    const delayIncrement = 500;
    const promises = mAnchors.map((anchor) => {
      delay += delayIncrement;
      return new Promise((resolve) => setTimeout(resolve, delay)).then(() => {
        dispatch(
          updateAnchor({
            mapId: selectedMapDetails.id,
            modifiedAnchor: anchor,
          })
        );
      });
    });
    Promise.all(promises).then((res) => {
      if (replaceModal) setReplaceModal(false);
      dispatch(
        setNewAlert({
          msg: "IAP details updated Successfully!",
          alertType: "success",
        })
      );
    });
  };

  const deleteIAP = () => {
    dispatch(
      deleteAnchor({ mapId: selectedMapDetails.id, anchorId: anchorIAPObj.id })
    ).then(() => {
      dispatch(
        setNewAlert({
          msg: "IAP Deleted Successfully!",
          alertType: "success",
        })
      );
      setDeleteModal(false);
    });
  };

  const editIAPThumbnail = (fileURL) => {
    let anchors = [...selectedMapDetails.metadata.anchors];
    let selectedAnchor = anchors.find(
      (anchor) => anchor.id === anchorIAPObj.id
    );
    selectedAnchor = omit(selectedAnchor, ["modifiedOn", "createdOn"]);
    let modifiedAnchor = {
      ...selectedAnchor,
      anchorthumbnail: fileURL,
    };
    dispatch(
      updateAnchor({ mapId: selectedMapDetails.id, modifiedAnchor })
    ).then(() => {
      dispatch(resetMapDetailsTab());
      dispatch(
        setNewAlert({
          msg: "IAP Thumnail updated Successfully!",
          alertType: "success",
        })
      );
    });
  };

  const renderModal = (
    modalFlag,
    onClose,
    onSuccess,
    header,
    successText,
    cancelText,
    successDisabled = false,
    children
  ) => {
    return (
      <CustomModal
        openModal={modalFlag}
        onCloseModal={() => onClose(false)}
        onSuccessModal={() => onSuccess()}
        modalHeader={header}
        textSuccess={successText}
        textCancel={cancelText}
        modalStyle={{ width: "50%" }}
        successDisabled={successDisabled}
      >
        {children}
      </CustomModal>
    );
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setThumbnailFileURL(URL.createObjectURL(e.target.files[0]));
      setThumbnailFile(e.target.files[0]);
    }
  };
  return (
    <div className="mb-5" key={id}>
      <div className="card bg-body rounded h-100" style={{ border: "none" }}>
        <div
          className={`card-body`}
          onMouseEnter={() => {
            setShowCheckbox(true);
          }}
          onMouseLeave={() => {
            setShowCheckbox(false);
          }}
        >
          <div
            className={`header ${
              checkSetter.isChecked
                ? "mapdetails__anchorcardBG"
                : "mapdetails__anchorcardDefaultBG"
            }`}
          >
            <div className="card-body iap__iapCardBorder align-items-center d-flex justify-content-center p-1 pb-3">
              {isChooseDIAP ? (
                <div className="bd-highlight" style={{ marginBottom: "4rem" }}>
                  <div
                    className="ps-2"
                    style={{
                      visibility: `${
                        checkSetter.isChecked
                          ? ""
                          : showCheckbox
                          ? ""
                          : "hidden"
                      }`,
                    }}
                  >
                    <input
                      style={{ cursor: "pointer", scale: "1.2" }}
                      className="form-check-input mapdetails__checkbox mb-3"
                      type="checkbox"
                      id={`checkboxNoLabel_${anchorIAPObj.id}`}
                      checked={checkSetter.isChecked}
                      onChange={() => {
                        checkSetter.setSeletedIAP(anchorIAPObj);
                      }}
                    />
                  </div>
                </div>
              ) : null}
              <div
                className={`bd-highlight ps-${isChooseDIAP ? "3" : "5"} pe-${
                  isChooseDIAP ? "5" : anchorIAPObj.isPrimaryAccess ? "3" : "5"
                }`}
              >
                <ARWayImage
                  className="mt-2"
                  src={`${
                    thumbnailFileURL
                      ? thumbnailFileURL
                      : anchorIAPObj.anchorthumbnail
                  }`}
                  style={{ height: "122px", width: "122px" }}
                />
              </div>
              {anchorIAPObj.isPrimaryAccess ? (
                <div className="bd-highlight pe-2">
                  <img
                    alt="IAP"
                    className="mt-2"
                    src={`${DefaultIAP}`}
                    style={{ paddingBottom: "6rem" }}
                  ></img>
                </div>
              ) : null}
            </div>
          </div>
          <div className="ps-3">
            <span className="float-start">
              <div
                className="stepper__SectionHeader"
                style={{ marginTop: "0.25rem" }}
              >
                {anchorIAPObj.name}
              </div>
              <div
                className="stepper__QRSubtitle"
                style={{ marginTop: "0.25rem" }}
              >
                Dimension:{" "}
                <strong>
                  {anchorIAPObj.dimension
                    ? `(${(
                        anchorIAPObj.dimension.sizeX * 100
                      ).toFixed(2)} x ${(
                        anchorIAPObj.dimension.sizeY * 100
                      ).toFixed(2)} cm)`
                    : "--"}
                </strong>
              </div>
              <div
                className="stepper__QRSubtitle"
                style={{ marginTop: "0.25rem" }}
              >
                Surface Direction:{" "}
                <strong>{anchorIAPObj?.direction ?? "--"}</strong>
              </div>
            </span>
            {isChooseDIAP ? null : (
              <div className="btn-group dropstart float-end">
                <button
                  type="button"
                  className="btn "
                  data-bs-toggle="dropdown"
                >
                  <MoreVertical />
                </button>
                <ul className="dropdown-menu">
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      if (isPrimaryAccess) setReplaceModal(true);
                      else saveDefaultIAP();
                    }}
                    style={
                      {
                        pointerEvents: anchorIAPObj.isPrimaryAccess ? "none" : "",
                      }
                    }
                  >
                    <div
                      className={`d-flex flex-row ${
                        anchorIAPObj.isPrimaryAccess ? "opacity-50" : ""
                      }`}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div className="pe-3">
                        <DefaultIAPIcon />
                      </div>
                      Set as Default
                    </div>
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setEditThumbnailModal(true);
                    }}
                  >
                    <div
                      className="d-flex flex-row"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="pe-3">
                        <EditIcon />
                      </div>
                      Edit Thumbnail
                    </div>
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setRenameModal(true);
                    }}
                  >
                    <div
                      className="d-flex flex-row"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="pe-3">
                        <Edit2 size={17} color="#353E5A" strokeWidth={1.4} />
                      </div>
                      Rename
                    </div>
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => setEditModal(true)}
                  >
                    <div
                      className="d-flex flex-row"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="pe-3">
                        <RenameIcon />
                      </div>
                      Edit Details
                    </div>
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={() => {
                      setDeleteModal(true);
                    }}
                  >
                    <div
                      className="d-flex flex-row"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="pe-3">
                        <DeleteIcon />
                      </div>
                      Delete
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        {renderModal(
          editThumbnailModal,
          setEditThumbnailModal,
          () => {
            createAsset(
              dispatch,
              thumbnailFile,
              "maps",
              "thumbnail",
              mapId,
              editIAPThumbnail
            );
            setEditThumbnailModal(false);
          },
          "Edit Thumbnail",
          "Confirm",
          "Cancel",
          isNull(thumbnailFile),
          <div className="d-inline-flex align-items-center">
            <div>
              <ARWayImage
                src={`${
                  thumbnailFileURL
                    ? thumbnailFileURL
                    : anchorIAPObj.anchorthumbnail
                }`}
                style={{
                  height: "306px",
                  width: "448px",
                  objectFit: "contain",
                }}
              />
              <div className="card-body pt-2">
                <div className="mapinfo__mapImageControlOverlay m-0">
                  <label htmlFor="thumbnail-input">
                    <span className="mapinfo__mapImageControlOverlay--text">
                      {anchorIAPObj?.anchorthumbnail
                        ? "Change Thumbnail"
                        : thumbnailStatus
                        ? "Uploading..."
                        : "Select Thumbnail"}
                    </span>
                  </label>
                  <input
                    id="thumbnail-input"
                    type="file"
                    accept="image/png, image/jpg"
                    onChange={handleFileChange}
                    onClick={() => {
                      setThumbnailFile(null);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {renderModal(
          renameModal,
          setRenameModal,
          renameIAP,
          "Rename",
          "Apply",
          "Cancel",
          false,
          <div className="mapinfo__mDescCont" style={{ width: "95%" }}>
            <input
              value={anchorName}
              onChange={(e) => {
                setAnchorName(e.target.value);
              }}
              className={`mapinfo__mapFormTextArea mapinfo__mapFormTextArea--active`}
              autoComplete="off"
              maxLength={20}
            />
          </div>
        )}
        {renderModal(
          deleteModal,
          setDeleteModal,
          deleteIAP,
          "Delete Image Access Point?",
          "Delete",
          "Cancel",
          false,
          <div className="mapinfo__mDescCont" style={{ width: "95%" }}>
            <div className="mapdetails__mapModalText">{`Delete “${anchorIAPObj.name}” from your map? Viewers will not be able to use this image to access your map when deleted.`}</div>
          </div>
        )}
        {renderModal(
          replaceModal,
          setReplaceModal,
          () => {
            saveDefaultIAP(true);
          },
          "Replace Default Image Access Point?",
          "Confirm",
          "Cancel",
          false,
          <div className="mapinfo__mDescCont" style={{ width: "95%" }}>
            <div className="mapdetails__mapModalText">{`Do you want to replace the current default image access point “${primaryIAP?.name}” and use “${anchorIAPObj.name}”?`}</div>
            <div className="card-body align-items-center d-flex justify-content-center p-1 pb-2 pt-4">
              <div className="bd-highlight ps-4 pe-3">
                <ARWayImage
                  src={`${primaryIAP?.anchorthumbnail}`}
                  style={{ height: "200px", width: "200px" }}
                />
                <div className="mapdetails__mapModalText pt-2">Current</div>
              </div>
              <div className="d-flex flex-column bd-highlight mb-3">
                <div className="p-2 bd-highlight">
                  <LArrow />
                </div>
                <div className="p-2 bd-highlight">
                  <RArrow />
                </div>
              </div>
              <div className="bd-highlight ps-4 pe-3">
                <ARWayImage
                  src={`${anchorIAPObj.anchorthumbnail}`}
                  style={{ height: "200px", width: "200px" }}
                />
                <div className="mapdetails__mapModalText pt-2">Replacement</div>
              </div>
            </div>
          </div>
        )}
        <CustomModal
          openModal={editModal}
          onCloseModal={() => setEditModal(false)}
          onSuccessModal={() => editIAP()}
          modalHeader={`Edit Details`}
          textSuccess="Save"
          textCancel="Cancel"
          modalStyle={{ width: 700 }}
          excludeAlignStyle={true}
        >
          <div className="stepper__qrEditModal">
            <div className="stepper__qrEditModal--iapCont">
              <ARWayImage
                src={
                  thumbnailFileURL
                    ? thumbnailFileURL
                    : anchorIAPObj.anchorthumbnail
                }
                alt="anchorthumbnail"
              />
            </div>
            <div className="stepper__qrEditModal--qrDataCont">
              <div>
                <div>
                  <span className="mapinfo__mDescCont--labelSpan">Title</span>
                  {
                    <span className="mapinfo__mDescCont--inputLenHelper">
                      {anchorName?.length}/20
                    </span>
                  }
                </div>
                <input
                  rows={2}
                  value={anchorName}
                  onChange={(e) => {
                    setAnchorName(e.target.value);
                  }}
                  className={`mapinfo__mapFormTextArea mapinfo__mapFormTextArea--active`}
                  autoComplete="off"
                  maxLength={20}
                />
              </div>
              <div>
                <div>
                  <span className="mapinfo__mDescCont--labelSpan">
                    Description
                  </span>
                  {
                    <span className="mapinfo__mDescCont--inputLenHelper">
                      {anchorName?.length}/100
                    </span>
                  }
                </div>
                <textarea
                  rows={3}
                  value={anchorDescription}
                  onChange={(e) => {
                    setAnchorDescription(e.target.value);
                  }}
                  className={`mapinfo__mapFormTextArea mapinfo__mapFormTextArea--active`}
                  autoComplete="off"
                  maxLength={100}
                />
              </div>
              <div className="stepper__qrEditModal--qrDisplayCont">
                <div className="stepper__qrEditModal--qrDisplayCont--qrInfoRow">
                  <strong>Surface Direction:</strong>
                  <span>{anchorIAPObj.direction || "--"}</span>
                </div>
                <div className="stepper__qrEditModal--qrDisplayCont--qrInfoRow">
                  <strong>Dimension:</strong>
                  <span>
                    {anchorIAPObj.dimension
                      ? `(${(
                          anchorIAPObj.dimension.sizeX * 100
                        ).toFixed(2)} x ${(
                          anchorIAPObj.dimension.sizeY * 100
                        ).toFixed(2)} cm)`
                      : "--"}
                  </span>
                </div>
                <div className="stepper__qrEditModal--qrDisplayCont--noteCard">
                  <img src={alertIcon} alt="alert-icon" />
                  <span>
                    <strong>Note:</strong> Edit surface direction and display
                    options in the ARway App.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    </div>
  );
};

export default IAPCard;
