import * as THREE from 'three';
// loader
import { GLTFLoader } from'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { PLYLoader } from '../../threeUtils/GZipPLYLoader/PLYLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

import EventEmitter from "../../utils/EventEmitter";
import EditorExperience from '../MinEditorExperience';

import Navigation from '../miniComponents/Navigation/Navigation';
import Floorplan from '../miniComponents/Floorplan/Floorplan';

import { 
    fromPosObjectToVec3Pos, 
    fromQuaternionObjectToEuler, 
    fromQuaternionObjectToQuaternion, 
    fromScaleObjectToVec3Scale 
} from '../../threeUtils/TransformConversions';
import FloorplanV1 from '../miniComponents/Floorplan/FloorplanV1';

export default class ObjectsLoader extends EventEmitter {
    
    constructor() {
        super();

        this.editor = new EditorExperience();
        this.sources = [];

        this.loadManager = new THREE.LoadingManager();

        this.items = {};
        this.toLoad = 0;
        this.preloadModels = 0;
        this.toInitLoading = false;
        this.loaded = 0;
        this.loaders = {
            texLoader: null,
            gltfLoader: null
        };

        this.asyncObject = [];

        this.plyFunctions = {
            'gz': 'loadGZIP',
            'ply': 'load',
        }

        this.preloadModelAssets = [
            { path: '/static/models/location_pin.glb', childLevel: 1, pinVar: 'mapPinObject' },
            { path: '/static/models/mappath_pin.glb', childLevel: 1, pinVar: 'anchorPinObject' },
            { path: '/static/models/start.glb', childLevel: 0, pinVar: 'navPinStart' },
            { path: '/static/models/end.glb', childLevel: 0, pinVar: 'navPinEnd' },
            { path: '/static/models/qr_pin.glb', childLevel: 0, pinVar: 'qrPin' },
            { path: '/static/models/qr_primary_pin.glb', childLevel: 0, pinVar: 'qrMainPin' },
            { path: '/static/models/marker_pin.glb', childLevel: 0, pinVar: 'markerPin' },
            { path: '/static/models/img_pin.glb', childLevel: 0, pinVar: 'imgAccessPin' },
            { path: '/static/models/img_primary_pin.glb', childLevel: 0, pinVar: 'imgAccessMainPin' },
            { path: '/static/models/floorplan_peg.glb', childLevel: 0, pinVar: 'floorplanPeg' },
        ]

        this.size = this.preloadModelAssets.length;

        this.on('initiateLoader', this.onInitiateLoader);
        this.editor.on('clearAndReset', this.onClearAndReset);
        this.editor.on('asyncObjectLoaded', this.onAsyncObjectLoaded);

        this.setLoaders();
    }

    onClearAndReset = () => {
        this.loaded = this.size;
        this.items = {};
        this.sources = []
    }

    initiateLoading = (sources) => {
        this.sources = this.toLinearArray(sources);
        this.toLoad =  this.sources.length; //this.calculateSourcesLength(sources);
        this.toLoad += this.size;
        !this.isVersionSwitch && this.toInitLoading && this.sources.length > 0  ? this.startLoading() : this.isReady();
    }

    onInitiateLoader = () => {
        this.toInitLoading = true;
        this.loaded += this.size;
        !this.isVersionSwitch && this.sources.length > 0 ?  this.startLoading() : this.isReady();
    }

    toLinearArray = (sources) => {
        let arr = []
        for(const source in sources) {
            if( source !== 'textNote3Ds' && source !== 'nftItems' && source !== 'floorplans' && source !== "marker" ) {
                arr = [...arr, ...sources[source]]
            }
        }
        //Make Array unique!
        return [...new Map(arr.map((item) => [item["waypoints"] && !item['id'] ? item["waypoints"][0]['id'] : item['id'] , item])).values()];
    }

    setLoaders = () => {

        var dracoLoader = new DRACOLoader(this.loadManager);
        dracoLoader.setDecoderPath('/static/draco/');
        this.loaders.gltfLoader = new GLTFLoader(this.loadManager);
        this.loaders.gltfLoader.setDRACOLoader(dracoLoader);

        this.loaders.texLoader = new THREE.TextureLoader(this.loadManager);
        this.loaders.texLoader.setCrossOrigin("anonymous");
        this.loaders.fontLoader = new FontLoader(this.loadManager);

        this.loaders.plyLoader = new PLYLoader(this.loadManager);
        this.loaders.plyLoader.setCrossOrigin("anonymous");

        for(const model of this.preloadModelAssets) {
            const scope = this;
            (async (model) => {
                const result = await scope.loaders.gltfLoader.loadAsync( model.path );
                scope.editor[model.pinVar] = model.childLevel === 2 ? result.scene.children[0].children[0] : model.childLevel === 1 ? result.scene.children[0] : result.scene;
                scope.preloadModels++;
                if(scope.preloadModels === this.size) {
                    scope.trigger('initiateLoader');
                }
            })(model);
        }
    }
    

    startLoading = () => {
        const scope = this;
        for(const source of this.sources) {
            if(source.id && source.id.includes('text')) {
                this.loaders.fontLoader.load('/static/fonts/Poppins_Light_Regular.json',  (font) => {
                    scope.addTextToScene(0.5, font, source);
                })
            }

            else if(source.id && source.id.includes('model')) {
                this.loaders.gltfLoader.load(source.link, (file) => {
                    if(!(source.id in this.items)){
                        const gltfModel = file.scene;
                        gltfModel.name = source.name;
                        gltfModel.position.copy(fromPosObjectToVec3Pos(source.position));
                        gltfModel.scale.copy(fromScaleObjectToVec3Scale(source.scale));
                        gltfModel.quaternion.copy(fromQuaternionObjectToQuaternion(source.rotation));
                        this.objectLoaded(source, source.id, "model", gltfModel);
                    }
                })
            }

            else if(source.id && source.id.includes('image')) {
                let imgTex = this.loaders.texLoader.load(source.link, (tex) => {
                    imgTex.needsUpdate = true;
                    imgMesh.scale.set(1.0, tex.image.height / tex.image.width, 1.0);
                });
                imgTex.minFilter = imgTex.magFilter = THREE.LinearFilter;
                imgTex.anisotropy = 4;

                let imgMat = new THREE.MeshBasicMaterial({ map: imgTex, side: THREE.DoubleSide});
                let imgGeo = new THREE.PlaneGeometry(1, 1, 32, 32);
                let imgMesh = new THREE.Mesh(imgGeo, imgMat);

                let imgGroup = new THREE.Group();
                imgGroup.add(imgMesh);
                source.id.includes('image') && imgGroup.rotateY(THREE.MathUtils.degToRad(180));
                imgGroup.name = source.name;
                imgGroup.position.copy(fromPosObjectToVec3Pos(source.position));
                imgGroup.scale.copy(fromScaleObjectToVec3Scale(source.scale));
                imgGroup.quaternion.copy(fromQuaternionObjectToQuaternion(source.rotation));
                this.objectLoaded(source, source.id, "image", imgGroup);
            }

            else if(source.id && source.id.includes('floorplan')) {
                if(source.id.includes('metadata_floorplans')) {
                    const len = source.floorplans.length;
                    const floor = source.floorplans[len - 1];
                    let floorplanVersion = 2.7;
                    if(len > 0 && this.asyncObject.indexOf(floor.id) === -1) {
                        if('floorplanVersion' in floor) {
                            floorplanVersion = floor.floorplanVersion;
                        }

                        if(floorplanVersion === 2.6) {
                            let metaFloorplans = [];
                            let metaFPlan = new FloorplanV1(floor, 'floorplan', true);
                            metaFloorplans.push(metaFPlan.imageMesh);
                            metaFPlan.alignmentStatus && metaFloorplans.push(metaFPlan.pegMesh);
                            this.objectLoaded(source, source.id, "floorplan", metaFloorplans);
                        } else {
                            new Floorplan(floor, 'floorplan', true);
                            this.asyncObject.push(floor.id);
                        }
                    } else {
                        this.objectLoaded(source, "SKIPPED_OBJECT", "SKIPPED_OBJECT", null);
                    }
                }
            }

            else if(source.id && source.id.includes('audio')) {
                const spriteMaterial = new THREE.SpriteMaterial({
                    map: new THREE.TextureLoader().load('/static/textures/mic-audio.png'),
                    transparent: true,
                    depthTest: false,
                    depthWrite: false
                });
                
                const audio = new THREE.Sprite(spriteMaterial);
                audio.scale.set(0.25, 0.25, 0.25);
                audio.name = source.name;
        
                const audioSprite = new THREE.Group();
                audioSprite.add(audio)
                audioSprite.position.copy(fromPosObjectToVec3Pos(source.position));
                audioSprite.scale.copy(fromScaleObjectToVec3Scale(source.scale));
                this.objectLoaded(source, source.id, "audio", audioSprite);
            }

            else if(source.id && source.id.includes('hotspot')) {
                const texture = new THREE.TextureLoader().load('/static/textures/hotspot.png');
                const spriteMaterial = new THREE.SpriteMaterial({
                    map: texture,
                    transparent: true,
                    depthTest: false,
                    depthWrite: false,
                });
                const hotspotSprite = new THREE.Sprite(spriteMaterial);
                hotspotSprite.scale.set(0.25, 0.25, 0.25);
                hotspotSprite.name = source.name;

                const hotspotGroup = new THREE.Group();
                hotspotGroup.add(hotspotSprite)
                hotspotGroup.position.copy(fromPosObjectToVec3Pos(source.position));
                hotspotGroup.scale.copy(fromScaleObjectToVec3Scale(source.scale));
                this.objectLoaded(source, source.id, "hotspot", hotspotGroup);
            }

            else if(source.id && source.id.includes('video')) {
                const spriteMaterial = new THREE.SpriteMaterial({
                    map: new THREE.TextureLoader().load('/static/textures/video.png'),
                    transparent: true,
                    depthTest: false,
                    depthWrite: false
                });
                
                const videoIcon = new THREE.Sprite(spriteMaterial);
                videoIcon.scale.set(0.25, 0.25, 0.25);
                videoIcon.name = source.name;
                videoIcon.visible = source.showAsIcon;
        
                let imgTex = new THREE.TextureLoader().load('/static/textures/vid-thumb.png');
                imgTex.minFilter = imgTex.magFilter = THREE.LinearFilter;
                imgTex.anisotropy = 4;
        
                const videObject = new THREE.Mesh(
                    new THREE.PlaneGeometry(1, 1, 32, 32), 
                    new THREE.MeshBasicMaterial({ 
                        map: imgTex, 
                        side: THREE.DoubleSide
                }));
                videObject.name = source.name;
                videObject.visible = !source.showAsIcon;
        
                const videoSprite = new THREE.Group();
        
                if(source.showAsIcon) {
                    videoSprite.add(videoIcon);
                } else {
                    videoSprite.add(videObject);
                }
                videoSprite.name = source.name;
                videoSprite.position.copy(fromPosObjectToVec3Pos(source.position));
                videoSprite.scale.copy(fromScaleObjectToVec3Scale(source.scale));
                this.objectLoaded(source, source.id, "video", videoSprite);
            }

            else if((source.id && (source.id.includes('wayPoint') || source.id.includes('locationPin') )) || ('waypoints' in source)) {
                if('waypoints' in source ) {
                    if(!(source.waypoints[0].id in this.items)) {
                        const wayPointLine = new Navigation(source.waypoints, undefined, undefined, true);
                        this.objectLoaded({...source, id: wayPointLine.id}, wayPointLine.id, "waypoints", [wayPointLine.line.mesh])
                    }
                } else if('waypoints' in source === false) {
                    const wayPoinDest = new Navigation(source, undefined, true, undefined);
                    this.objectLoaded(source, wayPoinDest.id, "destination", wayPoinDest.destination.mesh);
                } else {
                    const wayPoint = new Navigation(source, true, undefined, undefined);
                    let meshes = [];
                    wayPoint.destination && meshes.push(wayPoint.destination.mesh);
                    wayPoint.line && meshes.push(wayPoint.line.mesh)
                    this.objectLoaded(source, wayPoint.destination.id, "waypointDestination", meshes);
                }
            }

            else if(source.id && source.id.includes('qrAnchors')) {
                let qrMeshes = [];
                source.anchors.forEach(anchor => {
                    let qrModel;
                    if(anchor.isQrCode) {
                        qrModel = anchor.anchorIndex === 'primary' ? this.editor.qrMainPin.clone() : this.editor.qrPin.clone();
                        qrModel.rotateY(THREE.MathUtils.DEG2RAD * 90)
                    } else {
                        qrModel = anchor.isPrimaryAccess ? this.editor.imgAccessMainPin.clone() : this.editor.imgAccessPin.clone();
                    }
                    qrModel.scale.set(0.7, 0.7, 0.7);
                    qrModel.children.forEach(child => {
                        if(child.name === 'Direction') {
                            if(anchor.direction === 'verticle' || anchor.direction === 'vertical') {
                                child.rotateY(THREE.MathUtils.DEG2RAD * 90);
                            } else if(!anchor.direction) {
                                child.visible = false
                            }
                        }
                    })
                    qrModel.name = anchor.name || 'Sample Name';
                    const qrMesh = new THREE.Group();
                    qrMesh.add(qrModel);
                    qrMesh.name = anchor.name || 'Sample Name';
                    qrMesh.position.copy(fromPosObjectToVec3Pos(anchor.position));
                    qrMesh.rotation.set(0, fromQuaternionObjectToEuler(anchor.rotation).y, 0);
                    qrMeshes.push(qrMesh);
                });
                this.objectLoaded(source, source.id, "qrAnchor", qrMeshes);
            }

            else if(source.id && source.id.includes('marker')) {
                //Color COnversion Func!
                const hexToDec = (hex) => parseInt(hex.substring(1), 16);

                let marker = this.editor.markerPin.clone();
                marker.position.copy(fromPosObjectToVec3Pos(source.position));
                marker.scale.set(0.7, 0.7, 0.7);
                marker.name = source.name;

                //Color Update!
                const newColorMat = new THREE.MeshStandardMaterial().copy(marker.children[1].material);
                newColorMat.color.setHex(hexToDec(source.pathColor || '#36CCE7'), 'srgb-linear')

                for(var i = 0; i < marker.children.length; i++) {
                    if(marker.children[i].name.includes('_Mat')) {
                        marker.children[i].material = newColorMat;
                        marker.children[i].material.needsUpdate = true;
                    }
                }
                
                this.objectLoaded(source, source.id, "marker", marker);
                this.editor.markers.push(marker);
            }

            else if(source.id && source.id.includes('mapVisual')) {
                const plyFunction = this.plyFunctions[source.link.split('.').pop()];
                this.loaders.plyLoader[plyFunction](source.link, (geometry) => {
                    if(!(source.id in this.items)){
                        scope.addMapVisualtoScene(source, geometry);
                    }
                });
            }

            else this.objectLoaded(source, "SKIPPED_OBJECT", "SKIPPED_OBJECT", null);
        }
    }

    onAsyncObjectLoaded = (object, type) => {
        if(type === 'floorplan') {
            const idx = this.asyncObject.indexOf(object.id);
            if(!(object.id in this.items) && idx !== -1) {
                let metaFloorplans = [];
                this.asyncObject.splice(idx, 1);
                metaFloorplans.push(object.imageMesh);
                object.alignmentStatus && metaFloorplans.push(object.pegMesh);
                this.objectLoaded(object, object.id, "floorplan", metaFloorplans);
            }
        } else if(type === 'SKIPPED_OBJECT') {
            this.objectLoaded(object, "SKIPPED_OBJECT", "SKIPPED_OBJECT", null);
        }
    }

    addMapVisualtoScene = (source, geometry) => {
        const material = new THREE.PointsMaterial( { size: 0.035, vertexColors: true} );
        var mVisualMesh = new THREE.Points(geometry, material);
        mVisualMesh.name = source.name;
        mVisualMesh.userData['type'] = "mapVisual";
        mVisualMesh.userData['id'] = `${source.id}__mapVisual`;
        mVisualMesh.position.copy(fromPosObjectToVec3Pos(source.position)); // y = 1.3
        mVisualMesh.scale.copy(fromScaleObjectToVec3Scale(source.scale));
        mVisualMesh.quaternion.copy(fromQuaternionObjectToQuaternion(source.rotation));
        mVisualMesh.visible = false;

        mVisualMesh.geometry.computeBoundingSphere();
        const sphere = mVisualMesh.geometry.boundingSphere;
        const box3 = new THREE.Box3().setFromObject(mVisualMesh)

        const defGeoW = Math.abs(box3.max.x - box3.min.x);
        const defGeoH = Math.abs(box3.max.z - box3.min.z);
        const planeG = new THREE.PlaneGeometry(defGeoW, defGeoH);
        const planeM = new THREE.MeshBasicMaterial({
            side: THREE.DoubleSide, 
            transparent: true, 
            map: new THREE.TextureLoader().load('/static/textures/defFloorTex.png')
        });

        const floorMesh = new THREE.Mesh(planeG, planeM);
        floorMesh.rotation.x = THREE.MathUtils.degToRad(-90);
        floorMesh.name = `${source.mapName} floorplan`;
        floorMesh.userData['navHelper'] = 'floorplan';
        floorMesh.position.set(sphere.center.x, box3.min.y, sphere.center.z);

        this.editor.trigger('adjustGridPosition', [box3.min]);
        this.objectLoaded(source, source.id, "mapVisual", [ mVisualMesh, floorMesh ]);
    }

    addTextToScene = (fontSize, fontFamily, textData) => {

        let textMesh;
        let fontText = textData.textValue;
        let fontMat = new THREE.LineBasicMaterial({
            color: textData.color.substring(0, 7),
            side: THREE.DoubleSide
        });

        
        let fontGeo = new TextGeometry(
            fontText,
            {
                font: fontFamily,
                size: fontSize,
                height: 0.02,
                curveSegments: 5,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 4
            }
        );
        fontGeo.center();

        textMesh = new THREE.Group();
        const txtMesh = new THREE.Mesh(fontGeo, fontMat);
        txtMesh.scale.set(0.25, 0.25, 0.25);
        txtMesh.rotateY(THREE.MathUtils.degToRad(180));
        textMesh.add(txtMesh);
        textMesh.name = fontText;
        textMesh.position.copy(fromPosObjectToVec3Pos(textData.position));
        textMesh.scale.copy(fromScaleObjectToVec3Scale(textData.scale));
        textMesh.quaternion.copy(fromQuaternionObjectToQuaternion(textData.rotation));
        this.objectLoaded(textData, textData.id, "text", textMesh);
    }

    objectLoaded = (source, id, type, file) => {
        if(id === 'SKIPPED_OBJECT') {
            this.loaded++;
        } else {
            this.items[id] = {
                type,
                object: file
            };
            this.loaded++;

            if(file instanceof Array) {
                for(const obj of file) {
                    this.editor.addObjects(obj);
                }
            } else {
                this.editor.addObjects(file);
            }
        }

        // console.log("ITEMS", this.items, this.toLoad, this.loaded);

        this.isReady();
    }

    isReady = () => {
        if(this.loaded === this.toLoad) {
            this.trigger('ready');
        }
    }
}