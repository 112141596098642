import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import GenerateQR from "../common/GenerateQR";
import { MoreVertical } from "react-feather";
import { ReactComponent as DownloadIcon } from "../../../../../assets/svgs/dash-landing/mapdetails/download.svg";
import { ReactComponent as RenameIcon } from "../../../../../assets/svgs/dash-landing/mapdetails/rename.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/svgs/dash-landing/mapdetails/iap/delete.svg";
import alertIcon from "../../../../../assets/svgs/scenes/alert.svg";

import { mapDetails } from "../../../../../features/user/studio/studioSlice";
import { getUserMapDetails } from "../../../../../features/user/studio/studioSlice";
import CustomModal from "../common/CustomModal";
import { omit } from "lodash";
import {
  deleteAnchor,
  updateAnchor,
} from "../../../../../features/user/studio/anchorSlice";
import { setNewAlert } from "../../../../../features/common/alertSlice";
import Loader from "../../../../common/loader";
import MessageModal from "../../../../common/modal";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";
import { Edit2, Download } from "react-feather";
import { resetGeneratedBlob } from "../../../../../features/common/commonSlice";

const AnchorQRCard = ({ data, anchorObj, isSelectAll, setSelectedQRs }) => {
  const childRef = useRef(null);
  const { mapCode, mapName } = data;
  const dispatch = useDispatch();
  const selectedMapDetails = useSelector(mapDetails);
  const generatedBlob = useSelector((state) => state.common.generatedBlob);
  const [anchorName, setAnchorName] = useState(anchorObj.name);
  const [anchorDescription, setAnchorDescription] = useState(
    anchorObj.description
  );
  const [renameModal, setRenameModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);
  const [openDownload, toggleOpenDownload] = useState(false);
  const [downloadType, setDownloadType] = useState("PDF");

  useEffect(() => {
    setIsChecked(isSelectAll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectAll]);

  useEffect(() => {
    setSelectedQRs({ QRID: anchorObj.id, isChecked, name: anchorObj.name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  useEffect(() => {
    if (generatedBlob) {
      setLoaderModal(false);
      dispatch(resetGeneratedBlob());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedBlob]);

  const renameQRCode = () => {
    let anchors = [...selectedMapDetails.metadata.anchors];
    let selectedAnchor = anchors.find((anchor) => anchor.id === anchorObj.id);
    selectedAnchor = omit(selectedAnchor, ["modifiedOn", "createdOn"]);
    let modifiedAnchor = {
      ...selectedAnchor,
      name: anchorName,
    };
    dispatch(
      updateAnchor({ mapId: selectedMapDetails.id, modifiedAnchor })
    ).then(() => {
      if (renameModal) setRenameModal(false);
      dispatch(
        setNewAlert({
          msg: "QR Renamed Successfully!",
          alertType: "success",
        })
      );
      dispatch(getUserMapDetails(selectedMapDetails.id));
    });
  };

  const editQRCode = () => {
    let anchors = [...selectedMapDetails.metadata.anchors];
    let selectedAnchor = anchors.find((anchor) => anchor.id === anchorObj.id);
    selectedAnchor = omit(selectedAnchor, ["modifiedOn", "createdOn"]);
    let modifiedAnchor = {
      ...selectedAnchor,
      name: anchorName,
      description: anchorDescription,
    };
    dispatch(
      updateAnchor({ mapId: selectedMapDetails.id, modifiedAnchor })
    ).then(() => {
      if (editModal) setEditModal(false);
      dispatch(
        setNewAlert({
          msg: "QR Updated Successfully!",
          alertType: "success",
        })
      );
      dispatch(getUserMapDetails(selectedMapDetails.id));
    });
  };

  const deleteQR = () => {
    dispatch(
      deleteAnchor({ mapId: selectedMapDetails.id, anchorId: anchorObj.id })
    ).then(() => {
      dispatch(
        setNewAlert({
          msg: "QR Deleted Successfully!",
          alertType: "success",
        })
      );
      dispatch(getUserMapDetails(selectedMapDetails.id));
    });
    setDeleteModal(false);
  };

  const handlwDownload = () => {
    childRef.current.handleDownloadQRFile(downloadType, (val) =>
      setLoaderModal(val)
    );
    toggleOpenDownload(false);
  };

  const getGeneratedQR = (extId = false) => {
    return (
      <GenerateQR
        isExtId={extId}
        isChecked={isChecked}
        isAnchorQR={true}
        mapData={{
          mapCode,
          QRId: extId ? anchorObj.id + anchorObj.id.slice(-2) : anchorObj.id,
          mapName: `${mapName}_${anchorObj.name}`,
        }}
        ref={childRef}
      />
    );
  };
  return (
    <div className="card bg-body rounded h-100" style={{ border: "none" }}>
      <div
        className={`card-body`}
        onMouseEnter={() => {
          setShowCheckbox(true);
        }}
        onMouseLeave={() => {
          setShowCheckbox(false);
        }}
      >
        <div
          className={`header ${
            isChecked
              ? "mapdetails__anchorcardBG"
              : "mapdetails__anchorcardDefaultBG"
          }`}
        >
          <div className="card-body align-items-center d-flex justify-content-center p-1 pt-4 pb-4">
            <div className="bd-highlight mapdetails__qrChecker">
              <div
                className=""
                style={{
                  visibility: `${
                    isChecked ? "" : showCheckbox ? "" : "hidden"
                  }`,
                }}
              >
                <input
                  className="form-check-input mapdetails__checkbox"
                  type="checkbox"
                  style={{ cursor: "pointer", scale: "1.3" }}
                  id={`checkboxNoLabel_${anchorObj.id}`}
                  checked={isChecked}
                  onChange={() => {
                    setIsChecked(!isChecked);
                  }}
                />
              </div>
            </div>
            <div className="bd-highlight mapdetails__qrContainer">
              {getGeneratedQR()}
            </div>
          </div>
        </div>
        <div className="ps-3">
          <span className="float-start">
            <div className="stepper__QRtitle">{anchorObj.name}</div>
            <div
              className="stepper__QRSubtitle"
              style={{ marginTop: "0.25rem" }}
            >
              Dimension:{" "}
              <strong>
                {anchorObj.dimension
                  ? `(${(
                      anchorObj.dimension.sizeX * 100
                    ).toFixed(2)} x ${(anchorObj.dimension.sizeY * 100).toFixed(2)} cm)`
                  : "--"}
              </strong>
            </div>
            <div
              className="stepper__QRSubtitle"
              style={{ marginTop: "0.25rem" }}
            >
              Surface Direction: <strong>{anchorObj?.direction ?? "--"}</strong>
            </div>
          </span>
          <div className="btn-group dropstart float-end">
            <button
              type="button"
              className="btn"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <MoreVertical />
            </button>
            <ul className="dropdown-menu">
              <li
                className="dropdown-item"
                onClick={() => toggleOpenDownload(true)}
              >
                <div className="d-flex flex-row" style={{ cursor: "pointer" }}>
                  <div className="pe-3">
                    <DownloadIcon />
                  </div>
                  Download
                </div>
              </li>
              <li
                className="dropdown-item"
                onClick={() => {
                  setRenameModal(true);
                }}
              >
                <div className="d-flex flex-row" style={{ cursor: "pointer" }}>
                  <div className="pe-3">
                    <Edit2 size={17} color="#353E5A" strokeWidth={1.4} />
                  </div>
                  Rename
                </div>
              </li>
              <li className="dropdown-item" onClick={() => setEditModal(true)}>
                <div className="d-flex flex-row" style={{ cursor: "pointer" }}>
                  <div className="pe-3">
                    <RenameIcon />
                  </div>
                  Edit Details
                </div>
              </li>
              <li
                className="dropdown-item"
                onClick={() => {
                  setDeleteModal(true);
                }}
              >
                <div className="d-flex flex-row" style={{ cursor: "pointer" }}>
                  <div className="pe-3">
                    <DeleteIcon />
                  </div>
                  Delete
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <CustomModal
        openModal={renameModal}
        onCloseModal={() => setRenameModal(false)}
        onSuccessModal={() => renameQRCode()}
        modalHeader={`Rename QR Code?`}
        textSuccess="Rename"
        textCancel="Cancel"
      >
        <div className="mapinfo__mDescCont" style={{ width: "95%" }}>
          <div>
            <span className="mapinfo__mDescCont--labelSpan">QR Code Name</span>
            {
              <span className="mapinfo__mDescCont--inputLenHelper">
                {anchorName?.length}/20
              </span>
            }
          </div>
          <textarea
            rows={3}
            value={anchorName}
            onChange={(e) => {
              setAnchorName(e.target.value);
            }}
            className={`mapinfo__mapFormTextArea mapinfo__mapFormTextArea--active`}
            autoComplete="off"
            maxLength={20}
          />
        </div>
      </CustomModal>
      <CustomModal
        openModal={deleteModal}
        onCloseModal={() => setDeleteModal(false)}
        onSuccessModal={() => deleteQR()}
        modalHeader={`Delete QR Code?`}
        textSuccess="Delete"
        textCancel="Cancel"
      >
        <div className="mapinfo__mDescCont" style={{ width: "95%" }}>
          {getGeneratedQR(true)}
          <div className="mapdetails__mapModalText pt-2">{`Delete “${anchorObj.name}” from your map? Viewers will not be able to use this QR Code to access your map when deleted. Ensure you remove the physical QR code after deleting.`}</div>
        </div>
      </CustomModal>
      <CustomModal
        openModal={openDownload}
        onCloseModal={() => toggleOpenDownload(false)}
        onSuccessModal={() => handlwDownload()}
        modalHeader={`File Type Download`}
        textSuccess="Download"
        textCancel="Cancel"
      >
        <div className="stepper__qrDownloadModal">
          <div>Please select the file type for download</div>
          <div className="stepper__qrDownloadModal--checksCont">
            <FormGroup>
              <div className="stepper__qrDownloadModal--check">
                <FormControlLabel
                  checked={downloadType === "PNG"}
                  onChange={() => setDownloadType("PNG")}
                  control={
                    <Checkbox
                      defaultChecked
                      id="checkbox-png"
                      style={{ transform: "scale(1.3)" }}
                    />
                  }
                />
                <span className="stepper__qrDownloadModal--checkLabel">
                  <strong>PNG</strong>
                  <br />
                  Transparent background, Non-scalable, for smaller sized
                  physical prints (A4 or Letter sized)
                </span>
              </div>
              <div className="stepper__qrDownloadModal--check">
                <FormControlLabel
                  checked={downloadType === "PDF"}
                  control={
                    <Checkbox
                      id="checkbox-pdf"
                      style={{ transform: "scale(1.3)" }}
                    />
                  }
                  onChange={() => setDownloadType("PDF")}
                />
                <span className="stepper__qrDownloadModal--checkLabel">
                  <strong>PDF</strong>
                  <br />
                  Non-scalable for smaller-sized physical prints (A4 or
                  Letter-sized)
                </span>
              </div>
            </FormGroup>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        openModal={editModal}
        onCloseModal={() => setEditModal(false)}
        onSuccessModal={() => editQRCode()}
        modalHeader={`Edit Details`}
        textSuccess="Save"
        textCancel="Cancel"
        modalStyle={{ width: 700 }}
        excludeAlignStyle={true}
      >
        <div className="stepper__qrEditModal">
          <div className="stepper__qrEditModal--qrCont">
            <div className="bd-highlight mapdetails__qrContainer">
              {getGeneratedQR(true)}
            </div>
            <button
              onClick={() => {
                childRef.current.handleDownloadQR("png");
              }}
            >
              <Download size={15} style={{ marginRight: "8px" }} />
              Download
            </button>
          </div>
          <div className="stepper__qrEditModal--qrDataCont">
            <div>
              <div>
                <span className="mapinfo__mDescCont--labelSpan">Title</span>
                {
                  <span className="mapinfo__mDescCont--inputLenHelper">
                    {anchorName?.length}/20
                  </span>
                }
              </div>
              <input
                rows={2}
                value={anchorName}
                onChange={(e) => {
                  setAnchorName(e.target.value);
                }}
                className={`mapinfo__mapFormTextArea mapinfo__mapFormTextArea--active`}
                autoComplete="off"
                maxLength={20}
              />
            </div>
            <div>
              <div>
                <span className="mapinfo__mDescCont--labelSpan">
                  Description
                </span>
                {
                  <span className="mapinfo__mDescCont--inputLenHelper">
                    {anchorName?.length}/100
                  </span>
                }
              </div>
              <textarea
                rows={3}
                value={anchorDescription}
                onChange={(e) => {
                  setAnchorDescription(e.target.value);
                }}
                className={`mapinfo__mapFormTextArea mapinfo__mapFormTextArea--active`}
                autoComplete="off"
                maxLength={100}
              />
            </div>
            <div className="stepper__qrEditModal--qrDisplayCont">
              <div className="stepper__qrEditModal--qrDisplayCont--qrInfoRow">
                <strong>Surface Direction:</strong>
                <span>{anchorObj.direction || "--"}</span>
              </div>
              <div className="stepper__qrEditModal--qrDisplayCont--qrInfoRow">
                <strong>Dimension:</strong>
                <span>
                  {anchorObj.dimension
                    ? `(${(
                        anchorObj.dimension.sizeX * 100
                      ).toFixed(2)} x ${(anchorObj.dimension.sizeY * 100).toFixed(2)} cm)`
                    : "--"}
                </span>
              </div>
              <div className="stepper__qrEditModal--qrDisplayCont--noteCard">
                <img src={alertIcon} alt="alert-icon" />
                <span>
                  <strong>Note:</strong> Edit surface direction and display
                  options in the ARway App.
                </span>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <MessageModal
        openModal={loaderModal}
        onCloseModal={() => {
          // setIsDownloadingAdditional(false);
          // setLoaderModal(false);
        }}
        inlineStyle={{ height: "70vh", width: "90vw" }}
      >
        <Loader
          loaderText={`Download is in progress`}
          height="100%"
          width="500vw"
          // value={100}
        />
      </MessageModal>
    </div>
  );
};

export default AnchorQRCard;
