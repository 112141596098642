import * as THREE from "three";
// loader
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
//Geo
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";

/* Comp */
import EditorExperience from "../EditorExperience";
import { AddObject } from "../commands/AddObject";

// Random ID Gen
import { getRandomIdCode } from "./TransformConversions";

const generateTextMesh = (
    fontSize,
    fontText,
    color,
    positionVec,
    scaleVec,
    rotEuler,
    autoRotate
) => {
    const fontLoader = new FontLoader();
    const editor = new EditorExperience();

    fontLoader.load("/static/fonts/Poppins_Light_Regular.json", (font) => {
        let textMesh;
        let fontMat = new THREE.LineBasicMaterial({
            color: color,
            side: THREE.DoubleSide,
        });

        let fontGeo = new TextGeometry(fontText, {
            font: font,
            size: fontSize,
            height: 0.02,
            curveSegments: 5,
            bevelEnabled: true,
            bevelThickness: 0.03,
            bevelSize: 0.02,
            bevelOffset: 0,
            bevelSegments: 4,
        });
        fontGeo.center();

        textMesh = new THREE.Group();
        const txtMesh = new THREE.Mesh(fontGeo, fontMat);
        txtMesh.scale.set(0.25, 0.25, 0.25);
        txtMesh.rotateY(THREE.MathUtils.degToRad(180));
        textMesh.add(txtMesh);
        textMesh.name = fontText;
        textMesh.userData["type"] = "text";
        textMesh.userData["skipChild"] = "text";
        textMesh.userData["visible"] = true;
        textMesh.userData["downloadProximity"] = '10';
        textMesh.userData["autoRotate"] = autoRotate;
        textMesh.userData["id"] = `text_${getRandomIdCode()}`;
        textMesh.position.copy(positionVec);
        textMesh.rotation.copy(rotEuler);
        textMesh.scale.copy(scaleVec);
        editor.onCommand(new AddObject(editor, textMesh, false, true));
    });
};

export { generateTextMesh };
