import { configureStore } from "@reduxjs/toolkit";

//reducers
import authSlice from "./auth/authSlice";
import commonSlice from "./common/commonSlice";
import mapReportSlice from "./admin/mapreports/mapReportSlice";
import userReportSlice from "./admin/userreports/userReportSlice";
import studioSlice from "./user/studio/studioSlice";
import alertSlice from "./common/alertSlice";
import webStudioSlice from "./webstudio/webStudioSlice";
import developerSlice from "./user/developer/developerSlice";
import accessSlice from "./auth/accessSlice";
import settingsSlice from "./user/settings/settingsSlice";
import contentSlice from "./content/contentLibrarySlice";
import analyticsSlice from "./user/analytics/analyticsSlice";
import impersonateSlice from "./admin/impersonations/impersonateSlice";
import adminAnalyticsSlice from "./admin/analytics/adminAnalyticsSlice";
import adminSDKAnalyticsSlice from "./admin/analytics/adminSDKAnalyticsSlice";
import anchorSlice from "./user/studio/anchorSlice";
import editor2dSlice from "./webstudio/editor2dSlice";
import buildingSlice from "./webstudio/buildingSlice";
import locationSlice from "./webstudio/locationSlice"
import plansSlice from "./admin/plans/plansSlice";
import manageAccountsSlice from "./admin/manageaccounts/manageAccountsSlice";
import interactivemapSlice from "./interactivemap/interactivemapSlice";

const persistStateToLocalStorage = (prevState) => {
  try {
    const serializedState = JSON.stringify(prevState);
    
    const isImpersonation = sessionStorage.getItem('impersonateUser');
    if(isImpersonation === 'true') {
      sessionStorage.setItem("arway-state", serializedState);
    } else {
      localStorage.setItem("arway-state", serializedState);
    }
  } catch (e) {
    console.log("JSON ERROR : ", e, prevState);
  }
};

const fetchPersistatedStateFromLocalStorage = () => {
  try {
    const isImpersonation = sessionStorage.getItem('impersonateUser');
    const serializedState = isImpersonation === 'true' ? sessionStorage.getItem("arway-state") : localStorage.getItem("arway-state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log("JSON ERROR : ", e);
  }
};

const persistedState = fetchPersistatedStateFromLocalStorage();

const dataStore = configureStore({
  reducer: {
      alerts: alertSlice,
      auth: authSlice,
      common: commonSlice,
      mapreports: mapReportSlice,
      userreports: userReportSlice,
      studio: studioSlice,
      webstudio: webStudioSlice,
      developer: developerSlice,
      access: accessSlice,
      settings: settingsSlice,
      content: contentSlice,
      analytics:analyticsSlice,
      impersonate: impersonateSlice,
      adminanalytics: adminAnalyticsSlice,
      adminsdkanalytics:adminSDKAnalyticsSlice,
      anchors: anchorSlice,
      editor2D: editor2dSlice,
      building: buildingSlice,
      locationSlice: locationSlice,
      plans: plansSlice,
      manageaccounts: manageAccountsSlice,
      interactivemap: interactivemapSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
    preloadedState: persistedState,
    devTools: true
});

dataStore.subscribe(() => persistStateToLocalStorage(dataStore.getState()));

export { dataStore };
