import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import App from "./App";
import Success from "./auth/success/success";
import Expire from "./auth/expire/expire";
import { Register } from "./auth/register/Register";
import { Login } from "./auth/login/Login";
// import { ResetPassword } from "./components/auth/ResetPassword";
import { VerifyOtp } from "./auth/verify/VerifyOtp";
import { ForgotPassword } from "./auth/forgotpassword/ForgotPassword";
import { ResetPassword } from "./auth/forgotpassword/ResetPassword";
import { ResetSuccessful } from "./auth/forgotpassword/ResetSuccessful";
import { VerifyEmail } from "./auth/verify/VerifyEmail";
import Wrong from "./auth/failed/wrong";
import PageNotFound from "./common/pagenotfound/PageNotFound";
import AccountSetUpLanding from "./auth/accountsetup/landing/Landing";
import TwoFAHome from "./auth/2fa/TwoFAHome";

// DASHBoard Components
import Dashboard from './dashboard/dhome/DHome';
import DAnalytics from './dashboard/users/analytics/AnalyticsHome';
import DLanding from './dashboard/users/landing/LandingHome';
import DDownloads from './dashboard/users/downloads/DownloadHome';
import DStudio from './dashboard/users/studio/StudioHome';
import DSettings from './dashboard/users/settings/SettingsHome.js';
import DDevelopers from './dashboard/users/developers/DevelopersHome';
import DContentHome from "./dashboard/users/content-library/ContentHome";
// import DSupport from './dashboard/support/SupportHome';
import ReportMaps from './dashboard/admin/mapreports/MapReportsHome';
import ReportUsers from './dashboard/admin/userreports/UserReportsHome';
import MapDetailsPage from "./dashboard/admin/mapreports/MapDetailsPage";
import ImpersonationHome from "./dashboard/admin/impersonation/ImpersonationHome";
import SetImpersonation from "./dashboard/admin/impersonation/SetImpersonation";
import AdminAnalyticsHome from "./dashboard/admin/analytics/AdminAnalyticsHome";
import AdminSDKAnalyticsHome from "./dashboard/admin/sdkanalytics/AdminSDKAnalyticsHome";
import UserSDKAnalyticsPage from "./dashboard/admin/sdkanalytics/UserSDKAnalyticsPage";
import MapDetailsStudio from "./dashboard/users/studio/MyMaps";
import VenueDetailsStudio from './dashboard/users/studio/Venue/Building'
import WebStudioHome from "./webstudio/WebStudioHome";
import DAdminLanding from './dashboard/admin/landing/AdminLanding';
import DPlanHome from './dashboard/admin/plans/PlanHome';
import Alerts from "./common/alerts/Alerts";
import ManageAccountsHome from "./dashboard/admin/manageaccounts/AccountsHome";
import AccountDetails from "./dashboard/admin/manageaccounts/accountdetails/AccountDetailsHome";

// interactive maps
import InteractiveMapsHome from "./interactivemaps/InteractiveMapsHome";

// Paths
import {
  HOME, LOGIN,
  REGISTER, FORGOT_PASSWORD,
  VERIFY_OTP, RESET_PASSWORD,
  RESET_SUCCESS, VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_LINKEXP,
  VERIFY_EMAIL_ERROR,
  DASHBOARD_HOME, D_ANALYTICS,
  D_DEVELOPERS, D_DOWNLOADS,
  D_SETTINGS, D_LANDING,
  D_STUDIO, D_SUPPORT,
  D_CONTENT_MAPS, D_CONTENT_USERS,
  PNF, D_CONTENT_MAPS_DETAILS,
  D_STUDIO_DETAILS,D_STUDIO_VENUE_DETAILS, D_WEB_STUDIO,
  ACCOUNT_SETUP, D_CONTENT_LIBRARY,
  D_CONTENT_IMPERSONATE, D_IMPERSONATE_USER, VERIFY_2FA,
  D_CONTENT_ANALYTICS, D_CONTENT_SDK_ANALYTICS, 
  D_CONTENT_SDK_ANALYTICS_USER, D_CONTENT_LANDING, D_CONTENT_PLANS,
  D_CONTENT_MANAGE_ACCOUNTS, D_CONTENT_MANAGE_ACCOUNTS_USER,
  INTERACTIVE_MAPS
} from './_utils/routes';

const Support = React.lazy(() => import('./dashboard/support/SupportHome'))

const Router = () => {
  return (
    <React.Fragment>
      <Alerts />
      <div className="wrapper">
          <Routes>
            {/* <Route exact path="/" element={<App />} /> */}
            <Route exact path={HOME} element={<Navigate to={LOGIN} />} />
            <Route exact path={LOGIN} element={<Login />} />
            <Route exact path={REGISTER} element={<Register />} />
            <Route exact path={FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route exact path={VERIFY_OTP} element={<VerifyOtp />} />
            <Route exact path={RESET_PASSWORD} element={<ResetPassword />} />
            <Route exact path={RESET_SUCCESS} element={<ResetSuccessful />} />
            <Route exact path={VERIFY_EMAIL} element={<VerifyEmail />} />
            <Route exact path={VERIFY_EMAIL_SUCCESS} element={<Success />} />
            <Route exact path={VERIFY_EMAIL_LINKEXP} element={<Expire />} />
            <Route exact path={VERIFY_EMAIL_ERROR} element={<Wrong />} />
            <Route exact path={PNF} element={<PageNotFound />} />
            <Route exact path={ACCOUNT_SETUP} element={<AccountSetUpLanding />} />
            <Route exact path={VERIFY_2FA} element={<TwoFAHome />} />

            <Route exact path={DASHBOARD_HOME} element={<Dashboard />}>
              <Route exact path={D_LANDING} element={<DLanding />} />
              <Route exact path={D_ANALYTICS} element={<DAnalytics />} />
              <Route exact path={D_CONTENT_LIBRARY} element={<DContentHome />} />
              <Route exact path={D_DEVELOPERS} element={<DDevelopers />} />
              <Route exact path={D_DOWNLOADS} element={<DDownloads />} />
              <Route exact path={D_SETTINGS} element={<DSettings />} />
              <Route exact path={D_STUDIO} element={<DStudio />} />
              <Route exact path={D_STUDIO_DETAILS} element={<MapDetailsStudio />} />
              <Route exact path={D_STUDIO_VENUE_DETAILS} element={<VenueDetailsStudio/>}/>
              {/* <Route exact path={D_SUPPORT} element={<DSupport />} /> */}
              <Route path={D_SUPPORT} element={
                <React.Suspense fallback={<>...</>}>
                    <Support />
                  </React.Suspense>
                }
              />
              <Route exact path={D_CONTENT_MAPS} element={<ReportMaps />} />
              <Route exact path={D_CONTENT_LANDING} element={<DAdminLanding />} />
              <Route exact path={D_CONTENT_USERS} element={<ReportUsers />} />
              <Route exact path={D_CONTENT_MAPS_DETAILS} element={<MapDetailsPage />} />
              <Route exact path={D_CONTENT_IMPERSONATE} element={<ImpersonationHome />} />
              <Route exact path={D_CONTENT_ANALYTICS} element={<AdminAnalyticsHome />} />
              <Route exact path={D_CONTENT_SDK_ANALYTICS} element={<AdminSDKAnalyticsHome/>}/>
              <Route exact path={D_CONTENT_PLANS} element={<DPlanHome />} />
              <Route exact path={D_CONTENT_SDK_ANALYTICS_USER} element={<UserSDKAnalyticsPage/>}/>
              <Route exact path={D_CONTENT_MANAGE_ACCOUNTS} element={<ManageAccountsHome/>}/>
              <Route exact path={D_CONTENT_MANAGE_ACCOUNTS_USER} element={<AccountDetails/>}/>
              <Route path="/dashboard/*" element={<Navigate to={PNF} />} />
            </Route>
            <Route exact path={D_IMPERSONATE_USER} element={<SetImpersonation />} />
            <Route exact path={D_WEB_STUDIO} element={<WebStudioHome />} />
            <Route exact path={INTERACTIVE_MAPS} element={<InteractiveMapsHome />} />
          </Routes>
      </div>
    </React.Fragment>
  );
};

export default Router;
