import React, { useCallback, useEffect, useState } from "react";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { ReactComponent as ModalCloseBtnIcon } from "../../../../assets/svgs/ModalCloseBtnIcon.svg";
import { ReactComponent as UploadBlankIcon } from "../../../../assets/svgs/UploadBlankIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  validateSize,
  validateExt,
  validateMIME,
  fileLimits,
  fileTypes,
} from "../../../common/utils";
import {
  uploadContents,
  uploadingData,
} from "../../../../features/content/contentLibrarySlice";

import { setNewAlert } from "../../../../features/common/alertSlice";
import {
  getUserPlanDetails,
  planDetails,
} from "../../../../features/user/settings/settingsSlice";
import { encodeVideo } from "../../../webstudio/utils/FFMPEGLoader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "#ffffff",
  boxShadow:
    "0px 2px 16px rgba(40, 41, 61, 0.12), -2px 20px 32px rgba(96, 97, 112, 0.24)",
  p: 4,
  borderRadius: "16px",
};

const uploadContentType = [
  {
    value: "models",
    label: "3D Model",
  },
  {
    value: "images",
    label: "Images",
  },
  {
    value: "audios",
    label: "Audio",
  },
  {
    value: "floorplans",
    label: "Floor plans",
  },
  {
    value: "videos",
    label: "Videos",
  },
];

// const fileTypes = {
//   images: ".png,.jpg,.jpeg",
//   models: ".glb", //".obj,.fbx,.glb,.gltf,.ply,.blend",
//   audios: ".mp3,.wav,.mp4,.m4a,.ogg",
//   videos: ".mp4,.MP4",
//   floorplans: ".png,.jpg,.jpeg"
// };

// const maxSizes = {
//   images: 4048576,
//   models: 10485760,
//   audios: 4048576,
//   videos: 12582912,
//   floorplans: 4048576,
// }

const UploadContentModal = ({ open, handleClose, onSuccess }) => {
  const dispatch = useDispatch();
  const isUploading = useSelector(uploadingData);
  const [contentType, setContentType] = useState("models");
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const userPlanDetails = useSelector(planDetails);

  useEffect(() => {
    dispatch(getUserPlanDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setFile(null);
    setName("");
    setThumbnail(null);
    setUploadDisabled(true);
    setContentType(event.target.value);
  };

  const closeContentModal = (isSuccess = true) => {
    setFile(null);
    setName("");
    setThumbnail(null);
    setUploadDisabled(true);
    setContentType("models");
    handleClose();
    isSuccess && onSuccess();
  };

  const MBINBYTES = 1048576;

  const handleUpload = async () => {
    if (!userPlanDetails?.planStorageLeft) {
      dispatch(
        setNewAlert({
          msg: `Storage limit exceeded, available limit ${userPlanDetails?.planStorageLimit -
            userPlanDetails?.planCurrentStorage
            }`,
          alertType: "danger",
        })
      );
      return;
    }
    const contentUploadData = new FormData();
    if (contentType === 'videos') {
      const video = await encodeVideo(file);
      contentUploadData.append("file", video);
    } else {
      contentUploadData.append("file", file);

      if (contentType === "images") {
        contentUploadData.append("compressFile", true);
      }
    }
    contentUploadData.append("name", name);
    contentUploadData.append("contentType", contentType.toLowerCase());
    contentUploadData.append("thumbnail", thumbnail || "");
    dispatch(
      uploadContents({
        data: contentUploadData,
        onSuccess: closeContentModal,
      })
    );
  };

  const checkSize = (file, contentType) => {
    if (validateSize(file, contentType)) {
      return true;
    } else {
      setFile(null);
      document.getElementById("input-content").value = null;
      dispatch(
        setNewAlert({
          msg: `File Size Limit Exceeded! [Upload Limit : ${parseInt(
            fileLimits[contentType] / MBINBYTES
          )}MB]`,
          alertType: "danger",
        })
      );
    }
    return false;
  };

  const validateAndSetFile = (file) => {
    if (contentType === "images" || contentType === "floorplans") {
      validateMIME(file, contentType, (flag) => {
        let checkSizeBool = checkSize(file, contentType);
        if (flag && checkSizeBool) {
          setFile(file);
        } else {
          checkSizeBool && setFile(false);
        }
      });
    } else {
      if (validateExt(file, contentType)) {
        if (checkSize(file, contentType)) {
          setFile(file);
        }
      } else {
        setFile(false);
      }
    }
  };

  const handleFileChange = (e) => {
    const acceptedFile = e.target.files[0];
    validateAndSetFile(acceptedFile);
  };

  useEffect(() => {
    if (contentType && file && name) setUploadDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentType, file, name]);

  useEffect(() => {
    if (file === false || thumbnail === false) {
      dispatch(
        setNewAlert({
          msg: "File/Mime type not supported!",
          alertType: "danger",
        })
      );
      setFile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, thumbnail]);

  const { getRootProps, getInputProps } = useDropzone({
    minSize: 0,
    maxSize: fileLimits[contentType],
    onDrop: useCallback((acceptedFiles) => {
      let acceptedFile = acceptedFiles[0];
      validateAndSetFile(acceptedFile);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  });

  return (
    <Modal
      aria-labelledby="arway-upload-content-modal"
      aria-describedby="arway-upload-content-modal-description"
      open={open}
      onClose={() => closeContentModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={style}
          className="upload-content-modal"
          style={{ maxHeight: "100vh", overflowY: "auto" }}
        >
          <button
            className="upload-modal-close-btn"
            onClick={() => closeContentModal(false)}
            id="button-closeModal"
          >
            <ModalCloseBtnIcon />
          </button>
          <h1>Upload Content</h1>
          <label className="content-type-select">
            Select a content type*
            <select
              className="form-select"
              onChange={handleChange}
              value={contentType}
            >
              {uploadContentType.map((opt) => (
                <option
                  key={`option_${opt.value}`}
                  value={opt.value}
                  id={`option-${opt.value}`}
                >
                  {opt.label}
                </option>
              ))}
            </select>
          </label>
          <label className="content-type-select mt-4">
            Name*
            <input
              type="text"
              className="form-control"
              onChange={(e) => setName(e.target.value)}
              value={name}
              id="inupt-contentName"
            />
          </label>
          <div
            className="content-thumbnail"
            style={{
              backgroundImage: `url(${thumbnail ? URL.createObjectURL(thumbnail) : ""
                })`,
            }}
          >
            {!thumbnail && <UploadBlankIcon />}
          </div>
          <label className="thumbnail-select-btn" htmlFor="content-thumbnail">
            Select thumbnail (Optional)
          </label>
          <input
            id="content-thumbnail"
            type="file"
            accept="image/*"
            hidden
            onChange={(e) => {
              let acceptedFile = e.target.files[0];
              validateMIME(acceptedFile, (flag) => {
                if (flag) {
                  setThumbnail(acceptedFile);
                } else {
                  setThumbnail(false);
                }
              });
            }}
          />
          <label className="content-upload">
            <div
              className="content-dnd-zone"
              {...getRootProps()}
              onClick={(e) => e.stopPropagation()}
            >
              <input
                {...getInputProps()}
                accept={fileTypes[contentType]}
                onChange={handleFileChange}
                id="input-content"
              />
              <div className="content-dnd-msg">
                {file ? (
                  <p>{file.name}</p>
                ) : (
                  <>
                    <p>Drag your content here</p>
                    <p>
                      File Size Limit:{" "}
                      {parseInt(fileLimits[contentType] / MBINBYTES)}MB
                    </p>
                    <p>
                      <span></span>
                      or
                      <span></span>
                    </p>
                  </>
                )}
                <p className="choose-file-text" id="p-upload-Thumbnail">
                  {file ? "Choose a different file" : "Choose a file"}
                </p>
              </div>
            </div>
          </label>
          <div className="content-upload-btn-container">
            <button
              className="content-upload-btn"
              disabled={uploadDisabled || isUploading}
              onClick={handleUpload}
              id="button-modal-uploadContent"
            >
              {isUploading ? "Uploading..." : "Upload"}
            </button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UploadContentModal;
