//DEFAULT IMAGE ASSET
import SearchIcon from "../../../assets/svgs/scenes/search_icon_dark.svg";
import BackIcon from "../../../assets/svgs/scenes/chevron_dark.svg";
import pinIcon from "../../../assets/svgs/scenes/menu/navigation.svg";

import StraightIcon from "../../../assets/svgs/studio/interactiveMaps/arrow_straight.svg";
import LeftIcon from "../../../assets/svgs/studio/interactiveMaps/arrow_left.svg";
import RightIcon from "../../../assets/svgs/studio/interactiveMaps/arrow_right.svg";
import SlightLeftIcon from "../../../assets/svgs/studio/interactiveMaps/arrow_slight_left.svg";
import SlightRightIcon from "../../../assets/svgs/studio/interactiveMaps/arrow_slight_right.svg";
import ElevatorIcon from "../../../assets/svgs/studio/interactiveMaps/arrow_elevator.svg";
import EscalatorIcon from "../../../assets/svgs/studio/interactiveMaps/arrow_escalator.svg";
import StairsIcon from "../../../assets/svgs/studio/interactiveMaps/arrow_stairs.svg";
import BridgeIcon from "../../../assets/svgs/studio/interactiveMaps/arrow_bridge.svg";

import { getPlaceholderThumbnail } from "../../_utils/AssignPlaceholder";
import { has, isEmpty } from "lodash";
import {
    getPinThumbIcons,
    getTexNameFromId,
} from "../threeUtils/LocationPins/PinUtils";

//ICON Object
const icons = {
    LOCATION: pinIcon,
    //Directions
    STRAIGHT: StraightIcon,
    LEFT: LeftIcon,
    RIGHT: RightIcon,
    SLIGHT_LEFT: SlightLeftIcon,
    SLIGHT_RIGHT: SlightRightIcon,
    //Connectors
    ELEVATOR: ElevatorIcon,
    ESCALATOR: EscalatorIcon,
    STAIRS: StairsIcon,
    BRIDGE: BridgeIcon,
};

// UI Prototype
function UI(dom) {
    this.dom = dom;
}

UI.prototype = {
    addChild: function () {
        for (var i = 0; i < arguments.length; i++) {
            var argument = arguments[i];
            if (argument instanceof UI) {
                this.dom.appendChild(argument.dom);
            } else {
                console.log("UI:", argument, "is not an instantce of UI");
            }
        }
        return this;
    },

    deleteChild: function () {
        for (var i = 0; i < arguments.length; i++) {
            var argument = arguments[i];

            if (argument instanceof UI) {
                this.dom.removeChild(argument.dom);
            } else {
                console.log("UI:", argument, "is not an instantce of UI");
            }
        }
        return this;
    },

    clearChildren: function () {
        while (this.dom.children.length) {
            this.dom.removeChild(this.dom.lastChild);
        }
    },

    setDisabled: function (value) {
        this.dom.disabled = value;
        return this;
    },

    setDisplay: function (value) {
        this.dom.style.display = value;
    },

    setStyle: function (key, value) {
        this.dom.style[key] = value;
        return this;
    },

    setValue: function (value) {
        this.dom.value = value;
        return this;
    },

    setTextContent: function (value) {
        this.dom.textContent = value;
        return this;
    },

    addAttribute: function (key, value) {
        this.dom.setAttribute(key, value);
        return this;
    },

    setClass: function (name) {
        this.dom.className = name;
        return this;
    },

    addClass: function (name) {
        this.dom.classList.add(name);
        return this;
    },

    setId: function (id) {
        this.dom.id = id;
        return this;
    },

    removeClass: function (name) {
        this.dom.classList.remove(name);
        return this;
    },
};

var events = [
    "KeyUp",
    "KeyDown",
    "MouseOver",
    "MouseOut",
    "Click",
    "DblClick",
    "Change",
    "Mouseover",
    "Mouseout",
    "ImgChange",
];
events.forEach(function (event) {
    var method = "on" + event;
    UI.prototype[method] = function (callback) {
        this.dom.addEventListener(
            event.toLowerCase(),
            callback.bind(this),
            false
        );
        return this;
    };
});

// UIPanel
function UIPanel() {
    UI.call(this);
    var dom = document.createElement("div");
    this.dom = dom;
    return this;
}
UIPanel.prototype = Object.create(UI.prototype);
UIPanel.prototype.constructor = UIPanel;

function UIRow() {
    UI.call(this);

    const row = document.createElement("div");
    row.classList.add("ws_uiRow");
    this.dom = row;

    return this;
}

UIRow.prototype = Object.create(UI.prototype);
UIRow.prototype.constructor = UIRow;

function UICol() {
    UI.call(this);

    const row = document.createElement("div");
    row.classList.add("ws_uiCol");
    this.dom = row;

    return this;
}

UICol.prototype = Object.create(UI.prototype);
UICol.prototype.constructor = UICol;

function UISeparator(custClass) {
    UI.call(this);
    var dom = document.createElement("div");
    dom.classList.add(custClass || "ws_uiSeparator");
    this.dom = dom;
    return this;
}
UISeparator.prototype = Object.create(UI.prototype);
UISeparator.prototype.constructor = UISeparator;

//UITooltip
function UITooltip(dataVal, custClass = null) {
    UI.call(this);

    var dom = document.createElement("span");
    dom.setAttribute("data-text", dataVal);
    dom.className = custClass ? custClass : "ws_tooltip";
    this.dom = dom;

    return this;
}
UITooltip.prototype = Object.create(UI.prototype);
UITooltip.prototype.constructor = UITooltip;

function UIText(percentW, innerHtml, noClass = false) {
    UI.call(this);

    const textSpan = document.createElement("span");
    !noClass && textSpan.classList.add("ws_uiText");

    this.dom = textSpan;
    percentW && this.setStyle("width", percentW);
    this.setTextContent(innerHtml);
    return this;
}

UIText.prototype = Object.create(UI.prototype);
UIText.prototype.constructor = UIText;

//UI Text Header
function UITextHeader(percentW, innerHtml) {
    UI.call(this);

    const textSpan = document.createElement("h4");
    textSpan.classList.add("ws_uiTextHeader");

    this.dom = textSpan;
    percentW && this.setStyle("width", percentW);
    this.setTextContent(innerHtml);
    return this;
}

UITextHeader.prototype = Object.create(UI.prototype);
UITextHeader.prototype.constructor = UITextHeader;

//UI Image
function UIImage(src, width, height, title, handleError = false) {
    UI.call(this);

    this.handleError = handleError;

    const uiImageEle = document.createElement("img");
    uiImageEle.src = src;

    uiImageEle.classList.add("ws_uiImage");
    uiImageEle.draggable = false;
    uiImageEle.crossOrigin = "anonymous";
    if (this.handleError) {
        uiImageEle.onerror = () => {
            uiImageEle.src = getPlaceholderThumbnail();
        };
    }
    uiImageEle.style.width = width;
    uiImageEle.style.height = height;
    title && (uiImageEle.title = title);

    this.dom = uiImageEle;
    return this;
}

UIImage.prototype = Object.create(UI.prototype);
UIImage.prototype.constructor = UIImage;

UIImage.prototype.setSrc = function (src) {
    this.dom.src = src;
    if (this.handleError) {
        this.dom.onerror = () => {
            this.dom.src = getPlaceholderThumbnail();
        };
    }
    return this;
};

function UIInputText(
    id,
    percentW,
    defValue,
    maxLength,
    placeholder,
    extraClass = null
) {
    UI.call(this);

    const textInput = document.createElement("input");
    textInput.classList.add("ws_uiTextInput");
    extraClass !== null &&
        textInput.classList.add(`ws_uiTextInput--${extraClass}`);
    textInput.style.width = percentW;
    textInput.defaultValue = defValue || "";
    textInput.placeholder = placeholder || "";
    textInput.autocomplete = "off";
    maxLength && (textInput.maxLength = maxLength);
    textInput.id = id;

    this.dom = textInput;

    return this;
}

UIInputText.prototype = Object.create(UI.prototype);
UIInputText.prototype.constructor = UIInputText;

UIInputText.prototype.getValue = () => {
    return this.dom.value;
};

UIInputText.prototype.setReadOnly = function (value) {
    if (value !== undefined) {
        this.dom.readOnly = value;
        this.dom.style.pointerEvents = value ? "none" : "all";
    }

    return this;
};

function UIInputTextArea(
    id,
    percentW,
    defValue,
    maxLength,
    placeholder,
    rows = "2",
    extraClass = null
) {
    UI.call(this);

    const textInput = document.createElement("textarea");
    textInput.rows = rows;
    textInput.classList.add("ws_uiTextAreaInput");
    extraClass !== null &&
        textInput.classList.add(`ws_uiTextAreaInput--${extraClass}`);
    textInput.style.width = percentW;
    textInput.defaultValue = defValue || "";
    textInput.placeholder = placeholder || "";
    maxLength && (textInput.maxLength = maxLength);
    textInput.id = id;

    this.dom = textInput;

    return this;
}

UIInputTextArea.prototype = Object.create(UI.prototype);
UIInputTextArea.prototype.constructor = UIInputTextArea;

UIInputTextArea.prototype.getValue = () => {
    return this.dom.value;
};

UIInputTextArea.prototype.setReadOnly = function (value) {
    if (value !== undefined) {
        this.dom.readOnly = value;
        this.dom.style.pointerEvents = value ? "none" : "all";
    }

    return this;
};

function UINumber(number) {
    UI.call(this);

    var scope = this;

    const numContainer = document.createElement("div");
    numContainer.classList.add("ws_uiNumberCont");

    this.uiHelper = document.createElement("div");
    this.uiHelper.classList.add("ws_uiNumberHelper");

    this.numInput = document.createElement("input");
    this.numInput.style.cursor = "ns-resize";
    this.numInput.classList.add("ws_uiNumber");
    this.numInput.value = "0.00";

    numContainer.appendChild(this.uiHelper);
    numContainer.appendChild(this.numInput);

    this.value = 0;
    this.min = -Infinity;
    this.max = Infinity;
    this.precision = 2;
    this.step = 1;
    this.unit = "";
    this.helper = "";
    this.nudge = 0.01;

    this.dom = numContainer;

    this.setValue(number);

    var changeEvent = document.createEvent("HTMLEvents");
    changeEvent.initEvent("change", true, true);

    var distance = 0;
    var mouseDownVal = 0;

    var pointer = [0, 0];
    var prevPointer = [0, 0];

    function onMouseDown(e) {
        e.preventDefault();

        distance = 0;

        mouseDownVal = scope.value;

        prevPointer = [e.clientX, e.clientY];

        document.addEventListener("mousemove", onMouseMove, false);
        document.addEventListener("mouseup", onMouseUp, false);
    }

    function onMouseMove(e) {
        var currentVal = scope.value;

        pointer = [e.clientX, e.clientY];
        distance += pointer[0] - prevPointer[0] - (pointer[1] - prevPointer[1]);

        var value =
            mouseDownVal + (distance / (e.shiftKey ? 5 : 50)) * scope.step;
        value = Math.min(scope.max, Math.max(scope.min, value));

        if (currentVal !== value) {
            scope.setValue(value);
            scope.numInput.dispatchEvent(changeEvent);
        }

        prevPointer = [e.clientX, e.clientY];
    }

    function onMouseUp(e) {
        document.removeEventListener("mousemove", onMouseMove, false);
        document.removeEventListener("mouseup", onMouseUp, false);

        if (Math.abs(distance) < 2) {
            scope.numInput.focus();
            scope.numInput.select();
        }
    }

    function onTouchStart(e) {
        if (e.touches.length === 1) {
            distance = 0;

            mouseDownVal = scope.value;

            prevPointer = [e.touches[0].pageX, e.touches[0].pageY];

            document.addEventListener("touchmove", onTouchMove, false);
            document.addEventListener("touchend", onTouchEnd, false);
        }
    }

    function onTouchMove(e) {
        var currentVal = scope.value;

        pointer = [e.touches[0].pageX, e.touches[0].pageY];

        distance += pointer[0] - prevPointer[0] - (pointer[1] - prevPointer[1]);

        var value =
            mouseDownVal + (distance / (e.shiftKey ? 5 : 50)) * scope.step;
        value = Math.min(scope.max, Math.max(scope.min, value));

        if (currentVal !== value) {
            scope.setValue(value);
            scope.numInput.dispatchEvent(changeEvent);
        }

        prevPointer = [e.touches[0].pageX, e.touches[0].pageY];
    }

    function onTouchEnd(e) {
        if (e.touches.length === 0) {
            document.removeEventListener("touchmove", onTouchMove, false);
            document.removeEventListener("touchend", onTouchEnd, false);
        }
    }

    function onChange() {
        scope.setValue(scope.numInput.value ? scope.numInput.value : "0");
    }

    function onFocus() {
        scope.numInput.style.backgroundColor = "";
        scope.numInput.style.cursor = "";
        scope.uiHelper.style.display = "none";
    }

    function onBlur() {
        scope.numInput.style.backgroundColor = "transparent";
        scope.numInput.style.cursor = "ns-resize";
        scope.uiHelper.style.display = "";
    }

    function onKeyDown(e) {
        e.stopPropagation();

        switch (e.keyCode) {
            case 13: // Enter
                scope.numInput.blur();
                break;

            case 38: // key up
                e.preventDefault();
                scope.setValue(scope.getValue() + scope.nudge);
                scope.numInput.dispatchEvent(changeEvent);
                break;

            case 40: // key up
                e.preventDefault();
                scope.setValue(scope.getValue() - scope.nudge);
                scope.numInput.dispatchEvent(changeEvent);
                break;

            default:
                break;
        }
    }

    onBlur();

    this.numInput.addEventListener("keydown", onKeyDown, false);
    this.numInput.addEventListener("mousedown", onMouseDown, false);
    this.numInput.addEventListener("touchstart", onTouchStart, false);
    this.numInput.addEventListener("change", onChange, false);
    this.numInput.addEventListener("focus", onFocus, false);
    this.numInput.addEventListener("blur", onBlur, false);

    return this;
}

UINumber.prototype = Object.create(UI.prototype);
UINumber.prototype.constructor = UINumber;

UINumber.prototype.getValue = function () {
    return this.value;
};

UINumber.prototype.setValue = function (value) {
    if (value !== undefined) {
        value = parseFloat(value);

        if (value < this.min) value = this.min;
        if (value > this.max) value = this.max;

        this.value = value;
        this.numInput.value = value.toFixed(this.precision);

        if (this.unit !== "") this.numInput.value += " " + this.unit;
    }

    return this;
};

UINumber.prototype.setReadOnly = function (value) {
    if (value !== undefined) {
        this.numInput.readOnly = value;
        this.numInput.style.pointerEvents = value ? "none" : "all";
    }

    return this;
};

UINumber.prototype.setPrecision = function (precision) {
    this.precision = precision;
    return this;
};

UINumber.prototype.setStep = function (step) {
    this.step = step;
    return this;
};

UINumber.prototype.setNudge = function (nudge) {
    this.nudge = nudge;
    return this;
};

UINumber.prototype.setRange = function (min, max) {
    this.min = min;
    this.max = max;
    return this;
};

UINumber.prototype.setUnit = function (unit) {
    this.unit = unit;
    return this;
};

UINumber.prototype.setHelper = function (helper) {
    this.helper = helper;
    this.uiHelper.textContent = this.helper;
    return this;
};

function UICheckbox(bool) {
    UI.call(this);

    var checkBox = document.createElement("input");
    checkBox.type = "checkbox";
    checkBox.classList.add("wsInteractiveCheckbox");

    this.dom = checkBox;
    this.setValue(bool);

    return this;
}

UICheckbox.prototype = Object.create(UI.prototype);
UICheckbox.prototype.constructor = UICheckbox;

UICheckbox.prototype.getValue = function () {
    return this.dom.checked;
};

UICheckbox.prototype.setValue = function (bool) {
    if (bool !== undefined) {
        this.dom.checked = bool;
        this.dom.value = bool;
    }

    return this;
};

//UI Button
function UIButton(btnText, className = undefined, noClass = false) {
    UI.call(this);

    const uiBtn = document.createElement("button");
    !noClass && uiBtn.classList.add("ws_uiButton");

    className && (uiBtn.className = className);

    uiBtn.textContent = btnText;
    uiBtn.id = `ws_uiButton${btnText.replaceAll(/\s/g, "")}`;

    this.dom = uiBtn;
}

UIButton.prototype = Object.create(UI.prototype);
UIButton.prototype.constructor = UIButton;

function UISearchbar(
    placeholder,
    defValue = "",
    isBackBtn = false,
    callbacks,
    containerClass = null
) {
    UI.call(this);

    this.dom = null;
    this.value = "";

    this.isBackBtn = isBackBtn;

    const scope = this;
    this.callbacks = callbacks;

    var changeEvent = document.createEvent("HTMLEvents");
    changeEvent.initEvent("change", true, true);

    function onChange(e) {
        scope.setValue(e.target.value);
        scope.dom.dispatchEvent(changeEvent);
    }

    function onFocus() {
        if (scope.callbacks?.onfocus) {
            scope.callbacks.onfocus();
        }
        if (scope.isBackBtn) {
            scope.searchbarIcon.src = BackIcon;
            scope.searchbarIcon.style.transform = "rotate(90deg)";
            scope.searchbarIcon.style.opacity = "0.7";
        }
        document.addEventListener("keyup", onKeyDown, false);
    }

    function onBlur() {
        if (scope.callbacks?.onblur) {
            scope.callbacks.onblur();
        }
        document.removeEventListener("keyup", onKeyDown, false);
    }

    function onKeyDown(e) {
        scope.setValue(e.target.value);
        scope.dom.dispatchEvent(changeEvent);
    }

    const searchbarDiv = document.createElement("div");
    searchbarDiv.classList.add("wsInteractiveSearchbar");

    const searchCont = document.createElement("div");
    searchCont.classList.add("wsInteractiveSearchbar__container");
    containerClass && searchCont.classList.add(containerClass);

    this.searchbarIcon = document.createElement("img");
    this.searchbarIcon.classList.add("wsInteractiveSearchbar__icon");
    this.searchbarIcon.src = SearchIcon;
    this.searchbarIcon.draggable = false;
    this.searchbarIcon.crossOrigin = "anonymous";
    this.searchbarIcon.addEventListener(
        "click",
        (e) => {
            e.stopPropagation();
            if (scope.isBackBtn && scope.callbacks?.onback) {
                scope.callbacks.onback();
                this.searchbarIcon.src = SearchIcon;
                this.searchbarIcon.style.transform = "rotate(0deg)";
                this.searchbarIcon.style.opacity = "1";
            }
        },
        false
    );
    searchCont.appendChild(this.searchbarIcon);

    this.searchInput = document.createElement("input");
    this.searchInput.type = "text";
    this.searchInput.placeholder = placeholder;
    this.searchInput.value = defValue;
    this.searchInput.autocomplete = "off";
    this.searchInput.addEventListener("change", onChange, false);
    this.searchInput.addEventListener("focus", onFocus, false);
    this.searchInput.addEventListener("blur", onBlur, false);
    searchCont.appendChild(this.searchInput);

    searchbarDiv.appendChild(searchCont);

    this.dom = searchbarDiv;

    return this;
}

UISearchbar.prototype = Object.create(UI.prototype);
UISearchbar.prototype.constructor = UISearchbar;

UISearchbar.prototype.setValue = function (value, invokeChange = false) {
    this.searchInput.value = value;
    this.value = value;

    if (invokeChange) {
        this.searchInput.dispatchEvent(new Event("focus"));
        this.searchInput.dispatchEvent(new Event("change"));
    }
    return this;
};

UISearchbar.prototype.setIcon = function (pin) {
    if (pin) {
        this.searchbarIcon.src = null;
        this.searchbarIcon.src = getPinThumbIcons(
            pin.pinType === "amenity"
                ? getTexNameFromId(pin.amenityPinTypes[0].id)
                : "location",
            pin.pinColor
        );
        this.searchbarIcon.style.width = "24px";
        this.searchbarIcon.style.height = "24px";
        this.searchbarIcon.style.boxShadow =
            "4px 4px 8px 0px rgba(40, 41, 61, 0.161)";
        this.searchbarIcon.style.borderRadius = "6px";
    } else {
        this.searchbarIcon.src = null;
        this.searchbarIcon.src = SearchIcon;
        this.searchbarIcon.style.width = "18px";
        this.searchbarIcon.style.height = "18px";
        this.searchbarIcon.style.boxShadow = "none";
    }
    return this;
};

UISearchbar.prototype.getValue = function () {
    return this.value;
};

UISearchbar.prototype.setReadOnly = function (value) {
    this.searchInput.readOnly = value;
    return this;
};

function UIFeaturedPinCard(content, callBack, index = "") {
    UI.call(this);

    this.content = content;

    const uiCont = document.createElement("div");
    uiCont.classList.add("ws_uiObjectFeatLocationCard");

    uiCont.id = `ws_uiObjectCard${index}`;

    const uiIconCont = document.createElement("div");
    uiIconCont.classList.add("ws_uiObjectFeatLocationCard__iconCont");

    const uiTourIcon = document.createElement("img");
    uiTourIcon.classList.add("ws_uiObjectFeatLocationCard__iconCont--icon");
    if (
        this.content?.advanced &&
        has(this.content.advanced, "icon") &&
        has(this.content.advanced.icon, "link")
    ) {
        uiTourIcon.src = this.content.advanced.icon.link;
    } else if (this.content?.images && this.content.images.length) {
        uiTourIcon.src = this.content.images[0].link;
    } else {
        uiTourIcon.src = getPlaceholderThumbnail();
    }
    uiTourIcon.alt = "iconName";
    uiTourIcon.draggable = false;
    uiTourIcon.crossOrigin = "anonymous";

    uiIconCont.appendChild(uiTourIcon);

    const uiTextCont = document.createElement("div");
    uiTextCont.classList.add("ws_uiObjectFeatLocationCard__textCont");

    let uiNameLogo = document.createElement("img");
    if (
        this.content?.pinType === "regular" &&
        has(this.content.advanced, "logo") &&
        !isEmpty(this.content.advanced.logo)
    ) {
        uiNameLogo.src = this.content.advanced.logo?.link;
        uiNameLogo.style.border = `2px solid ${this.content.pinColor}`;
        uiNameLogo.style.borderRadius = "8px";
        uiNameLogo.style.padding = "2px";
        uiNameLogo.style.overflow = "hidden";
    } else {
        uiNameLogo.src = getPinThumbIcons(
            this.content.pinType === "amenity"
                ? getTexNameFromId(this.content.amenityPinTypes[0].id)
                : "location",
            this.content.pinColor
        );
    }
    uiNameLogo.alt = "iconName";
    uiNameLogo.draggable = false;
    uiNameLogo.crossOrigin = "anonymous";
    uiNameLogo.style.width = "30px";
    uiNameLogo.style.height = "30px";
    uiNameLogo.classList.add(
        "ws_uiObjectFeatLocationIconsCont__pinInfoLogoImg"
    );

    uiTextCont.appendChild(uiNameLogo);

    const uiHeaderCol = document.createElement("div");
    uiHeaderCol.classList.add(
        "ws_uiObjectFeatLocationCard__textCont__headerCol"
    );

    const uiText = document.createElement("div");
    uiText.classList.add(
        "ws_uiObjectFeatLocationCard__textCont__headerCol--text"
    );
    const uiAbbr = document.createElement("abbr");
    uiAbbr.title = this.content?.name || "";
    uiAbbr.textContent = this.content?.name || "";
    uiText.appendChild(uiAbbr);

    const uiSubText = document.createElement("span");
    uiSubText.classList.add(
        "ws_uiObjectFeatLocationCard__textCont__headerCol--subText"
    );

    if (this.content?.categories && this.content.categories.length) {
        uiSubText.textContent = this.content.categories[0].name;
    } else if (this.content?.pinType === "amenity") {
        uiSubText.textContent = this.content?.amenityPinTypes.length
            ? this.content?.amenityPinTypes[0].name
            : "Amenity Pin";
    }

    uiHeaderCol.appendChild(uiText);
    uiSubText.innerHTML && uiHeaderCol.appendChild(uiSubText);
    uiTextCont.appendChild(uiHeaderCol);

    uiCont.appendChild(uiIconCont);
    uiCont.appendChild(uiTextCont);

    this.dom = uiCont;

    const handleClick = (clickKey) => {
        callBack(this.content, clickKey);
    };
    this.dom.addEventListener(
        "click",
        (e) => {
            e.stopPropagation();
            handleClick(1);
        },
        false
    );
}

UIFeaturedPinCard.prototype = Object.create(UI.prototype);
UIFeaturedPinCard.prototype.constructor = UIFeaturedPinCard;

function UILocationCard(
    content,
    callBack,
    index = "",
    custClass = null,
    floor = null
) {
    UI.call(this);

    this.content = content;

    const uiCont = document.createElement("div");
    uiCont.classList.add("ws_uiObjectLocationCard");
    custClass && uiCont.classList.add(custClass);

    uiCont.id = `ws_uiObjectCard${index}`;

    const uiIconsCont = document.createElement("div");
    uiIconsCont.classList.add("ws_uiObjectFeatLocationIconsCont");

    let uiNameLogo = document.createElement("img");
    if (
        this.content?.pinType === "regular" &&
        has(this.content.advanced, "logo") &&
        !isEmpty(this.content.advanced.logo)
    ) {
        uiNameLogo.src = this.content.advanced.logo?.link;
        uiNameLogo.style.border = `2px solid ${this.content.pinColor}`;
        uiNameLogo.style.borderRadius = "8px";
        uiNameLogo.style.padding = "2px";
        uiNameLogo.style.overflow = "hidden";
    } else {
        uiNameLogo.src = getPinThumbIcons(
            this.content.pinType === "amenity"
                ? getTexNameFromId(this.content.amenityPinTypes[0].id)
                : "location",
            this.content.pinColor
        );
    }
    uiNameLogo.alt = "iconName";
    uiNameLogo.draggable = false;
    uiNameLogo.crossOrigin = "anonymous";
    uiNameLogo.style.width = "40px";
    uiNameLogo.style.height = "40px";
    uiNameLogo.classList.add("ws_uiObjectFeatLocationIconsCont__pinInfoImg");

    uiIconsCont.appendChild(uiNameLogo);

    const uiTextsCont = document.createElement("div");
    uiTextsCont.classList.add("ws_uiObjectLocationTextsCont");

    const uiText = document.createElement("span");
    uiText.classList.add("ws_uiObjectLocationTitle");
    const uiAbbr = document.createElement("abbr");
    uiAbbr.title = this.content?.name || "";
    uiAbbr.textContent = this.content?.name || "";
    uiText.appendChild(uiAbbr);

    uiTextsCont.appendChild(uiText);

    uiCont.appendChild(uiIconsCont);
    uiCont.appendChild(uiTextsCont);

    if (floor) {
        const uiSubText = document.createElement("span");
        uiSubText.classList.add("ws_uiObjectLocationFloorText");
        uiSubText.textContent = floor;
        uiCont.appendChild(uiSubText);
    }

    this.dom = uiCont;

    const handleClick = (clickKey) => {
        callBack(this.content, clickKey);
    };
    this.dom.addEventListener(
        "click",
        (e) => {
            e.stopPropagation();
            handleClick(1);
        },
        false
    );
}

UILocationCard.prototype = Object.create(UI.prototype);
UILocationCard.prototype.constructor = UILocationCard;

function UIDirectionCard(content, callBack, index = "") {
    UI.call(this);

    this.content = content;

    const uiCont = document.createElement("div");
    uiCont.classList.add("ws_uiObjectDirectionCard");

    uiCont.id = `ws_uiObjectCard${index}`;

    const uiIconsCont = document.createElement("div");
    uiIconsCont.classList.add("ws_uiObjectFeatLocationIconsCont");

    const uiTourIcon = document.createElement("img");
    uiTourIcon.classList.add("ws_uiObjectDirectionIcon");
    uiTourIcon.src = icons[this.content.directionType];
    uiTourIcon.alt = "iconName";
    uiTourIcon.draggable = false;
    uiTourIcon.crossOrigin = "anonymous";
    uiIconsCont.appendChild(uiTourIcon);

    const uiTextsCont = document.createElement("div");
    uiTextsCont.classList.add("ws_uiObjectLocationTextsCont");

    const uiText = document.createElement("span");
    uiText.classList.add("ws_uiObjectDirectionTitle");
    const uiAbbr = document.createElement("abbr");
    uiAbbr.title = this.content?.turnText || "";
    uiAbbr.textContent = this.content?.turnText || "";
    uiText.appendChild(uiAbbr);

    const uiSubText = document.createElement("span");
    uiSubText.classList.add("ws_uiObjectLocationSubTitle");
    uiSubText.textContent = this.content.distanceToNextTurn
        ? `${this.content.distanceToNextTurn.toFixed(2)} m`
        : "";

    uiTextsCont.appendChild(uiText);
    uiTextsCont.appendChild(uiSubText);

    uiCont.appendChild(uiIconsCont);
    uiCont.appendChild(uiTextsCont);

    this.dom = uiCont;

    const handleClick = (clickKey) => {
        callBack(this.content, clickKey);
    };
    this.dom.addEventListener(
        "click",
        (e) => {
            e.stopPropagation();
            handleClick(1);
        },
        false
    );
}

UIDirectionCard.prototype = Object.create(UI.prototype);
UIDirectionCard.prototype.constructor = UIDirectionCard;

function UIAmenityPinCard(content, callBack, index = "") {
    UI.call(this);

    this.content = content;

    const uiCont = document.createElement("div");
    uiCont.classList.add("ws_uiObjectAmenityCard");

    uiCont.id = `ws_uiObjectCard${index}`;

    const uiIconCont = document.createElement("div");
    uiIconCont.classList.add("ws_uiObjectAmenityCard__iconCont");

    const uiTourIcon = document.createElement("img");
    uiTourIcon.classList.add("ws_uiObjectAmenityCard__iconCont--icon");
    if (this.content.image) {
        uiTourIcon.src = this.content.image;
    } else {
        uiTourIcon.src = getPlaceholderThumbnail();
    }
    uiTourIcon.alt = "iconName";
    uiTourIcon.draggable = false;
    uiTourIcon.crossOrigin = "anonymous";

    uiIconCont.appendChild(uiTourIcon);

    const uiText = document.createElement("span");
    uiText.classList.add("ws_uiObjectAmenityCard__title");
    const uiAbbr = document.createElement("abbr");
    uiAbbr.title = this.content?.name || "";
    uiAbbr.textContent = this.content?.name || "";
    uiText.appendChild(uiAbbr);

    uiCont.appendChild(uiIconCont);
    uiCont.appendChild(uiText);

    this.dom = uiCont;

    const handleClick = (clickKey) => {
        callBack(this.content, clickKey);
    };
    this.dom.addEventListener(
        "click",
        (e) => {
            e.stopPropagation();
            handleClick(1);
        },
        false
    );
}

UIAmenityPinCard.prototype = Object.create(UI.prototype);
UIAmenityPinCard.prototype.constructor = UIAmenityPinCard;

export {
    UIPanel,
    UIRow,
    UICol,
    UISeparator,
    UITooltip,
    UIText,
    UIInputText,
    UIInputTextArea,
    UINumber,
    UIImage,
    UITextHeader,
    UIButton,
    UICheckbox,
    UISearchbar,
    UIFeaturedPinCard,
    UILocationCard,
    UIDirectionCard,
    UIAmenityPinCard,
};
