import EventEmitter from "../../utils/EventEmitter";
import { UIImage, UIPanel, UIText } from "../ui.lib";

import ChevronIcon from "../../../../assets/pngs/studio/interactiveMaps/arrow_next.png";

export default class ButtonNavigationLevels extends EventEmitter {
    constructor(editor) {
        super();
        this.editor = editor;

        this.dom = null;
        this.levelList = null;
        this.activeLevel = 0;

        this.venueMaps = [];
        this.names = {
            floors: "Floor",
            levels: "Level",
            zones: "Zone",
            areas: "Area",
            sections: "Section",
            parking: "Parking",
        };

        this.editor.on("setMultiNavigationButtons", this.onSetMultiNavigationButtons);
        this.editor.on("venueDataFetched", this.onVenueDataFetched);
        this.editor.on("navigationPreview", this.onNavigationPreview);

        this.initUI();
    }

    initUI = () => {
        this.uiContainer = new UIPanel().addClass("wsInteractiveLevelNavigationLevels");
        this.prevButton = new UIPanel().setClass(
            "wsInteractiveLevelSelector__levelSelector wsInteractiveLevelNavigationLevels__prevButton"
        );
        const prevBtnInnerLayer = new UIPanel().setClass(
            "wsInteractiveLevelSelector__levelSelectorInner"
        );
        const prevBtnIcon = new UIImage(
            ChevronIcon,
            "18px",
            "16px"
        ).setClass("wsInteractiveLevelSelector__levelIcon");
        prevBtnInnerLayer.addChild(prevBtnIcon);
        prevBtnIcon.dom.style.transform = "rotate(180deg)";
        prevBtnIcon.dom.style.margin = "0px 10px 0px 0px";
        this.prevBtnText = new UIText("", "Back: Level 1").setClass(
            "wsInteractiveLevelSelector__levelText"
        );
        prevBtnInnerLayer.addChild(this.prevBtnText);
        this.prevButton.addChild(prevBtnInnerLayer);
        this.prevButton.onClick(() => {
            this.activeLevel -= 1;
            this.setPrevButtonText(this.activeLevel - 1);
            this.setNextButtonText(this.activeLevel + 1);
            this.navigateSceneSwitch(this.levelList[this.activeLevel].mapId);
        });
        this.uiContainer.addChild(this.prevButton);

        // next btn
        this.nextButton = new UIPanel().setClass(
            "wsInteractiveLevelSelector__levelSelector wsInteractiveLevelNavigationLevels__nextButton"
        );
        const nextBtnInnerLayer = new UIPanel().setClass(
            "wsInteractiveLevelSelector__levelSelectorInner"
        );

        this.nextBtnText = new UIText("", "Next: Level 1").setClass(
            "wsInteractiveLevelSelector__levelText"
        );
        nextBtnInnerLayer.addChild(this.nextBtnText);
        const nextBtnIcon = new UIImage(
            ChevronIcon,
            "18px",
            "16px"
        ).setClass("wsInteractiveLevelSelector__levelIcon");
        nextBtnInnerLayer.addChild(nextBtnIcon);

        this.nextButton.addChild(nextBtnInnerLayer);
        this.nextButton.onClick(() => {
            this.activeLevel += 1;
            this.setPrevButtonText(this.activeLevel - 1);
            this.setNextButtonText(this.activeLevel + 1);
            this.navigateSceneSwitch(this.levelList[this.activeLevel].mapId);
        });
        this.uiContainer.addChild(this.nextButton);

        this.uiContainer.setDisplay("none");

        this.dom = this.uiContainer;
    }

    onVenueDataFetched = (mapsData) => {
        if (this.editor.venueDetails) {
            this.venueMaps = this.editor.venueDetails.maps;
        }
    }

    onSetMultiNavigationButtons = (levelList) => {
        this.levelList = levelList;
        this.activeLevel = 0;
        this.uiContainer.setDisplay("");
        this.prevButton.setDisplay("");
        this.nextButton.setDisplay("");

        this.setPrevButtonText(this.activeLevel - 1);
        this.setNextButtonText(this.activeLevel + 1);
    }

    setPrevButtonText = (level) => {

        if(level < 0) {
            this.prevButton.setDisplay("none");
            return;
        }

        this.prevButton.setDisplay("");
        const levelData = this.venueMaps.find(
            (map) => this.levelList[level].mapId === map.mapId
        );
        if (levelData) {
            this.prevBtnText.setTextContent(
                `Back: ${this.names[levelData.mapNamingType]} ${levelData.identifier}`
            );
        }
    }

    setNextButtonText = (index) => {
        if(index >= this.levelList.length) {
            this.nextButton.setDisplay("none");
            return;
        }

        this.nextButton.setDisplay("");
        const levelData = this.venueMaps.find(
            (map) => this.levelList[index].mapId === map.mapId
        );

        if (levelData) {
            this.nextBtnText.setTextContent(
                `Next: ${this.names[levelData.mapNamingType]} ${levelData.identifier}`
            );
        }
        
    }

    navigateSceneSwitch = (mapId) => {
        this.editor.trigger("updateLevelSelector", [mapId]);
    }

    onNavigationPreview = (show) => {
        if(show === false) {
            this.uiContainer.setDisplay("none");
            this.prevButton.setDisplay("none");
            this.nextButton.setDisplay("none");
            this.editor.trigger("updateConnectingPins");
        }
    }
}