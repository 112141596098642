import {
    BackSide,
    BoxGeometry,
    BufferAttribute,
    BufferGeometry,
    CanvasTexture,
    Clock,
    Color,
    Euler,
    LineBasicMaterial,
    LineSegments,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    OrthographicCamera,
    Quaternion,
    Raycaster,
    RepeatWrapping,
    SphereGeometry,
    Sprite,
    SpriteMaterial,
    Vector2,
    Vector3,
    TextureLoader,
    LinearFilter,
    ClampToEdgeWrapping
} from "three";

const [POS_X, POS_Y, POS_Z] = Array(3)
    .fill(0)
    .map((_, i) => i);

const axesColors = [
    new Color(0xff3653),
    new Color(0x8adb00),
    new Color(0x2c8fff),
];

const clock = new Clock();
const targetPosition = new Vector3();
const targetQuaternion = new Quaternion();
const euler = new Euler();
const q1 = new Quaternion();
const q2 = new Quaternion();
const point = new Vector3();
const dim = 128;
const turnRate = 2 * Math.PI; // turn rate in angles per second
const raycaster = new Raycaster();
const mouse = new Vector2();
const mouseStart = new Vector2();
const mouseAngle = new Vector2();
const dummy = new Object3D();
let radius = 0;

class ViewHelper extends Object3D {
    constructor(camera, renderer, editor, placement = "bottom-right", size = 128) {
        super();

        this.editor = editor;
        this.renderer = renderer.instance;
        this.camera = camera;
        this.domElement = renderer.instance.domElement;
        this.renderObject = renderer;

        this.currentView = "iso";

        this.textureLoader = new TextureLoader();
        this.orthoCamera = new OrthographicCamera(-1.8, 1.8, 1.8, -1.8, 0, 4);
        this.isViewHelper = true;
        this.animating = false;
        this.target = new Vector3();
        this.dragging = false;
        this.offsetHeight = 0;

        this.orthoCamera.position.set(0, 0, 2);

        // Textures
        this.front = null;
        this.back = null;
        this.right = null;
        this.left = null;
        this.top = null;
        this.frontHvr = null;
        this.backHvr = null;
        this.rightHvr = null;
        this.leftHvr = null;
        this.topHvr = null;

        // 3D Objects
        this.backgroundSphere = getBackgroundSphere();
        this.axesLines = getAxesLines();
        this.spritePoints = getAxesSpritePoints();
        this.box = this.getViewBox();

        this.add(this.backgroundSphere, this.box, this.axesLines, ...this.spritePoints);

        this.domContainer = getDomContainer(placement, size);
        this.isoBtn = this.isoViewBtn();
        this.viewBtnsList = this.viewBtns();

        this.domElement.parentElement.appendChild(this.domContainer);
        this.domElement.parentElement.appendChild(this.isoBtn);

        this.domRect = this.domContainer.getBoundingClientRect();
        this.startListening();

        this.controlsChangeEvent = { listener: () => this.updateOrientation() };

        this.update();
        this.updateOrientation();
    }

    getViewBox() {
        // load textures
        this.loadTextures();

        const boxGeo = new BoxGeometry();
        const boxMat = [
            new MeshBasicMaterial({ map: this.right }),
            new MeshBasicMaterial({ map: this.left }),

            new MeshBasicMaterial({ map: this.top }),
            new MeshBasicMaterial({ map: this.back }),

            new MeshBasicMaterial({ map: this.front }),
            new MeshBasicMaterial({ map: this.back }),
        ];
        const box = new Mesh(boxGeo, boxMat);
        box.position.set(0.425, 0.425, 0.425);
        box.scale.setScalar(0.85)

        return box;
    }

    loadTextures() {
        this.front = this.textureLoader.load("/static/textures/axisGizmo/front.png");
        this.front.minFilter = LinearFilter;
        this.front.wrapS = ClampToEdgeWrapping;
        this.front.wrapT = ClampToEdgeWrapping;

        this.back = this.textureLoader.load("/static/textures/axisGizmo/back.png");
        this.back.minFilter = LinearFilter;
        this.back.wrapS = ClampToEdgeWrapping;
        this.back.wrapT = ClampToEdgeWrapping;

        this.right = this.textureLoader.load("/static/textures/axisGizmo/right.png");
        this.right.minFilter = LinearFilter;
        this.right.wrapS = ClampToEdgeWrapping;
        this.right.wrapT = ClampToEdgeWrapping;

        this.left = this.textureLoader.load("/static/textures/axisGizmo/left.png");
        this.left.minFilter = LinearFilter;
        this.left.wrapS = ClampToEdgeWrapping;
        this.left.wrapT = ClampToEdgeWrapping;

        this.top = this.textureLoader.load("/static/textures/axisGizmo/top.png");
        this.top.minFilter = LinearFilter;
        this.top.wrapS = ClampToEdgeWrapping;
        this.top.wrapT = ClampToEdgeWrapping;

        this.frontHvr = this.textureLoader.load("/static/textures/axisGizmo/front_hvr.png");
        this.frontHvr.minFilter = LinearFilter;
        this.frontHvr.wrapS = ClampToEdgeWrapping;
        this.frontHvr.wrapT = ClampToEdgeWrapping;

        this.backHvr = this.textureLoader.load("/static/textures/axisGizmo/back_hvr.png");
        this.backHvr.minFilter = LinearFilter;
        this.backHvr.wrapS = ClampToEdgeWrapping;
        this.backHvr.wrapT = ClampToEdgeWrapping;

        this.rightHvr = this.textureLoader.load("/static/textures/axisGizmo/right_hvr.png");
        this.rightHvr.minFilter = LinearFilter;
        this.rightHvr.wrapS = ClampToEdgeWrapping;
        this.rightHvr.wrapT = ClampToEdgeWrapping;

        this.leftHvr = this.textureLoader.load("/static/textures/axisGizmo/left_hvr.png");
        this.leftHvr.minFilter = LinearFilter;
        this.leftHvr.wrapS = ClampToEdgeWrapping;
        this.leftHvr.wrapT = ClampToEdgeWrapping;

        this.topHvr = this.textureLoader.load("/static/textures/axisGizmo/top_hvr.png");
        this.topHvr.minFilter = LinearFilter;
        this.topHvr.wrapS = ClampToEdgeWrapping;
        this.topHvr.wrapT = ClampToEdgeWrapping;
    }

    isoViewBtn() {
        // Isometric view Button 
        const dummyTop = document.createElement("div");
        dummyTop.classList.add('camAxisGizmoElement__dummyTop');

        const isoDiv = document.createElement("div");
        isoDiv.classList.add('camAxisGizmoElement__dummyTop--isoBtn');
        isoDiv.onclick = () => {
            this.setOrientation("i");
        }

        dummyTop.appendChild(isoDiv);

        return dummyTop;
    }

    viewBtns() {

        const upView = document.createElement("div");
        upView.name = "topView";
        upView.classList.add('camAxisGizmoElement__viewBtns--up');
        this.domElement.parentElement.appendChild(upView);

        const downView = document.createElement("div");
        downView.name = "bottomView";
        downView.classList.add('camAxisGizmoElement__viewBtns--down');
        this.domElement.parentElement.appendChild(downView);


        const rightView = document.createElement("div");
        rightView.name = "rightView";
        rightView.classList.add('camAxisGizmoElement__viewBtns--right');
        this.domElement.parentElement.appendChild(rightView);


        const leftView = document.createElement("div");
        leftView.name = "leftView";
        leftView.classList.add('camAxisGizmoElement__viewBtns--left');
        this.domElement.parentElement.appendChild(leftView);

        return [upView, downView, rightView, leftView];
    }

    startListening() {
        this.domContainer.onpointerdown = (e) => this.onPointerDown(e);
        this.domContainer.onpointermove = (e) => this.onPointerMove(e);
        this.domContainer.onpointerleave = () => this.onPointerLeave();
    }

    onPointerDown(e) {
        const drag = (e) => {
            if (!this.dragging && isClick(e, mouseStart)) return;
            if (!this.dragging) {
                this.dragging = true;
            }

            mouseAngle
                .set(e.clientX, e.clientY)
                .sub(mouseStart)
                .multiplyScalar((1 / this.domRect.width) * Math.PI);

            this.rotation.x = clamp(
                rotationStart.x + mouseAngle.y,
                Math.PI / -2 + 0.001,
                Math.PI / 2 - 0.001
            );
            this.rotation.y = rotationStart.y + mouseAngle.x;
            this.updateMatrixWorld();

            q1.copy(this.quaternion).invert();

            this.camera.position
                .set(0, 0, 1)
                .applyQuaternion(q1)
                .multiplyScalar(radius)
                .add(this.target);

            this.camera.rotation.setFromQuaternion(q1);

            this.updateOrientation(false);

            this.domContainer.style.cursor = "move";
            this.showArrows(false);
        };
        const endDrag = () => {
            document.removeEventListener("pointermove", drag, false);
            document.removeEventListener("pointerup", endDrag, false);

            if (!this.dragging) {
                this.handleClick(e);
                return;
            }

            this.dragging = false;
            this.domContainer.style.cursor = "";
        };

        if (this.animating === true) return;
        e.preventDefault();

        mouseStart.set(e.clientX, e.clientY);

        const rotationStart = euler.copy(this.rotation);

        setRadius(this.camera, this.target);

        document.addEventListener("pointermove", drag, false);
        document.addEventListener("pointerup", endDrag, false);
    }

    onPointerMove(e) {
        if (this.dragging) return;
        this.backgroundSphere.material.color = new Color('#000000');
        this.backgroundSphere.material.opacity = 0.1;

        this.isoBtn.children[0].classList.remove("camAxisGizmoElement__dummyTop--isoBtn");
        this.isoBtn.children[0].classList.add("camAxisGizmoElement__dummyTop--isoBtnVisible");
        this.handleHover(e);
    }

    onPointerLeave() {
        if (this.dragging) return;
        this.backgroundSphere.material.color = new Color('#ffffff');
        this.backgroundSphere.material.opacity = 0.2;
        this.domContainer.stylecursor = "";

        this.isoBtn.children[0].classList.remove("camAxisGizmoElement__dummyTop--isoBtnVisible");
        this.isoBtn.children[0].classList.add("camAxisGizmoElement__dummyTop--isoBtn");
    }

    handleClick(e) {
        this.handleBoxOrientation(
            e,
            this.domRect,
            this.orthoCamera
        );
    }

    handleHover(e) {
        this.handleBoxIntersection(
            e,
            this.domRect,
            this.orthoCamera
        );
    }

    handleBoxOrientation(
        event,
        domRect,
        orthoCamera
    ) {
        updatePointer(event, domRect, orthoCamera);

        if (this.box) {
            const intersects = raycaster.intersectObjects([this.box]);

            const orientationArray = ['+x', '-x', '+y', '-y', '+z', '-z'];

            if (!intersects.length) return;

            const intersection = intersects[0];
            const materialIndex = intersection.face.materialIndex;

            this.setOrientation(orientationArray[materialIndex]);

        }
    }

    handleBoxIntersection(
        event,
        domRect,
        orthoCamera
    ) {
        updatePointer(event, domRect, orthoCamera);

        if (this.box) {
            const intersects = raycaster.intersectObjects([this.box]);

            const hoverTextureArray = [this.rightHvr, this.leftHvr, this.topHvr, this.backHvr, this.frontHvr, this.backHvr];

            this.resetBox();

            if (!intersects.length) return;

            const intersection = intersects[0];
            const materialIndex = intersection.face.materialIndex;

            intersection.object.material[materialIndex].map = hoverTextureArray[materialIndex];
            this.domContainer.style.cursor = "pointer"

        }
    }

    resetBox() {
        const textureArray = [this.right, this.left, this.top, this.back, this.front, this.back];
        this.box.material.forEach((mat, index) => mat.map = textureArray[index]);
        this.domContainer.style.cursor = ""

    }

    setControls(controls) {
        if (this.controls) {
            this.controls.removeEventListener(
                "change",
                this.controlsChangeEvent.listener
            );
            this.target = new Vector3();
        }

        if (!controls) return;

        this.controls = controls;
        controls.addEventListener("change", this.controlsChangeEvent.listener);
        this.target = controls.target;
    }

    render() {
        const delta = clock.getDelta();
        if (this.animating) this.animate(delta);

        const x = this.domRect.left;
        const y = this.offsetHeight - this.domRect.bottom;

        const autoClear = this.renderer.autoClear;
        this.renderer.autoClear = false;
        this.renderer.setViewport(x, y, dim, dim);

        this.renderer.render(this, this.orthoCamera);
        this.renderer.setViewport(this.renderObject.viewPort);

        this.renderer.autoClear = autoClear;
    }

    updateOrientation(fromCamera = true) {
        if (fromCamera) {
            this.quaternion.copy(this.camera.quaternion).invert();
            this.updateMatrixWorld();
        }

        updateSpritesOpacity(this.spritePoints, this.camera);
    }

    update() {
        this.domRect = this.domContainer.getBoundingClientRect();
        this.offsetHeight = this.domElement.offsetHeight;
        setRadius(this.camera, this.target);

        this.updateOrientation();
    }

    animate(delta) {
        const step = delta * turnRate;

        // animate position by doing a slerp and then scaling the position on the unit sphere
        q1.rotateTowards(q2, step);
        this.camera.position
            .set(0, 0, 1)
            .applyQuaternion(q1)
            .multiplyScalar(radius)
            .add(this.target);

        // animate orientation

        this.camera.quaternion.rotateTowards(targetQuaternion, step);

        this.updateOrientation();

        if (q1.angleTo(q2) === 0) {
            this.animating = false;
        }
    }

    setOrientation(orientation) {
        prepareAnimationData(this.camera, this.target, orientation);
        this.animating = true;

        const views = {
            "+y": "topView",
            "-y": "bottomView",
            "+x": "rightView",
            "-x": "leftView",
            "+z": "frontView",
            "-z": "backView",
            "i": "iso"
        }

        this.currentView = views[orientation];

        if (this.currentView !== "iso") {
            this.showArrows(true);
        } else {
            this.showArrows(false);
        }
    }

    showArrows(status) {
        this.viewBtnsList.forEach((btn) => {
            if (status) {
                btn.classList.add("camAxisGizmoElement__viewBtns--showBtns");

                this.setupViewBtnsControls(btn);
            } else {
                btn.classList.remove("camAxisGizmoElement__viewBtns--showBtns");
            }
        })
    }

    setupViewBtnsControls(btn) {

        const viewObj = {
            topView: {
                topView: "-z",
                bottomView: "+z",
                rightView: "+x",
                leftView: "-x",
            },
            bottomView: {
                topView: "+z",
                bottomView: "-z",
                rightView: "+x",
                leftView: "-x",
            },
            rightView: {
                topView: "+y",
                bottomView: "-y",
                rightView: "-z",
                leftView: "+z",
            },
            leftView: {
                topView: "+y",
                bottomView: "-y",
                rightView: "+z",
                leftView: "-z",
            },
            frontView: {
                topView: "+y",
                bottomView: "-y",
                rightView: "+x",
                leftView: "-x",
            },
            backView: {
                topView: "+y",
                bottomView: "-y",
                rightView: "-x",
                leftView: "+x",
            }
        }

        btn.onclick = () => this.setOrientation(viewObj[this.currentView][btn.name]);
    }

    dispose() {
        this.axesLines.geometry.dispose();
        this.axesLines.material.dispose();

        this.backgroundSpheregeometry.dispose();
        this.backgroundSpherematerial.dispose();

        this.spritePoints.forEach((sprite) => {
            sprite.material.map.dispose();
            sprite.material.dispose();
        });

        this.domContainer.remove();

        if (this.controls)
            this.controls.removeEventListener(
                "change",
                this.controlsChangeEvent.listener
            );
    }
}

function getDomContainer(placement, size) {
    const div = document.createElement("div");
    const style = div.style;

    style.height = `${size}px`;
    style.width = `${size}px`;
    style.borderRadius = "100%";
    style.position = "absolute";

    const [y, x] = placement.split("-");

    style.transform = "";
    style.left = x === "left" ? "0" : x === "center" ? "50%" : "";
    style.right = x === "right" ? "20px" : "";
    style.transform += x === "center" ? "translateX(-50%)" : "";
    style.top = y === "top" ? "0" : y === "bottom" ? "" : "50%";
    style.bottom = y === "bottom" ? "10px" : "";
    style.transform += y === "center" ? "translateY(-50%)" : "";
    div.classList.add('camAxisGizmoElement');

    return div;
}

function getAxesLines() {
    const distance = 1.1;
    const position = Array(6)
        .fill(0)
        .map((_, i) => [
            !i ? distance : 0,
            i === 1 ? distance : 0,
            i === 2 ? distance : 0,
            0,
            0,
            0,
        ])
        .flat();
    const color = Array(6)
        .fill(0)
        .map((_, i) =>
            i < 2
                ? axesColors[0].toArray()
                : i < 4
                    ? axesColors[1].toArray()
                    : axesColors[2].toArray()
        )
        .flat();

    const geometry = new BufferGeometry();
    geometry.setAttribute(
        "position",
        new BufferAttribute(new Float32Array(position), 3)
    );
    geometry.setAttribute(
        "color",
        new BufferAttribute(new Float32Array(color), 3)
    );

    return new LineSegments(
        geometry,
        new LineBasicMaterial({
            linewidth: 5,
            vertexColors: true,
        })
    );
}

function getBackgroundSphere() {
    const geometry = new SphereGeometry(1.8);
    const sphere = new Mesh(
        geometry,
        new MeshBasicMaterial({
            color: 0xffffff,
            side: BackSide,
            transparent: true,
            opacity: 0.2,
            depthTest: false,
        })
    );

    return sphere;
}

function getAxesSpritePoints() {
    const axes = ["x", "y", "z"];
    return Array(3)
        .fill(0)
        .map((_, i) => {
            const isPositive = i < 3;
            const sign = isPositive ? "+" : "-";
            const axis = axes[i % 3];
            const color = axesColors[i % 3];

            const sprite = new Sprite(
                getSpriteMaterial(color, isPositive ? axis : null)
            );
            sprite.userData.type = `${sign}${axis}`;
            sprite.scale.setScalar(isPositive ? 0.45 : 0.4);
            sprite.position[axis] = isPositive ? 1.22 : -1.4;
            sprite.renderOrder = 1;

            return sprite;
        });
}

function getSpriteMaterial(color, text = null) {
    const canvas = document.createElement("canvas");
    canvas.width = 128;
    canvas.height = 64;

    const context = canvas.getContext("2d");
    context.beginPath();
    context.arc(32, 32, 32, 0, 2 * Math.PI);
    context.closePath();
    context.fillStyle = color.getStyle();
    context.fill();

    context.beginPath();
    context.arc(96, 32, 32, 0, 2 * Math.PI);
    context.closePath();
    context.fillStyle = "#FFF";
    context.fill();

    if (text !== null) {
        context.font = "bold 48px Arial";
        context.textAlign = "center";
        context.fillStyle = "#000";
        context.fillText(text.toUpperCase(), 32, 48);
        context.fillText(text.toUpperCase(), 96, 48);
    }

    const texture = new CanvasTexture(canvas);
    texture.wrapS = texture.wrapT = RepeatWrapping;
    texture.repeat.x = 0.5;

    return new SpriteMaterial({
        map: texture,
        toneMapped: false,
        transparent: true,
    });
}

function prepareAnimationData(camera, focusPoint, axis) {
    switch (axis) {
        case "+x":
            targetPosition.set(1, 0, 0);
            targetQuaternion.setFromEuler(new Euler(0, Math.PI * 0.5, 0));
            break;

        case "+y":
            targetPosition.set(0, 1, 0);
            targetQuaternion.setFromEuler(new Euler(-Math.PI * 0.5, 0, 0));
            break;

        case "+z":
            targetPosition.set(0, 0, 1);
            targetQuaternion.setFromEuler(new Euler());
            break;

        case "-x":
            targetPosition.set(-1, 0, 0);
            targetQuaternion.setFromEuler(new Euler(0, -Math.PI * 0.5, 0));
            break;

        case "-y":
            targetPosition.set(0, -1, 0);
            targetQuaternion.setFromEuler(new Euler(Math.PI * 0.5, 0, 0));
            break;

        case "-z":
            targetPosition.set(0, 0, -1);
            targetQuaternion.setFromEuler(new Euler(0, Math.PI, 0));
            break;

        case "i":
            targetPosition.set(1, 0.8, 1);
            targetQuaternion.setFromEuler(new Euler());
            break;

        default:
            console.error("ViewHelper: Invalid axis.");
    }

    setRadius(camera, focusPoint);
    prepareQuaternions(camera, focusPoint);
}

function setRadius(camera, focusPoint) {
    radius = camera.position.distanceTo(focusPoint);
}

function prepareQuaternions(camera, focusPoint) {
    targetPosition.multiplyScalar(radius).add(focusPoint);

    dummy.position.copy(focusPoint);

    dummy.lookAt(camera.position);
    q1.copy(dummy.quaternion);

    dummy.lookAt(targetPosition);
    q2.copy(dummy.quaternion);
}

function updatePointer(e, domRect, orthoCamera) {
    mouse.x = ((e.clientX - domRect.left) / domRect.width) * 2 - 1;
    mouse.y = -((e.clientY - domRect.top) / domRect.height) * 2 + 1;

    raycaster.setFromCamera(mouse, orthoCamera);
}

function isClick(e, startCoords, threshold = 10) {
    return (
        Math.abs(e.clientX - startCoords.x) < threshold &&
        Math.abs(e.clientY - startCoords.y) < threshold
    );
}


function updateSpritesOpacity(sprites, camera) {
    point.set(0, 0, 1);
    point.applyQuaternion(camera.quaternion);

    if (point.x >= 0) {
        sprites[POS_X].material.opacity = 1;
    } else {
        sprites[POS_X].material.opacity = 0.5;
    }

    if (point.y >= 0) {
        sprites[POS_Y].material.opacity = 1;
    } else {
        sprites[POS_Y].material.opacity = 0.5;
    }

    if (point.z >= 0) {
        sprites[POS_Z].material.opacity = 1;
    } else {
        sprites[POS_Z].material.opacity = 0.5;
    }
}

function clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
}

export { ViewHelper };
