import * as THREE from 'three';
import EditorExperience from '../EditorExperience';
import EventEmitter from '../utils/EventEmitter';

import { 
    fromPosObjectToVec3Pos, 
    fromQuaternionObjectToQuaternion, 
    fromScaleObjectToVec3Scale 
} from './TransformConversions';
import { dispose } from '../utils/Dispose.Utils';


export default class Video extends EventEmitter {
    constructor(videoData) {
        super();
        this.editor = new EditorExperience();
        this.videoData = videoData;

        this.name = videoData.name.substring(0, 20);
        this.description = videoData.description || 'sample description';

        this.videoLink = videoData.link;
        this.showAsIcon = videoData.showAsIcon || false;
        this.videoSprite = null;

        this.visible = 'visible' in videoData ? videoData.visible : true;
        this.downloadProximity = 'downloadProximity' in videoData ? videoData.downloadProximity : "10";
        this.autoplay = 'autoplay' in videoData ? videoData.autoplay : true;
        this.autoRotate = 'autoRotate' in videoData ? videoData.autoRotate : false;

        this.position = videoData.position ? fromPosObjectToVec3Pos(videoData.position) : new THREE.Vector3(0, 0, 0);
        this.rotation = videoData.rotation ? fromQuaternionObjectToQuaternion(videoData.rotation) : new THREE.Quaternion(0, 0, 0, 0);
        this.scale = videoData.scale ? fromScaleObjectToVec3Scale(videoData.scale) : new THREE.Vector3(1, 1, 1);        
        this.setupGeometry();

        //events
        this.editor.on('objectChanged', this.objectChanged);
        this.editor.on('objectRemoved', this.onObjectRemoved);
    }  


    setupGeometry = () => {

        const spriteMaterial = new THREE.SpriteMaterial({
            map: new THREE.TextureLoader().load('/static/textures/video.png'),
            transparent: true,
            depthTest: false,
            depthWrite: false
        });
        
        this.videoIcon = new THREE.Sprite(spriteMaterial);
        this.videoIcon.scale.set(0.25, 0.25, 0.25);
        this.videoIcon.name = this.name;
        this.videoIcon.visible = this.showAsIcon;

        let imgTex = new THREE.TextureLoader().load('/static/textures/vid-thumb.png');
        imgTex.minFilter = imgTex.magFilter = THREE.LinearFilter;
        imgTex.colorSpace = THREE.SRGBColorSpace;
        imgTex.anisotropy = 4;

        this.videObject = new THREE.Mesh(
            new THREE.PlaneGeometry(1, 1, 32, 32), 
            new THREE.MeshBasicMaterial({ 
                map: imgTex, 
                side: THREE.DoubleSide
        }));
        this.videObject.name = this.name;
        this.videObject.visible = !this.showAsIcon;

        this.videoSprite = new THREE.Group();

        if(this.showAsIcon) {
            this.videoSprite.add(this.videoIcon);
        } else {
            this.videoSprite.add(this.videObject);
        }
        this.videoSprite.name = this.name;
        this.videoSprite.userData['type'] = "video";
        this.videoSprite.userData['skipChild'] = "video";
        this.videoSprite.userData['id'] =  this.videoData.id;
        this.videoSprite.userData['videoLink'] = this.videoLink;
        this.videoSprite.userData['autoplay'] = this.autoplay;
        this.videoSprite.userData['autoRotate'] = this.autoRotate;
        this.videoSprite.userData['showAsIcon'] = this.showAsIcon;
        this.videoSprite.userData['description'] = this.description;
        this.videoSprite.userData['downloadProximity'] = this.downloadProximity;
        this.videoSprite.userData['visible'] = this.visible;
        this.videoSprite.position.copy(this.position);
        this.videoSprite.scale.copy(this.scale);
        this.videoSprite.quaternion.copy(this.rotation);
        this.videoSprite.visible = this.visible;
    }

    objectChanged = (object) => {
        if(this.videoSprite === object) {
            this.description = object.userData.description;
            this.name = object.name;
            if(object.userData.showAsIcon !== this.showAsIcon) {
                this.showAsIcon = object.userData.showAsIcon;
                this.toggleVisibilityModes();
            }
        }
    }

    toggleVisibilityModes = () => {
        this.videoIcon.visible = this.showAsIcon;
        this.videObject.visible = !this.showAsIcon;
        if(this.showAsIcon) {
            this.videoSprite.remove(this.videObject);
            this.videoSprite.add(this.videoIcon);
        } else {
            this.videoSprite.remove(this.videoIcon);
            this.videoSprite.add(this.videObject);
        }
    }

    onObjectRemoved = (object) => {
        if(object === this.sprite) {
            this.editor.stop('objectChanged', this.objectChanged);
            this.editor.stop('objectRemoved', this.onObjectRemoved);
            dispose(this.sprite);
        }
    }
}