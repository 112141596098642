import EditorExperience from "../../InteractiveExperience";
import EventEmitter from "../../utils/EventEmitter";

// import UI Components
import { UIImage, UIPanel } from "../ui.lib";
import { SidebarPinInfo } from "./Sidebar.PinInfo";
import { SidebarDirections } from "./Sidebar.Directions";
import { detectMobile } from "../../../_utils/detectBrowser";

import CloseIcon from "../../../../assets/svgs/studio/interactiveMaps/cross_menu_dark.svg";

class Sidebar extends EventEmitter {
    constructor(props) {
        super(props);
        this.editor = new EditorExperience();
        this.parentEle = props.dom;

        this.selectedLocationPin = null;
        this.selectedPinMesh = null;

        // LOCATION_INFO, DIRECTIONS_SELECTOR, NAVIGATION
        this.activeType = "";
        this.contentProperties = {};
        this.objectProperties = {};

        this.editor.on(
            "toggleInteractiveSidebar",
            this.onToggleInteractiveSidebar
        );
        this.editor.on("updateSidebarMode", this.updateUIMode);
        this.editor.resources.on("ready", this.detectBrowserandAddEvents);

        this.editor.on("sceneSwitched", this.onExitNavigation);

        this.initUI();
    }

    initUI = () => {
        this.uiContainer = new UIPanel().addClass("wsInteractiveSidebar");

        const uiCloseBtn = new UIImage(CloseIcon, "26px", "26px")
            .addClass("wsInteractiveSidebar__closeBtn")
            .onClick(() => {
                this.onExitNavigation(false);
            });

        this.uiContainer.addChild(uiCloseBtn);

        // components
        this.sidebarLocationPinInfo = new SidebarPinInfo(this.editor);
        this.uiContainer.addChild(this.sidebarLocationPinInfo.dom);
        this.objectProperties["LOCATION_INFO"] = this.sidebarLocationPinInfo;
        this.contentProperties["LOCATION_INFO"] =
            this.sidebarLocationPinInfo.dom;

        this.sidebarDirectionSelector = new SidebarDirections(this.editor);
        this.uiContainer.addChild(this.sidebarDirectionSelector.dom);
        this.objectProperties["DIRECTIONS_SELECTOR"] =
            this.sidebarDirectionSelector;
        this.contentProperties["DIRECTIONS_SELECTOR"] =
            this.sidebarDirectionSelector.dom;

        this.uiContainer.setDisplay("none");
        this.parentEle.append(this.uiContainer.dom);
    };

    updateUIType = () => {
        for (const key in this.contentProperties) {
            this.contentProperties[key].setDisplay(
                key === this.activeType ? "" : "none"
            );
        }
    };

    updateUIData = () => {
        if (this.activeType in this.objectProperties) {
            this.objectProperties[this.activeType].refreshUI(
                this.selectedLocationPin
            );
        }
    };

    updateUIMode = (mode) => {
        if (mode in this.contentProperties) {
            this.activeType = mode;
            this.updateUIType();
            this.updateUIData();
        }
    };

    onToggleInteractiveSidebar = (locationPin, mode) => {
        this.activeType = mode;
        if (locationPin && this.activeType in this.contentProperties) {
            this.selectedLocationPin = locationPin;
            this.uiContainer.setDisplay("");
            this.updateUIType();
            this.updateUIData();
        } else {
            this.uiContainer.setDisplay("none");
            this.selectedLocationPin = null;
        }
    };

    onExitNavigation = (bFlag) => {
        if(bFlag === false || !this.editor.navigationTracking) {
            this.editor.trigger("toggleInteractiveSidebar", [null]);
            this.editor.trigger("clearPathBetweenPins");
            this.editor.select(null);
        }
    }

    detectBrowserandAddEvents = () => {
        const browser = detectMobile();
        if (browser) {
            const uiTouchHandler = new UIPanel().addClass(
                "wsInteractiveSidebar__touchHandler"
            );
            this.uiContainer.addChild(uiTouchHandler);

            let isFLoatingBarOpen = false;

            uiTouchHandler.dom.addEventListener("click", () => {
                if (isFLoatingBarOpen) {
                    this.uiContainer.dom.style.height = "15%";
                    this.uiContainer.dom.style.overflow = "hidden";
                    isFLoatingBarOpen = false;
                } else {
                    this.uiContainer.dom.style.height = "max-content";
                    this.uiContainer.dom.style.maxHeight = "100%";
                    this.uiContainer.dom.style.overflow = "auto";
                    isFLoatingBarOpen = true;
                }
            });
        }
    };
}

export { Sidebar };
