import * as THREE from "three";
import { LocationHeaderIcon, getLocatinSVGString } from "./LocationPinIcons";

const GenerateSvgString = (type, color, subType) => {
    let svgString = getLocatinSVGString(type, subType);

    if (svgString) {
        return svgString.replaceAll("CUSTOM_COLOR", `${color}`);
    } else {
        svgString = getLocatinSVGString();
        return svgString.replaceAll("CUSTOM_COLOR", `${color}`);
    }
};

const GenerateSvgThumbString = (type, color) => {
    let svgString = LocationHeaderIcon[type || "location"];

    if (svgString) {
        return svgString.replaceAll("CUSTOM_COLOR", `${color}`);
    } else {
        svgString = LocationHeaderIcon.location;
        return svgString.replaceAll("CUSTOM_COLOR", `${color}`);
    }
};

const getLocationCanvasTexture = (
    iconName,
    color,
    texSize,
    type = "default"
) => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = texSize;
        canvas.height = texSize;

        // Create SVG Image
        const svgImage = new Image();
        const svgString = GenerateSvgString(iconName, color, type);

        if (!svgString) reject("type not found");

        const svgBlob = new Blob([svgString], {
            type: "image/svg+xml;charset=utf-8",
        });
        const url = URL.createObjectURL(svgBlob);

        // load url
        svgImage.src = url;

        // img loaded successfully
        svgImage.onload = () => {
            ctx.drawImage(svgImage, 0, 10, texSize, texSize);

            // Use the canvas as the source for a texture
            const texture = new THREE.CanvasTexture(canvas);
            texture.minFilter = THREE.LinearFilter;
            texture.wrapS = THREE.ClampToEdgeWrapping;
            texture.wrapT = THREE.ClampToEdgeWrapping;

            // Destroy the canvas
            ctx.restore();
            canvas.remove();

            // Return Texture
            resolve(texture);
        };

        // Error Handling
        svgImage.onerror = () => reject("svg not supported");
    });
};

const idNames = {
    dev: {
        "643fa8fd47ab4a2de3ff7b15": "informationreception",
        "643fa94e47ab4a705cff7b25": "elevator",
        "643fa9b447ab4a94d4ff7b35": "restroom",
        "643fa9db47ab4ab5fcff7b3c": "mensrestroom",
        "645c9cdb9a922afe658792da": "womensrestroom",
        "645c9cfb9a922a900e879304": "restroom", //unisexs
        "645c9e319a922a255c87947f": "familyrestroom",
        "645c9e469a922a214d8794d0": "wheelchairaccessiblerestroom",
        "6459dc03ca02e25597fd379c": "escalator",
        "6459dc1dca02e20e4cfd37a7": "stairs",
        "645c9bb29a922a49398790af": "cafe",
        "645c9bd29a922a1a1d8790c3": "exit",
        "645c9beb9a922a322987912e": "lostfound",
        "645c9c079a922a681b87918b": "security",
    },
    prod: {
        "649c0a03fa6e60260870cb29": "informationreception",
        "649c0a87fa6e60a6d570cb9b": "elevator",
        "649c0bc3fa6e60069770ce89": "restroom",
        "649c0be7fa6e600f1270cef6": "mensrestroom",
        "649c0c02fa6e60e45970cefd": "womensrestroom",
        "649c0c36fa6e6009f470cf04": "restroom", //unisex
        "649c0c54fa6e6041d170cf0b": "familyrestroom",
        "649c0c7bfa6e607e5670cf12": "wheelchairaccessiblerestroom",
        "649c0a9afa6e60345770cbe2": "escalator",
        "649c0aeefa6e601e2e70cd27": "stairs",
        "649c0b35fa6e60529370cde1": "cafe",
        "649c0ab4fa6e603eb370cc4e": "exit",
        "649c0b5efa6e609f7670ce15": "lostfound",
        "649c0b0cfa6e60029d70cda2": "security",
    },
};

export const pinColors = [
    "#36CCE7",
    "#FFE768",
    "#4A4A4A",
    "#6689FF",
    "#BC67FF",
    "#FF6CA2",
    "#F166FF",
    "#CFFF69",
    "#6BFF7B",
    "#FF8165",
    "#FFB16A",
    "#FF68D5",
    "#803D00",
    "#17181C",
];

const getTexNameFromId = (id) => {
    const prod = process.env.REACT_APP_NODE_ENV === "production";
    const envType = prod ? "prod" : "dev";
    return idNames[envType][id] || "location";
};

const getPinThumbIcons = (type, color) => {
    const svgThumbString = GenerateSvgThumbString(type, color);

    if (!svgThumbString) return;

    const svgThumbBlob = new Blob([svgThumbString], {
        type: "image/svg+xml;charset=utf-8",
    });
    const urlThumb = URL.createObjectURL(svgThumbBlob);

    return urlThumb;
};

const getFloorIdentifiers = (maps, mapId) => {
    const list = {
        floors: "F",
        levels: "L",
        zones: "Z",
        areas: "A",
        sections: "S",
        parking: "P",
    };
    const map = maps.find((map) => map.mapId === mapId);
    return map ? `${list[map.mapNamingType]}${map.identifier}` : "F1";
};

export {
    getLocationCanvasTexture,
    getTexNameFromId,
    getPinThumbIcons,
    getFloorIdentifiers,
};
