import * as THREE from 'three';
// loader
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
// import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader';
import { PLYLoader } from '../threeUtils/GZipPLYLoader/PLYLoader';

//Geo
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { ComputeTourNodes } from '../threeUtils/GuidedTour/ComputeTourNodes';

import EventEmitter from "./EventEmitter";
import EditorExperience from '../EditorExperience';

import Hotspot from '../threeUtils/Hotspot';
import Audio from '../threeUtils/Audio';
import Navigation from '../threeUtils/Navigation/Navigation';
import MapVisualHelper from '../threeUtils/MappingPath/MapVisualHelper';
import Model from '../threeUtils/Model';
import Image from '../threeUtils/Image';
import QRAnchor from '../threeUtils/QRAnchor';
import Video from '../threeUtils/Video';
import MapMarker from '../threeUtils/MapMarker';
import ImageAccessPoint from '../threeUtils/ImageAccessPoint';
import Floorplan from '../threeUtils/Floorplan/Floorplan';
import FloorplanV1 from '../threeUtils/Floorplan/FloorplanV1';
import Path from '../threeUtils/PinAndPath/PathGraph';
import Pin from '../threeUtils/PinAndPath/Pin';
import Connector from '../threeUtils/PinAndPath/Connector';

export default class ObjectsLoader extends EventEmitter {

    constructor() {
        super();

        this.editor = new EditorExperience();
        this.sources = [];
        this.pathsPins = {};

        this.loadManager = new THREE.LoadingManager();

        this.items = {};
        this.toLoad = 0;
        this.preloadModels = 0;
        this.toInitLoading = false;
        this.isVersionSwitch = false;
        this.loaded = 0;
        this.loaders = {
            gltfLoader: null
        };

        this.asyncObject = [];

        this.plyFunctions = {
            'gz': 'loadGZIP',
            'ply': 'load',
        }

        this.preloadModelAssets = [
            { path: '/static/models/location_pin.glb', childLevel: 1, pinVar: 'mapPinObject' },
            { path: '/static/models/mappath_pin.glb', childLevel: 1, pinVar: 'anchorPinObject' },
            { path: '/static/models/start.glb', childLevel: 0, pinVar: 'navPinStart' },
            { path: '/static/models/end.glb', childLevel: 0, pinVar: 'navPinEnd' },
            { path: '/static/models/qr_pin.glb', childLevel: 0, pinVar: 'qrPin' },
            { path: '/static/models/qr_primary_pin.glb', childLevel: 0, pinVar: 'qrMainPin' },
            { path: '/static/models/marker_pin.glb', childLevel: 0, pinVar: 'markerPin' },
            { path: '/static/models/img_pin.glb', childLevel: 0, pinVar: 'imgAccessPin' },
            { path: '/static/models/img_primary_pin.glb', childLevel: 0, pinVar: 'imgAccessMainPin' },
            { path: '/static/models/floorplan_peg.glb', childLevel: 0, pinVar: 'floorplanPeg' },
        ]

        this.size = this.preloadModelAssets.length;

        this.on('initiateLoader', this.onInitiateLoader);
        this.editor.on('initiateVersionSwitch', this.onInitVersionSwitch);
        this.editor.on('clearAndReset', this.onClearAndReset);
        this.editor.on('asyncObjectLoaded', this.onAsyncObjectLoaded);
        this.editor.on('loadMapVersionFromHistory', () => {
            this.isVersionSwitch = true;
            this.loaded = this.size;
            this.sources = []
        });

        this.editor.on('initiateMapSwitch', () => {
            this.items = {};
            this.loaded = this.size;
            this.sources = []
        });

        this.setLoaders();
    }

    onClearAndReset = () => {
        this.loaded = this.size;
        this.items = {};
        this.sources = []
    }

    initiateLoading = (sources) => {
        this.sources = this.toLinearArray(sources);
        this.toLoad = this.sources.length; //this.calculateSourcesLength(sources);
        this.toLoad += this.size;
        !this.isVersionSwitch && this.toInitLoading && this.sources.length > 0 ? this.startLoading() : this.isReady();
    }

    onInitiateLoader = () => {
        this.toInitLoading = true;
        this.loaded += this.size;
        !this.isVersionSwitch && this.sources.length > 0 ? this.startLoading() : this.isReady();
    }

    onInitVersionSwitch = () => {
        this.items = {};
        this.isVersionSwitch = false;
        // this.sources.length > 0 &&  this.startLoading();
    }

    toLinearArray = (sources) => {
        let arr = []
        for (const source in sources) {
            if (source !== 'textNote3Ds' && source !== 'nftItems' && source !== 'floorplans' && source !== 'destination3ds') {
                if (source === 'waypoints3ds' && sources[source].length > 0) {
                    sources[source].forEach(waypoint => {
                        if ('waypoints' in waypoint && waypoint.waypoints.length > 0) {
                            arr = [...arr, waypoint]
                        }
                    });
                }
                arr = [...arr, ...sources[source]]
            }
        }
        //Make Array unique!
        return [...new Map(arr.map((item) => [item["waypoints"] && !item['id'] ? item["waypoints"][0]['id'] : item['id'], item])).values()];
    }

    setLoaders = () => {

        var dracoLoader = new DRACOLoader(this.loadManager);
        dracoLoader.setDecoderPath('/static/draco/');
        this.loaders.gltfLoader = new GLTFLoader(this.loadManager);
        this.loaders.gltfLoader.setDRACOLoader(dracoLoader);

        this.loaders.fontLoader = new FontLoader(this.loadManager);

        this.loaders.plyLoader = new PLYLoader(this.loadManager);
        this.loaders.plyLoader.setCrossOrigin("anonymous");

        for (const model of this.preloadModelAssets) {
            const scope = this;
            (async (model) => {
                const result = await scope.loaders.gltfLoader.loadAsync(model.path);
                scope.editor[model.pinVar] = model.childLevel === 2 ? result.scene.children[0].children[0] : model.childLevel === 1 ? result.scene.children[0] : result.scene;
                scope.preloadModels++;
                if (scope.preloadModels === this.size) {
                    scope.trigger('initiateLoader');
                }
            })(model);
        }
    }

    startLoading = () => {
        const scope = this;
        // console.log(this.sources);
        for (const source of this.sources) {
            // console.log(source);

            if (source.id && source.id.includes('text')) {
                this.loaders.fontLoader.load('/static/fonts/Poppins_Light_Regular.json', (font) => {
                    scope.addTextToScene(0.5, font, source);
                })
            }

            else if (source.id && source.id.includes('model')) {
                this.loaders.gltfLoader.load(source.link, (file) => {
                    if (!(source.id in this.items)) {
                        const model = new Model({ ...source, model: file })
                        this.objectLoaded(source, source.id, "model", model.gltfModel);
                    }
                }, null, () => {
                    this.objectLoaded(source, source.id, "model", []);
                })
            }

            else if (source.id && source.id.includes('image')) {
                if (!(source.id in this.items)) {
                    const imgMesh = new Image(source, 'image');
                    this.objectLoaded(source, source.id, "image", imgMesh.imageMesh);
                }
            }

            else if (source.id && source.id.includes('floorplan')) {
                if (!(source.id in this.items)) {
                    if (source.id.includes('metadata_floorplans')) {
                        const len = source.floorplans.length;
                        const floor = source.floorplans[len - 1];
                        if (len > 0 && this.asyncObject.indexOf(floor.id) === -1) {
                            if ('floorplanVersion' in floor) {
                                this.editor.floorplanVersion = floor.floorplanVersion;
                            }
                            console.log("Floor Version", this.editor.floorplanVersion);
                            if (this.editor.floorplanVersion === 2.6) {
                                let metaFloorplans = [];
                                let metaFPlan = new FloorplanV1(floor, 'floorplan', true);
                                if(metaFPlan.onError === false) {
                                    metaFloorplans.push(metaFPlan.imageMesh);
                                    metaFPlan.alignmentStatus && metaFloorplans.push(metaFPlan.pegMesh);
                                    this.objectLoaded(source, source.id, "floorplan", metaFloorplans);
                                } else {
                                    this.objectLoaded(source, "SKIPPED_OBJECT", "SKIPPED_OBJECT", null);
                                }
                                
                            } else {
                                new Floorplan(floor, 'floorplan', true);
                                this.asyncObject.push(floor.id);
                            }
                        } else {
                            this.objectLoaded(source, "SKIPPED_OBJECT", "SKIPPED_OBJECT", null);
                        }
                    }
                }
            }

            else if (source.id && source.id.includes('audio')) {
                if (!(source.id in this.items)) {
                    let audio = new Audio(source);
                    'visible' in source && (audio.audioSprite.visible = source.visible);
                    this.objectLoaded(source, source.id, "audio", audio.audioSprite);
                }
            }

            else if (source.id && source.id.includes('hotspot')) {
                if (!(source.id in this.items)) {
                    let hotspot = new Hotspot(source);
                    // this.editor.hotspots.push(hotspot);
                    // this.editor.raycasterObjects.push(hotspot.sprite);
                    'visible' in source && (hotspot.sprite.visible = source.visible);
                    this.objectLoaded(source, source.id, "hotspot", hotspot.sprite);
                }
            }

            else if (source.id && source.id.includes('video')) {
                if (!(source.id in this.items)) {
                    let video = new Video(source);
                    'visible' in source && (video.videoSprite.visible = source.visible);
                    this.objectLoaded(source, source.id, "video", video.videoSprite);
                }
            }

            else if ((source.id && (source.id.includes('wayPoint') || source.id.includes('locationPin'))) || ('waypoints' in source)) {
                if (('waypoints' in source && source.waypoints.length && !(source.waypoints[0].id in this.items)) || !(source.id in this.items)) {
                    if ('waypoints' in source && (!('nodes' in source) || ('nodes' in source && source.nodes.length === 0)) ) {
                        //Only waypoints
                        if (!(source.waypoints[0].id in this.items)) {
                            const nodeParser = new ComputeTourNodes(source.waypoints);
                            const guidedTour = this.editor.guidedTourController.initGuidedTours(nodeParser.getTourObject());
                            guidedTour 
                            ? this.objectLoaded({source, id: guidedTour.tourId}, guidedTour.tourId, "waypoints", guidedTour.tourGroup, false)
                            : this.objectLoaded(source, "SKIPPED_OBJECT", "SKIPPED_OBJECT", null);
                        }
                    } else if ('waypoints' in source && 'nodes' in source) {
                        const guidedTour = this.editor.guidedTourController.initGuidedTours(source);
                        guidedTour 
                            ? this.objectLoaded({source, id: guidedTour.tourId}, guidedTour.tourId, "waypoints", guidedTour.tourGroup, false)
                            : this.objectLoaded(source, "SKIPPED_OBJECT", "SKIPPED_OBJECT", null);
                    } else if ('waypoints' in source === false) {
                        //Only Destination
                        const wayPoinDest = new Navigation(source, undefined, true, undefined);
                        this.objectLoaded(source, wayPoinDest.id, "destination", wayPoinDest.destination.mesh);
                    } else {
                        // Old Navigation both destination & waypoints
                        const wayPoint = new Navigation(source, true, undefined, undefined);
                        let meshes = [];
                        wayPoint.destination && meshes.push(wayPoint.destination.mesh);
                        wayPoint.line && meshes.push(wayPoint.line.mesh)
                        this.objectLoaded(source, wayPoint.destination.id, "waypointDestination", meshes);
                    }
                }
            }

            else if (source.id && source.id.includes('qrAnchors')) {
                if (!(source.id in this.items)) {
                    let qrMeshes = [];
                    source.anchors.forEach(anchor => {
                        if ('isQrCode' in anchor && anchor.isQrCode === false) {
                            const imgAccess = new ImageAccessPoint(anchor);
                            this.editor.uiObjects.push(imgAccess);
                            qrMeshes.push(imgAccess.mesh);
                        } else {
                            const qrAnchor = new QRAnchor(anchor);
                            qrMeshes.push(qrAnchor.qrMesh);
                        }
                    });
                    this.objectLoaded(source, source.id, "qrAnchor", qrMeshes);
                }
            }

            else if (source.id && source.id.includes('marker')) {
                if (!(source.id in this.items)) {
                    let marker = new MapMarker(source);
                    this.objectLoaded(source, source.id, "marker", marker.mesh);
                }
            }

            else if (source.wsType && source.wsType.includes('mapPin')) {
                if (!(source.wsType in this.items)) {
                    let pinMesh;
                    if (source.pinType === 'connector') {
                        pinMesh = new Connector(source);
                    } else {
                        pinMesh = new Pin(source);
                    }
                    this.objectLoaded(source, source.wsType, "mapPin", pinMesh.mesh);
                }
            }

            else if (source.wsType && source.wsType.includes('mapPath')) {
                if (!(source.wsType in this.items)) {
                    const navMesh = new Path(source);
                    this.objectLoaded(source, source.wsType, "mapPath", navMesh.mesh);
                }
            }

            else if (source.id && source.id.includes('mapVisual')) {
                const plyFunction = this.plyFunctions[source.link.split('.').pop()];
                this.loaders.plyLoader[plyFunction](source.link, (geometry) => {
                    if (!(source.id in this.items)) {
                        scope.addMapVisualtoScene(source, geometry);
                    }
                });
            }

            else {
                //Skip this object
                this.objectLoaded(source, "SKIPPED_OBJECT", "SKIPPED_OBJECT", null);
            }
        }
    }

    onAsyncObjectLoaded = (object, type) => {
        if (type === 'floorplan') {
            const idx = this.asyncObject.indexOf(object.id);
            if (!(object.id in this.items) && idx !== -1) {
                let metaFloorplans = [];
                this.asyncObject.splice(idx, 1);
                metaFloorplans.push(object.imageMesh);
                object.alignmentStatus && metaFloorplans.push(object.pegMesh);
                this.objectLoaded(object, object.id, "floorplan", metaFloorplans);
            }
        } else if(type === 'SKIPPED_OBJECT') {
            this.objectLoaded(object, "SKIPPED_OBJECT", "SKIPPED_OBJECT", null);
        }
    }

    addMapVisualtoScene = (source, geometry) => {
        const material = new THREE.PointsMaterial({ size: 0.035, vertexColors: true });
        var mVisualMesh = new THREE.Points(geometry, material);
        mVisualMesh.name = source.name;
        mVisualMesh.userData['type'] = "mapVisual";
        mVisualMesh.userData['id'] = `${source.id}__mapVisual`;
        mVisualMesh.userData['link'] = source.link;
        mVisualMesh.position.set(-source.position.posX, source.position.posY, source.position.posZ); // y = 1.3
        mVisualMesh.scale.set(source.scale.scaX, source.scale.scaY, source.scale.scaZ);
        mVisualMesh.quaternion.set(source.rotation.rotX, -source.rotation.rotY, -source.rotation.rotZ, source.rotation.rotW);

        mVisualMesh.geometry.computeBoundingSphere();
        const mapBounds = {
            sphere: mVisualMesh.geometry.boundingSphere,
            box3: new THREE.Box3().setFromObject(mVisualMesh)
        }

        new MapVisualHelper(source.mapName, mapBounds, source.anchors);

        this.objectLoaded(source, source.id, "mapVisual", mVisualMesh);
    }

    addTextToScene = (fontSize, fontFamily, textData) => {

        if (textData.id in this.items) return;

        let textMesh;
        let fontText = textData.textValue;
        let fontMat = new THREE.LineBasicMaterial({
            color: textData.color.substring(0, 7),
            side: THREE.DoubleSide
        });


        let fontGeo = new TextGeometry(
            fontText,
            {
                font: fontFamily,
                size: fontSize,
                height: 0.02,
                curveSegments: 5,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 4
            }
        );
        fontGeo.center();

        textMesh = new THREE.Group();
        const txtMesh = new THREE.Mesh(fontGeo, fontMat);
        txtMesh.scale.set(0.25, 0.25, 0.25);
        txtMesh.rotateY(THREE.MathUtils.degToRad(180));
        textMesh.add(txtMesh);
        textMesh.name = fontText;
        textMesh.userData['type'] = "text";
        textMesh.userData['skipChild'] = "text";
        textMesh.userData['id'] = textData.id;
        textMesh.userData['visible'] = textData.visible || true;
        textMesh.userData['autoRotate'] = textData.autoRotate || false;
        textMesh.userData['downloadProximity'] = textData.downloadProximity || 10;
        textMesh.position.set(-textData.position.posX, textData.position.posY, textData.position.posZ);
        textMesh.scale.set(textData.scale.scaX, textData.scale.scaY, textData.scale.scaZ);
        textMesh.quaternion.set(textData.rotation.rotX, -textData.rotation.rotY, -textData.rotation.rotZ, textData.rotation.rotW)
        'visible' in textData && (textMesh.visible = textData.visible);
        this.objectLoaded(textData, textData.id, "text", textMesh);
    }

    objectLoaded = (source, id, type, file, toAdd = true) => {
        if (id === 'SKIPPED_OBJECT') {
            this.loaded++;
        } else {
            if (source.id in this.items) return;
            this.items[id] = {
                type,
                object: file
            };
            this.loaded++;

            if(toAdd) {
                if (file instanceof Array) {
                    for (const obj of file) {
                        this.editor.addObjects(obj);
                    }
                } else {
                    this.editor.addObjects(file);
                }
            }
        }

        // console.log("ITEMS", this.items, this.toLoad, this.loaded);

        this.isReady();
    }

    isReady = () => {
        if (this.loaded === this.toLoad) {
            this.trigger('ready');
        }
    }
}