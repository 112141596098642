import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { has, isEqual, omit } from "lodash";
import { NavLink as Link, useLocation, useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";

import ContentTabs from "../content-library/ContentTabs";
import MessageModal from "../../../common/modal/Modal";
import CustomModal from "../../../common/modal";
import Loader from "../../../common/loader";
import MapDetailsTab from "./MapDetailsTab";
import QRCodesTab from "./QRCodeTab/QRCodesTab";
import IAPTab from "./IAPTab/IAPTab";

//Icons
import StudioIcon from "../../../../assets/icons/sidebar/studioIcon.svg";
import StudioIconGray from "../../../../assets/icons/sidebar/studioIconGray.svg";
import { ReactComponent as PublishIcon } from "../../../../assets/icons/sidebar/publish_icon.svg";
import { ChevronLeft, Edit2, Share2, Copy, Save } from "react-feather";
import OccImgOn from "../../../../assets/pngs/occ_on.png";
import OccImgOff from "../../../../assets/pngs/occ_off.png";

import { convertToNamedDate } from "../../../_utils/dateFormatter";
import { getQRURL } from "../../../_utils/QRURLUtils";

import {
  getUserMapDetails,
  updateUserMap,
  deleteUserMap,
} from "../../../../features/user/studio/studioSlice";
import { getAmenityPinCategories } from "../../../../features/webstudio/editor2dSlice";
import { resetBuilding } from "../../../../features/webstudio/buildingSlice";
import {
  resetCommonLog,
  resetMapDetailsTab,
} from "../../../../features/common/commonSlice";
import { updateAnchor } from "../../../../features/user/studio/anchorSlice";
import {
  setNewAlert,
  clearAlerts,
} from "../../../../features/common/alertSlice";

const MyMaps = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const _isMounted = useRef(false);

  const mapInfo = useSelector((state) => state.studio.mapDetails);
  const studioLog = useSelector((state) => state.studio.studioLog);
  const deleteLog = useSelector((state) => state.studio.deleteLog);
  const replaceLog = useSelector((state) => state.studio.replaceLog);
  const anchorLog = useSelector((state) => state.anchors.anchorLog);

  const mapData = location.state;

  const [mapDetails, setMapDetails] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [fetching, setFetching] = useState(false);

  const [mapState, setMapState] = useState({
    mapName: null,
    description: null,
    mapStatus: "public",
    isPublished: false,
    IAP: false,
    mapOcclusion: false,
    dialIndicator: false,
    enableInteractive: false,
    hasAnchors: false,
    hasIAPs: false,
    isAssociated: false,
  });
  // helper bools
  const [helperBools, setHelperBools] = useState({
    isTitleEdit: true,
    isEdit: true,
    copied: false,
    qrGenerated: false,
    isBackClicked: false,
    externalIAPClick: false,
    isReplaceFloorplan: false,
  });
  // modal bools
  const [modalBools, setModalBools] = useState({
    deleteModal: false,
    shareModal: false,
    IAPModal: false,
    occlusionModal: false,
    replaceFloorplanModal: false,
  });

  useEffect(() => {
    _isMounted.current = true;
    window.addEventListener("popstate", onBackOrForwardButtonEvent);

    setFetching(true);
    dispatch(resetBuilding());
    dispatch(resetCommonLog());
    fetchMapDetails();
    dispatch(getAmenityPinCategories());

    return () => {
      dispatch(clearAlerts());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBackOrForwardButtonEvent = () => {
    if (_isMounted.current && localStorage.getItem("venueCreatedHandleBack")) {
      navigate("/dashboard/studio", { replace: true });
      localStorage.removeItem("venueCreatedHandleBack");
    }
  };

  useEffect(() => {
    if (mapInfo && !isEqual(mapInfo, mapDetails)) {
      setFetching(false);
      setMapDetails(mapInfo);
      setMapState((state) => ({
        ...state,
        mapName: mapInfo.metadata.mapName,
        description: mapInfo.metadata.description
          ? mapInfo.metadata.description.trim()
          : "",
        isPublished: mapInfo.metadata.isPublished,
        mapStatus: mapInfo.metadata.isPublicMap ? "public" : "private",
        mapOcclusion: mapInfo.metadata.occlusion || false,
        dialIndicator: mapInfo.metadata.indicator || false,
        enableInteractive: mapInfo.metadata?.interactiveMaps?.enable || false,
        hasAnchors: has(mapInfo?.metadata, "anchors") ? true : false,
        hasIAPs: has(mapInfo?.metadata, "anchors")
          ? mapInfo?.metadata.anchors.some((e) => e.isQrCode === false)
          : false,
        isAssociated: checkIsAssiociated(mapInfo?.metadata?.anchors),
        IAP: mapInfo?.metadata?.anchors
          ?.slice(1)
          ?.some((anchorId) => anchorId.isPrimaryAccess),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInfo]);

  // useEffect for logs!!
  useEffect(() => {
    if (anchorLog === "success") {
      fetchMapDetails();
    }

    if (studioLog === "success") {
      fetchMapDetails();
      !helperBools.isEdit &&
        setHelperBools((helpers) => ({ ...helpers, isEdit: !helpers.isEdit }));
      dispatch(resetCommonLog());
    }

    if (deleteLog === "success") {
      setModalBools((modal) => ({ ...modal, deleteModal: false }));
      navigate(-1);
    }

    if (replaceLog === "success") {
      setModalBools((modal) => ({ ...modal, replaceFloorplanModal: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorLog, studioLog, deleteLog, replaceLog]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (_isMounted.current && !mapState.hasIAPs && activeTab === 2) {
      setActiveTab(0);
    }
  });

  const fetchMapDetails = () => {
    dispatch(getUserMapDetails(mapData));
  };

  const handleFormChange = (e) => {
    const { id, value } = e.target;
    setMapState((state) => ({
      ...state,
      [id]: value,
    }));
  };

  const handleMapStatusChange = (e) => {
    const { value } = e.target;
    setMapState((state) => ({ ...state, mapStatus: value }));
  };

  const handleMapOcclusionChange = (value) => {
    setMapState((state) => ({ ...state, mapOcclusion: value }));
    let metadata = { ...mapDetails.metadata };
    metadata = { ...metadata, occlusion: value };
    dispatch(updateUserMap({ mapId: mapData, mapDetails: { metadata } }));
  };

  const handleInteractiveMapChange = (value) => {
    setMapState((state) => ({ ...state, enableInteractive: value }));
    let metadata = { ...mapDetails.metadata };
    metadata = { ...metadata, interactiveMaps: { ...metadata.interactiveMaps, enable: value } };
    dispatch(updateUserMap({ mapId: mapData, mapDetails: { metadata } }));
  }

  const handleDialIndicatorChange = (value) => {
    setMapState((state) => ({ ...state, dialIndicator: value }));
    let metadata = { ...mapDetails.metadata };
    metadata = {
      ...metadata,
      indicator: value,
    };
    dispatch(updateUserMap({ mapId: mapData, mapDetails: { metadata } }));
  };

  const handdleIAPChange = (e) => {
    if (!e.target.checked) {
      let anchors = [...mapDetails.metadata.anchors];
      let modifiedAnchor = anchors.find((anchor) => anchor.isPrimaryAccess);
      modifiedAnchor = {
        ...omit(modifiedAnchor, ["modifiedOn", "createdOn"]),
        isPrimaryAccess: false,
      };
      dispatch(updateAnchor({ mapId: mapData, modifiedAnchor }));
    } else {
      setActiveTab(2);
      setHelperBools((helpers) => ({ ...helpers, externalIAPClick: true }));
    }
  };

  const handleGoBack = () => {
    setHelperBools((helpers) => ({
      ...helpers,
      isBackClicked: !helpers.isBackClicked,
    }));
    if (activeTab === 0) {
      dispatch(resetMapDetailsTab());
    }
    navigate(-1);
  };

  const toggleModal = (varId) => {
    setModalBools((modals) => ({
      ...modals,
      [varId]: !modals[varId],
    }));
    setHelperBools((helpers) => ({
      ...helpers,
      copied: false,
    }));
  };

  const handleDelete = () => {
    dispatch(deleteUserMap({ mapId: mapData }));
  };

  const handleReplaceFloorplan = () => {
    setHelperBools((helpers) => ({
      ...helperBools,
      isReplaceFloorplan: !helpers.isReplaceFloorplan,
    }));
  };

  const handleSaveChanges = (e) => {
    let { mapName } = mapState;
    if (mapName.length <= 0) {
      dispatch(
        setNewAlert({
          msg: "Map Name cannot be empty!",
          alertType: "danger",
        })
      );
    } else if (mapName.length < 3) {
      dispatch(
        setNewAlert({
          msg: "Minimum three character required!",
          alertType: "danger",
        })
      );
    } else {
      setHelperBools((helper) => ({
        ...helper,
        isTitleEdit: !helper.isTitleEdit,
      }));
    }
  };

  const setIsBackClicked = (val) => {
    setHelperBools((helpers) => ({ ...helpers, isBackClicked: val }));
  };

  const checkIsAssiociated = (anchors) => {
    let primaryAnchor = anchors?.find((anc) => anc.anchorIndex === "primary");
    return primaryAnchor && "isAssociated" in primaryAnchor
      ? primaryAnchor.isAssociated
      : true;
  };

  const tabItems = {
    "Map Details": (
      <MapDetailsTab
        state={{ ...mapState, ...helperBools, mapDetails, mapData }}
        handleBack={(val) => setIsBackClicked(val)}
        handletoggleModal={toggleModal}
        mapOcclusionChange={handleMapOcclusionChange}
        interactiveMapChange={handleInteractiveMapChange}
        dialIndicatorChanges={handleDialIndicatorChange}
        IAPModalChange={handdleIAPChange}
        mapStatusChange={handleMapStatusChange}
        studioLog={studioLog}
        replaceLog={replaceLog}
        handleReplaceFP={() =>
          setHelperBools((helper) => ({
            ...helper,
            isReplaceFloorplan: !helper.isReplaceFloorplan,
          }))
        }
      />
    ),
    ...(mapDetails?.metadata?.anchorType === "qrAnchors"
      ? {
          "QR Codes": <QRCodesTab />,
        }
      : ""),
    ...(mapState.hasIAPs
      ? {
          "Image Access Points": (
            <IAPTab
              data={{
                externalIAPClick: helperBools.externalIAPClick,
                onClick: () => {
                  setHelperBools((helpers) => ({
                    ...helpers,
                    externalIAPClick: false,
                  }));
                },
              }}
            />
          ),
        }
      : ""),
  };

  if (fetching && !mapDetails) {
    return (
      <Loader loaderText="Fetching Map Details..." height="100%" width="100%" />
    );
  }
  if (!mapDetails) {
    return <h2>Map Details Not Available!</h2>;
  }

  return (
    <Box className="mapinfo">
      <Grid container={true}>
        <Grid
          item
          xs={12}
          className={`mapinfo__separator mapinfo__mapTopRow ${
            !helperBools.isEdit ? "mapinfo__separatorPadded" : ""
          }`}
        >
          <div
            className="mapinfo__backBtn"
            onClick={handleGoBack}
            id="button-mapBack"
          >
            <ChevronLeft
              size={24}
              color="#353E5A"
              className="mapinfo__mapIcon"
            />
            Back
          </div>
        </Grid>
        <form onSubmit={(e) => e.preventDefault()} style={{ width: "100%" }}>
          <Grid container={true}>
            <Grid item xs={6} className="mapinfo__mapTopRow">
              <div className="d-flex bd-highlight">
                <div className="p-2 flex-fill bd-highlight">
                  {helperBools.isTitleEdit ? (
                    <label
                      className={`mapinfo__mapFormInputMapname`}
                      id="input-mapName"
                    >
                      {mapState.mapName}
                    </label>
                  ) : (
                    <input
                      type="text"
                      readOnly={helperBools.isTitleEdit}
                      value={mapState.mapName}
                      onChange={handleFormChange}
                      onBlur={(e) =>
                        setMapState((state) => ({
                          ...state,
                          mapName: e.target.value.trim(),
                        }))
                      }
                      className={`mapinfo__mapFormInput ${
                        !helperBools.isTitleEdit
                          ? "mapinfo__mapFormInput--active"
                          : ""
                      }`}
                      autoComplete="off"
                      maxLength={30}
                      minLength={3}
                      id="mapName"
                    />
                  )}
                </div>
                <div className="p-2 flex-fill bd-highlight">
                  {helperBools.isTitleEdit ? (
                    <Edit2
                      style={{ cursor: "pointer", marginTop: "1.6rem" }}
                      onClick={() =>
                        setHelperBools((helper) => ({
                          ...helper,
                          isTitleEdit: !helper.isTitleEdit,
                        }))
                      }
                      id="button-editMapName"
                    />
                  ) : (
                    <Save
                      style={{ cursor: "pointer", marginTop: "1.6rem" }}
                      onClick={() => {
                        let metadata = { ...mapDetails.metadata };
                        metadata = {
                          ...metadata,
                          mapName: mapState.mapName,
                        };
                        if (
                          mapState.mapName !== mapInfo.metadata.mapName &&
                          mapState.mapName.length >= 3
                        ) {
                          dispatch(
                            updateUserMap({
                              mapId: mapData,
                              mapDetails: { metadata },
                            })
                          );
                          setHelperBools((helper) => ({
                            ...helper,
                            isTitleEdit: !helper.isTitleEdit,
                          }));
                        } else {
                          handleSaveChanges();
                        }
                      }}
                      id="button-saveMapName"
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container={true}>
            <Grid item xs={12} className="mapinfo__separator mapinfo__mapTopRow">
              <div className="mapinfo__mapStatusRow">
                <div
                  style={{ height: "100%" }}
                  className={`mapinfo__mapStatusSpan ${
                    mapDetails.metadata.isPublished
                      ? "mapinfo__mapStatusSpan--published"
                      : mapState.hasAnchors
                      ? mapState.isAssociated
                        ? "mapinfo__mapStatusSpan--draft"
                        : "mapinfo__mapStatusSpan--unactivated"
                      : "mapinfo__mapStatusSpan--draft"
                  }`}
                  id="span-mapStatus"
                >
                  {mapDetails.metadata.isPublished
                    ? "Published"
                    : mapState.hasAnchors
                    ? mapState.isAssociated
                      ? "Draft"
                      : "Unactivated"
                    : "Draft"}
                </div>
                <div>
                  <span className="mapinfo__mapStatusSpan2">
                    {mapDetails.metadata.isPublicMap === true
                      ? "Public"
                      : "Private"}
                  </span>
                  <span className="mapinfo__mapStatusSpan2">&bull;</span>
                  <span className="mapinfo__mapStatusSpan2">
                    Modified on{" "}
                    {convertToNamedDate(mapDetails.metadata.modifiedOn)}
                  </span>
                  <span className="mapinfo__mapStatusSpan2">&bull;</span>
                  <span className="mapinfo__mapStatusSpan2">
                    Created on{" "}
                    {convertToNamedDate(mapDetails.metadata.createdOn)}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              item
              xs={12}
              className="mapinfo__separator"
            >
              {mapDetails?.metadata?.anchorType === "qrAnchors" ? (
                <div className="">
                  <button
                    className="mapinfo__mactionBtn"
                    style={{ background: "none", height: "44px" }}
                    onClick={() => toggleModal("shareModal")}
                    id="button-shareMap"
                  >
                    {" "}
                    <Share2
                      size={16}
                      color="#353E5A"
                      className="mapinfo__mapIcon"
                    />{" "}
                    Share Map
                  </button>
                </div>
              ) : null}
              {
                <div className="">
                  <button
                    disabled={!mapState.isAssociated}
                    className="mapinfo__mactionBtn"
                    style={{
                      background: "none",
                      height: "44px",
                      opacity: `${!mapState.isAssociated ? "0.5" : "1"}`,
                    }}
                    onClick={() => {
                      let metadata = { ...mapDetails.metadata };
                      metadata = {
                        ...metadata,
                        isPublished: !mapState.isPublished,
                      };
                      dispatch(
                        updateUserMap({
                          mapId: mapData,
                          mapDetails: { metadata },
                        })
                      );
                    }}
                    id="button-mapState"
                  >
                    {" "}
                    <PublishIcon
                      size={16}
                      color="#353E5A"
                      className="mapinfo__mapIcon"
                    />{" "}
                    {mapDetails?.metadata?.isPublished
                      ? `Unpublish`
                      : "Publish"}
                  </button>
                </div>
              }
              {mapState.isAssociated && mapState.enableInteractive ? (
                <Link
                  to={{
                    pathname: `/interactive-maps/${mapDetails.metadata.mapCode}`,
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkTagMap"
                  style={{ paddingTop: "10px" }}
                  id="link-interactiveMap"
                >
                  <span className="mapinfo__studioInteractiveBtn">
                    Interactive Map
                  </span>
                </Link>
              ) : null}
              <Link
                to={`/editor/${mapDetails.metadata.mapCode}-${mapDetails.id}`}
                /* target="_blank"
                  rel="noopener noreferrer" */
                className={`linkTagMap ${
                  mapState.isAssociated ? "" : "linkDisabled"
                }`}
                style={{ paddingTop: "10px" }}
                id="link-openInStudio"
              >
                <span
                  className={`${
                    mapState.isAssociated
                      ? "mapinfo__studioIconBtn"
                      : "mapinfo__studioIconDisabledBtn"
                  }`}
                >
                  <img
                    src={mapState.isAssociated ? StudioIcon : StudioIconGray}
                    alt="toStudioBtn"
                  />
                  Open in Studio
                </span>
              </Link>
            </Grid>
          </Grid>
          {(() => {
            if (mapState.hasAnchors) {
              return !mapState.isAssociated ? (
                <div className="card mt-2 mb-2 ">
                  <div className="d-flex flex-row card-body">
                    <div className="me-2 mapdetails__warn"></div>
                    <div className="me-2 mapdetails__warnlogo"></div>
                    <div className="mapdetails__warntext">
                      You must activate the primary QR code in the ARway app
                      before being able to add/edit content.
                    </div>
                  </div>
                </div>
              ) : null;
            }
          })()}
          <ContentTabs
            activeTab={activeTab}
            onTabChange={(e, value) => setActiveTab(value)}
            tabItems={tabItems}
          />
        </form>
      </Grid>
      <MessageModal
        openModal={modalBools.deleteModal}
        onCloseModal={() => toggleModal("deleteModal")}
        onSuccessModal={handleDelete}
        modalHeader={`Delete Map: ${mapState.mapName}`}
        modalText="Are you sure you want to delete your map?"
        modalSubText="This cannot be undone!"
        textSuccess="Delete"
        textCancel="Cancel"
      />
      <MessageModal
        openModal={modalBools.replaceFloorplanModal}
        onCloseModal={() => toggleModal("replaceFloorplanModal")}
        onSuccessModal={handleReplaceFloorplan}
        modalHeader={`Replace Floor plan?`}
        modalText="Do you want to replace this floor plan? Any floor plans that have been aligned will have to be re-aligned once replaced."
        modalSubText="This cannot be undone!"
        textSuccess="Replace"
        textCancel="Cancel"
      />
      <CustomModal
        openModal={modalBools.shareModal}
        onCloseModal={() => toggleModal("shareModal")}
      >
        <Box className="mapinfo__miModalBody">
          <h4 className="mapinfo__miModalHeader">Share Your Map</h4>
          <div className="mapinfo__miModalSubCont">
            <span className="mapinfo__miModalSubHeader">
              {getQRURL(
                mapDetails?.metadata?.anchors?.find(
                  (anc) => anc.anchorIndex === "primary"
                )?.id
              )}
            </span>
            <button
              className={`mapinfo__miModalBtn ${
                helperBools.copied ? "mapinfo__miModalBtn--copied" : ""
              }`}
              onClick={() => {
                navigator.clipboard.writeText(
                  getQRURL(
                    mapDetails?.metadata?.anchors?.find(
                      (anc) => anc.anchorIndex === "primary"
                    )?.id
                  )
                );
                setHelperBools((helpers) => ({ ...helpers, copied: true }));
              }}
              id="button-copyMap"
              disabled={helperBools.copied}
            >
              <Copy
                size={18}
                color="#ffffff"
                className="mapinfo__miModalBtn--idIcon"
              />
              {helperBools.copied ? "Link Copied" : "Copy Link"}
            </button>
          </div>
        </Box>
      </CustomModal>

      <CustomModal
        openModal={modalBools.occlusionModal}
        onCloseModal={() => toggleModal("occlusionModal")}
      >
        <Box className="mapinfo__miModalBody">
          <span className="mapinfo__miModalSubHeader mapinfo__miModalSubHeader--alignCenter">
            Enabling occlusion will show content behind real world objects
            without breaking immersion. Disabling occlusion will show content in
            front of real world objects at all times.
          </span>
          <div className="mapinfo__miModalInfoRow">
            <div className="mapinfo__miModalInfoCol">
              <img src={OccImgOn} alt="occ_img_on" draggable="false" />
              <span className="mapinfo__miModalInfoCol--text">
                Occlusion On
              </span>
            </div>
            <div className="mapinfo__miModalInfoCol">
              <img src={OccImgOff} alt="occ_img_off" draggable="false" />
              <span className="mapinfo__miModalInfoCol--text">
                Occlusion Off
              </span>
            </div>
          </div>
        </Box>
      </CustomModal>
      <CustomModal
        openModal={modalBools.IAPModal}
        onCloseModal={() => toggleModal("IAPModal")}
      >
        <Box className="mapinfo__miModalBody">
          <span className="mapinfo__miModalSubHeader mapinfo__miModalSubHeader--alignCenter">
            Default image access points are recommended for locations with one
            entrance. Turning this on will direct visitors to a specific access
            point for map entry.
          </span>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default MyMaps;
