import * as THREE from "three";

import EventEmitter from "./utils/EventEmitter";
import Resizer from "./utils/Resizer";
import Time from "./utils/Time";
import Camera from "./utils/Camera";
import Renderer from "./utils/Renderer";
import ObjectsLoader from "./utils/ObjectLoader";
import WorldMain from "./components/World.Main";
import KeyEvents from "./utils/KeyEvents";

import Navigation from "./threeUtils/Navigation/Navigation";
import { getObjectByUserDataProperty } from "./threeUtils/TransformConversions";
import AsyncObjectLoader from "./utils/AsyncObjectLoader";
import WorldEnvironment from "./components/World.Environment";
import { has } from "lodash";

let instance = null;

export default class InteractiveExperience extends EventEmitter {
    constructor(
        mapDetails,
        mapCode,
        sceneName,
        canvas,
        callbacks,
        labelsElement
    ) {
        super();

        if (instance) return instance;

        instance = this;

        this.mapData = mapDetails;
        this.mapCode = mapCode;
        // this.mapId = mapId;
        this.callbacks = callbacks;

        this.canvas = canvas;
        this.sizes = new Resizer();
        this.time = new Time();
        this.resources = new ObjectsLoader();
        this.labelsElement = labelsElement;
        this.keyEvents = new KeyEvents();

        this.scene = new THREE.Scene();
        this.scene.name = sceneName;
        this.renderer = new Renderer();
        this.camera = new Camera();
        this.world = new WorldMain();
        this.environment = new WorldEnvironment(this.scene);

        this.navigationHelper = new Navigation();

        this.jsonLocationPins = [];
        this.jsonConnectorPins = [];

        // venue related data
        this.isVenue = false;
        this.buildingId = null;
        this.venueDetails = null;
        this.activeMap = null;
        this.sceneObjectData = {};
        this.loadedScenes = [];

        this.selectedObject = null;
        this.previousSelected = null;
        this.destinationPin = null;

        this.floorY = undefined;

        this.isReady = false;
        this.loadedExtraResources = false;

        this.uiObjects = [];

        // navigation data
        this.activeNavigationData = {
            sourcePin: null,
            destinationPin: null,
            path: null,
            isMultiNavigation: false,
            multiPath: {
                totalSteps: 1,
                currentStep: 0,
                activeDirection: null,
                directions: [],
            },
        };
        this.navigationTracking = false;

        this.sizes.on("resize", this.resize);
        this.time.on("tick", this.update);
        this.resources.on("ready", this.makeHTMLVisible);
        // this.keyEvents.on("keydownEvent", this.onKeydownEvent);

        this.on(
            "searchDirectionBetweenPins",
            this.onSearchDirectionBetweenPins
        );
        this.on("drawLineBetweenPins", this.drawPathBetweenPins);
        this.on(
            "setMultiLevelNavigationData",
            this.onSetMultiLevelNavigationData
        );
        this.on("locationPinSelected", this.onLocationPinSelected);
        this.on("updateMapVisibility", this.onUpdateMapVisibility);
        this.on("navigationPreview", this.onNavigationPreview);
    }

    select = (object) => {
        if (object !== null && this.selectedObject === object) return;

        this.previousSelected = this.selectedObject;
        this.selectedObject = object;

        this.trigger("objectSelected", [object]);
    };

    deselect = () => {
        this.select(null);
    };

    addObjects = (object) => {
        // console.log("OBJECT", object);
        if (object) {
            this.scene.add(object);
            this.trigger("objectAdded", [object]);
        }
    };

    removeObject = (object) => {
        if (object?.parent === null) return;

        this.scene.remove(object);

        this.trigger("objectRemoved", [object]);
    };

    initResourceLoading = (mapDetails, buildingId) => {
        if (!this.isReady && mapDetails) {
            console.log("REsource", mapDetails.metadata);
            this.mapData = mapDetails;
            this.mapId = this.activeMap = mapDetails.metadata.mapId;
            this.mapCode = mapDetails.metadata.mapCode;
            this.buildingId = buildingId;
            this.isVenue = buildingId ? true : false;
            this.scene.name = this.mapId;
            this.loadedScenes.push(this.mapId);

            this.sceneObjectData[this.mapId] = {
                metadata: mapDetails.metadata,
                scene: this.scene,
                isLoaded: true,
                loading: false,
            };

            this.trigger("updateLevelsVisibility", [this.isVenue]);

            const obj = this.getMapContent(mapDetails.metadata);
            this.resources.initiateLoading(obj);
        }
    };

    loadExtraResources = (locationData) => {
        if (!this.loadedExtraResources && !this.isReady) {
            const pins = this.getMapContent(null, locationData);
            pins?.locationPins?.length &&
                this.resources.setExtraResources(
                    pins.locationPins,
                    "locationPins"
                );

            pins?.connectorPins?.length &&
                this.resources.setExtraResources(
                    pins.connectorPins,
                    "connectorPins"
                );

            if (has(this.sceneObjectData, this.mapId)) {
                this.sceneObjectData[this.mapId].pins = pins.locationPins || [];
                this.sceneObjectData[this.mapId].connectors =
                    pins.connectorPins || [];
            }
        }
    };

    setLocationPins = (locationPins) => {
        if (locationPins) {
            this.navigationPins = locationPins;
            this.jsonLocationPins =
                locationPins.filter((pin) => pin.pinType !== "connector") || [];
        }
    };

    getMapContent = (metadata, pins) => {
        let mapContent = {};
        if (metadata && metadata.floors) {
            let floors = [
                {
                    id: "metadata_floorplans",
                    floorplans: metadata.floors,
                },
            ];
            mapContent = { ...mapContent, floors };
        }

        if (!metadata && pins) {
            if (pins.length) {
                let locationPins = pins.filter(
                    (pin) => pin.pinType !== "connector"
                );
                mapContent = { ...mapContent, locationPins };
            } else {
                this.loadedExtraResources = true;
                mapContent = { ...mapContent, locationPins: [] };
            }
        }

        if (!metadata && pins) {
            if (pins.length) {
                let connectorPins = pins.filter(
                    (pin) => pin.pinType === "connector"
                );
                mapContent = { ...mapContent, connectorPins };
            } else {
                this.loadedExtraResources = true;
                mapContent = { ...mapContent, connectorPins: [] };
            }
        }

        return { ...mapContent };
    };

    resize = () => {
        this.camera.resize();
        this.renderer.resize();
    };

    update = () => {
        this.camera.update();
        this.renderer.update();

        for (const uiObj of this.uiObjects) {
            if (uiObj) {
                if (this.isVenue && uiObj.pinProps.mapId !== this.activeMap) {
                    uiObj?.uiCont?.setDisplay("none");
                    continue;
                }

                uiObj.updateScaleOfMesh && uiObj.updateScaleOfMesh();
                uiObj.updateScaleOfText && uiObj.updateScaleOfText();
            }
        }
    };

    makeHTMLVisible = () => {
        if (this.loadedExtraResources) {
            console.log("READY");
            this.isReady = true;

            this.floorY !== undefined &&
                this.trigger("repositionYOfObjects", [this.floorY]);

            this.callbacks.loadingComplete();
        }
    };

    setVenueDetails = (venueDetails) => {
        if (venueDetails) {
            this.venueDetails = venueDetails;
        }
    };

    loadVenueMaps = async (mapsData) => {
        if (mapsData) {
            if (mapsData.length) {
                mapsData.forEach((map) => {
                    if (
                        map.metadata.mapId !== this.mapId &&
                        !this.loadedScenes.includes(map.metadata.mapId)
                    ) {
                        this.sceneObjectData[map.metadata.mapId] = {
                            metadata: map.metadata,
                            pins:
                                map.pins?.filter(
                                    (pin) => pin.pinType !== "connector"
                                ) || [],
                            connectors:
                                map.pins?.filter(
                                    (pin) => pin.pinType === "connector"
                                ) || [],
                            scene:
                                map.metadata.mapId === this.mapId
                                    ? this.scene
                                    : this.getNewScene(map.metadata.mapId),
                            isLoaded:
                                map.metadata.mapId === this.mapId
                                    ? true
                                    : false,
                            loading: false,
                        };
                    }
                });
            }
            this.trigger("venueDataFetched", [mapsData]);
            console.log("SCENE OBJECT DATA", this.sceneObjectData);
            this.setUpScenes();
        }
    };

    getNewScene = (sceneName) => {
        let scene = new THREE.Scene();
        scene.name = sceneName;
        new WorldEnvironment(scene);
        return scene;
    };

    setUpScenes = () => {
        for (const scene in this.sceneObjectData) {
            if (
                !this.sceneObjectData[scene].isLoaded &&
                !this.sceneObjectData[scene].loading
            ) {
                new AsyncObjectLoader(
                    this.sceneObjectData[scene].scene,
                    this.sceneObjectData[scene].metadata,
                    this.sceneObjectData[scene].pins,
                    this.sceneObjectData[scene].connectors
                );
                this.sceneObjectData[scene].loading = true;
            }
        }
    };

    invokeARNavigation = (pin) => {
        if (pin) {
            const domainLink = `https://${
                this.sceneObjectData[this.activeMap].metadata.domain.length
                    ? this.sceneObjectData[this.activeMap].metadata.domain
                    : "anchor.arway.ai/map/"
            }${this.activeMap}/${pin.id}`;
            this.callbacks.onToggleARModal(domainLink);
        }
    };

    // Callbacks
    onUpdateMapVisibility = (mapId) => {
        if (mapId) {
            this.activeMap = mapId;
            this.scene = this.sceneObjectData[mapId].scene;
            this.trigger("renderUpdatedScene", [mapId]);
        }
    };

    onNavigationPreview = (isPreview) => {
        this.navigationTracking = isPreview;
    };

    onLocationPinSelected = (locationPin) => {
        if (locationPin) {
            this.selectedObject = getObjectByUserDataProperty(
                this.scene,
                "id",
                locationPin.id
            );
        } else {
            this.selectedObject = null;
        }
    };

    onSearchDirectionBetweenPins = (sourcePin, destinationPin) => {
        if (
            this.callbacks.onRequestPathBetweenPins &&
            sourcePin &&
            destinationPin
        ) {
            this.activeNavigationData.sourcePin = sourcePin;
            this.activeNavigationData.destinationPin = destinationPin;

            this.callbacks.onRequestPathBetweenPins(
                destinationPin.id,
                sourcePin.position,
                this.activeMap
            );
        }
    };

    onPathBetweenPinsResponse = (path) => {
        if (path) {
            this.activeNavigationData.path = path;
            this.trigger("onPathBetweenPinsResponse", [path]);
        }
    };

    drawPathBetweenPins = (path, isMulti = false) => {
        if (path && !isMulti) {
            this.trigger("navigationPreview", [true]);
            this.activeNavigationData.isMultiNavigation = false;
            this.activeNavigationData.multiPath = {
                totalSteps: 1,
                currentStep: 0,
                activeDirection: null,
                directions: [],
            };
            this.navigationHelper.setWaypoints(path);
        }
    };

    onSetMultiLevelNavigationData = (multiData, index) => {
        this.trigger("navigationPreview", [true]);
        this.activeNavigationData.isMultiNavigation = true;
        this.activeNavigationData.multiPath = {
            totalSteps: multiData.length,
            currentStep: index,
            activeDirection: multiData[index],
            directions: multiData,
        };
        this.navigationHelper.setMultiWaypoints(multiData.paths);
        this.trigger("updateConnectingPins", [multiData.paths]);
        this.trigger("setMultiNavigationButtons", [multiData.directions]);
    };

    clearAndReset = () => {
        this.trigger("clearAndReset");

        while (this.scene?.children.length) {
            this.scene.remove(this.scene.children[0]);
        }
        delete this;
        instance = null;
    };
}
