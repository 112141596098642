import * as THREE from "three";
import { connectorPinIconSvgs } from "./ConnectorIcons";

const GenerateSvgString = (type, color) => {
    let svgString = connectorPinIconSvgs[type];

    if (svgString) {
        return svgString.replaceAll("CUSTOM_COLOR", `${color}`);
    } else {
        svgString = connectorPinIconSvgs.elevator;
        return svgString.replaceAll("CUSTOM_COLOR", `${color}`);
    }
};

const getConnectorCanvasTexture = (
    iconName,
    color,
    texSize,
) => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = texSize;
        canvas.height = texSize;

        // Create SVG Image
        const svgImage = new Image();
        const svgString = GenerateSvgString(iconName, color);

        if (!svgString) reject("type not found");

        const svgBlob = new Blob([svgString], {
            type: "image/svg+xml;charset=utf-8",
        });
        const url = URL.createObjectURL(svgBlob);

        // load url
        svgImage.src = url;

        // img loaded successfully
        svgImage.onload = () => {
            ctx.drawImage(svgImage, 0, 0, texSize, texSize);

            // Use the canvas as the source for a texture
            const texture = new THREE.CanvasTexture(canvas);
            texture.minFilter = THREE.LinearFilter;
            texture.wrapS = THREE.ClampToEdgeWrapping;
            texture.wrapT = THREE.ClampToEdgeWrapping;

            // Destroy the canvas
            ctx.restore();
            canvas.remove();

            // Return Texture
            resolve(texture);
        };

        // Error Handling
        svgImage.onerror = () => reject("svg not supported");
    });
};

export {
    getConnectorCanvasTexture,
};
