import * as THREE from "three";
import EditorExperience from "../../InteractiveExperience";
import EventEmitter from "../../utils/EventEmitter";

import {
    fromPosObjectToVec3Pos,
    fromQuaternionObjectToQuaternion,
    fromScaleObjectToVec3Scale,
} from "../TransformConversions";

export default class Floorplan extends EventEmitter {
    constructor(floorData, type) {
        super();

        this.editor = new EditorExperience();

        this.position = floorData.position
            ? fromPosObjectToVec3Pos(floorData.position)
            : new THREE.Vector3(0, 0, 0);
        this.rotation = floorData.rotation
            ? fromQuaternionObjectToQuaternion(floorData.rotation)
            : new THREE.Quaternion(-0.7071068, 0, 0, 0.7071068);
        this.scale = floorData.scale
            ? fromScaleObjectToVec3Scale(floorData.scale)
            : new THREE.Vector3(1, 1, 1);
        this.imageMesh = null;

        this.type = type || "floorplan";
        this.name = floorData.name ? floorData.name.substring(0, 20) : "";
        this.id = floorData.id;
        this.assetLink = floorData.link;
        this.visible = "visible" in floorData ? floorData.visible : true;
        this.alignmentStatus =
            "alignmentStatus" in floorData ? floorData.alignmentStatus : false;

        this.pegs = "pegs" in floorData ? floorData.pegs : [];

        this.texLoader = new THREE.TextureLoader();

        this.createLoaderTex();
        this.isLoading = true;
        this.setupObject();
    }

    setupObject = () => {
        this.imgMat = new THREE.MeshBasicMaterial({
            map: this.loaderTexture,
            side: THREE.DoubleSide,
            transparent: true,
        });

        let imgTex = this.texLoader.load(this.assetLink, (tex) => {
            // on load
            this.imgMat.map = tex;
            this.imgMat.transparent = false;
            this.imgMat.needsUpdate = true;
            this.isLoading = false;

            clearInterval(this.loadIntv);
        });
        imgTex.minFilter = imgTex.magFilter = THREE.LinearFilter;
        imgTex.colorSpace = THREE.SRGBColorSpace;
        imgTex.anisotropy = 4;
        if (this.alignmentStatus) {
            imgTex.flipY = false;
            imgTex.center.set(0.5, 0.5);
            imgTex.repeat.set(1, 1);
        }

        let imgGeo = new THREE.PlaneGeometry(10, 10, 32, 32);

        this.imgMesh = new THREE.Mesh(imgGeo, this.imgMat);
        if (this.alignmentStatus) {
            this.imgMesh.position.set(5, 0, 5);
            this.imgMesh.rotation.x = THREE.MathUtils.degToRad(90);
        }
        this.imgMesh.name = this.name.substring(0, 20);

        // new wrapper mesh
        this.imgWrapper = new THREE.Group();
        this.imgWrapper.add(this.imgMesh);
        this.imgWrapper.quaternion.copy(this.rotation);
        this.imgWrapper.position.copy(this.position);
        this.imgWrapper.scale.copy(this.scale);

        this.imageMesh = new THREE.Group();
        this.imageMesh.add(this.imgWrapper);
        this.imageMesh.name = this.name.substring(0, 20);
        this.imageMesh.renderOrder = 999;
        this.imageMesh.userData["id"] = this.id;
        this.imageMesh.userData["link"] = this.assetLink;
        this.imageMesh.userData["visible"] = this.visible;
        this.imageMesh.userData["alignmentStatus"] = this.alignmentStatus;
        this.imageMesh.userData["type"] = this.type;
        this.imageMesh.visible = this.visible;

        let pegsPosY = [];

        this.pegs.forEach((peg) => {
            let fPeg = fromPosObjectToVec3Pos(peg.position);
            pegsPosY.push(fPeg.y);
        });

        let bFlag = pegsPosY.length ? true : false;

        if (bFlag && this.imageMesh.position.y === -1) {
            // when -1, it's the values from alignment response)
            let min = Math.min(...pegsPosY);
            this.imageMesh.position.y = min;
            this.editor.floorY = min;
        } else {
            this.editor.floorY = this.imageMesh.position.y;
        }

        const box = new THREE.Box3().setFromObject(this.imageMesh);
        this.editor.floor = this.imageMesh;
        this.editor.floorSize = box.getSize(new THREE.Vector3());
        this.editor.floorMax = Math.max(
            this.editor.floorSize.x,
            this.editor.floorSize.z
        );
    };

    createLoaderTex = () => {
        var canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d");
        canvas.width = 512;
        canvas.height = 512;

        const scope = this;

        // load image and draw it on canvas
        const img = document.createElement("img");
        img.onload = () => {
            let rot = 0;
            this.loadIntv = setInterval(() => {
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                ctx.save();
                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate((rot * Math.PI) / 180);
                ctx.drawImage(img, -img.width / 2, -img.width / 2);
                ctx.restore();

                if (rot >= 360) rot = 0;
                else rot += 15;

                scope.isLoading &&
                    scope.imgMesh &&
                    scope.imgMesh.material &&
                    (scope.imgMesh.material.map.needsUpdate = true);
            }, 100);
        };
        img.src = "/static/textures/loader.png";
        this.loaderTexture = new THREE.CanvasTexture(canvas);
    };
}
