import {
	Mesh,
	Object3D,
	PlaneGeometry,
	MeshBasicMaterial,
	DoubleSide,
	Vector3,
	Matrix4,
	Quaternion,
	Raycaster,
	LineBasicMaterial,
	Line,
	BufferGeometry,
	CircleGeometry,
	TorusGeometry,
	MathUtils,
} from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';

const _raycaster = new Raycaster();
const _dracoLoader = new DRACOLoader();
_dracoLoader.setDecoderPath('/draco/');

const _gltfLoader = new GLTFLoader();
_gltfLoader.setDRACOLoader(_dracoLoader);

const _tempVector = new Vector3();
const _tempVector2 = new Vector3();
const _tempQuaternion = new Quaternion();
const _unit = {
	X: new Vector3(1, 0, 0),
	Y: new Vector3(0, 1, 0),
	Z: new Vector3(0, 0, 1)
};

const _changeEvent = { type: 'change' };
const _mouseDownEvent = { type: 'mouseDown', mode: null };
const _mouseUpEvent = { type: 'mouseUp', mode: null };
const _objectChangeEvent = { type: 'objectChange' };


class TransformControls extends Object3D {
	constructor(camera, domElement) {
		super();

		if (domElement === undefined) {
			console.warn('THREE.TransformControls: The second parameter "domElement" is now mandatory.');
			domElement = document;
		}

		this.isTransformControls = true;
		this.gizmoPosition = null;

		this.visible = false;
		this.domElement = domElement;
		this.domElement.style.touchAction = 'none'; // disable touch scroll

		const _gizmo = new TransformControlsGizmo();
		this._gizmo = _gizmo;
		this.add(_gizmo);

		const _plane = new TransformControlsPlane();
		this._plane = _plane;
		this.add(_plane);

		const points = [];
		points.push(new Vector3(0, -100, 0));
		points.push(new Vector3(0, 100, 0));

		const lineGeometry = new BufferGeometry().setFromPoints(points);
		const lineMaterial = new LineBasicMaterial();

		this.referenceLine = new Line(lineGeometry, lineMaterial);
		this.referenceLine.visible = false;

		this.add(this.referenceLine);

		this.startRotAngle = 0;
		this.startInterSection = new Vector3(0, 0, 0);

		const scope = this;

		// Defined getter, setter and store for a property
		function defineProperty(propName, defaultValue) {

			let propValue = defaultValue;

			Object.defineProperty(scope, propName, {

				get: function () {

					return propValue !== undefined ? propValue : defaultValue;

				},

				set: function (value) {

					if (propValue !== value) {

						propValue = value;
						_plane[propName] = value;
						_gizmo[propName] = value;

						scope.dispatchEvent({ type: propName + '-changed', value: value });
						scope.dispatchEvent(_changeEvent);

					}

				}

			});

			scope[propName] = defaultValue;
			_plane[propName] = defaultValue;
			_gizmo[propName] = defaultValue;

		}

		// Define properties with getters/setter
		// Setting the defined property will automatically trigger change event
		// Defined properties are passed down to gizmo and plane

		defineProperty('camera', camera);
		defineProperty('object', undefined);
		defineProperty('enabled', true);
		defineProperty('axis', null);
		defineProperty('mode', 'translate');
		defineProperty('translationSnap', null);
		defineProperty('rotationSnap', null);
		defineProperty('scaleSnap', null);
		defineProperty('space', 'world');
		defineProperty('size', 1);
		defineProperty('dragging', false);
		defineProperty('showX', true);
		defineProperty('showY', true);
		defineProperty('showZ', true);

		// Reusable utility variables

		const worldPosition = new Vector3();
		const worldPositionStart = new Vector3();
		const worldQuaternion = new Quaternion();
		const worldQuaternionStart = new Quaternion();
		const cameraPosition = new Vector3();
		const cameraQuaternion = new Quaternion();
		const pointStart = new Vector3();
		const pointEnd = new Vector3();
		const rotationAxis = new Vector3();
		const rotationAngle = 0;
		const eye = new Vector3();

		// TODO: remove properties unused in plane and gizmo

		defineProperty('worldPosition', worldPosition);
		defineProperty('worldPositionStart', worldPositionStart);
		defineProperty('worldQuaternion', worldQuaternion);
		defineProperty('worldQuaternionStart', worldQuaternionStart);
		defineProperty('cameraPosition', cameraPosition);
		defineProperty('cameraQuaternion', cameraQuaternion);
		defineProperty('pointStart', pointStart);
		defineProperty('pointEnd', pointEnd);
		defineProperty('rotationAxis', rotationAxis);
		defineProperty('rotationAngle', rotationAngle);
		defineProperty('eye', eye);

		this._offset = new Vector3();
		this._startNorm = new Vector3();
		this._endNorm = new Vector3();
		this._cameraScale = new Vector3();

		this._parentPosition = new Vector3();
		this._parentQuaternion = new Quaternion();
		this._parentQuaternionInv = new Quaternion();
		this._parentScale = new Vector3();

		this._worldScaleStart = new Vector3();
		this._worldQuaternionInv = new Quaternion();
		this._worldScale = new Vector3();

		this._positionStart = new Vector3();
		this._quaternionStart = new Quaternion();
		this._scaleStart = new Vector3();

		this._getPointer = getPointer.bind(this);
		this._onPointerDown = onPointerDown.bind(this);
		this._onPointerHover = onPointerHover.bind(this);
		this._onPointerMove = onPointerMove.bind(this);
		this._onPointerUp = onPointerUp.bind(this);

		this.domElement.addEventListener('pointerdown', this._onPointerDown);
		this.domElement.addEventListener('pointermove', this._onPointerHover);
		this.domElement.addEventListener('pointerup', this._onPointerUp);

	}

	initRotationHelper() {
		const geometry = new TorusGeometry(1, 0.005, 16, 100);
		const material = new MeshBasicMaterial({ color: 'black' });
		this.rotationHelperMesh = new Mesh(geometry, material);

		const circGeometry = new CircleGeometry(1, 64);
		const circMat = new MeshBasicMaterial({
			color: 'white',
			transparent: true,
			side: DoubleSide,
			opacity: 0.2,
			depthTest: false
		});
		const circle = new Mesh(circGeometry, circMat);

		const angleCircGeometry = new CircleGeometry(1, 64, 0, MathUtils.degToRad(1));
		const angleCircMat = new MeshBasicMaterial({
			color: 'red',
			depthTest: false,
			side: DoubleSide,
			transparent: true,
			opacity: 0.4,

		});
		this.angleCircle = new Mesh(angleCircGeometry, angleCircMat);

		const planeGeometry = new PlaneGeometry(100000, 100000, 2, 2);
		const planeMaterial = new MeshBasicMaterial({
			visible: false,
			wireframe: false,
			side: DoubleSide,
			transparent: true,
			opacity: 0.1,
			toneMapped: false,
		});
		this.rotationPlane = new Mesh(planeGeometry, planeMaterial);

		this.rotationHelperMesh.add(circle, this.angleCircle);

		this.rotationHelperMesh.visible = false;

		this.add(this.rotationPlane, this.rotationHelperMesh);

		// Create A dom element to display the angle
		this.angleText = document.createElement('div');
		this.angleText.id = 'angleText';
		this.angleText.style.position = 'absolute';
		this.angleText.style.color = 'white';
		this.angleText.style.zIndex = 1000;
		this.angleText.style.display = 'none';
		this.angleText.style.top = "0";
		this.angleText.style.padding = '6px 12px';
		this.angleText.style.border = '2px solid rgb(105, 114, 143)';
		this.angleText.style.borderRadius = '30px';
		this.angleText.style.fontFamily = 'Poppins';
		this.angleText.style.fontSize = '13px';
		this.angleText.style.fontWeight = '500';
		this.angleText.style.lineHeight = '12px';
		this.angleText.style.letterSpacing = '0.5px';
		this.angleText.style.textAlign = 'center';
		this.angleText.style.background = 'rgb(53, 62, 90)';
		this.angleText.style.boxShadow = '0 0 10px rgba(0, 0, 0, 0.2)';
		this.angleText.style.pointerEvents = 'none';

		document.body.appendChild(this.angleText);
	}

	angleBetweenVectors(a, b, c) {
		// Check if B is at the origin
		if (a.x === 0 && a.y === 0 && a.z === 0) {
			// Calculate and return the angle between B and C directly
			let dotProduct = b.dot(c);
			let bMagnitude = b.length();
			let cMagnitude = c.length();
			const angle = Math.acos(dotProduct / (bMagnitude * cMagnitude));
			return angle;
		}

		if (b.x === 0 && b.y === 0 && b.z === 0) {
			// Calculate and return the angle between A and C directly
			let dotProduct = a.dot(c);
			let aMagnitude = a.length();
			let cMagnitude = c.length();
			const angle = Math.acos(dotProduct / (aMagnitude * cMagnitude));
			return angle;
		}

		if (c.x === 0 && c.y === 0 && c.z === 0) {
			// Calculate and return the angle between A and B directly
			let dotProduct = a.dot(b);
			let aMagnitude = a.length();
			let bMagnitude = b.length();
			const angle = Math.acos(dotProduct / (aMagnitude * bMagnitude));
			return angle;
		}

		// Create vectors BA and BC from points
		let ba = a.clone().sub(b);
		let bc = c.clone().sub(b);

		// Calculate the dot product
		let dotProduct = ba.dot(bc);

		// Calculate the magnitudes of BA and BC
		let baMagnitude = ba.length();
		let bcMagnitude = bc.length();

		// Calculate the angle (in radians)
		let angle = Math.acos(dotProduct / (baMagnitude * bcMagnitude));

		// Calculate cross product of the two vectors
		let crossProduct = new Vector3().crossVectors(ba, bc);

		// Use dot product with a reference vector to determine if angle is greater than 180 degrees
		if (crossProduct.dot(b) < 0) {
			angle = 2 * Math.PI - angle;
		}

		// If you want the angle in degrees, you can convert it:
		// let angleInDegrees = angle * (180 / Math.PI);

		return angle;
	}

	// updateMatrixWorld updates key transformation variables
	updateMatrixWorld(force) {

		if (this.object !== undefined) {


			this.object.updateMatrixWorld();

			if (this.object.parent === null) {

				console.error('TransformControls: The attached 3D object must be a part of the scene graph.');

			} else {

				this.object.parent.matrixWorld.decompose(this._parentPosition, this._parentQuaternion, this._parentScale);

			}

			this.object.matrixWorld.decompose(this.worldPosition, this.worldQuaternion, this._worldScale);

			this._parentQuaternionInv.copy(this._parentQuaternion).invert();
			this._worldQuaternionInv.copy(this.worldQuaternion).invert();

		}

		this.camera.updateMatrixWorld();
		this.camera.matrixWorld.decompose(this.cameraPosition, this.cameraQuaternion, this._cameraScale);

		if (this.camera.isOrthographicCamera) {

			this.camera.getWorldDirection(this.eye).negate();

		} else {

			this.eye.copy(this.cameraPosition).sub(this.worldPosition).normalize();

		}


		super.updateMatrixWorld(force);

	}

	pointerHover(pointer) {
		if (this.object === undefined || this.dragging === true) return;

		if (pointer !== null) _raycaster.setFromCamera(pointer, this.camera);

		const intersect = intersectObjectWithRay(this._gizmo.gizmo[this.mode], _raycaster);

		if (intersect) {
			this.axis = intersect.object.name;

			if (this.mode === "rotate") {
				const axisToRotateIn = {
					"X": "y",
					"Y": "x",
					"Z": "z"
				}

				this.rotationPlane.position.copy(this._gizmo.gizmo['rotate'].position);
				this.rotationPlane.rotation.set(0, 0, 0);
				this.rotationPlane.rotation[axisToRotateIn[this.axis]] = Math.PI / 2;
			}

			if (this.mode === "translate") this.computeReferenceLine(intersect.object);
		} else {
			this.axis = null;
			if (this.mode === "translate") this.computeReferenceLine(null);
		}
	}

	pointerDown(pointer) {

		if (this.object === undefined || this.dragging === true || (pointer != null && pointer.button !== 0)) return;

		if (this.axis !== null) {

			if (pointer !== null) _raycaster.setFromCamera(pointer, this.camera);

			if (this.mode === "rotate") {
				const axisToRotateIn = {
					"X": "y",
					"Y": "x",
					"Z": "z"
				}

				const axisColors = {
					"X": "#F54953",
					"Y": "#24DFAD",
					"Z": "#389EFD",
				}

				this.rotationHelperMesh.position.copy(this._gizmo.gizmo['rotate'].position);
				this.rotationHelperMesh.rotation[axisToRotateIn[this.axis]] = -Math.PI / 2;
				this.rotationHelperMesh.scale.copy(this._gizmo.gizmo['rotate'].scale);
				this.rotationHelperMesh.material.color.set(axisColors[this.axis]);
				this._gizmo.gizmo['rotate'].visible = false;
				this.rotationHelperMesh.visible = true;


				const planeIntersect = intersectObjectWithRay(this.rotationPlane, _raycaster, true);


				const startVectorAxis = {
					"X": new Vector3(this._gizmo.gizmo['rotate'].position.x, this._gizmo.gizmo['rotate'].position.y, this._gizmo.gizmo['rotate'].position.z + 1),
					"Y": new Vector3(this._gizmo.gizmo['rotate'].position.x + 1, this._gizmo.gizmo['rotate'].position.y, this._gizmo.gizmo['rotate'].position.z),
					"Z": new Vector3(this._gizmo.gizmo['rotate'].position.x, this._gizmo.gizmo['rotate'].position.y - 1, this._gizmo.gizmo['rotate'].position.z),
				}

				if (planeIntersect) {
					const angle = this.angleBetweenVectors(startVectorAxis[this.axis], this._gizmo.gizmo['rotate'].position, planeIntersect.point);

					this.startRotAngle = angle;
					this.startInterSection.copy(planeIntersect.point);

					this.angleCircle.geometry.dispose();
					this.angleCircle.geometry = new CircleGeometry(1, 64, this.startRotAngle, MathUtils.degToRad(0.5));
					this.angleCircle.material.color.set(axisColors[this.axis]);

					this.object.updateMatrixWorld();
					this.object.parent.updateMatrixWorld();

					this._positionStart.copy(this.object.position);
					this._quaternionStart.copy(this.object.quaternion);
					this._scaleStart.copy(this.object.scale);

					this.object.matrixWorld.decompose(this.worldPositionStart, this.worldQuaternionStart, this._worldScaleStart);

					this.pointStart.copy(planeIntersect.point).sub(this.worldPositionStart);

					this.angleText.textContent = "0 \u00B0";

					// convert the normalized position to CSS coordinates
					const x = (pointer.x * .5 + .5) * this.domElement.clientWidth;
					const y = (pointer.y * - .5 + .5) * this.domElement.clientHeight;


					// move the elem to that position
					this.angleText.style.transform = `translate(-50%, -50%) translate(${x}px,${y}px)`;
					this.angleText.style.display = "block";

				}

			} else {

				const planeIntersect = intersectObjectWithRay(this._plane, _raycaster, true);

				if (planeIntersect) {

					this.object.updateMatrixWorld();
					this.object.parent.updateMatrixWorld();

					this._positionStart.copy(this.object.position);
					this._quaternionStart.copy(this.object.quaternion);
					this._scaleStart.copy(this.object.scale);

					this.object.matrixWorld.decompose(this.worldPositionStart, this.worldQuaternionStart, this._worldScaleStart);

					this.pointStart.copy(planeIntersect.point).sub(this.worldPositionStart);

				}


			}

			this.dragging = true;
			_mouseDownEvent.mode = this.mode;
			this.dispatchEvent(_mouseDownEvent);

		}

	}

	pointerMove(pointer) {

		const axis = this.axis;
		const mode = this.mode;
		const object = this.object;
		let space = this.space;

		if (mode === 'scale') {

			space = 'local';

		} else if (axis === 'E' || axis === 'XYZE' || axis === 'XYZ') {

			space = 'world';

		}

		if (object === undefined || axis === null || this.dragging === false || (pointer !== null && pointer.button !== - 1)) return;

		if (pointer !== null) _raycaster.setFromCamera(pointer, this.camera);

		const planeIntersect = intersectObjectWithRay(this._plane, _raycaster, true);

		if (!planeIntersect) return;

		this.pointEnd.copy(planeIntersect.point).sub(this.worldPositionStart);

		if (mode === 'translate') {

			// Apply translate
			this._offset.copy(this.pointEnd).sub(this.pointStart);

			if (space === 'local' && axis !== 'XYZ') {

				this._offset.applyQuaternion(this._worldQuaternionInv);

			}

			if (axis.indexOf('X') === - 1) this._offset.x = 0;
			if (axis.indexOf('Y') === - 1) this._offset.y = 0;
			if (axis.indexOf('Z') === - 1) this._offset.z = 0;

			if (space === 'local' && axis !== 'XYZ') {

				this._offset.applyQuaternion(this._quaternionStart).divide(this._parentScale);

			} else {

				this._offset.applyQuaternion(this._parentQuaternionInv).divide(this._parentScale);

			}

			object.position.copy(this._offset).add(this._positionStart);

			// Apply translation snap
			if (this.translationSnap) {

				if (space === 'local') {

					object.position.applyQuaternion(_tempQuaternion.copy(this._quaternionStart).invert());

					if (axis.search('X') !== - 1) {

						object.position.x = Math.round(object.position.x / this.translationSnap) * this.translationSnap;

					}

					if (axis.search('Y') !== - 1) {

						object.position.y = Math.round(object.position.y / this.translationSnap) * this.translationSnap;

					}

					if (axis.search('Z') !== - 1) {

						object.position.z = Math.round(object.position.z / this.translationSnap) * this.translationSnap;

					}

					object.position.applyQuaternion(this._quaternionStart);

				}

				if (space === 'world') {

					if (object.parent) {

						object.position.add(_tempVector.setFromMatrixPosition(object.parent.matrixWorld));

					}

					if (axis.search('X') !== - 1) {
						object.position.x = Math.round(object.position.x / this.translationSnap) * this.translationSnap;

					}

					if (axis.search('Y') !== - 1) {

						object.position.y = Math.round(object.position.y / this.translationSnap) * this.translationSnap;

					}

					if (axis.search('Z') !== - 1) {

						object.position.z = Math.round(object.position.z / this.translationSnap) * this.translationSnap;

					}

					if (object.parent) {

						object.position.sub(_tempVector.setFromMatrixPosition(object.parent.matrixWorld));

					}

				}

			}

		} else if (mode === 'scale') {

			if (axis.search('XYZ') !== - 1) {

				let d = this.pointEnd.length() / this.pointStart.length();

				if (this.pointEnd.dot(this.pointStart) < 0) d *= - 1;

				_tempVector2.set(d, d, d);

			} else {

				_tempVector.copy(this.pointStart);
				_tempVector2.copy(this.pointEnd);

				_tempVector.applyQuaternion(this._worldQuaternionInv);
				_tempVector2.applyQuaternion(this._worldQuaternionInv);

				_tempVector2.divide(_tempVector);

				if (axis.search('X') === - 1) {

					_tempVector2.x = 1;

				}

				if (axis.search('Y') === - 1) {

					_tempVector2.y = 1;

				}

				if (axis.search('Z') === - 1) {

					_tempVector2.z = 1;

				}

			}

			// Apply scale

			object.scale.copy(this._scaleStart).multiply(_tempVector2);

			if (this.scaleSnap) {

				if (axis.search('X') !== - 1) {

					object.scale.x = Math.round(object.scale.x / this.scaleSnap) * this.scaleSnap || this.scaleSnap;

				}

				if (axis.search('Y') !== - 1) {

					object.scale.y = Math.round(object.scale.y / this.scaleSnap) * this.scaleSnap || this.scaleSnap;

				}

				if (axis.search('Z') !== - 1) {

					object.scale.z = Math.round(object.scale.z / this.scaleSnap) * this.scaleSnap || this.scaleSnap;

				}

			}

		} else if (mode === 'rotate') {

			this._offset.copy(this.pointEnd).sub(this.pointStart);

			const ROTATION_SPEED = 20 / this.worldPosition.distanceTo(_tempVector.setFromMatrixPosition(this.camera.matrixWorld));

			let _inPlaneRotation = false;

			if (axis === 'XYZE') {

				this.rotationAxis.copy(this._offset).cross(this.eye).normalize();
				this.rotationAngle = this._offset.dot(_tempVector.copy(this.rotationAxis).cross(this.eye)) * ROTATION_SPEED;

			} else if (axis === 'X' || axis === 'Y' || axis === 'Z') {

				this.rotationAxis.copy(_unit[axis]);

				_tempVector.copy(_unit[axis]);

				if (space === 'local') {

					_tempVector.applyQuaternion(this.worldQuaternion);

				}

				_tempVector.cross(this.eye);

				// When _tempVector is 0 after cross with this.eye the vectors are parallel and should use in-plane rotation logic.
				if (_tempVector.length() === 0) {

					_inPlaneRotation = true;

				} else {

					this.rotationAngle = this._offset.dot(_tempVector.normalize()) * ROTATION_SPEED;

				}


			}

			if (axis === 'E' || _inPlaneRotation) {

				this.rotationAxis.copy(this.eye);
				this.rotationAngle = this.pointEnd.angleTo(this.pointStart);

				this._startNorm.copy(this.pointStart).normalize();
				this._endNorm.copy(this.pointEnd).normalize();

				this.rotationAngle *= (this._endNorm.cross(this._startNorm).dot(this.eye) < 0 ? 1 : - 1);

			}

			// Apply rotation snap

			if (this.rotationSnap) this.rotationAngle = Math.round(this.rotationAngle / this.rotationSnap) * this.rotationSnap;

			// Apply rotate
			if (space === 'local' && axis !== 'E' && axis !== 'XYZE') {

				object.quaternion.copy(this._quaternionStart);
				object.quaternion.multiply(_tempQuaternion.setFromAxisAngle(this.rotationAxis, this.rotationAngle)).normalize();

			} else {
				const planeIntersect = intersectObjectWithRay(this.rotationPlane, _raycaster, true);

				let angle = this.angleBetweenVectors(this.startInterSection, this._gizmo.gizmo['rotate'].position, planeIntersect.point);

				this.angleCircle.geometry.dispose();
				this.angleCircle.geometry = new CircleGeometry(1, 64, this.startRotAngle, angle);

				if (this.axis === 'X') angle = -angle;

				this.rotationAxis.applyQuaternion(this._parentQuaternionInv);
				const computedQuat = new Quaternion();
				computedQuat.copy(_tempQuaternion.setFromAxisAngle(this.rotationAxis, angle));
				computedQuat.multiply(this._quaternionStart).normalize();

				this.angleText.textContent = `${Math.abs(Math.round(MathUtils.radToDeg(angle))) || 0} \u00B0`;

				this.object.quaternion.copy(computedQuat);
			}

		}

		this.dispatchEvent(_changeEvent);
		this.dispatchEvent(_objectChangeEvent);

	}

	pointerUp(pointer) {

		if (pointer !== null && pointer.button !== 0) return;

		if (this.mode === 'rotate') {
			this.rotationHelperMesh.rotation.set(0, 0, 0);
			this.rotationHelperMesh.visible = false;
			this.angleText.style.display = "none";
			// this._gizmo.gizmo['rotate'].visible = true;
		}

		if (this.dragging && (this.axis !== null)) {

			_mouseUpEvent.mode = this.mode;
			this.dispatchEvent(_mouseUpEvent);

		}

		this.dragging = false;
		this.axis = null;

	}

	computeReferenceLine(object) {
		if (!this.referenceLine) return;

		if (!object) {
			this.referenceLine.rotation.set(0, 0, 0);
			this.referenceLine.visible = false;
			this._gizmo.gizmo['translate'].children[0].material.opacity = 1;
			return;
		}

		const rotateWithRespectTo = {
			'X': 'z',
			'Y': 'y',
			'Z': 'x'
		}

		if (rotateWithRespectTo[this.axis]) {
			if (object) {
				this.referenceLine.position.copy(object.parent.position);
				this.referenceLine.rotation.set(0, 0, 0);
				this.referenceLine.rotation[rotateWithRespectTo[this.axis]] = Math.PI / 2;
				this.referenceLine.material.color.set(object.material.color);
				this.referenceLine.visible = true;
			}
		} else if (this.axis === 'XZ') {
			object.parent.children[0].material.opacity = 0.2
		}
	}

	dispose() {

		this.domElement.removeEventListener('pointerdown', this._onPointerDown);
		this.domElement.removeEventListener('pointermove', this._onPointerHover);
		this.domElement.removeEventListener('pointermove', this._onPointerMove);
		this.domElement.removeEventListener('pointerup', this._onPointerUp);

		this.traverse(function (child) {

			if (child.geometry) child.geometry.dispose();
			if (child.material) child.material.dispose();

		});

	}

	// Set current object
	attach(object) {
		this.object = object;
		this.visible = true;

		return this;
	}

	// Detach from object
	detach() {

		this.object = undefined;
		this.visible = false;
		this.axis = null;

		return this;

	}

	reset() {

		if (!this.enabled) return;

		if (this.dragging) {

			this.object.position.copy(this._positionStart);
			this.object.quaternion.copy(this._quaternionStart);
			this.object.scale.copy(this._scaleStart);

			this.dispatchEvent(_changeEvent);
			this.dispatchEvent(_objectChangeEvent);

			this.pointStart.copy(this.pointEnd);

		}

	}

	getRaycaster() {

		return _raycaster;

	}

	// TODO: deprecate

	getMode() {

		return this.mode;

	}

	setMode(mode) {

		this.mode = mode;

		if (mode === 'rotate') {
			this.initRotationHelper();
		}
	}

	setTranslationSnap(translationSnap) {

		this.translationSnap = translationSnap;

	}

	setRotationSnap(rotationSnap) {

		this.rotationSnap = rotationSnap;

	}

	setScaleSnap(scaleSnap) {

		this.scaleSnap = scaleSnap;

	}

	setSize(size) {

		this.size = size;

	}

	setSpace(space) {

		this.space = space;

	}

}

// mouse / touch event handlers

function getPointer(event) {

	if (this.domElement.ownerDocument.pointerLockElement) {

		return {
			x: 0,
			y: 0,
			button: event.button
		};

	} else {

		const rect = this.domElement.getBoundingClientRect();

		return {
			x: (event.clientX - rect.left) / rect.width * 2 - 1,
			y: - (event.clientY - rect.top) / rect.height * 2 + 1,
			button: event.button
		};

	}

}

function onPointerHover(event) {

	if (!this.enabled) return;

	switch (event.pointerType) {

		case 'mouse':
		case 'pen':
			this.pointerHover(this._getPointer(event));
			break;

		default:
			break;
	}

}

function onPointerDown(event) {

	if (!this.enabled) return;

	if (!document.pointerLockElement) {

		this.domElement.setPointerCapture(event.pointerId);

	}

	this.domElement.addEventListener('pointermove', this._onPointerMove);

	this.pointerHover(this._getPointer(event));
	this.pointerDown(this._getPointer(event));

}

function onPointerMove(event) {

	if (!this.enabled) return;

	this.pointerMove(this._getPointer(event));

}

function onPointerUp(event) {

	if (!this.enabled) return;

	this.domElement.releasePointerCapture(event.pointerId);

	this.domElement.removeEventListener('pointermove', this._onPointerMove);

	this.pointerUp(this._getPointer(event));

}

function intersectObjectWithRay(object, raycaster, includeInvisible) {

	if (!object) return;

	const allIntersections = raycaster.intersectObject(object, true);

	for (let i = 0; i < allIntersections.length; i++) {

		if (allIntersections[i].object.visible || includeInvisible) {

			return allIntersections[i];

		}

	}

	return false;

}

const _alignVector = new Vector3(0, 1, 0);
const _identityQuaternion = new Quaternion();
const _dirVector = new Vector3();
const _tempMatrix = new Matrix4();

const _unitX = new Vector3(1, 0, 0);
const _unitY = new Vector3(0, 1, 0);
const _unitZ = new Vector3(0, 0, 1);

const _v1 = new Vector3();
const _v2 = new Vector3();
const _v3 = new Vector3();

class TransformControlsGizmo extends Object3D {
	constructor() {

		super();

		this.isTransformControlsGizmo = true;

		this.type = 'TransformControlsGizmo';

		this.configGizmo();
	}

	// updateMatrixWorld will update transformations and appearance of individual handles
	async configGizmo() {
		async function setupGizmo(gizmoUrl, type) {

			const gizmo = new Object3D();
			const gltf = await loadModel(gizmoUrl);

			if (gltf.scene) {
				gltf.scene.children.forEach((child) => {
					const clonedChild = child.clone();
					const ogMaterial = child.material;
					clonedChild.material = new MeshBasicMaterial({
						color: ogMaterial.color,
						name: ogMaterial.name,
						transparent: true,
						depthTest: false,
						depthWrite: false
					});
					gizmo.add(clonedChild);
				});
			}

			if (type === "scale") {
				const points = [
					new Vector3(0.5, 0, 0),
					new Vector3(0, 0.5, 0),
					new Vector3(0, 0, -0.5),
				]
				const triGeo = new BufferGeometry().setFromPoints(points);
				const triMat = new MeshBasicMaterial({ color: 'yellow', side: DoubleSide, transparent: true, opacity: 0.5, depthTest: false });
				// const triGeo = new SphereGeometry(0.2, 32, 32);
				// const triMat = new MeshBasicMaterial({color: 'yellow', side: DoubleSide});
				const tri = new Mesh(triGeo, triMat);
				tri.name = "XYZ"
				// tri.scale.setScalar(1.6)
				gizmo.add(tri);
			}

			return gizmo;
		}



		// Gizmo creation

		this.gizmo = {};

		const gizmoPath = {
			'translate': '/static/gizmo/transformAxis.glb',
			'rotate': '/static/gizmo/rotateAxisGizmo.glb',
			'scale': '/static/gizmo/scaleAxisGixmo.glb',
		}

		this.add(this.gizmo['translate'] = await setupGizmo(gizmoPath['translate'], 'translate'));
		this.add(this.gizmo['rotate'] = await setupGizmo(gizmoPath['rotate'], 'rotate'));
		this.add(this.gizmo['scale'] = await setupGizmo(gizmoPath['scale'], 'scale'));

		// /* this.add(  */this.gizmo[ 'rotate' ] = setupGizmo( gizmoRotate )/*  ); */
		// /* this.add(  */this.gizmo[ 'scale' ] = setupGizmo( gizmoScale )/*  ); */

		// Pickers should be hidden always

		// this.picker[ 'translate' ].visible = false;
		// this.picker[ 'rotate' ].visible = false;
		// this.picker[ 'scale' ].visible = false;
	}

	updateMatrixWorld(force) {

		const space = (this.mode === 'scale') ? 'local' : this.space; // scale always oriented to local rotation

		const quaternion = (space === 'local') ? this.worldQuaternion : _identityQuaternion;

		// // Show only gizmos for current transform mode


		if (!this.gizmo[this.mode]) return;



		if (this.gizmo['translate']) this.gizmo['translate'].visible = this.mode === 'translate';
		if (this.gizmo['rotate']) this.gizmo['rotate'].visible = this.mode === 'rotate' && !this.parent.rotationHelperMesh.visible;

		if (this.gizmo['scale']) this.gizmo['scale'].visible = this.mode === 'scale';


		let handles = [];
		handles = handles.concat(this.gizmo[this.mode]);

		for (let i = 0; i < handles.length; i++) {

			const handle = handles[i];

			// hide aligned to camera
			// handle.visible = true;
			handle.position.copy(this.worldPosition);

			let factor;
			if (this.camera.isOrthographicCamera) {
				factor = (this.camera.top - this.camera.bottom) / this.camera.zoom;
			} else {
				factor = this.worldPosition.distanceTo(this.cameraPosition) * Math.min(1.9 * Math.tan(Math.PI * this.camera.fov / 360) / this.camera.zoom, 7);
			}

			handle.quaternion.copy(quaternion);

			handle.scale.set(1, 1, 1).multiplyScalar(factor * this.size / 4);

			// Align handles to current local or world rotation

			if (!this.showX && !this.showY && !this.showZ) handle.visible = false;
			else {
				for (let j = 0; j < handle.children.length; j++) {

					const handleChild = handle.children[j];

					const handleVisibility = {
						"X": () => handleChild.visible = this.showX,
						"Y": () => handleChild.visible = this.showY,
						"Z": () => handleChild.visible = this.showZ,
						"XZ": () => handleChild.visible = this.showZ && this.showX,
						"E": () => handleChild.visible = this.showX && this.showY && this.showZ,
					}

					const val = handleVisibility[handleChild.name]
					if (val) val();
				}
			}

		}

		super.updateMatrixWorld(force);

	}
}

function loadModel(gizmoUrl) {
	return new Promise((resolve, reject) => {
		_gltfLoader.load(gizmoUrl, (gltf) => resolve(gltf), undefined, (e) => reject(e));
	});
}

class TransformControlsPlane extends Mesh {

	constructor() {

		super(
			new PlaneGeometry(100000, 100000, 2, 2),
			new MeshBasicMaterial({ visible: false, wireframe: true, side: DoubleSide, transparent: true, opacity: 0.1, toneMapped: false })
		);

		this.isTransformControlsPlane = true;

		this.type = 'TransformControlsPlane';

	}

	updateMatrixWorld(force) {

		let space = this.space;

		this.position.copy(this.worldPosition);

		if (this.mode === 'scale') space = 'local'; // scale always oriented to local rotation

		_v1.copy(_unitX).applyQuaternion(space === 'local' ? this.worldQuaternion : _identityQuaternion);
		_v2.copy(_unitY).applyQuaternion(space === 'local' ? this.worldQuaternion : _identityQuaternion);
		_v3.copy(_unitZ).applyQuaternion(space === 'local' ? this.worldQuaternion : _identityQuaternion);

		// Align the plane for current transform mode, axis and space.

		_alignVector.copy(_v2);

		switch (this.mode) {

			case 'translate':
			case 'scale':
				switch (this.axis) {

					case 'X':
						_alignVector.copy(this.eye).cross(_v1);
						_dirVector.copy(_v1).cross(_alignVector);
						break;
					case 'Y':
						_alignVector.copy(this.eye).cross(_v2);
						_dirVector.copy(_v2).cross(_alignVector);
						break;
					case 'Z':
						_alignVector.copy(this.eye).cross(_v3);
						_dirVector.copy(_v3).cross(_alignVector);
						break;
					case 'XY':
						_dirVector.copy(_v3);
						break;
					case 'YZ':
						_dirVector.copy(_v1);
						break;
					case 'XZ':
						_alignVector.copy(_v3);
						_dirVector.copy(_v2);
						break;
					case 'XYZ':
					case 'E':
						_dirVector.set(0, 0, 0);
						break;

					default:
						break;

				}

				break;
			case 'rotate':
			default:
				// special case for rotate
				_dirVector.set(0, 0, 0);

		}

		if (_dirVector.length() === 0) {

			// If in rotate mode, make the plane parallel to camera
			this.quaternion.copy(this.cameraQuaternion);

		} else {

			_tempMatrix.lookAt(_tempVector.set(0, 0, 0), _dirVector, _alignVector);

			this.quaternion.setFromRotationMatrix(_tempMatrix);

		}

		super.updateMatrixWorld(force);

	}

}

export { TransformControls, TransformControlsGizmo, TransformControlsPlane };
