import * as THREE from "three";
import EditorExperience from "../../2DEditorExperience";
import { v4 } from "uuid";
import Server from "../../../../../api";

import EventEmitter from "../../../utils/EventEmitter";
import {
    fromVec3PosToPosObject,
    getRandomIdCode,
    getTransformed3DPos,
    getTransformed3DPosV1,
} from "../../../threeUtils/TransformConversions";

import { PathGraph } from "./PathGraph";
import { has } from "lodash";

class PathController extends EventEmitter {
    constructor() {
        super();

        this.editor = new EditorExperience();
        this.canvas = this.editor.canvas;
        this.scene = this.editor.scene;
        this.camera = this.editor.camera;

        this.startNavigationTrack = false;
        this.activeAction = "new";
        this.currentType = "allAccess";

        this.intersectionPoint = new THREE.Vector3();
        this.intersectObject = null;
        this.raycaster = new THREE.Raycaster();
        this.cylinMesh = null;

        this.raycastObjects = [];

        //setup path Graph
        this.sessionPaths = new Map();

        this.activePath = null;
        this.activeNode = null;
        this.sessionNodes = []; // stores id, if len is 2 call draw edge!
        this.sessionGroup = null;
        this.currentHeader = null;

        // hover box
        this.hoverBox = new THREE.BoxHelper();
        this.hoverBox.material.color.set(0x2a4dc2);
        this.hoverBox.material.depthTest = false;
        this.hoverBox.material.transparent = true;
        this.hoverBox.visible = false;
        this.hoverBox.renderOrder = 999;
        this.scene.add(this.hoverBox);

        // this.setUpCylnderObject();

        this.editor.on("editor2DGraphUpdated", this.onEditor2DGraphUpdated);
        // this.editor.on('updateObject3DPositionsWithNewFloorMatrix', this.onUpdateObject3DPositions);
    }

    setUpCylnderObject = () => {
        // const size = 10 + (10 * this.editor.floorData.SCALE_SIZE);
        const size = this.editor.floorData.MIN_SIZE * 0.006;
        const cylinGeo = new THREE.CylinderGeometry(size, size, 0.5, 32, 32);
        const tex = new THREE.TextureLoader().load(
            "/static/textures/placed_node.png"
        );
        tex.colorSpace = THREE.SRGBColorSpace;
        const cylinMat = new THREE.MeshBasicMaterial({
            map: tex,
            side: THREE.FrontSide,
            depthTest: false,
            depthWrite: false,
        });
        this.cylinMesh = new THREE.Mesh(cylinGeo, cylinMat);
        this.cylinMesh.renderOrder = 999;
        this.cylinMesh.rotateX(THREE.MathUtils.degToRad(90));
    };

    // to load paths from API response
    loadPaths = (path) => {
        if (this.sessionPaths.get(path.id)) {
            return this.sessionPaths.get(path.id);
        }

        const newPath = new PathGraph(path);
        this.sessionPaths.set(path.id, newPath);

        return newPath;
    };

    removePath = (id) => {
        this.sessionPaths.delete(id);
        this.editor.trigger("updatePenToolsUI");
    };

    setPathType = (type) => {
        this.currentType = type;
    };

    // to add new path from UI
    setupControls = (action) => {
        if (
            this.startNavigationTrack ||
            this.editor.locPinHelper.startNavigationTrack
        )
            return;

        this.scene.children.forEach((child) => {
            if (
                child.userData &&
                child.userData.navHelper &&
                child.userData.navHelper === "floorplan"
            ) {
                this.raycastObjects.push(child);
            }
        });

        if (this.raycastObjects.length > 0) {
            this.startNavigationTrack = true;
            this.editor.trigger("navigationTracking", [true]);

            this.activeAction = action;

            if (this.activeAction === "new") {
                // goes for new Path
                this.activePath = new PathGraph({
                    pathType: this.currentType,
                    id: v4(),
                });
                this.sessionNodes = [];
                this.sessionGroup = this.activePath.mesh;

                // new node Obj
                const nodeObj = {
                    id: v4(),
                    position2d: {
                        posX: 0,
                        posY: 0,
                    },
                    position: this.getTransformedPos({
                        posX: 0,
                        posY: 0,
                    }),
                    nodeState: this.activeAction,
                };
                this.activeNode = this.activePath.addNode(nodeObj.id, nodeObj);
                this.sessionNodes.push(this.activeNode);
                this.currentHeader = this.activeNode.nodeMesh;

                this.layoutNavPaths(0, new THREE.Vector3(0, 0, 1));
                document.body.classList.add("wsNew_cursor");
            } else if (this.activeAction === "add") {
                // goes to edit
                this.setupAddSelector();
                document.body.classList.add("wsAdd_cursor");
            } else {
                this.setupRemoveSelector();
                document.body.classList.add("wsRemove_cursor");
            }
        } else {
            this.editor.editor3d.callbacks.generateAlert({
                msg: "This map doesn't have floor plan",
                alertType: "information",
            });
        }
    };

    layoutNavPaths = (index = -1, vec3Pos) => {
        // this.currentHeader = this.cloneNavCylinder();

        // this.currentHeader.position.copy(vec3Pos);
        index === 0 && this.editor.addObjects(this.sessionGroup);
        // this.sessionGroup.add(this.currentHeader);

        this.canvas.addEventListener("mousemove", this.onMouseMove, false);
        this.canvas.addEventListener("mousedown", this.onMouseDown, false);
        document.addEventListener("keydown", this.onKeyDownExit, false);
    };

    setupAddSelector = () => {
        this.activePath = null;
        this.sessionNodes = [];
        this.sessionGroup = null;
        this.currentHeader = null;
        this.intersectObject = null;

        this.canvas.addEventListener("mousemove", this.onEditMouseMove, false);
        this.canvas.addEventListener("mousedown", this.onEditMouseDown, false);
        document.addEventListener("keydown", this.onKeyDownExit, false);
    };

    setupRemoveSelector = () => {
        this.intersectObject = null;
        this.canvas.addEventListener("mousemove", this.onEditMouseMove, false);
        this.canvas.addEventListener(
            "mousedown",
            this.onRemoveMouseDown,
            false
        );
        document.addEventListener("keydown", this.onKeyDownExit, false);
    };

    onMouseMove = (e) => {
        if (this.startNavigationTrack) {
            let viewportDown = new THREE.Vector2();
            const rect = this.canvas.getBoundingClientRect();
            viewportDown.x = ((e.offsetX - rect.left) / rect.width) * 2 - 1;
            viewportDown.y = -(((e.offsetY - rect.top) / rect.height) * 2) + 1;

            this.raycaster.setFromCamera(viewportDown, this.camera.instance);
            const intersects = this.raycaster.intersectObjects(
                this.raycastObjects,
                true
            );

            if (intersects.length > 0) {
                const intersect = intersects[0];
                this.intersectionPoint.copy(intersect.point);
                this.currentHeader?.position.copy(this.intersectionPoint);

                // updateNode// add Node!
                if (this.sessionNodes.length > 0) {
                    this.activePath.updateNode(this.activeNode.id, {
                        position2d: {
                            posX: this.intersectionPoint.x,
                            posY: Math.abs(this.intersectionPoint.y),
                        },
                        position: this.getTransformedPos({
                            posX: this.intersectionPoint.x,
                            posY: Math.abs(this.intersectionPoint.y),
                        }),
                    });
                }
                if (this.sessionNodes.length > 1) {
                    this.activePath.setEdge(
                        this.sessionNodes[0].id,
                        this.sessionNodes[1].id,
                        "DRAWING"
                    );
                }
            }
        }
    };

    withinBounds = (e) => {
        let viewportBound = new THREE.Vector2();
        let raycasterBound = new THREE.Raycaster();
        const rect = this.canvas.getBoundingClientRect();
        viewportBound.x = ((e.offsetX - rect.left) / rect.width) * 2 - 1;
        viewportBound.y = -(((e.offsetY - rect.top) / rect.height) * 2) + 1;

        raycasterBound.setFromCamera(viewportBound, this.camera.instance);
        const intersects = raycasterBound.intersectObjects(
            this.raycastObjects,
            true
        );

        return intersects.length > 0;
    };

    onMouseDown = (e) => {
        if (!this.withinBounds(e)) return;

        switch (e.which) {
            case 2:
                this.exitNavigationTracking();
                break;

            case 1:
                this.currentHeader &&
                    this.currentHeader.position.copy(this.intersectionPoint);
                this.activePath.updateNode(this.activeNode.id, {
                    position2d: {
                        posX: this.intersectionPoint.x,
                        posY: Math.abs(this.intersectionPoint.y),
                    },
                    position: this.getTransformedPos({
                        posX: this.intersectionPoint.x,
                        posY: Math.abs(this.intersectionPoint.y),
                    }),
                });

                if (this.sessionNodes.length === 1) {
                    //create new node
                    this.addNewNodeAndSetEdge({
                        id: v4(),
                        position2d: {
                            posX: this.intersectionPoint.x,
                            posY: Math.abs(this.intersectionPoint.y),
                        },
                        position: this.getTransformedPos({
                            posX: this.intersectionPoint.x,
                            posY: Math.abs(this.intersectionPoint.y),
                        }),
                        nodeState: this.activeAction,
                    });
                } else {
                    this.addNewNodeAndSetEdge(
                        {
                            id: v4(),
                            position2d: {
                                posX: this.intersectionPoint.x,
                                posY: Math.abs(this.intersectionPoint.y),
                            },
                            position: this.getTransformedPos({
                                posX: this.intersectionPoint.x,
                                posY: Math.abs(this.intersectionPoint.y),
                            }),
                            nodeState: this.activeAction,
                        },
                        true
                    );
                }

                // !TO DO FOR ADD NEW NODE
                if (this.sessionNodes.length === 2) {
                    // add edge
                    if (this.activeAction === "add") {
                        // if node state is existing then change it to updated
                        const prevNode = this.activePath.nodes.get(
                            this.sessionNodes[0].id
                        );
                        prevNode.nodeState === "existing" &&
                            prevNode.setNodeState("updated");
                    }
                }

                this.canvas.removeEventListener(
                    "mousedown",
                    this.onMouseDown,
                    false
                );
                this.canvas.removeEventListener(
                    "mousemove",
                    this.onMouseMove,
                    false
                );
                this.layoutNavPaths(-1, this.intersectionPoint);
                break;

            case 3:
                const nodeLen = Array.from(this.activePath.nodes.keys()).length;
                if (this.activeAction === "new" && nodeLen <= 1) return;

                this.currentHeader.position.copy(this.intersectionPoint);
                this.activePath.updateNode(this.activeNode.id, {
                    position2d: {
                        posX: this.intersectionPoint.x,
                        posY: Math.abs(this.intersectionPoint.y),
                    },
                    position: this.getTransformedPos({
                        posX: this.intersectionPoint.x,
                        posY: Math.abs(this.intersectionPoint.y),
                    }),
                });
                this.addNewNodeAndSetEdge(
                    {
                        id: v4(),
                        position2d: {
                            posX: this.intersectionPoint.x,
                            posY: Math.abs(this.intersectionPoint.y),
                        },
                        position: this.getTransformedPos({
                            posX: this.intersectionPoint.x,
                            posY: Math.abs(this.intersectionPoint.y),
                        }),
                        nodeState: this.activeAction,
                    },
                    true
                );
                this.endNavigationTracking();
                break;

            default:
                break;
        }
    };

    addNewNodeAndSetEdge = (nodeObj, toShift = false) => {
        this.activeNode = this.activePath.addNode(nodeObj.id, nodeObj);
        this.sessionNodes.push(this.activeNode);
        this.currentHeader = this.activeNode.nodeMesh;

        if (toShift) {
            this.activePath.setEdge(
                this.sessionNodes[0].id,
                this.sessionNodes[1].id,
                "DRAWN"
            );
        }

        toShift && this.sessionNodes.shift();

        this.activePath.addEdge(
            this.sessionNodes[0].id,
            this.sessionNodes[1].id
        );
        this.activePath.setEdge(
            this.sessionNodes[0].id,
            this.sessionNodes[1].id,
            "DRAW"
        );
    };

    // edit flow
    onEditMouseMove = (e) => {
        if (this.startNavigationTrack) {
            let viewportDown = new THREE.Vector2();
            const rect = this.canvas.getBoundingClientRect();
            viewportDown.x = ((e.offsetX - rect.left) / rect.width) * 2 - 1;
            viewportDown.y = -(((e.offsetY - rect.top) / rect.height) * 2) + 1;

            const nodes = [];
            this.editor.scene.traverse((obj) => {
                if (has(obj.userData, "nodeMesh")) {
                    nodes.push(obj);
                }
            });

            this.raycaster.setFromCamera(viewportDown, this.camera.instance);
            const intersects = this.raycaster.intersectObjects(nodes, true);

            if (intersects.length > 0) {
                const intersect = intersects[0];
                if (
                    intersect.object.userData &&
                    has(intersect.object.userData, "nodeMesh")
                ) {
                    this.intersectObject = intersect.object.clone();
                    this.intersectionPoint.copy(intersect.point);
                    this.hoverBox.setFromObject(this.intersectObject);
                    this.hoverBox.visible = true;
                }
            } else {
                this.hoverBox.visible = false;
                this.intersectObject = null;
            }
        }
    };

    onEditMouseDown = (e) => {
        //set actve graph and session node
        if (
            e.which === 1 &&
            this.intersectObject &&
            this.intersectObject.userData &&
            has(this.intersectObject.userData, "nodeMesh")
        ) {
            const nodeId = this.intersectObject.userData.id;
            Array.from(this.sessionPaths.values())?.forEach(
                (graph) => graph.nodes.has(nodeId) && (this.activePath = graph)
            );
            this.activeNode = this.activePath.getNode(nodeId);
            this.sessionNodes.push(this.activeNode);
            this.sessionGroup = this.activePath.mesh;
            //
            this.addNewNodeAndSetEdge({
                id: v4(),
                position2d: {
                    posX: this.activeNode.position2d.x,
                    posY: Math.abs(this.activeNode.position2d.y),
                },
                position: this.getTransformedPos({
                    posX: this.activeNode.position2d.x,
                    posY: Math.abs(this.activeNode.position2d.y),
                }),
                nodeState: this.activeAction,
            });

            this.layoutNavPaths(-1, this.intersectionPoint);

            this.hoverBox.visible = false;
            this.canvas.removeEventListener(
                "mousemove",
                this.onEditMouseMove,
                false
            );
            this.canvas.removeEventListener(
                "mousedown",
                this.onEditMouseDown,
                false
            );
            this.activeAction === "add" &&
                document.removeEventListener(
                    "keydown",
                    this.onKeyDownExit,
                    false
                );
        }
    };

    onRemoveMouseDown = (e) => {
        //set actve graph and session node
        if (
            e.which === 1 &&
            this.intersectObject &&
            this.intersectObject.userData &&
            has(this.intersectObject.userData, "nodeMesh")
        ) {
            const nodeId = this.intersectObject.userData.id;
            Array.from(this.sessionPaths.values()).forEach(
                (graph) => graph.nodes.has(nodeId) && (this.activePath = graph)
            );

            this.activePath && this.activePath.removeNodeandEdges(nodeId);

            this.hoverBox.visible = false;
            // let user click on save and not exit if a node is removed!
            document.removeEventListener("keydown", this.onKeyDownExit, false);
        }
    };

    endNavigationTracking = () => {
        if (this.activeAction === "new" && this.sessionNodes.length === 0) {
            this.exitNavigationTracking();
            return;
        } else if (
            (this.activeAction === "add" || this.activeAction === "remove") &&
            !this.activePath
        ) {
            this.exitNavigationTracking();
            return;
        }

        if (this.activeAction === "add" && this.sessionNodes.length < 1) return;

        if (!this.activePath) return;

        const nodeLen = Array.from(this.activePath?.nodes?.keys()).length;

        if (this.activeAction === "new" && nodeLen <= 2) return;

        if (this.currentHeader) {
            this.activePath.removeActiveNodeEdge(this.activeNode.id);
        }

        this.activePath.computeNodePath();

        // console.log(nodeLen, this.activeAction);

        if (this.activeAction === "new" && nodeLen > 1) {
            this.createPath();
        } else if (this.activeAction === "add") {
            this.updatePath();
        } else if (this.activeAction === "remove") {
            this.updateRemoveNodes();
        }

        this.startNavigationTrack = false;
        this.editor.trigger("toggleConnectorMenuMode", [null]);

        this.canvas.removeEventListener("mousemove", this.onMouseMove, false);
        this.canvas.removeEventListener("mousedown", this.onMouseDown, false);
        this.canvas.removeEventListener(
            "mousemove",
            this.onEditMouseMove,
            false
        );
        this.canvas.removeEventListener(
            "mousedown",
            this.onEditMouseDown,
            false
        );
        this.canvas.removeEventListener(
            "mousedown",
            this.onRemoveMouseDown,
            false
        );
        document.removeEventListener("keydown", this.onKeyDownExit, false);
        this.resetClasses();
    };

    onKeyDownExit = (e) => {
        if (e.keyCode === 27) {
            this.exitNavigationTracking();
        }
    };

    exitNavigationTracking = () => {
        // remove placeholder header
        this.currentHeader && this.sessionGroup.remove(this.currentHeader);

        if (this.activeAction === "new") {
            this.editor.removeObject(this.sessionGroup);
        } else if (this.activeAction === "add") {
            // remove only newly added!
            this.activePath && this.activePath.removeNewNodesAndEdges();
        } else if (this.activeAction === "remove") {
            // undo remove thing!
        }

        this.hoverBox && (this.hoverBox.visible = false);
        this.startNavigationTrack = false;
        this.currentHeader = null;
        this.editor.trigger("toggleConnectorMenuMode", [null]);
        this.editor.trigger("navigationTracking", [false]);
        this.editor.deselect();
        this.canvas.removeEventListener("mousemove", this.onMouseMove, false);
        this.canvas.removeEventListener("mousedown", this.onMouseDown, false);
        this.canvas.removeEventListener(
            "mousemove",
            this.onEditMouseMove,
            false
        );
        this.canvas.removeEventListener(
            "mousedown",
            this.onEditMouseDown,
            false
        );
        this.canvas.removeEventListener(
            "mousedown",
            this.onRemoveMouseDown,
            false
        );
        document.removeEventListener("keydown", this.onKeyDownExit, false);
        this.resetClasses();
    };

    cloneNavCylinder = () => {
        let header = this.cylinMesh.clone();
        const randID = getRandomIdCode();
        header.name = `node_${randID}`;
        header.userData["id"] = `node_${randID}`;
        return header;
    };

    getTransformedPos = (pos) => {
        const { worldMatrix, imgWidth, imgHeight } = this.editor.floorData;
        return this.editor.floorplanVersion !== 2.6
            ? getTransformed3DPos(worldMatrix, imgWidth, imgHeight, pos, true)
            : getTransformed3DPosV1(
                  worldMatrix,
                  imgWidth,
                  imgHeight,
                  pos,
                  true
              );
    };

    resetClasses = () => {
        document.body.classList.remove("wsNew_cursor");
        document.body.classList.remove("wsAdd_cursor");
        document.body.classList.remove("wsRemove_cursor");
    };

    onEditor2DGraphUpdated = (graphId) => {
        this.activePath = this.sessionPaths.get(graphId);
        if (this.activePath) {
            this.updatePath();
        }
    };

    onUpdateObject3DPositions = () => {
        Array.from(this.sessionPaths.values()).forEach(async (path) => {
            // update pos3D/pos2d of each node with status as updated
            path.computeUpdatedNodePositions(
                this.editor.manualAligned === "3d_change"
            );
            path.computeNodePath();
            const { id, nodes, nodePath } = path;
            const updateNodes = Array.from(nodes.values());
            let reqObj = {
                id: id,
                nodes: updateNodes.map((node) => {
                    return {
                        id: node.id,
                        position2d: {
                            posX: node.position2d.x,
                            posY: Math.abs(node.position2d.y),
                        },
                        position: fromVec3PosToPosObject(node.position),
                    };
                }),
                nodePath: nodePath,
            };

            this.editor.pathReadjustObj.push(reqObj);
            this.editor.trigger("requestPinPathReadjustment");
        });
    };

    createPath = () => {
        const { id, currentType, pathStroke, nodes, nodePath } =
            this.activePath;
        const reqObj = {
            id: id,
            pathType: currentType,
            pathStroke,
            nodes: Array.from(nodes.values()).map((node) => {
                return {
                    id: node.id,
                    position2d: {
                        posX: node.position2d.x,
                        posY: Math.abs(node.position2d.y),
                    },
                    position: fromVec3PosToPosObject(node.position),
                    adjacentNodes: Array.from(node.adjacents.values()).map(
                        (ad) => ad.id
                    ),
                };
            }),
            nodePath: nodePath,
        };
        // console.log(reqObj);
        Server.post(
            `/v1/map/locationpin/path/${this.editor.editor3d.mapId}`,
            reqObj
        )
            .then((res) => {
                this.editor.trigger("navigationTracking", [false]);
                if (res.status === 200) {
                    console.log("created Successfully!", res.data);
                    // this.editor.jsonNavPaths.push(res.data.data);
                    this.sessionPaths.set(id, this.activePath);
                    this.activePath = null;
                    this.sessionNodes = [];
                    this.sessionGroup = null;
                    this.currentHeader = null;
                    this.editor.trigger("updatePenToolsUI");
                } else {
                    this.editor.editor3d.callbacks.generateAlert({
                        msg: "Error while creating path!",
                        alertType: "information",
                    });
                }
            })
            .catch((e) => console.log(e.response));
    };

    updatePath = () => {
        const { id, currentType, pathStroke, nodes, nodePath } =
            this.activePath;
        const updateNodes = Array.from(nodes.values()).filter(
            (n) => n.nodeState === "add" || n.nodeState === "updated"
        );
        const reqObj = {
            id: id,
            pathType: currentType,
            pathStroke,
            nodes: updateNodes.map((node) => {
                return {
                    id: node.id,
                    position2d: {
                        posX: node.position2d.x,
                        posY: Math.abs(node.position2d.y),
                    },
                    position: fromVec3PosToPosObject(node.position),
                    action: node.nodeState === "add" ? "added" : node.nodeState,
                    adjacentNodes: Array.from(node.adjacents.values()).map(
                        (ad) => ad.id
                    ),
                };
            }),
            nodePath: nodePath,
        };
        // console.log(reqObj);
        Server.put(
            `/v1/map/locationpin/path/${this.editor.editor3d.mapId}`,
            reqObj
        )
            .then((res) => {
                this.editor.trigger("navigationTracking", [false]);
                if (res.status === 200) {
                    console.log("Updated Successfully!", res.data);
                    this.activePath.updateNodeStates();
                    this.activePath = null;
                    this.sessionNodes = [];
                    this.sessionGroup = null;
                    this.currentHeader = null;
                    this.editor.trigger("updatePenToolsUI");
                } else {
                    this.editor.editor3d.callbacks.generateAlert({
                        msg: "Error while updating path!",
                        alertType: "information",
                    });
                }
            })
            .catch((e) => console.log(e.response));
    };

    updateRemoveNodes = () => {
        Array.from(this.sessionPaths.values()).forEach(async (path) => {
            if (path.removedNodes.length > 0) {
                path.computeNodePath();
                const { id, currentType, pathStroke, nodes, nodePath } = path;
                const updateNodes = Array.from(nodes.values()).filter(
                    (n) => n.nodeState === "updated"
                );
                const reqObj = {
                    id: id,
                    pathType: currentType,
                    pathStroke,
                    nodes: [
                        ...path.removedNodes.map((node) => {
                            return {
                                id: node.id,
                                action: "deleted",
                            };
                        }),
                        ...updateNodes.map((node) => {
                            return {
                                id: node.id,
                                position2d: {
                                    posX: node.position2d.x,
                                    posY: Math.abs(node.position2d.y),
                                },
                                position: fromVec3PosToPosObject(node.position),
                                action: node.nodeState,
                                adjacentNodes: Array.from(
                                    node.adjacents.values()
                                ).map((ad) => ad.id),
                            };
                        }),
                    ],
                    nodePath: nodePath,
                };
                // console.log(reqObj);
                try {
                    const response = await Server.put(
                        `/v1/map/locationpin/path/${this.editor.editor3d.mapId}`,
                        reqObj
                    );
                    if (response.status === 200) {
                        console.log("Deleted Successfully!", response.data);
                        this.editor.trigger("updatePenToolsUI");
                    } else {
                        this.editor.editor3d.callbacks.generateAlert({
                            msg: "Error while updating path!",
                            alertType: "information",
                        });
                    }
                } catch (e) {
                    console.log(e.response);
                }
            }
        });
        this.editor.trigger("navigationTracking", [false]);
        this.activePath = null;
        this.sessionNodes = [];
        this.sessionGroup = null;
    };

    toJSON = () => {
        return Array.from(this.sessionPaths.values()).map((path) =>
            path.toJSON()
        );
    };

    getPathCount = () => {
        return Array.from(this.sessionPaths.values()).length;
    };

    clearGraphs = () => {
        //setup path Graph
        this.sessionPaths = new Map();
    };
}

export default PathController;
