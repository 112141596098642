const heatVertex = `
uniform sampler2D heightMap;
uniform float heightRatio;
varying vec2 vUv;
varying float hValue;

void main() {
    vUv = uv;
    vec3 pos = position;
    hValue = texture2D(heightMap, vUv).r;
    pos.y = hValue * heightRatio;
    
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos,1.0);
}
`;

const heatFragment = `
uniform int uHeatType;
varying float hValue;

float square(float s) { return s * s; }

vec3 rainbowGradient(float t) {
    vec3 c = 1.0 - pow(abs(vec3(t) - vec3(0.65, 0.5, 0.2)) * vec3(2.0, 2.0, 3.0), vec3(1.4, 1.3, 1.7));
    c.r = max((0.15 - square(abs(t - 0.04) * 5.0)), c.r);
    c.g = (t < 0.5) ? smoothstep(0.04, 0.45, t) : c.g;
    return clamp(c, 0.0, 1.0);
}

void main() {
    float v = abs(hValue - 1.);
    vec4 outColor;
    if(uHeatType == 1) {
        outColor = vec4(rainbowGradient(hValue), 1. - v * v);
    } else if(uHeatType == 2) {
        outColor = vec4(0.10, 0.55, 0.80, 1. - v * v);
    } else if(uHeatType == 3) {
        outColor = vec4(0.97, 0.32, 0.23, 1. - v * v);
    } else {
        outColor = vec4(1.0, 0.0, 0.0, 1.0);
    }
    gl_FragColor = outColor; 
}
`;

export { heatVertex, heatFragment };
