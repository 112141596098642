import * as THREE from "three";
import EditorExperience from "../../InteractiveExperience";
import { has, isEqual, isObject } from "lodash";
import { fromPosObjectToVec3Pos } from "../TransformConversions";
import { getLocationCanvasTexture, getTexNameFromId } from "./PinUtils";
import { UIPanel, UIText } from "../../ui/ui.lib";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer.js";

export default class LocationPin {
    constructor(pinProps) {
        this.pinProps = pinProps;

        this.prevDistToCamera = 0;

        this.editor = new EditorExperience();
        this.pinType = pinProps.pinType;

        this.name = pinProps.name || "";
        this.id = pinProps.id;
        this.isSource = false;

        this.amenityType =
            this.pinType === "amenity" && pinProps.amenityPinTypes.length
                ? pinProps.amenityPinTypes[0]
                : null;
        this.amenityType = isObject(this.amenityType)
            ? this.amenityType.id
            : this.amenityType;
        this.texType =
            this.pinType === "amenity"
                ? getTexNameFromId(this.amenityType)
                : "location";

        this.pinColor = pinProps.pinColor || "#36CCE7";

        this.position = pinProps.position
            ? fromPosObjectToVec3Pos(pinProps.position)
            : new THREE.Vector3(0, 0, 0);

        !has(pinProps, "mapId") && this.editor.jsonLocationPins.push(pinProps);

        if (this.pinType === "regular") {
            this.pinSubType = "Custom Pin";
        } else {
            this.pinSubType = pinProps.amenityPinTypes.length
                ? pinProps.amenityPinTypes[0].name
                : "Amenity Pin";
        }

        this.zoomLevel = 1;
        this.scaleY = 1;
        this.zoomLevelIconType = {
            regular: {
                4: "default",
                3: "zoomed",
                2: "selected",
                1: "selected",
            },
            amenity: {
                4: "default",
                3: "selected",
                2: "selected",
                1: "selected",
            },
        };

        this.editor.on("locationPinClicked", this.onLocationPinClicked);
        this.editor.on("repositionYOfObjects", this.onRepositionYOfObjects);
        this.editor.on("navigationPreview", this.onNavigationPreview);
        this.editor.on("clearAndReset", this.clearAndReset);

        this.setUpPin();
        this.initHTML();
    }

    setUpPin = async () => {
        const spriteTex = await getLocationCanvasTexture(
            this.texType,
            this.pinColor,
            512,
            "selected"
        );
        spriteTex.colorSpace = THREE.SRGBColorSpace;
        const spriteMaterial = new THREE.SpriteMaterial({
            map: spriteTex,
            side: THREE.DoubleSide,
            transparent: true,
        });

        this.mesh = new THREE.Sprite(spriteMaterial);
        this.mesh.position.copy(this.position);
        this.mesh.rotation.y = THREE.MathUtils.degToRad(180);

        this.sizeY = this.calculateHeightOfMesh();

        this.mesh.position.y =
            (this.editor.floorY ? this.editor.floorY : this.mesh.position.y) +
            this.sizeY / 2;

        this.mesh.scale.set(1, this.scaleY, 1);

        this.mesh.name = this.name;
        this.mesh.userData["type"] = "Location Pin";
        this.mesh.userData["id"] = this.id;
        this.mesh.userData["pinType"] = this.pinType;

        this.editor.trigger("asyncObjectLoaded", [this, "locationPin"]);
    };

    emptyContent = () => {
        if (this.uiCont) {
            let child = this.uiCont.dom.lastChild;
            while (child) {
                this.uiCont.dom.removeChild(child);
                child = this.uiCont.dom.lastChild;
            }
        }
    };

    initHTML = () => {
        //Init Img UI
        this.emptyContent();

        this.uiCont = new UIPanel();
        this.uiCont.addClass("interactivePinContainer");

        this.uiCont.onClick(() => {
            this.editor.trigger("locationPinSelected", [this.pinProps]);
            this.editor.trigger("clearPathBetweenPins");
        });

        const uiNameHeader = new UIText("", "").addClass(
            "interactivePinContainer__pinInfoHeader"
        );

        const uiAbbr = document.createElement("abbr");
        uiAbbr.title = this.name || "";
        uiAbbr.textContent = this.name || "";
        uiNameHeader.dom.appendChild(uiAbbr);

        this.uiCont.addChild(uiNameHeader);
        this.uiCont.setDisplay("none");

        this.annotationRenderer = new CSS2DObject(this.uiCont.dom);
    };

    onRepositionYOfObjects = (yValue) => {
        this.mesh && (this.mesh.position.y = yValue + this.sizeY / 2);
        this.annotationRenderer &&
            (this.annotationRenderer.position.y = yValue + this.sizeY * 1.15);
    };

    calculateHeightOfMesh = () => {
        return new THREE.Box3()
            .setFromObject(this.mesh)
            .getSize(new THREE.Vector3()).y;
    };

    clearAndReset = () => {
        if (this.uiCont) {
            this.emptyContent();
        }
    };

    getZoomLevelFromMaxDistance = (distance) => {
        let zoomLevel = 1;
        const maxDistance = this.editor.camera.controls.maxDistance;
        const level = Math.floor((distance / maxDistance) * 100);
        if (level <= 25) {
            zoomLevel = 0.85;
        } else if (level >= 26 && level <= 50) {
            zoomLevel = 0.75;
        } else if (level >= 51 && level <= 75) {
            zoomLevel = 0.65;
        } else if (level >= 76) {
            zoomLevel = 0.55;
        }
        return zoomLevel;
    };

    updateScaleOfMesh = async () => {
        if (this.mesh.visible === false) return;

        const distance = this.editor?.sceneCenter?.distanceTo(
            this.editor.camera?.instance?.position.clone()
        );

        if (this.prevDistToCamera === distance) return;

        let zoomLevel;
        if (this.editor.navigationTracking) {
            zoomLevel = 0.85;
        } else {
            zoomLevel = this.getZoomLevelFromMaxDistance(distance);
            if (zoomLevel === this.zoomLevel) return;
        }

        this.zoomLevel = zoomLevel;

        const mappedZoomLevel = {
            0.85: 1,
            0.75: 2,
            0.65: 3,
            0.55: 4,
        };

        const mappedZoom = mappedZoomLevel[zoomLevel];

        if (!this.editor.navigationTracking) {
            const pinTex = await getLocationCanvasTexture(
                this.texType,
                this.pinColor,
                512,
                this.zoomLevelIconType[this.pinType][mappedZoom]
            );
            pinTex.colorSpace = THREE.SRGBColorSpace;
            this.mesh.material.map = pinTex;
            this.mesh.material.needsUpdate = true;
        }

        this.prevDistToCamera = distance;
        if (distance > this.editor.floorMax / 2) {
            let factor =
                this.editor.sceneCenter.distanceTo(
                    this.editor.camera.instance.position.clone()
                ) *
                Math.min(
                    (this.zoomLevel *
                        Math.tan(
                            (Math.PI * this.editor.camera.instance.fov) / 360
                        )) /
                        this.editor.camera.instance.zoom,
                    7
                );

            if(this.editor.navigationTracking) {
                if (this.pinType === "regular" && !this.isSource) {
                    this.scaleY = 1;
                } else if (this.pinType === "amenity" && !this.isSource) {
                    this.scaleY = 1.2;
                }  else {
                    this.scaleY = 1;
                }
            } else {
                if (
                    this.pinType === "regular" &&
                    mappedZoom <= 2
                ) {
                    this.scaleY = 1.2;
                } else if (
                    this.pinType === "amenity" &&
                    mappedZoom <= 3
                ) {
                    this.scaleY = 1.2;
                } else {
                    this.scaleY = 1;
                }
            }
            this.mesh.scale.set(1, this.scaleY, 1).multiplyScalar(factor / 10);
            this.sizeY = this.calculateHeightOfMesh();

            this.mesh.position.y =
                (this.editor.floorY
                    ? this.editor.floorY
                    : this.mesh.position.y) +
                this.sizeY / 2;

            this.annotationRenderer.position.y =
                (this.editor.floorY
                    ? this.editor.floorY
                    : this.mesh.position.y) +
                this.sizeY * 1.15;
        }
    };

    onCorrectZoomLevel = () => {
        const mappedZoomLevel = {
            0.85: 1,
            0.75: 2,
            0.65: 3,
            0.55: 4,
        };
        const mappedZoom = mappedZoomLevel[this.zoomLevel];

        if (this.pinType === "regular" && mappedZoom < 2) {
            return true;
        } else if (this.pinType === "amenity" && mappedZoom < 3) {
            return true;
        }
        return false;
    };

    determineCameraView = (camera) => {
        let direction = new THREE.Vector3();
        camera.getWorldDirection(direction);
        const absDirection = new THREE.Vector3(
            Math.abs(direction.x),
            Math.abs(direction.y),
            Math.abs(direction.z)
        );

        if (
            absDirection.x > absDirection.y &&
            absDirection.x > absDirection.z
        ) {
            return "X";
        } else if (
            absDirection.y > absDirection.x &&
            absDirection.y > absDirection.z
        ) {
            return "Y";
        } else {
            return "Z";
        }
    };

    updateScaleOfText = () => {
        if (
            this.uiCont?.dom &&
            this.onCorrectZoomLevel() &&
            this.mesh.visible &&
            !this.editor.navigationTracking
        ) {
            if (
                this.editor.isVenue &&
                this.editor.activeMap !== this.pinProps.mapId
            )
                return;
            this.uiCont.setDisplay("");

            const cameraView = this.determineCameraView(
                this.editor.camera.instance
            );

            if (cameraView === "Y") {
                this.annotationRenderer.position.z =
                    this.mesh.position.z - this.sizeY / 1.5;
            } else {
                this.annotationRenderer.position.y =
                    (this.editor.floorY
                        ? this.editor.floorY
                        : this.mesh.position.y) +
                    this.sizeY * 1.15;
                this.annotationRenderer.position.z = this.mesh.position.z;
            }
        } else {
            this.uiCont.setDisplay("none");
        }
    };

    onLocationPinClicked = (object) => {
        if (object && object === this.mesh) {
            this.editor.trigger("locationPinSelected", [this.pinProps]);
            this.editor.trigger("clearPathBetweenPins");
        }
    };

    onNavigationPreview = async (isPreview) => {
        if (isPreview) {
            if (
                !isEqual(
                    this.id,
                    this.editor.activeNavigationData.sourcePin.id
                ) &&
                !isEqual(
                    this.id,
                    this.editor.activeNavigationData.destinationPin.id
                )
            ) {
                this.mesh.visible = false;
            } else if (
                isEqual(this.id, this.editor.activeNavigationData.sourcePin.id)
            ) {
                this.mesh.visible = true;
                const sourceTexture = new THREE.TextureLoader().load(
                    "/static/sprites/sourceNav_pin.png"
                );
                sourceTexture.colorSpace = THREE.SRGBColorSpace;
                this.mesh.material.map = sourceTexture;
                this.mesh.material.needsUpdate = true;
                this.mesh.scale.set(1, 1, 1);
                this.scaleY = this.calculateHeightOfMesh();
                this.isSource = true;
            } else if (
                isEqual(
                    this.id,
                    this.editor.activeNavigationData.destinationPin.id
                )
            ) {
                this.mesh.visible = true;
                const destTexture = await getLocationCanvasTexture(
                    this.texType,
                    this.pinColor,
                    512,
                    "selected"
                );
                destTexture.colorSpace = THREE.SRGBColorSpace;
                this.mesh.material.map = destTexture;
                this.mesh.material.needsUpdate = true;
            }
        } else {
            if (this.mesh.visible) {
                const pinTex = await getLocationCanvasTexture(
                    this.texType,
                    this.pinColor,
                    512
                );
                pinTex.colorSpace = THREE.SRGBColorSpace;
                this.mesh.material.map = pinTex;
                this.mesh.material.needsUpdate = true;
                this.isSource = false;
            } else {
                this.mesh.visible = true;
                this.isSource = false;
            }
        }
    };
}
