import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  FormControl,
  Checkbox,
  Button,
  MenuItem,
  Select,
  ListItemText,
} from "@mui/material";
import { ArrowRight } from "react-feather";
import {
  mapDetails,
  getUserMapDetails,
} from "../../../../../features/user/studio/studioSlice";
import { ReactComponent as NoQRPlacedIcon } from "../../../../../assets/svgs/dash-landing/mapdetails/noQRPlaced.svg";
import MultiQRViewport from "./MultiQRViewport";
import Loader from "../../../../common/loader";
import MessageModal from "../../../../common/modal";
import Dropdown from "../../../../webstudio/studiocommon/ImportMaps/VenuesDetails/Dropdown";

import GenerateQR from "../common/GenerateQR";
import QRHelperModal from "../common/QRHelperModal";
import { setNewAlert } from "../../../../../features/common/alertSlice";
import { resetGeneratedBlob } from "../../../../../features/common/commonSlice";
import { saveAs } from "file-saver";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const QRCodesTab = ({ data }) => {
  const dispatch = useDispatch();
  const childRef = useRef(null);
  const [selectedTypes, setSelectedTypes] = useState("PDF");
  const [additionalSelectedTypes, setAdditionalSelectedTypes] = useState("PDF");
  const [additional, setAdditional] = useState(0);
  const [loaderModal, setLoaderModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [level, setLevel] = useState(`0-${data?.maps[0]?.identifier}`);
  const [openQRHelper, toggleQRHelper] = useState(false);

  const types = ["PDF", "PNG"];

  useEffect(() => {
    if (data?.isVenueTab) {
      getUserMapDetails(data?.mapData.mapId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedMapDetails = useSelector(mapDetails);
  const generatedBlob = useSelector((state) => state.common.generatedBlob);

  let mapData = {
    mapCode: selectedMapDetails?.metadata?.mapCode,
    QRId: selectedMapDetails?.metadata?.anchors.find(
      (anc) => anc.anchorIndex === "primary"
    )?.id,
    mapName: selectedMapDetails?.metadata?.mapName,
  };
  const { mapCode, QRId, mapName } = mapData;

  useEffect(() => {
    if (data?.isVenue) {
      /* console.log(
        data?.maps.find(
          (m) =>
            m.mapId === data?.maps.find((m,i) => `${i}-${m.identifier}` === level)["mapId"]
        )["mapId"]) */
      dispatch(
        getUserMapDetails(
          data?.maps.find(
            (m) =>
              m.mapId ===
              data?.maps.find((m, i) => `${i}-${m.identifier}` === level)[
              "mapId"
              ]
          )["mapId"]
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  useEffect(() => {
    if (generatedBlob) {
      downloadZipFile(generatedBlob);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedBlob]);

  const downloadZipFile = async (generatedQRFile) => {
    if (generatedQRFile) {
      let blob = new Blob([generatedQRFile], { type: "application/zip" });

      saveAs(blob, mapName + "_QRs.zip");
    }
    setLoaderModal(false);
    dispatch(resetGeneratedBlob());
  }

  const handleDownload = () => {
    if (selectedTypes) {
      childRef.current.handleDownloadQRFile(selectedTypes, setLoaderModal);
    } else {
      dispatch(
        setNewAlert({
          msg: "Please select download type to download QR.",
          alertType: "information",
        })
      );
    }
  };

  const handleDownloadAdditional = () => {
    if (additionalSelectedTypes && additional > 0) {
      childRef.current.handleDownloadMultipleQRFile(additionalSelectedTypes, additional, setLoaderModal);
    } else {
      dispatch(
        setNewAlert({
          msg: "Please select download type to download QR.",
          alertType: "information",
        })
      );
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTypes(value);
  };

  const handleAdditionalChange = (event) => {
    const {
      target: { value },
    } = event;
    setAdditionalSelectedTypes(value);
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h5 className="card-title mapdetails__title pt-4 pb-3">QR Codes</h5>
        {data?.isVenue ? (
          <h5 className="card-title mapdetails__title pt-3">
            <Dropdown
              list={data.maps.map((m, i) => {
                return {
                  value: `${i}-${m.identifier}`,
                  label: `${m.identifier} - ${m.mapName}`,
                };
              })}
              setvalue={(val) => {
                setLevel(val);
              }}
            />
          </h5>
        ) : null}
      </div>
      {data?.isVenue && (
        <div>
          <div className="mapdetails__qrTitle">
            <strong>Note:</strong> We suggest you to add access points near
            connector areas(Elevator, Stair etc)
          </div>
          <div
            className="stepper__CardLink"
            onClick={() => toggleQRHelper(true)}
          >
            Display & Sizing Recommendations <ArrowRight size={18} />
          </div>
        </div>
      )}

      <Grid container={true} spacing={2} className={"pt-4"}>
        <Grid item xs={6}>
          <div className="shadow p-3 mb-3 bg-body rounded mapdetails__QRCodeCard">
            <div className="stepper__SectionHeader">Primary QR Code</div>
            <label
              className="content-type-select mt-4"
              style={{ lineHeight: "normal", textAlign: "center" }}
            >
              Need to print your primary QR code? Download, post, and activate
              your QR code using the ARway app.
            </label>
            <div className="mt-4">
              <GenerateQR mapData={{ mapCode, QRId, mapName }} ref={childRef} />
            </div>
            <div className="content-type-select mt-4 mb-2 widthQR-90">
              <h6>Download Type</h6>
              <FormControl className="widthQR-100">
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple={false}
                  value={[selectedTypes]}
                  onChange={handleChange}
                  renderValue={(selected) => selected}
                  MenuProps={MenuProps}
                >
                  {types.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={selectedTypes === name} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Button
              className="mt-2"
              onClick={handleDownload}
              variant="contained"
              id="button-qrDownload"
              disabled={selectedTypes.length <= 0}
            >
              Download Primary QR Code
            </Button>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="shadow p-3 mb-3 bg-body rounded mapdetails__QRCodeCard">
            <div className="stepper__SectionHeader">Additional QR Codes</div>
            <label
              className="content-type-select mt-4"
              style={{ lineHeight: "normal", textAlign: "center" }}
            >
              Download, print and post multiple QR codes in different areas of
              your map location to allow viewers to open the experience from
              multiple access points. Ensure QR codes are printed at a minimium
              of 6”x6”.
            </label>
            <div className="container" style={{ marginTop: "6rem" }}>
              <span
                className="border border-dark mt-4 d-flex justify-content-between"
                style={{
                  width: "100%",
                  height: "42px",
                  display: "inline-block",
                  borderRadius: "6px",
                }}
              >
                <span
                  className="p-2 ps-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (additional > 0) {
                      setAdditional(additional - 1);
                    }
                  }}
                  id="span-minusQR"
                >
                  -
                </span>
                <label className="p-2" id="label-totalQRCount">
                  {additional}
                </label>
                <span
                  className="p-2 pe-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setAdditional(additional + 1);
                  }}
                  id="span-plusQR"
                >
                  +
                </span>
              </span>
            </div>
            <div
              className="content-type-select widthQR-95 mb-3"
              style={{ marginTop: "2rem" }}
            >
              <h6>Download Type</h6>
              <FormControl className="widthQR-100">
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple={false}
                  value={[additionalSelectedTypes]}
                  onChange={handleAdditionalChange}
                  renderValue={(selected) => selected}
                  MenuProps={MenuProps}
                >
                  {types.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={additionalSelectedTypes === name}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Button
              className="mt-2"
              onClick={handleDownloadAdditional}
              variant="contained"
              disabled={additional <= 0 || !additionalSelectedTypes}
              id="button-downloadMultiQR"
            >
              Download Additional
            </Button>
          </div>
        </Grid>
        <Grid className="pt-5" item xs={12}>
          {/* (val) => setLoaderModal(val),
        (val) => setProgress(val), */}
          {selectedMapDetails?.metadata?.anchors.filter(
            (anchorObj) => anchorObj.isQrCode
          ).length > 1 ? (
            <MultiQRViewport
              data={{ mapCode, QRId, mapName }}
              downloadProgressModal={{ setLoaderModal, setProgress }}
            />
          ) : (
            <div className="shadow p-3 mb-3 bg-body rounded mapdetails__mapDefaultFloorplan">
              <div className="pb-4">No QR Codes Placed</div>
              <NoQRPlacedIcon />
              <label
                className="content-type-select mt-4"
                style={{ lineHeight: "normal" }}
              >
                No QR codes placed yet. Your QR codes will appear here.
              </label>
              <div className="pt-4"></div>
            </div>
          )}
        </Grid>
      </Grid>
      <MessageModal
        openModal={loaderModal}
        onCloseModal={() => {
          // setIsDownloadingAdditional(false);
          // setLoaderModal(false);
        }}
        inlineStyle={{ height: "70vh", width: "90vw" }}
      >
        <Loader
          loaderText={`Download is in progress`}
          height="100%"
          width="500vw"
          value={Math.round(progress * 100)}
        />
      </MessageModal>
      <QRHelperModal
        openModal={openQRHelper}
        onCloseModal={() => toggleQRHelper(false)}
      />
    </div>
  );
};

export default QRCodesTab;