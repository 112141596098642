import React, { useEffect, useState, useRef } from "react";
import MapBox from "../MapBox";
import {
  Button,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { Edit2, Save, HelpCircle, Loader } from "react-feather";
import NoLocationFG from "../../../../../assets/pngs/mapdetails/noLocationfg.png";
import MapThumbnailFG from "../../../../../assets/pngs/mapdetails/mapThumbnailfg.png";
import { ReactComponent as FloorplanIcon } from "../../../../../assets/svgs/dash-landing/mapdetails/defaultFloorplan.svg";
import { ReactComponent as LocationIcon } from "../../../../../assets/svgs/dash-landing/mapdetails/location.svg";
import { convertToNamedDate } from "../../../../_utils/dateFormatter";
import {
  uploadAsset,
  isThumbnailUploading,
  isFloorplanUploading,
  thumbnailData,
  floorplanData,
  deleteAsset,
  commonLog,
  resetMapDetailsTab,
} from "../../../../../features/common/commonSlice";
import { bulkDeletePaths } from "../../../../../features/webstudio/editor2dSlice";
import {
  getUserMapDetails,
  updateUserMap,
  putFloorplan,
  resetLogs,
} from "../../../../../features/user/studio/studioSlice";
import { setNewAlert } from "../../../../../features/common/alertSlice";
import {
  getVenueDetails,
  updateVenueMap,
  ungroupMap,
} from "../../../../../features/webstudio/buildingSlice";

import { useDispatch, useSelector } from "react-redux";
import { has, isNull, isEmpty, isEqual, isUndefined } from "lodash";
import ARWayImage from "../../../../common/CO/ARWayImage";
import { validateMIME } from "../../../../common/utils";
import SampleFloorplans from "../../../../common/sampleFloorplans";
import MessageModal from "../../../../common/modal";

const SelectedMapDetails = ({
  state,
  handletoggleModal,
  studioLog,
  replaceLog,
  handleReplaceFP,
  selectedMap,
  venueId,
  venueName,
  setSelectedMap,
}) => {
  const { mapId } = selectedMap;
  const { isReplaceFloorplan } = state;
  const maps = state.mapDetails?.maps;
  const identifier = maps.find((m) => m.mapId === selectedMap.mapId)[
    "identifier"
  ];
  const dispatch = useDispatch();
  const commonLogData = useSelector(commonLog);
  const thumbnailFileData = useSelector(thumbnailData);
  const floorplanFileData = useSelector(floorplanData);
  const prevFloorPlanFile = useRef(null);
  const prevThumbnailFile = useRef(null);
  const [thumbnailFileURL, setThumbnailFileURL] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [floorplanFileURL, setFloorplanFileURL] = useState(null);
  const [floorplanFile, setFloorplanFile] = useState(null);
  const [isEditDescription, setisEditDescription] = useState(false);
  const thumbnailStatus = useSelector(isThumbnailUploading);
  const floorplanStatus = useSelector(isFloorplanUploading);
  const [floorplan, setFloorplan] = useState(null);
  const [mapOcclusion, setMapOcclusion] = useState(false);
  const [dialIndicator, setDialIndicator] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(
    maps.find((m) => m.mapId === selectedMap.mapId)["mapName"]
  );
  const mapDetails = useSelector((state) => state.studio.mapDetails);
  const [description, setDescription] = useState("");
  const [isTitleEdit, setIsTitleEdit] = useState(true);
  const [showFloorModal, toggleShowFloorModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setFP();
    dispatch(getUserMapDetails(mapId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapDetails?.metadata?.floors]);

  useEffect(() => {
    setDescription(mapDetails?.metadata?.description);
    setMapOcclusion(mapDetails?.metadata?.occlusion);
    setDialIndicator(mapDetails?.metadata?.indicator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapDetails]);

  const setFP = () => {
    if (has(mapDetails?.metadata, "floors")) {
      const length = mapDetails?.metadata?.floors.length;
      if (length > 0) {
        const floor = mapDetails?.metadata?.floors[length - 1];
        setFloorplan(floor);
      }
    }
  };

  useEffect(() => {
    if (thumbnailFile === false || floorplanFile === false) {
      dispatch(
        setNewAlert({
          msg: "File/Mime type not supported!",
          alertType: "danger",
        })
      );
      if (!thumbnailFile) {
        setThumbnailFile(null);
      }
      if (!floorplanFile) {
        setFloorplanFile(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnailFile, floorplanFile]);

  // useEffect(() => {
  //   setThumbnailFile(null);
  //   setFloorplanFile(null);
  //   setThumbnailFileURL(null);
  //   setFloorplanFileURL(null);
  //   setisEditDescription(false);
  // }, [isBackClicked]);

  useEffect(() => {
    if (replaceLog === "success") {
      dispatch(getVenueDetails(mapId));
      dispatch(bulkDeletePaths({ mapId, reqObj: {}, deleteAll: true }));
      dispatch(resetLogs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replaceLog]);

  useEffect(() => {
    if (isReplaceFloorplan) {
      if (floorplanFile.isUrl) {
        // init sample replace
        handleSampleFloorReplace();
        handleReplaceFP();
      } else {
        createAsset("floorplan").then(() => {
          handleReplaceFP();
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReplaceFloorplan]);

  useEffect(() => {
    // console.log(commonLogData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonLogData]);

  useEffect(() => {
    if (thumbnailFileData) {
      prevThumbnailFile.current = thumbnailFile;
      let metadata = {
        ...mapDetails.metadata,
        thumbnailImage: thumbnailFileData.file,
      };
      dispatch(
        updateUserMap({
          mapId,
          mapDetails: {
            metadata,
          },
        })
      );
    }
    if (floorplanFileData) {
      prevFloorPlanFile.current = floorplanFile;
      dispatch(
        putFloorplan({
          mapId,
          mapDetails,
          floorplanData: {
            link: floorplanFileData?.compressedFile || floorplanFileData.file,
            name: floorplanFile?.name?.replace(/\.[^/.]+$/, ""),
          },
          currFloorplan: floorplan,
        })
      );
    }
    dispatch(resetMapDetailsTab());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnailFileData, floorplanFileData]);

  const handleSampleFloorReplace = () => {
    prevFloorPlanFile.current = floorplanFile;
    dispatch(
      putFloorplan({
        mapId,
        mapDetails,
        floorplanData: {
          link: floorplanFile.image,
          name: floorplanFile?.name?.replace(/\.[^/.]+$/, ""),
        },
        currFloorplan: floorplan,
      })
    );
    dispatch(resetMapDetailsTab());
  };

  const handleFileChange = (e, fData) => {
    var file = e ? e.target.files[0] : fData;
    validateMIME(file, "images", (flag) => {
      if (flag) {
        if (e?.target.id === "thumbnail-input") {
          setThumbnailFileURL(URL.createObjectURL(file));
          setThumbnailFile(file);
        } else {
          setFloorplanFileURL(URL.createObjectURL(file));
          setFloorplanFile(file);
        }
      } else {
        if (e?.target.id === "thumbnail-input") {
          setThumbnailFile(false);
          setThumbnailFileURL(null);
        } else {
          setFloorplanFile(false);
          setFloorplanFileURL(null);
        }
      }
    });
  };

  const removeAsset = (url) => {
    dispatch(deleteAsset({ assetUrl: url }));
  };

  const createAsset = (assetType) => {
    let isThumbnail = assetType === "thumbnail";
    if (isThumbnail && mapDetails?.metadata.thumbnailImage) {
      removeAsset(mapDetails?.metadata.thumbnailImage);
    }
    /* if (!isThumbnail && mapDetails?.metadata.floors?.length > 0) {
      removeAsset(floorplan.link);
    } */
    let reqObj = new FormData();
    reqObj.append("file", isThumbnail ? thumbnailFile : floorplanFile);
    reqObj.append("contentType", isThumbnail ? "maps" : "floorplans");
    reqObj.append("mapId", mapDetails?.metadata.id);
    reqObj.append("compressFile", true);
    let data = {
      reqObj,
      ...(isThumbnail
        ? { isThumbnailUploading: true }
        : { isFloorplanUploading: true }),
      assetType,
    };
    return dispatch(uploadAsset(data));
  };

  const onSampleFloorSuccess = (object) => {
    if (object) {
      if (object.type === "file") handleFileChange(null, object.data);
      else if (object.type === "url") {
        setFloorplanFileURL(object.data.image);
        setFloorplanFile({ ...object.data, isUrl: true });
      }
    }
    toggleShowFloorModal(false);
  };

  const handleMapOcclusionChange = (value) => {
    setMapOcclusion(value);
    let metadata = { ...mapDetails.metadata };
    metadata = {
      ...metadata,
      occlusion: value,
    };

    dispatch(updateUserMap({ mapId, mapDetails: { metadata } })).then(() => {
      setLoading(false);
    });
  };

  const handleDialIndicatorChange = (value) => {
    setDialIndicator(value);
    let metadata = { ...mapDetails.metadata };
    metadata = {
      ...metadata,
      indicator: value,
    };
    dispatch(updateUserMap({ mapId, mapDetails: { metadata } })).then(() => {
      setLoading(false);
    });
  };

  const capitalizeFirstChar = (str) => {
    return isNull(str) || isUndefined(str) || isEmpty(str)
      ? ""
      : str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleDescriptionOnclick = (e) => {
    let metadata = { ...mapDetails.metadata, description };
    dispatch(
      updateUserMap({
        mapId,
        mapDetails: { metadata },
      })
    );
  };

  const handleUngroupingMaps = () => {
    dispatch(ungroupMap({ venueId, mapId, venueName, mapName: name }));
    setSelectedMap();
    setOpenModal(false);
  };

  const handleSaveChanges = (e) => {
    if (name.length <= 0) {
      setNewAlert({
        msg: "Name cannot be empty!",
        alertType: "danger",
      });
    } else if (name.length < 3) {
      setNewAlert({
        msg: "Minimum three character required!",
        alertType: "danger",
      });
    } else if (description.length <= 0) {
      setNewAlert({
        msg: "Description cannot be empty!",
        alertType: "danger",
      });
    } else {
    }
  };

  const getLocationString = () => {
    let location  = '';

    if(mapDetails?.metadata?.location?.city) {
      location += capitalizeFirstChar(
        mapDetails?.metadata?.location?.city
      ) + ", "
    }

    if(mapDetails?.metadata?.location?.region) {
      location += capitalizeFirstChar(
        mapDetails?.metadata?.location?.region
      ) + ", "
    }

    if(mapDetails?.metadata?.location?.country) {
      location += capitalizeFirstChar(
        mapDetails?.metadata?.location?.country
      );
    }

    return location;
  }

  const isValidLocation =
    mapDetails?.metadata?.gpsCoordinates?.coordinates[1] !== 0 &&
    mapDetails?.metadata?.gpsCoordinates?.coordinates[0];
  return (
    <div>
      <Grid container spacing={2} className={"pt-4"}>
        <Grid item xs={12}>
          <div className="shadow p-3 mb-3 mt-2 bg-body rounded">
            <Grid container>
              <Grid item xs={4} className="mapinfo__mapTopRow">
                <div className="d-flex bd-highlight">
                  <div className="p-2 flex-fill bd-highlight">
                    {isTitleEdit ? (
                      <label
                        className={`mapinfo__mapFormInputMapname mapinfo__mapFormInputSubMapname`}
                        id="input-mapName"
                      >
                        {`${identifier} -${name}`}
                      </label>
                    ) : (
                      <input
                        type="text"
                        readOnly={isTitleEdit}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        onBlur={(e) => {
                          setName(e.target.value.trim());
                        }}
                        className={`mapinfo__mapFormInput ${
                          !isTitleEdit ? "mapinfo__mapFormInput--active" : ""
                        }`}
                        autoComplete="off"
                        maxLength={30}
                        minLength={3}
                        id="name"
                      />
                    )}
                  </div>
                  <div className="p-2 flex-fill bd-highlight">
                    {isTitleEdit ? (
                      <Edit2
                        style={{ cursor: "pointer", marginTop: "1.2rem" }}
                        onClick={() => {
                          setIsTitleEdit(!isTitleEdit);
                        }}
                        id="button-editMapName"
                      />
                    ) : (
                      <Save
                        style={{ cursor: "pointer", marginTop: "1.6rem" }}
                        onClick={() => {
                          let venueDetails = state.mapDetails;
                          let updatedMaps = venueDetails.maps.map((m) => {
                            if (m.mapId === selectedMap.mapId) {
                              return { ...m, mapName: name };
                            }
                            return m;
                          });
                          if (name !== mapDetails.name && name.length >= 3) {
                            dispatch(
                              updateVenueMap({
                                mapId: state.mapData,
                                venueDetails: {
                                  ...venueDetails,
                                  maps: updatedMaps,
                                },
                              })
                            );
                            setIsTitleEdit(!isTitleEdit);
                          } else {
                            handleSaveChanges();
                          }
                        }}
                        id="button-saveMapName"
                      />
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={6}
                className="mapinfo__separator mapinfo__mapTopRow"
              >
                <div className="mapinfo__mapStatusRow">
                  <div
                    style={{ height: "100%" }}
                    className={`mapinfo__mapStatusSpan mapinfo__mapStatusSpan--draft`}
                    id="span-mapStatus"
                  >
                    {identifier}
                  </div>
                  <div>
                    <span className="mapinfo__mapStatusSpan2">&bull;</span>
                    <span className="mapinfo__mapStatusSpan2">
                      Modified on {convertToNamedDate(selectedMap?.modifiedOn)}
                    </span>
                    <span className="mapinfo__mapStatusSpan2">&bull;</span>
                    <span className="mapinfo__mapStatusSpan2">
                      Created on {convertToNamedDate(selectedMap?.createdOn)}
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="mapinfo__mDescCont pt-3">
              <div className="mapdetails__title">
                <span className="mapinfo__mDescCont--labelSpan">
                  Floor Description
                </span>
                {isEditDescription && (
                  <span className="mapinfo__mDescCont--inputLenHelper">
                    {description?.length}/500
                  </span>
                )}
              </div>
              <textarea
                rows={3}
                id="description"
                readOnly={!isEditDescription}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                className={`mapinfo__mapFormTextArea ${
                  isEditDescription
                    ? "mapinfo__mapFormTextArea--active"
                    : "mapinfo__mapFormTextArea--inactive"
                }`}
                autoComplete="off"
                maxLength={500}
              />
              <div className="mapinfo__mapImageControlOverlay m-0">
                <label
                  onClick={() => {
                    setisEditDescription(!isEditDescription);
                  }}
                >
                  {isEditDescription ? (
                    <span
                      className="mapinfo__mapImageControlOverlay--text"
                      id="span-saveDescription"
                      onClick={(e) => {
                        handleDescriptionOnclick(e);
                        setisEditDescription(!isEditDescription);
                      }}
                    >
                      Save Description
                    </span>
                  ) : (
                    <span
                      className="mapinfo__mapImageControlOverlay--text"
                      id="span-editDescription"
                    >
                      Edit Description
                    </span>
                  )}
                </label>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={6} className="mapinfo__separator">
          <div className="card shadow p-3 bg-body rounded">
            <div className="card-header mapdetails__cardbg">
              <h5 className="card-title mapdetails__title">
                {identifier} Thumbnail
              </h5>
            </div>
            <ARWayImage
              src={
                thumbnailFileURL
                  ? thumbnailFileURL
                  : mapDetails?.metadata?.thumbnailImage
                  ? mapDetails?.metadata?.thumbnailImage
                  : MapThumbnailFG
              }
              className={`card-img-top ${
                thumbnailFileURL || mapDetails?.metadata?.thumbnailImage
                  ? "mapdetails__mapthumbnail"
                  : "mapdetails__mapthumbnailbgImage"
              }`}
              alt="Thumbail image"
              style={{
                borderRadius: "8px",
                objectFit:
                  thumbnailFileURL || mapDetails?.metadata?.thumbnailImage
                    ? "contain"
                    : "none",
              }}
            />
            <div className="card-body">
              <div className="d-inline-flex align-items-center">
                <div className="mapinfo__mapImageControlOverlay m-0">
                  <label htmlFor="thumbnail-input" id="label-uploadThumbnail">
                    <span className="mapinfo__mapImageControlOverlay--text">
                      {mapDetails?.isPublished
                        ? "Change Thumbnail"
                        : thumbnailStatus
                        ? "Uploading..."
                        : "Select Thumbnail"}
                    </span>
                  </label>
                  <input
                    id="thumbnail-input"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    onChange={handleFileChange}
                  />
                </div>
                {!isEqual(prevThumbnailFile.current, thumbnailFile) ? (
                  <div
                    className="ps-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      createAsset("thumbnail");
                    }}
                  >
                    <div className="mapinfo__mapImageControlOverlay m-0">
                      <label>
                        <span
                          className="mapinfo__mapImageControlOverlay--text"
                          id="thumbnail-save"
                        >
                          Save
                        </span>
                      </label>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={6} className="mapinfo__separator">
          <React.Fragment>
            <div
              className="card shadow p-3 bg-body rounded"
              style={{ height: "100%" }}
            >
              <div className="card-header mapdetails__cardbg">
                <h5 className="card-title mapdetails__title">Map Location</h5>
              </div>
              <img
                alt={isValidLocation ? "" : "No Location"}
                src={isValidLocation ? "" : NoLocationFG}
                className={`card-img-top  ${
                  isValidLocation ? "" : "mapdetails__mapboxbgImage"
                }`}
              />
              <div className={`card-body ${isValidLocation ? "p-0" : ""}`}>
                {isValidLocation ? (
                  <MapBox
                    lat={mapDetails?.metadata?.gpsCoordinates?.coordinates[1]}
                    lon={mapDetails?.metadata?.gpsCoordinates?.coordinates[0]}
                  />
                ) : null}
                {mapDetails?.metadata?.location ? (
                  <div className="mt-3">
                    <label
                      className="content-type-select"
                      style={{ lineHeight: "normal" }}
                    >
                      <LocationIcon />{" "}
                      {getLocationString()}
                    </label>
                  </div>
                ) : (
                  "No Location Available"
                )}
              </div>
            </div>
          </React.Fragment>
        </Grid>
        <Grid item xs={12}>
          {has(mapDetails?.metadata, "floors") &&
          (mapDetails?.metadata?.floors?.length > 0 || floorplanFileURL) ? (
            <div className="card shadow p-3 bg-body rounded">
              <div className="card-header mapdetails__cardbg">
                <div className="d-flex justify-content-between">
                  <h5 className="card-title mapdetails__title">
                    {`${identifier}`} - Floor Plans
                  </h5>
                </div>
              </div>
              <ARWayImage
                src={
                  floorplanFileURL
                    ? floorplanFileURL
                    : has(mapDetails?.metadata, "floors") &&
                      mapDetails?.metadata?.floors?.length > 0
                    ? floorplan
                      ? floorplan.link
                      : ""
                    : ""
                }
                className="card-img-top mapdetails__mapFloorplan"
                alt="Floorplan image"
              />
              <div className="card-body">
                <h5 className="card-title pt-3">
                  {floorplanFile
                    ? floorplanFile?.name
                    : has(mapDetails?.metadata, "floors") &&
                      mapDetails?.metadata?.floors?.length > 0
                    ? floorplan
                      ? floorplan.name
                      : ""
                    : ""}
                </h5>
                <div className="d-inline-flex align-items-center pt-4">
                  <div className="mapinfo__mapImageControlOverlay m-0">
                    <div
                      style={{ cursor: "pointer" }}
                      className="dashboardpage__dpIntroCard--button"
                      onClick={() => toggleShowFloorModal(true)}
                    >
                      {floorplanFile || floorplan
                        ? "Change Floor plan"
                        : floorplanStatus
                        ? "Uploading..."
                        : "Select Floor plan"}
                    </div>
                  </div>
                  {(floorplanFile && !prevFloorPlanFile.current) ||
                  !isEqual(prevFloorPlanFile.current, floorplanFile) ? (
                    <div
                      className="ps-3"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        if (
                          mapDetails.metadata.isPublished &&
                          mapDetails?.metadata?.floors?.length > 0
                        ) {
                          handletoggleModal("replaceFloorplanModal");
                        } else {
                          if (floorplanFile.isUrl) handleSampleFloorReplace();
                          else createAsset("floorplan");
                        }
                      }}
                    >
                      <div className="mapinfo__mapImageControlOverlay m-0">
                        <label
                          style={{ cursor: "pointer" }}
                          className={`dashboardpage__dpIntroCard--button dashboardpage__dpIntroCard--button-w120 ${floorplanStatus && "dashboardpage__dpIntroCard--button--disabled"}`}
                          id="save-floorplan"
                        >
                          {floorplanStatus ?<Loader className="dashboardpage__dpIntroCard--loader" /> : "Save" }
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div className="shadow p-3 mb-3 bg-body rounded mapdetails__mapDefaultFloorplan">
              <div className="pb-4">Uploaded Floor plan</div>
              <FloorplanIcon />
              <label
                className="content-type-select mt-4"
                style={{ lineHeight: "normal" }}
              >
                No floor plan uploaded yet. Tap the button to upload a floor
                plan.
              </label>
              <div className="pt-4">
                <div className="mapinfo__mapImageControlOverlay m-0">
                  <div
                    style={{ cursor: "pointer" }}
                    className="dashboardpage__dpIntroCard--button"
                    onClick={() => toggleShowFloorModal(true)}
                  >
                    Upload New Floor plan
                  </div>
                </div>
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <div className="shadow p-3 mb-5 bg-body rounded">
            <div className="mapinfo__mDescCont--labelSpan mapdetails__title">
              Map Settings
            </div>
            <div
              /* className={`mapinfo__mDataContainer ${
                mapDetails?.metadata.isPublished ? "" : "opacity-50"
              }`} */
              className="mapinfo__mDataContainer"
            >
              <div className="mapinfo__mDataContainer--header">
                Occlusion
                <HelpCircle
                  size={18}
                  color="#353E5A"
                  className="mapinfo__mDataContainer--mapIcon"
                  onClick={() => handletoggleModal("occlusionModal")}
                />
              </div>
              <div className="mapinfo__mDataContainer--infoRow">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value="public"
                  name="radio-buttons-group"
                  className="mapinfo__mDataContainer--radioCont"
                >
                  <div className="mapinfo__mDataContainer--radioCont--dataCol">
                    <FormControlLabel
                      className="mapinfo__mDataContainer--data"
                      id="mapStatus"
                      value="private"
                      // disabled={!mapDetails?.metadata.isPublished}
                      checked={mapOcclusion === true}
                      control={<Radio />}
                      label="Enabled"
                      onChange={() => {
                        setLoading(true);
                        handleMapOcclusionChange(true);
                      }}
                      disabled={loading}
                    />
                    <span className="mapinfo__mDataContainer--radioCont--text">
                      Objects will not be visible if placed behind a wall
                    </span>
                  </div>
                  <div className="mapinfo__mDataContainer--radioCont--dataCol">
                    <FormControlLabel
                      className="mapinfo__mDataContainer--data"
                      id="mapStatus"
                      value="private"
                      // disabled={!mapDetails?.metadata.isPublished}
                      checked={mapOcclusion === false}
                      control={<Radio />}
                      label="Disabled"
                      onChange={() => {
                        setLoading(true);
                        handleMapOcclusionChange(false);
                      }}
                      disabled={loading}
                    />
                    <span className="mapinfo__mDataContainer--radioCont--text">
                      Objects will be visible if placed behind a wall or another
                      real world object
                    </span>
                  </div>
                </RadioGroup>
              </div>
            </div>
            <div
              /* className={`mapinfo__mDataContainer ${
                mapDetails?.metadata.isPublished ? "" : "opacity-50"
              }`} */
              className="mapinfo__mDataContainer"
            >
              <div className="mapinfo__mDataContainer--header">
                Drift Dial Indicator
                <HelpCircle
                  size={18}
                  color="#353E5A"
                  className="mapinfo__mDataContainer--mapIcon"
                />
              </div>
              <div className="mapinfo__mDataContainer--infoRow">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value="public"
                  name="radio-buttons-group"
                  className="mapinfo__mDataContainer--radioCont"
                >
                  <div className="mapinfo__mDataContainer--radioCont--dataCol">
                    <FormControlLabel
                      className="mapinfo__mDataContainer--data"
                      id="mapStatus"
                      value="private"
                      // disabled={!mapDetails?.metadata.isPublished}
                      checked={dialIndicator === true}
                      control={<Radio />}
                      label="Show Dial"
                      onChange={() => {
                        setLoading(true);
                        handleDialIndicatorChange(true);
                      }}
                      disabled={loading}
                    />
                    <span className="mapinfo__mDataContainer--radioCont--text">
                      The dial in the top right corner will always be visible
                      paired with notifications
                    </span>
                  </div>
                  <div className="mapinfo__mDataContainer--radioCont--dataCol">
                    <FormControlLabel
                      className="mapinfo__mDataContainer--data"
                      id="mapStatus"
                      value="private"
                      // disabled={!mapDetails?.metadata.isPublished}
                      checked={dialIndicator === false}
                      control={<Radio />}
                      label="Show Notifications"
                      onChange={() => {
                        setLoading(true);
                        handleDialIndicatorChange(false);
                      }}
                      disabled={loading}
                    />
                    <span className="mapinfo__mDataContainer--radioCont--text">
                      Dial will be hidden, notifications will appear when
                      visitors need to scan an access point
                    </span>
                  </div>
                </RadioGroup>
              </div>
            </div>
          </div>
        </Grid>
        <div className="mapinfo__mDeleteCont ps-3">
          <Button
            variant="outlined"
            className="mapinfo__mDeleteCont--span"
            style={{ boxShadow: "0px 0px 2px 2px red" }}
            onClick={() => setOpenModal(true)}
            id="button-deleteMap"
          >
            <span className="mapinfo__mDescCont--labelSpan">
              Ungroup from the map
            </span>
          </Button>
        </div>
      </Grid>
      <SampleFloorplans
        openModal={showFloorModal}
        onSuccess={onSampleFloorSuccess}
        onCloseModal={() => toggleShowFloorModal(false)}
        modalData={mapDetails}
      />
      <MessageModal
        openModal={openModal}
        onCloseModal={() => setOpenModal(false)}
        inlineStyle={{ height: "max-content", width: "60vw" }}
      >
        <div className="infomodal">
          <div className="infomodal__mapModalHeader">
            Ungroup {name} from the building!
          </div>
          <div className="infomodal__mapModalText">
            Are you sure, you want to ungroup {name} from the building?
          </div>
          <div className="infomodal__mapModalText">
            Users won't be able to see this map in building. <br /> Please
            delete Connector Pins (if any) in the Connected Maps.
          </div>
          <div className="publishModalBtnsDiv">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="errorRed"
              onClick={() => handleUngroupingMaps()}
              className="mapdetails__mapModalBtnText"
            >
              Ungroup
            </Button>
          </div>
        </div>
      </MessageModal>
    </div>
  );
};

export default SelectedMapDetails;
