import * as THREE from "three";
import EditorExperience from "../../MinEditorExperience";
import EventEmitter from "../../../utils/EventEmitter";

import {
    fromPosObjectToVec3Pos,
    fromQuaternionObjectToQuaternion,
    fromScaleObjectToVec3Scale,
} from "../../../threeUtils/TransformConversions";

export default class FloorplanV1 extends EventEmitter {
    constructor(floorData) {
        super();
        this.editor = new EditorExperience();

        this.position = floorData.position
            ? fromPosObjectToVec3Pos(floorData.position)
            : new THREE.Vector3(0, 0, 0);
        this.rotation = floorData.rotation
            ? fromQuaternionObjectToQuaternion(floorData.rotation)
            : new THREE.Quaternion(-0.7071068, 0, 0, 0.7071068);
        this.scale = floorData.scale
            ? fromScaleObjectToVec3Scale(floorData.scale)
            : new THREE.Vector3(1, 1, 1);
        this.imageMesh = null;

        this.name = floorData.name ? floorData.name.substring(0, 20) : "";
        this.assetLink = floorData.link;
        this.alignmentStatus =
            "alignmentStatus" in floorData ? floorData.alignmentStatus : false;

        this.pegs = "pegs" in floorData ? floorData.pegs : [];

        this.texLoader = new THREE.TextureLoader();

        this.setupObject();
    }

    setupObject = () => {
        let imgTex = this.texLoader.load(this.assetLink);
        imgTex.minFilter = imgTex.magFilter = THREE.LinearFilter;
        imgTex.colorSpace = THREE.SRGBColorSpace;
        imgTex.anisotropy = 4;
        if (this.alignmentStatus) {
            imgTex.flipY = false;
            imgTex.center.set(0.5, 0.5);
            imgTex.repeat.set(1, 1);
        }

        this.imgMat = new THREE.MeshBasicMaterial({
            map: imgTex,
            side: THREE.DoubleSide,
        });
        let imgGeo = new THREE.PlaneGeometry(10, 10, 32, 32);
        let imgMesh = new THREE.Mesh(imgGeo, this.imgMat);
        if (this.alignmentStatus) {
            imgMesh.position.set(5, 0, 5);
            imgMesh.rotation.x = THREE.MathUtils.degToRad(90);
        }
        imgMesh.name = this.name.substring(0, 20);

        this.imageMesh = new THREE.Group();
        this.imageMesh.add(imgMesh);
        this.imageMesh.name = this.name.substring(0, 20);
        this.imageMesh.userData.type = "floorplan";
        this.imageMesh.quaternion.copy(this.rotation);
        this.imageMesh.position.copy(this.position);
        this.imageMesh.scale.copy(this.scale);

        let min = this.position.y;

        this.pegs.forEach((peg, index) => {
            let fPegPos = fromPosObjectToVec3Pos(peg.position);
            // default it to 1sr peg
            if (index === 0) {
                min = fPegPos.y;
            }
            if (fPegPos.y < min) {
                min = fPegPos.y;
            }
        });

        this.imageMesh.position.y = min;
    };
}
