import { omit, has } from "lodash";
import * as THREE from "three";
import EditorExperience from "../EditorExperience";
import EventEmitter from "../utils/EventEmitter";

import {
    fromPosObjectToVec3Pos,
    fromQuaternionObjectToEuler,
    fromQuaternionToQuaternionObject,
    fromVec3PosToPosObject,
} from "./TransformConversions";

export default class QRAnchor extends EventEmitter {
    constructor(qrData) {
        super();
        this.editor = new EditorExperience();
        this.qrData = qrData;
        this.qrMesh = null;

        this.id = qrData.id;
        this.anchorType = qrData.anchorIndex;
        this.name =
            qrData.name && qrData.name.length > 0
                ? qrData.name.substring(0, 20)
                : "";
        this.description = qrData.description || "Sample Description";

        this.visible = "visible" in qrData ? qrData.visible : true;
        this.isPrimary = qrData.anchorIndex === "primary";

        this.direction = qrData.direction || "--";
        this.dimension = qrData.dimension || "--";

        this.position = qrData.position
            ? fromPosObjectToVec3Pos(qrData.position)
            : new THREE.Vector3(0, 0, 0);
        this.rotation = qrData.rotation
            ? fromQuaternionObjectToEuler(qrData.rotation)
            : new THREE.Euler(0, 0, 0);

        this.editor.jsonQRAnchors.push({
            ...qrData,
            name: this.name,
            description: this.description,
        });
        this.bufferedChanges = {};

        this.editor.on("MetaObjectChanged", this.onMetaObjectChanged);
        this.editor.on("SaveMetaObject", this.onSaveMetaObject);
        this.editor.on("objectRemoved", this.onObjectRemoved);
        this.editor.on("objectChanged", this.onObjectChanged);
        this.editor.on("saveAdjustAccessChanges", this.updateObject);

        this.setupSprite();
    }

    setupSprite = () => {
        const qrModel = this.isPrimary
            ? this.editor.qrMainPin.clone()
            : this.editor.qrPin.clone();
        qrModel.scale.set(0.7, 0.7, 0.7);
        qrModel.rotateX(THREE.MathUtils.DEG2RAD * 90);
        qrModel.rotateY(THREE.MathUtils.DEG2RAD * 90);
        qrModel.children.forEach((child) => {
            if (child.name === "Direction") {
                if (
                    this.direction === "verticle" ||
                    this.direction === "vertical"
                ) {
                    child.rotateY(THREE.MathUtils.DEG2RAD * 90);
                } else if (this.direction === "--") {
                    child.visible = false;
                }
            }
        });
        qrModel.name = this.name;
       
        this.qrMesh = new THREE.Group();
        this.qrMesh.add(qrModel);
        this.qrMesh.name = this.name;
        this.qrMesh.userData["type"] = "qrAnchor";
        this.qrMesh.userData["skipChild"] = "qrAnchor";
        this.qrMesh.userData["id"] = this.id;
        this.qrMesh.userData["description"] = this.description;
        this.qrMesh.userData["transformation"] = "NO_TRANSFORM";
        this.qrMesh.userData["qrAnchor"] = true;
        // this.qrMesh.userData["rotateOn"] = "Y";
        this.qrMesh.userData["visible"] = this.visible;
        this.qrMesh.userData["qrcodeview"] = true;
        this.qrMesh.userData["MetaObject"] = true;
        this.qrMesh.userData["direction"] = this.direction;
        this.qrMesh.userData["dimension"] = this.dimension;
        this.qrMesh.userData["accessPointDimDir"] = true;
        this.qrMesh.userData["accessAdjust"] = true;
        this.qrMesh.userData["primaryAnchor"] = this.isPrimary;
        this.qrMesh.position.copy(this.position);
        this.qrMesh.rotation.copy(this.rotation);

        this.qrMesh.visible = this.visible;
    };

    onSaveMetaObject = () => {
        if (Object.keys(this.bufferedChanges).length > 0) {
            const idx = this.editor.getIndex("jsonQRAnchors", this.id);
            let reqObj = { ...this.editor.jsonQRAnchors[idx] };

            reqObj = omit(reqObj, ["createdOn", "modifiedOn"]);

            if (has(this.bufferedChanges, "name")) {
                reqObj.name = this.bufferedChanges.name;
                this.name = this.bufferedChanges.name;
                this.qrMesh.name = this.bufferedChanges.name;
            }
            if (has(this.bufferedChanges, "description")) {
                reqObj.description = this.bufferedChanges.description;
                this.description = this.bufferedChanges.description;
                this.qrMesh.userData.description =
                    this.bufferedChanges.description;
            }
            this.editor.jsonQRAnchors[idx] = reqObj;
            this.editor.onAnchorAPICalls(reqObj, "UPDATE");
        }
    };

    onMetaObjectChanged = (object, property, value, discardAll = false) => {
        if (object === this.qrMesh) {
            this.bufferedChanges[property] = value;
        } else if (discardAll) {
            this.bufferedChanges = {};
        }
    };

    onObjectChanged = (object) => {
        if (this.qrMesh === object) {
            this.position.copy(object.position);
            this.rotation.copy(object.rotation);
        }
    };

    updateObject = (object) => {
        if (this.qrMesh === object) {
            const idx = this.editor.getIndex("jsonQRAnchors", this.id);
            let reqObj = { ...this.editor.jsonQRAnchors[idx] };

            reqObj = omit(reqObj, ["createdOn", "modifiedOn"]);
            const quat = new THREE.Quaternion().setFromEuler(object.rotation);



            reqObj.position = fromVec3PosToPosObject(object.position);
            reqObj.rotation = fromQuaternionToQuaternionObject(quat);


            this.editor.jsonQRAnchors[idx] = reqObj;
            this.editor.onAnchorAPICalls(reqObj, "UPDATE");
        }
    };

    onObjectRemoved = (object, delayAutosave, isVersionSwitch = false) => {
        if (!isVersionSwitch && object === this.qrMesh) {
            const idx = this.editor.getIndex("jsonQRAnchors", this.id);
            const anchor = this.editor.jsonQRAnchors.splice(idx, 1);
            this.editor.onAnchorAPICalls(anchor[0].id, "DELETE");
            this.onCallDestructor(object);
        }
    };

    onCallDestructor = (object) => {
        if (object === this.qrMesh) {
            this.editor.stop("MetaObjectChanged", this.onMetaObjectChanged);
            this.editor.stop("SaveMetaObject", this.onSaveMetaObject);
            this.editor.stop("objectRemoved", this.onObjectRemoved);
            this.editor.stop("objectChanged", this.onObjectChanged);
        }
    };
}
