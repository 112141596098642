import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
//Comp
import EditorExperience from "../InteractiveExperience";
import GSAP from "gsap";
import { detectMobile } from "../../_utils/detectBrowser";

export default class Camera {
    constructor() {
        this.editor = new EditorExperience();
        this.sizes = this.editor.sizes;
        this.canvas = this.editor.canvas;
        this.scene = this.editor.scene;

        this.animTimer = 2;
        this.zoomLevel = 100;

        this.initInstance();
        this.initOrbitControls();

        this.editor.resources.on("ready", this.updateCameraPosition);
        this.editor.on("interactiveZoomSelector", this.onZoomSelector);
        this.editor.on("sceneSwitched", this.updateCameraPosition);
    }

    initInstance = () => {
        this.instance = new THREE.PerspectiveCamera(
            35,
            this.sizes.width / this.sizes.height,
            0.1,
            2000
        );
        this.instance.position.set(8, 6, 8);
        this.instance.name = "ARway-Camera";
        this.instance.lookAt(this.scene.position);
        this.scene.add(this.instance);
    };

    initOrbitControls = () => {
        this.controls = new OrbitControls(this.instance, this.canvas);
        this.controls.enableDamping = true;
        this.controls.rotateSpeed = 0.8;
        this.controls.zoomSpeed = 0.8;
        this.controls.panSpeed = 0.8;
        this.controls.dampingFactor = 0.2;
        this.controls.maxPolarAngle = THREE.MathUtils.degToRad(85);
        this.controls.mouseButtons = {
            LEFT: THREE.MOUSE.PAN,
            MIDDLE: THREE.MOUSE.DOLLY,
            RIGHT: THREE.MOUSE.ROTATE,
        };
        this.controls.screenSpacePanning = false;
    };

    resize = () => {
        this.instance.aspect = this.sizes.width / this.sizes.height;
        this.instance.updateProjectionMatrix();
    };

    update = () => {
        this.controls.update();
    };

    updateCameraPosition = () => {
        this.scene = this.editor.scene;

        if(detectMobile()) {
            this.controls.mouseButtons = {
                LEFT: THREE.MOUSE.ROTATE,
                MIDDLE: THREE.MOUSE.DOLLY,
                RIGHT: THREE.MOUSE.PAN,
            };
        } else {
            this.controls.mouseButtons = {
                LEFT: THREE.MOUSE.PAN,
                MIDDLE: THREE.MOUSE.DOLLY,
                RIGHT: THREE.MOUSE.ROTATE,
            };
        }
        const sceneGroup = new THREE.Group();
        this.scene.children.map((child) => sceneGroup.add(child.clone()));
        const box = new THREE.Box3().setFromObject(sceneGroup);

        const center = box.getCenter(new THREE.Vector3());
        const size = box.getSize(new THREE.Vector3());
        const maxSize = Math.max(size.x, size.y, size.z);

        this.controls.target.set(center.x, center.y, center.z);
        this.instance.position.set(center.x, center.y + (maxSize * 1.5), center.z); 
        this.editor.sceneCenter = center.clone();

        this.setZoomLimits(box);
    };

    relocateCamera = (target) => {
        // Calculate Bounds!
        const box = new THREE.Box3().setFromObject(target);
        const center = box.getCenter(new THREE.Vector3());
        const size = box.getSize(new THREE.Vector3());

        const maxSize = Math.max(size.x, size.y, size.z);

        this.controls.enabled = false;

        let distance = center.y + (maxSize < 15 ? maxSize + 15 : maxSize * 1.5);

        GSAP.to(this.instance.position, {
            duration: this.animTimer,
            x: center.x,
            y: distance,
            z: center.z,
            onUpdate: () => {
                this.instance.lookAt(target.position);
            },
        });

        GSAP.to(this.controls.target, {
            duration: this.animTimer,
            x: center.x,
            y: center.y,
            z: center.z,
            onComplete: () => {
                this.controls.enabled = true;
            },
        });
    };

    setZoomLimits = (box3) => {
        const bSize = box3.getSize(new THREE.Vector3());

        const maxSize = Math.max(bSize.x, bSize.y, bSize.z);
        const fitHeightDistance =
            maxSize / (2 * Math.atan((Math.PI * this.instance.fov) / 360));
        const fitWidthDistance = fitHeightDistance / this.instance.aspect;

        let maxDistance = 1.2 * Math.max(fitHeightDistance, fitWidthDistance);

        // console.log("maxDistance", maxDistance, fitHeightDistance, fitWidthDistance);

        // for smaller make the max distance 10 units
        if (maxDistance < 20) {
            maxDistance = 20;
        }

        this.controls.maxDistance = maxDistance;
        this.controls.minDistance = 0;

        this.update();
    };

    onZoomSelector = (option) => {

        if(option === "reset") {
            this.zoomLevel = 100;
            this.updateCameraPosition();
        } else {
            this.scene = this.editor.scene;
            let offset = 1;
            if(option === "zoomIn") {
                this.zoomLevel > 10 && (this.zoomLevel -= 10);
                const zoomTo = (parseInt(this.zoomLevel / 10, 10) * 10 - 10) / 100;
                offset = zoomTo < 0.1 ? 0.1 : zoomTo;
            } else if(option === "zoomOut") {
                this.zoomLevel < 120 && (this.zoomLevel += 10);
                offset = (parseInt(this.zoomLevel / 10, 10) * 10 + 10) / 100
            }
    
            const sceneGroup = new THREE.Group();
            for (var i = 0; i < this.scene.children.length; i++) {
            const obj = this.scene.children[i];
            if (this.toAdd(obj)) sceneGroup.add(obj.clone());
            }
            const box3 = new THREE.Box3().setFromObject(sceneGroup);
            this.zoomCameraAroundBox3(box3, offset);
        }
    }

    toAdd(object) {
        if (object && object.type.includes("Camera")) return false;
        if (object && object.type.includes("Light")) return false;
        if (object && object.type.includes("GridHelper")) return false;
        if (object && object.type.includes("BoxHelper")) return false;
    
        return true;
    }


    zoomCameraAroundBox3 = (box3, fitOffset = 1) => {
        const bCenter = box3.getCenter(new THREE.Vector3());
        const bSize = box3.getSize(new THREE.Vector3());

        const maxSize = Math.max(bSize.x, bSize.y, bSize.z);
        const fitHeightDistance =
            maxSize / (2 * Math.atan((Math.PI * this.instance.fov) / 360));
        const fitWidthDistance = fitHeightDistance / this.instance.aspect;

        let maxDistance = Math.max(fitHeightDistance, fitWidthDistance);

        // for smaller make the max distance 10 units
        if(maxDistance < 20) {
            maxDistance = 10
        }

        let distance =
            fitOffset * maxDistance;

        distance = distance === 0 ? 1 : distance;

        const direction = this.controls.target
            .clone()
            .sub(this.instance.position)
            .normalize()
            .multiplyScalar(distance);

        this.controls.target.copy(bCenter);

        this.instance.updateProjectionMatrix();

        this.instance.position.copy(this.controls.target).sub(direction);

        this.controls.update();
    };
}
