import EventEmitter from "./EventEmitter";
import EditorExperience from "../InteractiveExperience";
// ThreeJS Classes
import Floorplan from "../threeUtils/Floorplan/Floorplan";
import FloorplanV1 from "../threeUtils/Floorplan/FloorplanV1";
import LocationPin from "../threeUtils/LocationPins/LocationPin";
import Connector from "../threeUtils/Connectors/ConnectorPin";

export default class ObjectsLoader extends EventEmitter {
    constructor() {
        super();

        this.editor = new EditorExperience();
        this.sources = [];

        this.items = {};
        this.toLoad = 0;
        this.loaded = 0;

        this.asyncObject = [];

        this.editor.on("clearAndReset", this.onClearAndReset);
        this.editor.on("asyncObjectLoaded", this.onAsyncObjectLoaded);
    }

    onClearAndReset = () => {
        this.loaded = 0;
        this.items = {};
        this.sources = [];
    };

    initiateLoading = (sources) => {
        this.sources = this.toLinearArray(sources);
        this.toLoad = this.sources.length;
        this.sources.length > 0 ? this.startLoading() : this.isReady();
    };

    toLinearArray = (sources) => {
        let arr = [];
        for (const source in sources) {
            if (
                source !== "textNote3Ds" &&
                source !== "nftItems" &&
                source !== "floorplans" &&
                source !== "destination3ds"
            ) {
                arr = [...arr, ...sources[source]];
            }
        }
        //Make Array unique!
        return [...new Map(arr.map((item) => [item["id"], item])).values()];
    };

    setExtraResources = (resources, type) => {
        if (resources && type === "locationPins") {
            this.toLoad += resources.length;
            this.loadPins(resources);
            this.editor.loadedExtraResources = true;
        } else if (resources && type === "connectorPins") {
            this.loadConnectors(resources);
        }
    };

    startLoading = () => {
        // console.log(this.sources);
        for (const source of this.sources) {
            if (source.id && source.id.includes("floorplan")) {
                if (!(source.id in this.items)) {
                    if (source.id.includes("metadata_floorplans")) {
                        const len = source.floorplans.length;
                        const floor = source.floorplans[len - 1];
                        if (
                            len > 0 &&
                            this.asyncObject.indexOf(floor.id) === -1
                        ) {
                            if ("floorplanVersion" in floor) {
                                this.editor.floorplanVersion =
                                    floor.floorplanVersion;
                            }
                            // console.log("Floor Version", this.editor.floorplanVersion);
                            if (this.editor.floorplanVersion === 2.6) {
                                let metaFloorplans = [];
                                let metaFPlan = new FloorplanV1(
                                    floor,
                                    "floorplan",
                                    true
                                );
                                metaFloorplans.push(metaFPlan.imageMesh);
                                metaFPlan.alignmentStatus &&
                                    metaFloorplans.push(metaFPlan.pegMesh);
                                this.objectLoaded(
                                    source,
                                    source.id,
                                    "floorplan",
                                    metaFloorplans
                                );
                            } else {
                                new Floorplan(floor, "floorplan", true);
                                this.asyncObject.push(floor.id);
                            }
                        } else {
                            this.objectLoaded(
                                source,
                                "SKIPPED_OBJECT",
                                "SKIPPED_OBJECT",
                                null
                            );
                        }
                    }
                }
            } else {
                //Skip this object
                this.objectLoaded(
                    source,
                    "SKIPPED_OBJECT",
                    "SKIPPED_OBJECT",
                    null
                );
            }
        }
    };

    loadPins = (pins) => {
        for (const pin of pins) {
            if (pin.pinType && pin.pinType !== "connector") {
                if (!(pin.id in this.items)) {
                    new LocationPin(pin);
                    this.asyncObject.push(pin.id);
                }
            }
        }
    };

    loadConnectors = (pins) => {
        for (const pin of pins) {
            if (pin.pinType && pin.pinType === "connector") {
                if (!(pin.id in this.items)) {
                    new Connector(pin);
                    this.asyncObject.push(pin.id);
                }
            }
        }
    };

    onAsyncObjectLoaded = (object, type) => {
        if (type === "floorplan") {
            const idx = this.asyncObject.indexOf(object.id);
            if (!(object.id in this.items) && idx !== -1) {
                let metaFloorplans = [];
                this.asyncObject.splice(idx, 1);
                metaFloorplans.push(object.imageMesh);

                this.objectLoaded(
                    object,
                    object.id,
                    "floorplan",
                    metaFloorplans
                );
            }
        } else if (type === "locationPin") {
            const idx = this.asyncObject.indexOf(object.id);
            if (!(object.id in this.items) && idx !== -1) {
                this.asyncObject.splice(idx, 1);
                this.editor.uiObjects.push(object);
                object.annotationRenderer.position.copy(object.mesh.position);
                this.objectLoaded(object, object.id, "mapPin", [
                    object.mesh,
                    object.annotationRenderer,
                ]);
            }
        } else if (type === "connectorPin") {
            const idx = this.asyncObject.indexOf(object.id);
            if (idx !== -1) {
                this.asyncObject.splice(idx, 1);
                this.editor.uiObjects.push(object);
                // object.annotationRenderer.position.copy(object.mesh.position)
                this.editor.addObjects(object.mesh);
            }
        } else if (type === "SKIPPED_OBJECT") {
            this.objectLoaded(object, "SKIPPED_OBJECT", "SKIPPED_OBJECT", null);
        }
    };

    objectLoaded = (source, id, type, file, toAdd = true) => {
        if (id === "SKIPPED_OBJECT") {
            this.loaded++;
        } else {
            if (source.id in this.items) return;
            this.items[id] = {
                type,
                object: file,
            };
            this.loaded++;

            if (toAdd) {
                if (file instanceof Array) {
                    for (const obj of file) {
                        this.editor.addObjects(obj);
                    }
                } else {
                    this.editor.addObjects(file);
                }
            }
        }

        // console.log("ITEMS", this.items, this.toLoad, this.loaded);

        this.isReady();
    };

    isReady = () => {
        if (this.loaded === this.toLoad) {
            this.trigger("ready");
        }
    };
}
