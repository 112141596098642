import { has } from "lodash";
import EventEmitter from "../../utils/EventEmitter";
import {
    UIPanel,
    UIText,
    UIRow,
    UILocationCard,
    UICol,
    UIImage,
} from "../ui.lib";

import ChevronIcon from "../../../../assets/svgs/scenes/chevron_dark.svg";
import { getFloorIdentifiers } from "../../threeUtils/LocationPins/PinUtils";

class FloatingbarCategories extends EventEmitter {
    constructor(editor) {
        super();
        this.editor = editor;
        this.dom = null;

        this.locationPins = [];
        this.editor.resources.on("ready", this.updateUI);

        this.initUI();
    }

    initUI = () => {
        const uiContainer = new UIPanel().addClass(
            "wsInteractiveFloatingbar__categoryListContainer"
        );

        const uiLocationTitle = new UIText("80%", "Categories").addClass(
            "wsInteractiveFloatingbar__topLocationTitle"
        );
        uiContainer.addChild(uiLocationTitle);

        this.uiLocationList = new UIRow().addClass(
            "wsInteractiveFloatingbar__locationListCategories"
        );
        uiContainer.addChild(this.uiLocationList);

        this.dom = uiContainer;
    };

    emptyContent = () => {
        var child = this.uiLocationList.dom.lastChild;
        while (child) {
            this.uiLocationList.dom.removeChild(child);
            child = this.uiLocationList.dom.lastChild;
        }
    };

    renderLocationList = () => {
        this.emptyContent();
        const categories = Object.keys(this.locationPins);
        if (categories.length === 0) {
            const uiNoDataCard = new UIPanel();
            uiNoDataCard.addClass("ws_uiNoDataCardInteractive");
            uiNoDataCard.setStyle("margin", "10px 0px");
            uiNoDataCard.setStyle("padding", "15px 20px");
            const uiSubText = document.createElement("div");
            uiSubText.classList.add("ws_uiNoDataCardInteractive--text");
            uiSubText.textContent = "You haven’t added any location pin.";
            uiNoDataCard.dom.appendChild(uiSubText);
            this.uiLocationList.addChild(uiNoDataCard);
        } else {
            for (let i = 0; i < categories.length; i++) {
                const category = categories[i];
                const uiCategoryContainer = new UIPanel().addClass(
                    "wsInteractiveFloatingbar__categoryContainer"
                );

                const uiCategoryHeader = new UIRow().addClass(
                    "wsInteractiveFloatingbar__categoryHeader"
                );
                uiCategoryHeader.addChild(
                    new UIText(
                        "",
                        category === "ZOther" ? "Others" : category
                    ).addClass("wsInteractiveFloatingbar__categoryTitle")
                );
                const uiChevron = new UIImage(ChevronIcon, "24px", "24px");
                uiCategoryHeader.addChild(uiChevron);
                uiCategoryContainer.addChild(uiCategoryHeader);

                const uiCategoryList = new UICol();
                this.locationPins[category].pins.forEach(
                    (locationPin, index) => {
                        const uiContainer = new UILocationCard(
                            locationPin,
                            this.handlePinClick.bind(this),
                            index,
                            "ws_uiObjectLocationCard--noShadow",
                            has(locationPin, "mapId")
                                ? getFloorIdentifiers(
                                      this.editor.venueDetails.maps,
                                      locationPin.mapId
                                  )
                                : null
                        );
                        uiCategoryList.addChild(uiContainer);
                    }
                );
                uiCategoryContainer.addChild(uiCategoryList);
                this.uiLocationList.addChild(uiCategoryContainer);

                if (this.locationPins[category].open) {
                    uiCategoryList.setStyle("display", "block");
                    uiChevron.setStyle("transform", "rotate(180deg)");
                } else {
                    uiCategoryList.setStyle("display", "none");
                    uiChevron.setStyle("transform", "rotate(0deg)");
                }

                uiCategoryHeader.onClick(() => {
                    this.locationPins[category].open =
                        !this.locationPins[category].open;
                    this.renderLocationList();
                });
            }
        }
    };

    categoziePins = (pins) => {
        let sortedPins = {};
        pins.forEach((pin) => {
            if (pin.categories && pin.categories.length > 0) {
                pin.categories.forEach((category) => {
                    if (has(sortedPins, category.name)) {
                        sortedPins[category.name] = {
                            pins: [...sortedPins[category.name].pins, pin],
                            open: false,
                        };
                    } else {
                        sortedPins[category.name] = {
                            pins: [pin],
                            open: false,
                        };
                    }
                });
            } else {
                if ("ZOther" in sortedPins) {
                    sortedPins["ZOther"] = {
                        pins: [...sortedPins["ZOther"].pins, pin],
                        open: false,
                    };
                } else {
                    sortedPins["ZOther"] = {
                        pins: [pin],
                        open: false,
                    };
                }
            }
        });
        return sortedPins;
    };

    updateUI = () => {
        this.locationPins = this.categoziePins(this.editor.jsonLocationPins);
        this.renderLocationList();
    };

    handlePinClick = (locationPin) => {
        if (
            this.editor.isVenue &&
            this.editor.activeMap !== locationPin.mapId
        ) {
            //Switch Scene
            this.editor.trigger("requestSceneSwitch", [locationPin.mapId]);
            this.editor.trigger("locationPinSelected", [locationPin]);
        } else {
            this.editor.trigger("locationPinSelected", [locationPin]);
        }
    };
}

export { FloatingbarCategories };
