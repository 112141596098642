import InteractiveExperience from "../../InteractiveExperience";
import EventEmitter from "../../utils/EventEmitter";

import { UIImage, UIPanel } from "../ui.lib";
import { LevelSelector } from "./Buttons.Levels";
import ButtonNavigationLevels from "./Buttons.NavigationLevels";

//icons
import ZoomInIcon from "../../../../assets/pngs/studio/interactiveMaps/zoom_in.png";
import ZoomOutIcon from "../../../../assets/pngs/studio/interactiveMaps/zoom_out.png";
import ResetIcon from "../../../../assets/pngs/studio/interactiveMaps/zoom_reset.png";
import { detectMobile } from "../../../_utils/detectBrowser";

class ActionButtons extends EventEmitter {
    constructor(props) {
        super(props);

        this.editor = new InteractiveExperience();
        this.parentEle = props.dom;

        this.editor.resources.on("ready", this.detectBrowserandAddEvents);

        this.initUI();
    }

    initUI = () => {
        this.uiContainer = new UIPanel().addClass("wsInteractiveActionButtons");

        this.zoomInBtn = new UIPanel().setClass(
            "wsInteractiveActionButtons__zoomBtn wsInteractiveActionButtons__zoomInBtn"
        );
        this.zoomInBtn.addChild(new UIImage(ZoomInIcon, "22px", "22px"));
        this.zoomInBtn.onClick(() => {
            this.editor.trigger("interactiveZoomSelector", ["zoomIn"]);
        });

        this.zoomOutBtn = new UIPanel().setClass(
            "wsInteractiveActionButtons__zoomBtn wsInteractiveActionButtons__zoomOutBtn"
        );
        this.zoomOutBtn.addChild(new UIImage(ZoomOutIcon, "22px", "22px"));
        this.zoomOutBtn.onClick(() => {
            this.editor.trigger("interactiveZoomSelector", ["zoomOut"]);
        });

        this.resetBtn = new UIPanel().setClass(
            "wsInteractiveActionButtons__zoomBtn wsInteractiveActionButtons__resetBtn"
        );
        this.resetBtn.addChild(new UIImage(ResetIcon, "22px", "22px"));
        this.resetBtn.onClick(() => {
            this.editor.trigger("interactiveZoomSelector", ["reset"]);
        });

        this.uiContainer.addChild(this.zoomOutBtn);
        this.uiContainer.addChild(this.zoomInBtn);
        this.uiContainer.addChild(this.resetBtn);

        // levels
        this.levelSelector = new LevelSelector(this.editor);
        this.navigationButtons = new ButtonNavigationLevels(this.editor);

        this.uiContainer.addChild(this.levelSelector.dom);
        this.uiContainer.addChild(this.navigationButtons.dom);

        this.parentEle.appendChild(this.uiContainer.dom);
    };

    detectBrowserandAddEvents = () => {
        if (detectMobile()) {
            this.zoomInBtn.setDisplay("none");
            this.zoomOutBtn.setDisplay("none");
        } else {
            this.zoomInBtn.setDisplay("");
            this.zoomOutBtn.setDisplay("");
        }
    };
}

export { ActionButtons };
