import * as THREE from "three";
import EditorExperience from "../../InteractiveExperience";
import { fromPosObjectToVec3Pos } from "../TransformConversions";

import { getConnectorCanvasTexture } from "./ConnectorUtils";
import { isEqual } from "lodash";

export default class Connector {
    constructor(pinProps) {
        this.pinProps = pinProps;

        this.editor = new EditorExperience();

        this.name = pinProps.name || "";
        this.id = pinProps.id;
        this.connectorType = pinProps.connectorType;

        this.pinColor = pinProps.pinColor || "#36CCE7";

        this.position = pinProps.position
            ? fromPosObjectToVec3Pos(pinProps.position)
            : new THREE.Vector3(0, 0, 0);

        this.editor.jsonConnectorPins.push(pinProps);

        this.editor.on("repositionYOfObjects", this.onRepositionYOfObjects);
        this.editor.on("updateConnectingPins", this.onUpdateConnectingPins);

        this.setUpConnectorPin();
    }

    setUpConnectorPin = async () => {
        const spriteTex = await getConnectorCanvasTexture(
            this.connectorType,
            this.pinColor,
            512
        );
        spriteTex.colorSpace = THREE.SRGBColorSpace;
        this.spriteMaterial = new THREE.SpriteMaterial({
            map: spriteTex,
            side: THREE.DoubleSide,
            transparent: true,
        });

        this.mesh = new THREE.Sprite(this.spriteMaterial);
        this.mesh.position.copy(this.position);
        this.mesh.scale.set(1, 1.2, 1);
        this.mesh.rotation.y = THREE.MathUtils.degToRad(180);

        this.sizeY = new THREE.Box3()
            .setFromObject(this.mesh)
            .getSize(new THREE.Vector3()).y;

        this.mesh.position.y =
            (this.editor.floorY ? this.editor.floorY : this.mesh.position.y) +
            this.sizeY / 2;

        this.mesh.name = this.name;
        this.mesh.userData["type"] = "Connector Pin";
        this.mesh.userData["id"] = this.id;
        this.mesh.userData["pinType"] = this.pinType;
        this.mesh.visible = false;

        this.editor.trigger("asyncObjectLoaded", [this, "connectorPin"]);
    };

    onRepositionYOfObjects = (yValue) => {
        this.mesh && (this.mesh.position.y = yValue + this.sizeY / 2);
    };

    updateScaleOfMesh = async () => {
        if (this.mesh.visible === false) return;

        const distance = this.editor?.sceneCenter?.distanceTo(
            this.editor.camera?.instance?.position.clone()
        );

        if (this.prevDistToCamera === distance) return;

        this.prevDistToCamera = distance;
        if (distance > this.editor.floorMax / 2) {
            let factor =
                this.editor.sceneCenter.distanceTo(
                    this.editor.camera.instance.position.clone()
                ) *
                Math.min(
                    (0.85 *
                        Math.tan(
                            (Math.PI * this.editor.camera.instance.fov) / 360
                        )) /
                        this.editor.camera.instance.zoom,
                    7
                );

            this.mesh.scale.set(1, 1.2, 1).multiplyScalar(factor / 10);
            this.sizeY = new THREE.Box3()
                .setFromObject(this.mesh)
                .getSize(new THREE.Vector3()).y;

            this.mesh.position.y =
                (this.editor.floorY
                    ? this.editor.floorY
                    : this.mesh.position.y) +
                this.sizeY / 2;
        }
    };

    onUpdateConnectingPins = (paths) => {
        
        if(paths && paths.length > 0) {
            let bFound = false;
            paths.forEach((path) => {
                if(!bFound) {
                    if (isEqual(path.sourcePin, this.id) || isEqual(path.destinationPin,this.id)) {
                        this.mesh.visible = true;
                        bFound = true;
                    } else {
                        this.mesh.visible = false;
                    }
                }
            });
        } else {
            this.mesh.visible = false;
        }
    }
}
