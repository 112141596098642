import * as THREE from "three";
import InteractiveExperience from "../InteractiveExperience";

export default class WorldMain {
    constructor() {
        this.editor = new InteractiveExperience();
        this.canvas = this.editor.canvas;
        this.camera = this.editor.camera;

        this.objects = [];

        this.setUpControls();

        this.editor.on("objectAdded", this.objectAdded);
        this.editor.on("sceneSwitched", this.onSceneSwitched);
    }

    setUpControls = () => {
        this.raycaster = new THREE.Raycaster();
        var mouse = new THREE.Vector2();
        var onDownPos = new THREE.Vector2();
        var onUpPos = new THREE.Vector2();
        this.intersected = null;

        const getIntersects = (point, objects) => {
            mouse.set(point.x * 2 - 1, -(point.y * 2) + 1);
            this.raycaster.setFromCamera(mouse, this.camera.instance);
            return this.raycaster.intersectObjects(objects);
        };

        const getMousePos = (dom, x, y) => {
            var rect = dom.getBoundingClientRect();
            return [(x - rect.left) / rect.width, (y - rect.top) / rect.height];
        };

        const onMouseDown = (e) => {
            var array = getMousePos(this.canvas, e.clientX, e.clientY);
            onDownPos.fromArray(array);

            document.addEventListener("mouseup", onMouseUp, false);
        };

        const onMouseUp = (e) => {
            var array = getMousePos(this.canvas, e.clientX, e.clientY);
            onUpPos.fromArray(array);

            handleClick();

            document.removeEventListener("mouseup", onMouseUp, false);
        };

        const onTouchStart = (e) => {
            var touch = e.changedTouches[0];

            var array = getMousePos(this.canvas, touch.clientX, touch.clientY);
            onDownPos.fromArray(array);

            document.addEventListener("touchend", onTouchEnd, false);
        };

        const onTouchEnd = (e) => {
            var touch = e.changedTouches[0];

            var array = getMousePos(this.canvas, touch.clientX, touch.clientY);
            onUpPos.fromArray(array);

            handleClick();

            document.removeEventListener("touchend", onTouchEnd, false);
        };

        const handleClick = () => {
            if (onDownPos.distanceTo(onUpPos) === 0) {
                var intersects = getIntersects(onUpPos, this.objects);

                if (intersects.length > 0) {
                    var isSelected = false;
                    intersects.forEach((intersect) => {
                        var object = intersect.object;
                        if (isSelected) return;

                        if (
                            object &&
                            object.parent === this.editor.scene &&
                            object.userData &&
                            object.userData.type === "Location Pin" &&
                            this.editor.selectedObject !== object
                        ) {
                            this.editor.trigger("locationPinClicked", [object]);
                            isSelected = true;
                        } else {
                            let parent = object.parent;
                            let bDone = true;
                            while (bDone && parent !== this.editor.scene) {
                                if (parent.parent === this.editor.scene) {
                                    bDone = false;
                                } else {
                                    parent = parent.parent;
                                }
                            }
                            if (parent === this.editor.selectedObject) {
                                isSelected = true;
                                return;
                            }

                            if (
                                parent &&
                                parent.userData.type === "Location Pin" &&
                                this.editor.selectedObject !== parent
                            ) {
                                this.editor.trigger("locationPinClicked", [
                                    parent,
                                ]);
                                isSelected = true;
                            } else {
                                this.editor.select(null);
                            }
                        }
                    });
                } else {
                    this.editor.select(null);
                }
            }
        };

        this.canvas.addEventListener("mousedown", onMouseDown, false);
        this.canvas.addEventListener("touchstart", onTouchStart, false);
    };

    objectAdded = (object) => {
        object &&
            object.traverse((child) => {
                this.objects.push(child);
            });
    };

    onSceneSwitched = () => {
        this.objects = [];
        const scene =
            this.editor.sceneObjectData[this.editor.activeMap].scene ||
            this.editor.scene;
        scene.traverse((child) => {
            this.objects.push(child);
        });
    };
}
