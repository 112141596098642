import React, { useState, useEffect, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import { XCircle } from "react-feather";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SelectMapsImport from "./SelectMapsImport";
import { isObject, isEmpty } from "lodash";
import EnterVenueDetails from "./VenuesDetails/EnterVenueDetails";
import {
  createBuilding,
  updateVenueMap,
} from "../../../../features/webstudio/buildingSlice";
import { resetMapDetailsTab, uploadAsset } from "../../../../features/common/commonSlice";
import Cancel from "./Cancel";
import Congratulations from "./Congratulations";
import Loading from "./Loading";
import {
  resetBuilding,
  setIsloading,
} from "../../../../features/webstudio/buildingSlice";
// import { getUserMapDetails } from "../../../../features/user/studio/studioSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "80% !important",
  height: "80%",
  transform: "translate(-50%, -50%)",
  width: "68%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  border: "none !important",
  outline: "none !important",
  display: "flex",
  flexDirection: "column",
  alignItems: "center !important",
  borderRadius: "16px",
};

export const MapsContext = createContext();

const ImportMaps = ({ openModal, onCloseModal, modalData }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedMaps, setSelectedMaps] = useState([]);
  const [building, setBuilding] = useState({});
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [dMaps, setDMaps] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!modalData?.venueDetails) {
      if (modalData && selectedMaps.filter((m) => m.isChecked).length === 0) {
        let sMaps = [
          {
            mapId: modalData?.mapDetails?.mapId,
            isChecked: true,
            thumbnailImg: modalData?.mapDetails?.thumbnailImage,
            name: modalData?.mapDetails?.mapName,
            order: 0,
            identifier: "",
            type: null,
          },
        ];
        setSelectedMaps(sMaps);
        setDMaps(sMaps);
      }
    } else {
      if (modalData && selectedMaps.filter((m) => m.isChecked).length === 0) {
        let sMaps = modalData?.venueDetails?.maps?.map((m) => ({
          mapId: m.mapId,
          isChecked: true,
          thumbnailImg: m.thumbnailImage ? m.thumbnailImage : m.thumbnailImg,
          name: m.mapName,
          order: m.order,
          identifier: m.identifier,
          type: m.mapNamingType,
        }));
        setSelectedMaps(sMaps);
        setDMaps(sMaps);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData]);

  const closeActions = () => {
    setActiveStep(0);
    setShowCancelModal(false);
    setSelectedMaps([]);
    setBuilding([]);
    dispatch(resetBuilding());
    onCloseModal();
  };

  const continueImportMaps = () => {
    setActiveStep(0);
    setSelectedMaps(dMaps);
    setShowCancelModal(false);
  }

  const handleNext = () => {
    if (activeStep === 1) {
      let reqObj = new FormData();
      reqObj.append("file", building.thumbnail);
      reqObj.append("contentType", "images");
      reqObj.append("compressFile", true);
      let data = {
        reqObj,
      };

      if (modalData?.venueDetails) {
        if (isObject(building.thumbnail)) {
          dispatch(setIsloading(true));
          dispatch(uploadAsset(data)).then((res) => {
            dispatch(
              updateVenueMap({
                venueDetails: {
                  ...building,
                  ...(res.payload.data && { thumbnail: res.payload.data.file }),
                  file: null,
                },
                mapId: modalData.venueDetails.id,
              })
            ).then((res) => {
              dispatch(resetMapDetailsTab());
              closeActions();
            });
          });
        } else {
          dispatch(setIsloading(true));
          dispatch(
            updateVenueMap({
              venueDetails: {
                ...building,
                file: null,
              },
              mapId: modalData?.venueDetails?.id,
            })
          ).then((res) => {
            dispatch(resetMapDetailsTab());
            closeActions();
          });
        }
      } else {
        if (building.thumbnail) {
          dispatch(setIsloading(true));
          dispatch(uploadAsset(data)).then((res) => {
            dispatch(
              createBuilding({
                ...building,
                ...(res.payload.data && { thumbnail: res.payload.data.file }),
                file: null,
              })
            ).then((res) => {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            });
          });
        } else {
          dispatch(
            createBuilding({
              ...building,
              file: null,
            })
          ).then((res) => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          });
        }
      }
    }
    // building creation modal
    if (activeStep !== 1) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building]);

  const handleCancel = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setShowCancelModal(true);
    // onCloseModal();
  };

  const isLoading = useSelector((state) => state.building.isLoading);
  const getStepperContent = (index) => {
    switch (index) {
      case 0:
        return (
          <SelectMapsImport
            selectedMaps={selectedMaps}
            isVenue={modalData?.venueDetails !== null}
            dMaps={dMaps}
            setSelectedMaps={(mapdetails) => {
              if (modalData?.venueDetails === null) {
                if (
                  isEmpty(
                    selectedMaps.filter(
                      (mapObj) => mapObj.mapId === mapdetails.mapId
                    )
                  ) ||
                  isEmpty(selectedMaps)
                ) {
                  setSelectedMaps((prev) => [
                    ...prev,
                    {
                      mapId: mapdetails.mapId,
                      isChecked: mapdetails.isChecked,
                      thumbnailImg: mapdetails.thumbnailImg,
                      name: mapdetails.name,
                      order: 1,
                      identifier: "",
                      type: null,
                    },
                  ]);
                } else {
                  setSelectedMaps((prev) => {
                    let IDs = prev.map((mapObj) => {
                      if (mapObj.mapId === mapdetails.mapId) {
                        let item = {
                          ...mapObj,
                          isChecked: mapdetails.isChecked,
                        };
                        return item;
                      }
                      return mapObj;
                    });
                    return IDs;
                  });
                }
              } else {
                if (
                  isEmpty(
                    selectedMaps.filter((m) => m.mapId === mapdetails.mapId)
                  ) &&
                  mapdetails.mapId
                ) {
                  setSelectedMaps((prev) => [
                    ...prev,
                    {
                      mapId: mapdetails.mapId,
                      isChecked: mapdetails.isChecked,
                      thumbnailImg: mapdetails.thumbnailImg,
                      name: mapdetails.name,
                      order: 1,
                      identifier: "",
                      type: null,
                    },
                  ]);
                } else if (mapdetails.mapId) {
                  setSelectedMaps((prev) => {
                    let IDs = prev.map((mapObj) => {
                      if (mapObj.mapId === mapdetails.mapId) {
                        let item = {
                          ...mapObj,
                          isChecked: mapdetails.isChecked,
                        };
                        return item;
                      }
                      return mapObj;
                    });
                    return IDs;
                  });
                }
              }
            }}
          />
        );
      case 1:
        return (
          <EnterVenueDetails
            setBuilding={setBuilding}
            venueData={modalData?.venueDetails}
          />
        );
      case 2:
        return <Congratulations />;
      default:
        return null;
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 600,
        }}
      >
        <form onSubmit={() => { }} noValidate>
          <Fade in={openModal}>
            <Box
              sx={
                showCancelModal || isLoading
                  ? { ...style, height: 'max-content' }
                  : { ...style }
              }
            >
              <XCircle
                color="#353E5A"
                strokeWidth="1.5"
                className="mapdetails__mapModalCloseIcon"
                onClick={() => {
                  closeActions();
                }}
              />
              <Box sx={{ width: "100%" }}>
                {
                  <React.Fragment>
                    {showCancelModal ? (
                      <>
                        <Cancel
                          continueImportMaps={continueImportMaps}
                          closeModal={() => closeActions()}
                        />
                      </>
                    ) : (
                      <>
                        {isLoading ? (
                          <Loading isVenue={modalData?.venueDetails !== null} />
                        ) : (
                          <Typography component={"span"} sx={{ mt: 2, mb: 1 }}>
                            <MapsContext.Provider
                              value={{ selectedMaps, setSelectedMaps }}
                            >
                              {getStepperContent(activeStep)}
                            </MapsContext.Provider>
                          </Typography>
                        )}
                      </>
                    )}
                    {showCancelModal || activeStep === 2 ? null : (
                      <>
                        <hr style={{ margin: "0.7rem" }} />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            mr: 5,
                          }}
                        >
                          <Button
                            id="button-back"
                            variant="outlined"
                            onClick={handleCancel}
                            sx={{ mr: 5 }}
                            disabled={isLoading}
                          >
                            cancel
                          </Button>
                          <Button
                            id="button-next"
                            variant="contained"
                            onClick={handleNext}
                            disabled={
                              isLoading ||
                              (activeStep === 1 &&
                                (isEmpty(building?.name) ||
                                  isEmpty(building?.description) ||
                                  building?.name.trim().length === 0 ||
                                  building?.description.trim().length === 0)) ||
                              (activeStep === 0 && selectedMaps?.filter((m) => m.isChecked).length < 2)
                            }
                          >
                            {activeStep === 1 && isLoading
                              ? "Importing"
                              : activeStep === 1
                                ? "Save & Continue"
                                : "Next"}
                          </Button>
                        </Box>
                      </>
                    )}
                  </React.Fragment>
                }
              </Box>
            </Box>
          </Fade>
        </form>
      </Modal>
    </div>
  );
};

export default ImportMaps;
