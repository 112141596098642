import * as THREE from "three";
import EditorExperience from "../EditorExperience";

import { ViewHelper } from "../utils/ViewHelper";
import { TransformControls } from "../utils/TransformControls.js";

//Commands
import { SetPosition } from "../commands/SetPosition";
import { SetScale } from "../commands/SetScale";
import { SetRotation } from "../commands/SetRotation";
import WorldEnvironment from "./World.Environment";

export default class WorldMain {
    constructor() {
        this.objects = [];

        this.editor = new EditorExperience();
        this.canvas = this.editor.canvas;
        this.scene = this.editor.scene;
        this.camera = this.editor.camera;
        this.time = this.editor.time;
        this.renderer = this.editor.renderer;
        this.orbitControls = this.editor.camera.controls;
        this.navigationTracking = false;
        this.environment = new WorldEnvironment();

        this.initHelpers();
        this.setUpControls();
        this.editor.on("objectAdded", this.objectAdded);
        this.editor.on("objectSelected", this.onObjectSelected);
        this.editor.on("objectChanged", this.onObjectChanged);
        this.editor.on("objectRemoved", this.onObjectRemoved);
        this.editor.on("transformModeChanged", this.onTransformModeChanged);
        this.editor.on("navigationTracking", this.toggleNavigationTracking);
        this.editor.on("adjustGridPosition", this.gridPositioning);
        this.editor.on("toggleAdjustObject", this.onToggleAdjustObject);
        this.editor.on("toggleAdjustAccess", this.onToggleAdjustObject);
    }

    setUpControls = () => {
        // Transform COntrols
        this.box = new THREE.Box3();

        this.selectionBox = new THREE.BoxHelper();
        this.selectionBox.material.depthTest = false;
        this.selectionBox.material.transparent = true;
        this.selectionBox.visible = false;
        this.scene.add(this.selectionBox);

        var objPosOnDown = null;
        var objRotOnDown = null;
        var objScaleOnDown = null;

        this.transformControls = new TransformControls(
            this.camera.instance,
            this.canvas
        );
        this.transformControls.addEventListener("change", () => {
            var object = this.transformControls.object;
            if (object !== undefined) {
                this.selectionBox.setFromObject(object);
                this.editor.trigger("refreshSidebarObjects", [object]);
            }
            if (
                !this.editor.toAdjustValues &&
                !this.editor.toAdjustAccess
            ) {
                const toEnable =
                    object?.userData?.transformation === "NO_TRANSFORM";

                this.transformControls.enabled = !toEnable;
                this.transformControls.showX = !toEnable;
                this.transformControls.showY = !toEnable;
                this.transformControls.showZ = !toEnable;
            }

            if (this.editor.toAdjustAccess) {
                const toEnable = false;
                let toEnableXZ = toEnable;

                if (
                    object?.userData?.rotateOn &&
                    this.transformControls.mode === "rotate"
                ) {
                    toEnableXZ = true;
                }
                this.transformControls.enabled = !toEnable;
                this.transformControls.showX = !toEnableXZ;
                this.transformControls.showY = !toEnable;
                this.transformControls.showZ = !toEnableXZ;
            }
        });

        this.transformControls.addEventListener("mouseDown", () => {
            var object = this.transformControls.object;

            objPosOnDown = object.position.clone();
            objRotOnDown = object.rotation.clone();
            objScaleOnDown = object.scale.clone();

            this.orbitControls.enabled = false;
        });

        this.transformControls.addEventListener("mouseUp", () => {
            var object = this.transformControls.object;

            if (object !== undefined) {
                const delayAutosave = !(
                    "metaFloorplan" in object.userData ||
                    "navigationMode" in object.userData
                );

                switch (this.transformControls.getMode()) {
                    case "translate":
                        if (!objPosOnDown.equals(object.position)) {
                            this.editor.onCommand(
                                new SetPosition(
                                    this.editor,
                                    object,
                                    object.position,
                                    objPosOnDown,
                                    delayAutosave
                                )
                            );
                        }
                        break;

                    case "rotate":
                        if (!objRotOnDown.equals(object.rotation)) {
                            this.editor.onCommand(
                                new SetRotation(
                                    this.editor,
                                    object,
                                    object.rotation,
                                    objRotOnDown,
                                    delayAutosave
                                )
                            );
                        }
                        break;

                    case "scale":
                        if (!objScaleOnDown.equals(object.scale)) {
                            this.editor.onCommand(
                                new SetScale(
                                    this.editor,
                                    object,
                                    object.scale,
                                    objScaleOnDown,
                                    delayAutosave
                                )
                            );
                        }
                        break;

                    default:
                        break;
                }
            }
            this.orbitControls.enabled = true;
        });

        this.scene.add(this.transformControls);

        this.raycaster = new THREE.Raycaster();
        var mouse = new THREE.Vector2();
        var onDownPos = new THREE.Vector2();
        var onUpPos = new THREE.Vector2();
        // var onDblClickPos = new THREE.Vector2();

        const getIntersects = (point, objects) => {
            mouse.set(point.x * 2 - 1, -(point.y * 2) + 1);
            this.raycaster.setFromCamera(mouse, this.camera.instance);
            return this.raycaster.intersectObjects(objects);
        };

        const getMousePos = (dom, x, y) => {
            var rect = dom.getBoundingClientRect();
            return [(x - rect.left) / rect.width, (y - rect.top) / rect.height];
        };

        const onMouseDown = (e) => {
            var array = getMousePos(this.canvas, e.clientX, e.clientY);
            onDownPos.fromArray(array);

            document.addEventListener("mouseup", onMouseUp, false);
        };

        const onMouseUp = (e) => {
            var array = getMousePos(this.canvas, e.clientX, e.clientY);
            onUpPos.fromArray(array);

            handleClick();

            document.removeEventListener("mouseup", onMouseUp, false);
        };

        const onTouchStart = (e) => {
            var touch = e.changedTouches[0];

            var array = getMousePos(this.canvas, touch.clientX, touch.clientY);
            onDownPos.fromArray(array);

            document.addEventListener("touchend", onTouchEnd, false);
        };

        const onTouchEnd = (e) => {
            var touch = e.changedTouches[0];

            var array = getMousePos(this.canvas, touch.clientX, touch.clientY);
            onUpPos.fromArray(array);

            handleClick();

            document.removeEventListener("touchend", onTouchEnd, false);
        };

        /* const onDoubleClick = (e) => {
            
        } */

        const handleClick = () => {
            if (
                !this.navigationTracking &&
                onDownPos.distanceTo(onUpPos) === 0
            ) {
                var intersects = getIntersects(onUpPos, this.objects);
                if (intersects.length > 0) {
                    var object = intersects[0].object;

                    if (object && object.parent && object.parent.userData?.navigationMode === 'waypoints') return;

                    if (this.isNonInteractiveObject(object.uuid)) {
                        this.editor.trigger(
                            "interactionWithNonInteractiveObject",
                            [object]
                        );
                        this.editor.deselect();
                    } else if (object && object.visible) {
                        if (object.parent === this.editor.scene) {
                            !this.isSkipObject(object) &&
                                object.userData.visible &&
                                this.editor.select(object);
                            this.editor.trigger(
                                "interactionWithNonInteractiveObject",
                                [null]
                            );
                            this.isSkipObject(object) && this.editor.deselect();
                        } else {
                            let parent = object.parent;
                            let bDone = true;
                            while (bDone && parent !== this.editor.scene) {
                                if (parent.parent === this.editor.scene) {
                                    bDone = false;
                                } else if (
                                    parent.parent instanceof THREE.Group &&
                                    "FloorPegsGroup" in parent.parent.userData
                                ) {
                                    bDone = false;
                                } else {
                                    parent = parent.parent;
                                }
                            }
                            !this.isSkipObject(parent) && parent.userData.visible && this.editor.select(parent);
                            this.editor.trigger('interactionWithNonInteractiveObject', [null]);

                            this.isSkipObject(parent) && this.editor.deselect();
                        }
                    } else {
                        this.editor.deselect();
                    }
                } else {
                    this.editor.deselect();
                }
            }
        };

        this.canvas.addEventListener("mousedown", onMouseDown, false);
        this.canvas.addEventListener("touchstart", onTouchStart, false);
        // this.canvas.addEventListener('dblclick', onDoubleClick, false);
    };

    isNonInteractiveObject = (uuid) => {
        const index = this.editor.nonInteractiveObjects.indexOf(uuid);
        return index !== -1;
    };

    isSkipObject = (object) => {
        let bFlag = false;
        if (
            object.userData &&
            "skipScene" in object.userData &&
            object.userData.skipScene === true
        ) {
            bFlag = true;
        }
        return bFlag;
    };

    initHelpers = () => {
        this.gridHelper = new THREE.GridHelper(500, 500, "#555555", "#bfbfbf");
        this.gridHelper.position.set(0, -0.1, 0);
        this.scene.add(this.gridHelper);
        
        // View Helper
        this.viewHelper = new ViewHelper(this.camera.instance, this.renderer, this.editor);
        this.viewHelper.setControls(this.orbitControls);
    };

    objectAdded = (object) => {
        !object.userData['id']?.includes('__mapVisual') && !object.userData['id']?.includes('__arpath13') && object.traverse((child) => {
            this.objects.push(child);
        });
    }



    onObjectSelected = (object) => {

        if (object && object.userData?.navigationMode === 'waypoints') return;

        this.selectionBox.visible = false;
        this.transformControls.detach();

        if (
            object !== null &&
            object !== this.scene &&
            object !== this.camera.instance &&
            !this.navigationTracking &&
            object.userData.type !== "qrAnchors"
        ) {
            this.box.setFromObject(object);
            if (this.box.isEmpty() === false) {
                this.selectionBox.setFromObject(object);
                this.selectionBox.visible = true;
            }
            this.transformControls.attach(object);

            if (
                object.userData?.navigationMode === "waypoints" ||
                (object.userData?.metaFloorplan === "floorplan" &&
                    this.editor.floorplanVersion === 2.6)
            ) {
                const center = new THREE.Vector3();
                this.box.getCenter(center);
                this.transformControls.position.set(
                    center.x,
                    center.y,
                    center.z
                );
            } else {
                this.transformControls.position.set(0, 0, 0);
            }
        }
    };

    toggleNavigationTracking = (bFlag) => {
        this.navigationTracking = bFlag;
        this.transformControls.enabled = !bFlag;
    };

    onObjectChanged = (object) => {
        if (this.editor.selectedObject === object) {
            this.selectionBox.setFromObject(object);
        }

        if (object.type.includes("Camera")) {
            object.updateProjectionMatrix();
        }
    };

    gridPositioning = (newVec) => {
        newVec &&
            (this.gridHelper.position.y = newVec.clone().multiplyScalar(1.3).y);
    };

    onObjectRemoved = (object) => {
        this.orbitControls.enabled = true;
        if (object === this.transformControls.object) {
            this.transformControls.detach();
        }

        object.traverse((child) => {
            this.objects.splice(this.objects.indexOf(child), 1);
        });
    };

    onTransformModeChanged = (transformMode) => {
        if (transformMode === null) return;
        if (transformMode !== "edit")
            this.transformControls.setMode(transformMode);
    };

    update = () => {
        // TO DO
        this.renderer.instance.autoClear = false;
        this.viewHelper.render();
        this.renderer.instance.autoClear = true;

        this.viewHelper.update();
    };

    onToggleAdjustObject = (state, object) => {
        if (object === this.transformControls.object) {
            this.editor.toAdjustValues = state;
            this.transformControls.showX = state;
            this.transformControls.showY = state;
            this.transformControls.showZ = state;
            this.transformControls.enabled = state;
        }
    };
}
