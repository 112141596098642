import { Grid } from "@mui/material";
import Switch from "../../../../common/toggleSwitch";
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  mapDetails,
  getUserMapDetails,
} from "../../../../../features/user/studio/studioSlice";
import { updateAnchor } from "../../../../../features/user/studio/anchorSlice";
import { setNewAlert } from "../../../../../features/common/alertSlice";

import { omit, isEmpty } from "lodash";
import Dropdown from "../../../../webstudio/studiocommon/ImportMaps/VenuesDetails/Dropdown";
import IAPCard from "./IAPCard";
import DefaultIAPModal from "./DefaultIAPModal";
import Loader from "../../../../common/loader";

const IAPTab = ({ data }) => {
  const selectedMapDetails = useSelector(mapDetails);

  const anchorIds = useMemo(() => {
    return selectedMapDetails?.metadata?.anchors.filter((a) => !a.isQrCode);
  }, [selectedMapDetails]);

  const anchorLog = useSelector((state) => state.anchors.anchorLog);

  const isPrimaryAccess = anchorIds?.some(
    (anchorId) => anchorId.isPrimaryAccess
  );

  const [loading, setLoading] = useState(false);
  const [defaultIAP, setDefaultIAP] = useState(false);
  const [defaultIAPIsClicked, setDefaultIAPIsClicked] = useState(false);
  const [defaultIAPModal, setDefaultIAPModal] = useState(false);
  const [seletedIAP, setSeletedIAP] = useState({});
  const dispatch = useDispatch();
  const [level, setLevel] = useState(
    data?.maps ? `0-${data?.maps[0]?.identifier}` : null
  );

  useEffect(() => {
    if (data?.isVenueTab) {
      setLoading(true);
      getUserMapDetails(data?.mapData.mapId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.externalIAPClick) {
      document.getElementById("toggle-defIAP").click();
    }
    return () => {
      if (data?.onClick) {
        data?.onClick();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedMapDetails) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMapDetails]);

  useEffect(() => {
    if (anchorLog === "success") {
      setLoading(true);
      dispatch(getUserMapDetails(selectedMapDetails.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorLog]);

  useEffect(() => {
    if (data?.isVenue) {
      setLoading(true);
      dispatch(
        getUserMapDetails(
          data.maps.find(
            (m) =>
              m.mapId ===
              data?.maps.find((m, i) => `${i}-${m.identifier}` === level)[
                "mapId"
              ]
          )["mapId"]
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  useEffect(() => {
    if (defaultIAP && defaultIAPIsClicked) {
      setDefaultIAPModal(true);
      setDefaultIAPIsClicked(false);
    }
    if (!defaultIAP && defaultIAPIsClicked) {
      removeDefaultIAP();
      setDefaultIAPIsClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultIAP, defaultIAPIsClicked]);

  useEffect(() => {
    if (isPrimaryAccess) setDefaultIAP(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrimaryAccess]);

  const removeDefaultIAP = () => {
    let anchors = [...selectedMapDetails.metadata.anchors];
    let modifiedAnchor = anchors.find((anchor) => anchor.isPrimaryAccess);
    modifiedAnchor = {
      ...omit(modifiedAnchor, ["modifiedOn", "createdOn"]),
      isPrimaryAccess: false,
    };
    dispatch(
      updateAnchor({ mapId: selectedMapDetails.id, modifiedAnchor })
    ).then(() => {
      dispatch(getUserMapDetails(selectedMapDetails.id));
      dispatch(
        setNewAlert({
          msg: "IAP details updated",
          alertType: "success",
        })
      );
    });
  };

  const saveDefaultIAP = () => {
    let anchors = [...selectedMapDetails.metadata.anchors];
    let modifiedAnchor = anchors.find((anchor) => anchor.id === seletedIAP.id);
    modifiedAnchor = {
      ...omit(modifiedAnchor, ["modifiedOn", "createdOn"]),
      isPrimaryAccess: true,
    };
    dispatch(
      updateAnchor({ mapId: selectedMapDetails.id, modifiedAnchor })
    ).then(() => {
      setDefaultIAPModal(false);
      dispatch(getUserMapDetails(selectedMapDetails.id));
      dispatch(
        setNewAlert({
          msg: "IAP details updated",
          alertType: "success",
        })
      );
    });
  };

  const generateIAPCards = (isChooseDIAP) => {
    return anchorIds
      ?.filter((anchorObj) => !anchorObj.isQrCode)
      .map((anchorIAPObj) => (
        <div
          className="col"
          key={anchorIAPObj.id}
          style={{ width: "fit-content" }}
        >
          <IAPCard
            anchorIAPObj={anchorIAPObj}
            isChooseDIAP={isChooseDIAP}
            checkSetter={{
              isChecked: anchorIAPObj.id === seletedIAP.id,
              setSeletedIAP,
            }}
            id={anchorIAPObj.id}
          />
        </div>
      ));
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h5 className="card-title mapdetails__title pt-4 pb-3">
          Image Access Points
        </h5>
        {data?.isVenue ? (
          <h5 className="card-title mapdetails__title pt-3">
            <Dropdown
              list={data.maps.map((m, i) => {
                return {
                  value: `${i}-${m.identifier}`,
                  label: `${m.identifier} - ${m.mapName}`,
                };
              })}
              setvalue={(val) => {
                setLevel(`${val}`);
              }}
            />
          </h5>
        ) : null}
      </div>
      <Grid container={true} spacing={2} className={"pt-2"}>
        {data?.isVenue ? null : (
          <Grid className="pt-5" item xs={12}>
            <div className="shadow p-3 mb-2 bg-body rounded">
              <div>
                <div className="mapinfo__mDataContainer--header">
                  Default Image Access Point
                </div>
                <div className="mapinfo__mDataContainer--infoRow mb-2 ">
                  <span className="mapinfo__mDataContainer--subHeader">
                    Default image access points are recommended for locations
                    with one entrance. Turning this on will direct visitors to a
                    specific access point for map entry.
                  </span>
                  <Switch
                    enabled={defaultIAP}
                    onEnableToggle={(e) => {
                      setDefaultIAP(!defaultIAP);
                      setDefaultIAPIsClicked(true);
                    }}
                    id="toggle-defIAP"
                  />
                </div>
              </div>
            </div>
          </Grid>
        )}

        <Grid className="pt-5" item xs={12}>
          {/* (val) => setLoaderModal(val),
        (val) => setProgress(val), */}
          {loading ? (
            <div
              className="shadow p-3 mb-3 bg-body rounded iapscroll"
              style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
              <Loader loaderText="Loading..." height="100%" width="100%" />
            </div>
          ) : anchorIds?.length > 0 && !loading ? (
            <div
              className="shadow p-3 mb-3 bg-body rounded iapscroll"
              style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
              <div className="d-flex bd-highlight mb-3">
                <div className="p-2 bd-highlight">
                  <div className="stepper__SectionHeader pb-2">
                    All Image Access Points
                  </div>
                </div>
              </div>
              <div className="row row-cols-1 row-cols-md-4">
                {generateIAPCards(false)}
              </div>
            </div>
          ) : (
            <div
              className="shadow p-3 mb-3 bg-body rounded mapdetails__mapDefaultFloorplan"
              style={{ height: "50vh" }}
            >
              <div className="pb-4">No IAPs Placed</div>
              <label
                className="content-type-select mt-4"
                style={{ lineHeight: "normal" }}
              >
                No IAP placed yet. Your IAPs will appear here.
              </label>
              <div className="pt-4"></div>
            </div>
          )}
        </Grid>
      </Grid>
      <DefaultIAPModal
        modalSetter={{
          defaultIAPModal,
          setDefaultIAPModal: () => {
            setSeletedIAP({});
            setDefaultIAPModal();
          },
          isPrimaryAccess,
          setDefaultIAP,
          successDisabled: isEmpty(seletedIAP),
        }}
        saveDefaultIAP={() => {
          saveDefaultIAP();
          setSeletedIAP({});
        }}
      >
        <div
          className="iapscroll mx-5"
          style={{
            maxHeight: "60vh",
            overflowY: "auto",
            maxWidth: "100%",
            overflowX: "hidden",
          }}
        >
          <div className="row row-cols-1 row-cols-md-4">
            {generateIAPCards(true)}
          </div>
        </div>
      </DefaultIAPModal>
    </div>
  );
};

export default IAPTab;
