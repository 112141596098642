import { detectMobile } from "../../../_utils/detectBrowser";
import EditorExperience from "../../InteractiveExperience";
import { getObjectByUserDataProperty } from "../../threeUtils/TransformConversions";
import EventEmitter from "../../utils/EventEmitter";

// import UI Components
import { UICol, UIImage, UIPanel, UISearchbar, UITextHeader } from "../ui.lib";
import { FloatingbarAmenityCards } from "./Floatingbar.AmenityCards";
import { FloatingbarCategories } from "./Floatingbar.Categories";
import { FloatingbarFeaturedPin } from "./Floatingbar.FeaturedPin";
import { FloatingbarLocationList } from "./Floatingbar.LocationList";

import menuDropIcon from "../../../../assets/svgs/studio/interactiveMaps/menu-chevron.svg";

class Floatingbar extends EventEmitter {
    constructor(props) {
        super(props);
        this.editor = new EditorExperience();
        this.parentEle = props.dom;

        this.selectedLocationPin = null;
        this.selectedPinMesh = null;
        this.toggleDirectory = true;
        this.hideAll = true;

        this.editor.on("locationPinSelected", this.onLocationPinSelected);
        this.editor.on(
            "toggleInteractiveSidebar",
            this.onTopggleInteractiveSidebar
        );

        this.editor.resources.on("ready", this.detectBrowserandAddEvents);
        this.editor.on("amenityTypeSelected", this.onAmenityTypeSelected);
        this.editor.on("hasFeaturedPins", this.onHasFeaturedPins);

        this.initUI();
    }

    initUI = () => {
        this.uiContainer = new UIPanel().addClass("wsInteractiveFloatingbar");

        this.staticHeader = new UIPanel().addClass(
            "wsInteractiveFloatingbar__staticHeader"
        );

        const uiHeaderCont = new UICol().addClass(
            "wsInteractiveFloatingbar__headerCont"
        );
        uiHeaderCont.addChild(
            new UITextHeader("", "Browse Our Directory").addClass(
                "wsInteractiveFloatingbar__header"
            )
        );
        this.staticHeader.addChild(uiHeaderCont);

        // searchbar
        this.searchbar = new UISearchbar("Search for a Location", "", true, {
            onfocus: this.onSearchFocus.bind(this),
            onback: this.onSearchBack.bind(this),
        }, 'wsInteractiveFloatingbar__searchbar--bgWhite').onChange(() => this.handleSearch());
        this.searchbar.addClass("wsInteractiveFloatingbar__searchbar");
        this.staticHeader.addChild(this.searchbar);

        this.uiContainer.addChild(this.staticHeader);

        // Featured Location Pins
        this.topLocationContainer = new FloatingbarFeaturedPin(this.editor);
        this.locationPinsList = new FloatingbarLocationList(this.editor);
        this.amenityPinsList = new FloatingbarAmenityCards(this.editor);
        this.categorizedPinsList = new FloatingbarCategories(this.editor);

        this.uiMyContent = new UIPanel();
        this.uiMyContent.addClass("wsInteractiveFloatingbar__scrollableContent");
        this.uiMyContent.addChild(this.amenityPinsList.dom);
        this.uiMyContent.addChild(this.topLocationContainer.dom);
        this.uiMyContent.addChild(this.locationPinsList.dom);
        this.uiMyContent.addChild(this.categorizedPinsList.dom);

        this.uiDropDownCont = new UIPanel().addClass("wsInteractiveFloatingbar__dropdownCont");
        this.uiDropIcon = new UIImage(menuDropIcon, "20px", "20px").addClass("wsInteractiveFloatingbar__dropdownIcon")
        this.uiDropDownCont.addChild(this.uiDropIcon);
        this.uiDropDownCont.onClick(() => {
            this.toggleDirectory = !this.toggleDirectory;

            this.handleVisibility();
        });

        this.locationPinsList.dom.setDisplay("none");

        this.uiContainer.addChild(this.uiMyContent);
        this.uiContainer.addChild(this.uiDropDownCont);

        this.handleVisibility();

        this.parentEle.append(this.uiContainer.dom);
    };

    handleSearch = () => {
        const searchText = this.searchbar.getValue();
        this.locationPinsList.updateSearchText(searchText);
    };

    onSearchFocus = () => {
        if(this.toggleDirectory) {
            this.toggleDirectory = false;
            this.handleVisibility();
        }
        this.topLocationContainer.dom.setDisplay("none");
        this.amenityPinsList.dom.setDisplay("none");
        this.categorizedPinsList.dom.setDisplay("none");
        this.locationPinsList.dom.setDisplay("");
    };

    onSearchBack = () => {
        this.searchbar.setValue("");
        const searchText = this.searchbar.getValue();
        console.log("onSearchBack", searchText.length);

        if (searchText.length === 0) {
            this.topLocationContainer.dom.setDisplay("");
            this.amenityPinsList.dom.setDisplay("");
            this.categorizedPinsList.dom.setDisplay("");
            this.locationPinsList.dom.setDisplay("none");
        }
    };

    handleVisibility = () => {
        if(this.toggleDirectory) {
            if(this.hideAll) {
                this.uiMyContent.setDisplay("none");
            } else {
                this.uiMyContent.setDisplay("");
                !this.hideAll ? this.topLocationContainer.dom.setDisplay("") : this.topLocationContainer.dom.setDisplay("none");

                this.amenityPinsList.dom.setDisplay("none");
                this.categorizedPinsList.dom.setDisplay("none");
                this.locationPinsList.dom.setDisplay("none");
            }
            this.uiDropIcon.dom.style.transform = "rotate(0deg)";
        } else {
            this.uiMyContent.setDisplay("");
            this.amenityPinsList.dom.setDisplay("");
            this.categorizedPinsList.dom.setDisplay("");
            this.locationPinsList.dom.setDisplay("none");
            this.uiDropIcon.dom.style.transform = "rotate(180deg)";
        }
    }

    onLocationPinSelected = (locationPin) => {
        if (locationPin) {
            const pinMesh = getObjectByUserDataProperty(
                this.editor.scene,
                "id",
                locationPin.id
            );
            this.selectedLocationPin = locationPin;
            this.selectedPinMesh = pinMesh;
            this.editor.camera.relocateCamera(pinMesh);
            this.editor.trigger("toggleInteractiveSidebar", [
                locationPin,
                "LOCATION_INFO",
            ]);
        }
    };

    onAmenityTypeSelected = (amenityType) => {
        this.searchbar.setValue(amenityType, true);
    }

    onTopggleInteractiveSidebar = (locationPin) => {
        if (locationPin === null) {
            this.uiContainer.setDisplay("");
            this.topLocationContainer.dom.setDisplay("");
            this.amenityPinsList.dom.setDisplay("");
            this.categorizedPinsList.dom.setDisplay("");
            this.locationPinsList.dom.setDisplay("none");
        } else {
            this.uiContainer.setDisplay("none");
        }
    };

    onHasFeaturedPins = (bFlag) => {
        this.hideAll = !bFlag;
        this.handleVisibility();
    }

    detectBrowserandAddEvents = () => {
        const browser = detectMobile();
        if(browser) {
            const uiTouchHandler = new UIPanel().addClass("wsInteractiveFloatingbar__touchHandler");
            this.staticHeader.addChild(uiTouchHandler);

            let isFLoatingBarOpen = false;

            this.toggleDirectory = false;
            this.uiDropDownCont.setDisplay("none");
            this.handleVisibility();

            uiTouchHandler.dom.addEventListener("click", () => {
                if(isFLoatingBarOpen) {
                    this.uiContainer.dom.style.height = "25%";
                    this.uiContainer.dom.style.overflow = "hidden";
                    isFLoatingBarOpen = false;
                } else {
                    this.uiContainer.dom.style.height = "100%";
                    this.uiContainer.dom.style.overflow = "auto";
                    isFLoatingBarOpen = true;
                }
            });
        }
    }
}

export { Floatingbar };
