import EventEmitter from "../../utils/EventEmitter";
import { UIPanel, UIFeaturedPinCard, UITextHeader } from "../ui.lib";

class FloatingbarFeaturedPin extends EventEmitter {
    constructor(editor) {
        super();
        this.editor = editor;
        this.dom = null;

        this.editor.resources.on("ready", this.updateUI);

        this.initUI();
    }

    initUI = () => {
        const uiContainer = new UIPanel().addClass(
            "wsInteractiveFloatingbar__topLocationContainer"
        );

        uiContainer.setId("topLocationContainer");

        const topLocationTitle = new UITextHeader("80%", "Featured").addClass(
            "wsInteractiveFloatingbar__topLocationTitle"
        );
        this.topLocationList = new UIPanel().addClass(
            "wsInteractiveFloatingbar__topLocationList"
        );
        uiContainer.addChild(topLocationTitle);
        uiContainer.addChild(this.topLocationList);

        this.dom = uiContainer;
    };

    emptyContent = () => {
        var child = this.topLocationList.dom.lastChild;
        while (child) {
            this.topLocationList.dom.removeChild(child);
            child = this.topLocationList.dom.lastChild;
        }
    };

    updateUI = () => {
        this.emptyContent();
        const pins = this.editor.jsonLocationPins || [];
        const featuredPins = pins.filter((pin) => pin.isFeaturedPin === true);
        if (featuredPins.length > 0) {
            this.dom.setDisplay("");
            this.editor.trigger("hasFeaturedPins", [true]);
            pins.forEach((locationPin, index) => {
                if (locationPin.isFeaturedPin === false) return;
                const uiCont = new UIPanel().addClass(
                    "wsInteractiveFloatingbar__topLocationCard"
                );
                const uiContainer = new UIFeaturedPinCard(
                    locationPin,
                    this.handlePinClick,
                    index
                );
                uiCont.addChild(uiContainer);
                this.topLocationList.addChild(uiCont);
            });
        } else {
            this.dom.setDisplay("none");
            this.editor.trigger("hasFeaturedPins", [false]);
        }
    };

    handlePinClick = (locationPin) => {
        if (
            this.editor.isVenue &&
            this.editor.activeMap !== locationPin.mapId
        ) {
            //Switch Scene
            this.editor.trigger("requestSceneSwitch", [locationPin.mapId]);
            this.editor.trigger("locationPinSelected", [locationPin]);
        } else {
            this.editor.trigger("locationPinSelected", [locationPin]);
        }
    };
}

export { FloatingbarFeaturedPin };
