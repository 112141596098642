const connectorPinIconSvgs = {
    stairs: `
        <svg width="56" height="71" viewBox="0 0 56 71" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="27.5" cy="27.3795" r="25.2206" fill="url(#paint0_radial_1578_8492)" stroke="CUSTOM_COLOR" stroke-width="4.11765"/>
        <ellipse cx="27.307" cy="68.548" rx="4.11765" ry="1.28676" fill="CUSTOM_COLOR"/>
        <path d="M35.9465 52.5396C34.4492 55.2415 30.7634 64.3606 27.308 64.3606C24.2673 64.3606 20.2821 56.4799 18.6696 52.5396H27.9991H35.9465Z" fill="CUSTOM_COLOR"/>
        <path d="M27.8334 27.676H20.6447V33.2444H13.4559V38.8127H41.658V15.9824H34.4692V21.5508H27.8334V27.676Z" fill="#353E5A" stroke="#353E5A" stroke-width="2.01444" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <radialGradient id="paint0_radial_1578_8492" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.4118 18.1148) rotate(63.0343) scale(32.917 34.1318)">
        <stop stop-color="#F9FAFA"/>
        <stop offset="0.588027" stop-color="#F8FAFA"/>
        <stop offset="0.794223" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#ECEEEE"/>
        </radialGradient>
        </defs>
        </svg>

    `,
    escalator: `
        <svg width="56" height="70" viewBox="0 0 56 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="27.307" cy="68.5509" rx="4.11765" ry="1.28676" fill="CUSTOM_COLOR"/>
        <circle cx="27.5" cy="27.3824" r="25.2206" fill="url(#paint0_radial_1578_8247)" stroke="CUSTOM_COLOR" stroke-width="4.11765"/>
        <path d="M35.9465 52.543C34.4492 55.2449 30.7634 64.364 27.308 64.364C24.2673 64.364 20.2821 56.4833 18.6696 52.543H27.9991H35.9465Z" fill="CUSTOM_COLOR"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8842 25.7191C25.5795 25.7191 24.5016 26.7974 24.5016 28.1026V31.4512L28.9264 26.911C28.5291 26.2297 27.7919 25.7191 26.8842 25.7191Z" fill="#353E5A"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8274 22.2573C29.0398 22.2573 30.7983 20.4982 30.7983 18.2849C30.7983 16.0717 29.0398 14.3125 26.8274 14.3125C24.6151 14.3125 22.8566 16.0717 22.8566 18.2849C22.8562 20.4982 24.6717 22.2573 26.8274 22.2573ZM28.5294 18.3416C28.5294 17.3768 27.7352 16.639 26.8274 16.639C25.9197 16.639 25.1255 17.3769 25.1255 18.3416C25.1255 19.3063 25.8631 20.0442 26.8274 20.0442C27.7918 20.0442 28.5294 19.3063 28.5294 18.3416Z" fill="#353E5A"/>
        <path d="M30.5148 25.2081C29.664 24.1297 28.359 23.3919 26.8277 23.3919C24.275 23.3919 22.1758 25.4915 22.1758 28.0456V33.7773L24.5016 31.4512V28.1026C24.5016 26.7974 25.5795 25.7191 26.8842 25.7191C27.7919 25.7191 28.5291 26.2297 28.9264 26.911L30.5148 25.2081Z" fill="#353E5A"/>
        <path d="M26.8274 16.639C27.7352 16.639 28.5294 17.3768 28.5294 18.3416C28.5294 19.3063 27.7918 20.0442 26.8274 20.0442C25.8631 20.0442 25.1255 19.3063 25.1255 18.3416C25.1255 17.3769 25.9197 16.639 26.8274 16.639Z" fill="#353E5A"/>
        <path d="M45.4443 22.7584V22.7587C45.4446 23.6726 44.6101 24.5418 43.5864 24.5418H38.4232C37.9462 24.5418 37.2258 24.7029 36.7594 25.2418L23.0406 38.6736H15.3514C14.2976 38.6736 13.4936 37.8383 13.4936 36.9524C13.4936 36.0664 14.2976 35.2312 15.3514 35.2312H20.4494C20.9267 35.2312 21.6478 35.0699 22.1142 34.5302L35.8334 21.0371H43.5865C44.6402 21.0371 45.4443 21.8725 45.4443 22.7584Z" fill="#353E5A" stroke="#353E5A" stroke-width="2.0362" stroke-linejoin="round"/>
        <defs>
        <radialGradient id="paint0_radial_1578_8247" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.4118 18.1177) rotate(63.0343) scale(32.917 34.1318)">
        <stop stop-color="#F9FAFA"/>
        <stop offset="0.588027" stop-color="#F8FAFA"/>
        <stop offset="0.794223" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#ECEEEE"/>
        </radialGradient>
        </defs>
        </svg>

    `,
    elevator: `
        <svg width="55" height="70" viewBox="0 0 55 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="27.5" cy="27.3824" r="25.2206" fill="url(#paint0_radial_1578_8246)" stroke="CUSTOM_COLOR" stroke-width="4.11765"/>
        <ellipse cx="27.307" cy="68.5509" rx="4.11765" ry="1.28676" fill="CUSTOM_COLOR"/>
        <path d="M35.9465 52.543C34.4492 55.2449 30.7634 64.364 27.308 64.364C24.2673 64.364 20.2821 56.4833 18.6696 52.543H27.9991H35.9465Z" fill="CUSTOM_COLOR"/>
        <path d="M38.2834 13.3691H16.921C15.7938 13.3691 14.877 14.289 14.877 15.4199V36.8576C14.877 37.9835 15.7938 38.9034 16.921 38.9034H38.288C39.4152 38.9034 40.332 37.9835 40.332 36.8526V15.4195C40.327 14.2886 39.4106 13.3691 38.2834 13.3691ZM26.8959 37.486H16.921C16.5753 37.486 16.2947 37.2045 16.2947 36.8577V15.4201C16.2947 15.0732 16.5753 14.7918 16.921 14.7918H26.8959V37.486ZM38.9146 36.8527C38.9146 37.1995 38.6341 37.481 38.2884 37.481H28.3084V14.7865H38.2833C38.629 14.7865 38.9096 15.068 38.9096 15.4148V36.8524L38.9146 36.8527Z" fill="#353E5A"/>
        <path d="M26.8959 37.486H16.921C16.5753 37.486 16.2947 37.2045 16.2947 36.8577V15.4201C16.2947 15.0732 16.5753 14.7918 16.921 14.7918H26.8959V37.486Z" fill="#353E5A"/>
        <path d="M38.9146 36.8527C38.9146 37.1995 38.6341 37.481 38.2884 37.481H28.3084V14.7865H38.2833C38.629 14.7865 38.9096 15.068 38.9096 15.4148V36.8524L38.9146 36.8527Z" fill="#353E5A"/>
        <path d="M21.0927 29.9325C21.233 30.0733 21.4134 30.1386 21.5937 30.1386C21.7741 30.1386 21.9545 30.0683 22.0948 29.9325L24.3292 27.6908C24.6047 27.4143 24.6047 26.9669 24.3292 26.6855C24.0536 26.409 23.6077 26.409 23.3272 26.6855L22.3001 27.7159V22.8455C22.3001 22.4534 21.9845 22.1367 21.5937 22.1367C21.203 22.1367 20.8873 22.4534 20.8873 22.8455V27.7159L19.8603 26.6905C19.5848 26.4141 19.1389 26.4141 18.8583 26.6905C18.5828 26.967 18.5828 27.4143 18.8583 27.6958L21.0927 29.9325Z" fill="#F3F3F3"/>
        <path d="M34.1063 22.3428C33.966 22.2021 33.7857 22.1367 33.6053 22.1367C33.4249 22.1367 33.2446 22.2071 33.1043 22.3428L30.8699 24.5846C30.5943 24.861 30.5943 25.3084 30.8699 25.5899C31.1454 25.8663 31.5913 25.8663 31.8718 25.5899L32.8989 24.5595V29.4299C32.8989 29.822 33.2145 30.1386 33.6053 30.1386C33.9961 30.1386 34.3117 29.822 34.3117 29.4299V24.5595L35.3387 25.5899C35.6143 25.8663 36.0601 25.8663 36.3407 25.5899C36.6162 25.3134 36.6162 24.8661 36.3407 24.5846L34.1063 22.3428Z" fill="#F3F3F3"/>
        <path d="M27.0054 14.6855C27.0054 14.3136 27.3067 14.012 27.6787 14.0117C28.051 14.0115 28.353 14.3132 28.353 14.6855V37.4493C28.353 37.8214 28.0513 38.1231 27.6792 38.1231C27.307 38.1231 27.0054 37.8214 27.0054 37.4493V14.6855Z" fill="#F3F3F3"/>
        <defs>
        <radialGradient id="paint0_radial_1578_8246" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.4118 18.1177) rotate(63.0343) scale(32.917 34.1318)">
        <stop stop-color="#F9FAFA"/>
        <stop offset="0.588027" stop-color="#F8FAFA"/>
        <stop offset="0.794223" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#ECEEEE"/>
        </radialGradient>
        </defs>
        </svg>
    `,
    bridge: `
        <svg width="58" height="70" viewBox="0 0 58 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="28.5" cy="27.3824" r="25.2206" fill="url(#paint0_radial_1578_8244)" stroke="CUSTOM_COLOR" stroke-width="4.11765"/>
        <ellipse cx="28.307" cy="68.5509" rx="4.11765" ry="1.28676" fill="CUSTOM_COLOR"/>
        <path d="M36.9465 52.543C35.4492 55.2449 31.7634 64.364 28.308 64.364C25.2673 64.364 21.2821 56.4833 19.6696 52.543H28.9991H36.9465Z" fill="CUSTOM_COLOR"/>
        <path d="M45.2644 36.7316H41.8861C41.2555 33.9722 39.796 31.522 37.7399 29.7708C35.6837 28.0198 33.1489 27.0685 30.5391 27.0685H27.4691C24.8594 27.0685 22.3246 28.0198 20.2684 29.7708C18.2122 31.5219 16.7526 33.9723 16.1222 36.7316H12.7439V25.8538L14.83 24.9336C19.3408 22.9835 24.1493 21.982 29.0021 21.982C33.8549 21.982 38.6637 22.9835 43.1741 24.9336L45.2603 25.8538L45.2644 36.7316Z" fill="#353E5A"/>
        <path d="M45.2644 23.8752L43.7864 23.2449C39.0782 21.2098 34.0586 20.1646 28.9935 20.1646C23.9285 20.1646 18.9089 21.2098 14.2007 23.2449L12.748 23.8753V20.7925L14.8341 19.8722C19.3449 17.9222 24.1534 16.9205 29.0062 16.9205C33.859 16.9205 38.6675 17.922 43.1783 19.872L45.2644 20.7923L45.2644 23.8752Z" fill="#353E5A"/>
        <defs>
        <radialGradient id="paint0_radial_1578_8244" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.4118 18.1177) rotate(63.0343) scale(32.917 34.1318)">
        <stop stop-color="#F9FAFA"/>
        <stop offset="0.588027" stop-color="#F8FAFA"/>
        <stop offset="0.794223" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#ECEEEE"/>
        </radialGradient>
        </defs>
        </svg>
    `
}

export  { connectorPinIconSvgs }