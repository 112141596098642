import * as THREE from "three";
import InteractiveExperience from "../../InteractiveExperience";

import { PathGeometry } from "../../../webstudio/utils/pathLib/PathGeometry";
import { PathPointList } from "../../../webstudio/utils/pathLib/PathPointList";

class NavigationLine {
    constructor(waypoints) {
        this.editor = new InteractiveExperience();

        this.renderer = this.editor.renderer.instance;

        this.pointList = new PathPointList();
        this.points = waypoints;

        this.lineGeometry = null;
        this.material = null;
        this.line = new THREE.Group();

        this.editor.time.on("tick", this.update);

        this.createMaterial();
        this.createPathPointList();
    }

    createMaterial = () => {
        const textureLoader = new THREE.TextureLoader();
        this.texture = textureLoader.load("/static/textures/navPath.png");
        this.texture.wrapS = this.texture.wrapT = THREE.RepeatWrapping;
        this.texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        // this.texture.rotation = Math.PI;

        this.material = new THREE.MeshLambertMaterial({
            color: "#2A4DC2",
            side: THREE.DoubleSide,
            map: this.texture,
            alphaMap: this.texture,
            blending: THREE.MultiplyBlending,
            transparent: true,
        });
    };

    createPathPointList = () => {
        // console.log("this.points", this.points);
        /* for (let i = 0; i < this.points.length; i++) {
            const sphere = new THREE.Mesh(
                new THREE.SphereGeometry(0.1, 32, 32),
                new THREE.MeshBasicMaterial({ color: "#FF0000" })
            );
            sphere.position.copy(this.points[i]);
            this.line.add(sphere);
        } */
        this.pointList.set(this.points, 0.3, 15, undefined, false);
        this.createLine();
    };

    createLine = () => {
        let width = 0.4;
        width += this.editor.floorMax * 0.005;
        // console.log("width", width);
        this.lineGeometry = new PathGeometry({
            pathPointList: this.pointList,
            options: {
                width,
                arrow: false,
                progress: 1,
                side: "both",
            },
            usage: THREE.DynamicDrawUsage,
        });

        const line = new THREE.Mesh(this.lineGeometry, this.material);
        this.line.add(line);
        this.line.position.y += 0.05;
    };

    update = () => {
        this.texture.offset.x -= 0.05;
        this.texture.repeat.x = 1;
    };
}

export default NavigationLine;
