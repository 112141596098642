import { uploadAsset, deleteAsset } from "../../../../../features/common/commonSlice";

export const createAsset = (
  dispatch,
  file,
  contentType,
  assertType,
  mapId,
  cb
) => {
  let reqObj = new FormData();
  reqObj.append("file", file);
  reqObj.append("contentType", contentType);
  reqObj.append("mapId", mapId);
  reqObj.append("compressFile", true);

  let data = {
    reqObj,
    isThumbnailUploading: true,
    assertType,
  };
  dispatch(uploadAsset(data)).then((resp) => {
    cb(resp?.payload?.data?.file);
  });
};

export const deleteAssetURL = (dispatch, fileURL, cb = null) => {
  dispatch(deleteAsset({ assetUrl: fileURL })).then((resp) => {
    if (cb) {
      cb()
    }
    console.log(resp)
  })
}