import EventEmitter from "../../utils/EventEmitter";
import { has } from "lodash";
import {
    UIPanel,
    UIRow,
    UILocationCard,
    UIImage,
    UISearchbar,
    UICol,
    UIDirectionCard,
    UIText,
    UITextHeader,
    UIButton,
} from "../ui.lib";

import BackIcon from "../../../../assets/svgs/scenes/chevron_dark.svg";
import SwapIcon from "../../../../assets/svgs/studio/interactiveMaps/swap_arrows.svg";
import ComputeDirections from "../../threeUtils/ComputeDirections";
import LoaderSVG from "../../../../assets/svgs/loader.png";
import WheelChairIcon from "../../../../assets/svgs/studio/interactiveMaps/wheelchair_access.svg";
import StepsIcon from "../../../../assets/svgs/studio/interactiveMaps/steps_indicator.svg";
import DestinationIcon from "../../../../assets/svgs/studio/interactiveMaps/destination_pin.svg";
import RouteIcon from "../../../../assets/pngs/studio/interactiveMaps/Route_Graphic.png";
import { detectMobile } from "../../../_utils/detectBrowser";

import { getFloorIdentifiers } from "../../threeUtils/LocationPins/PinUtils";
import ComputeMultiDirections from "../../threeUtils/ComputeMultiDirections";

class SidebarDirections extends EventEmitter {
    constructor(editor) {
        super();
        this.editor = editor;
        this.dom = null;

        this.locationPins = [];
        this.searchedPins = [];
        this.searchSourceText = "";
        this.searchDestinationText = "";
        this.searchOnFocus = "source";

        this.sourcePin = null;
        this.destinationPin = null;

        this.fetchingPath = false;

        this.editor.on(
            "onPathBetweenPinsResponse",
            this.onPathBetweenPinsResponse
        );
        this.editor.on("clearPathBetweenPins", this.onClearPathBetweenPins);

        this.initUI();
    }

    initUI = () => {
        const uiContainer = new UIPanel().addClass(
            "wsInteractiveSidebar__directionsContainer"
        );

        const uiBackRow = new UIRow().addClass(
            "wsInteractiveSidebar__directionHeaderRow"
        );
        uiBackRow.dom.style.width = "max-content";
        const uiBackButton = new UIImage(BackIcon, "26px", "26px");
        uiBackButton.setStyle("transform", "rotate(90deg)");
        uiBackButton.addClass("wsInteractiveSidebar__backIcon");
        uiBackRow.addChild(uiBackButton);
        uiBackRow.addChild(
            new UIText("80%", "Back").addClass(
                "wsInteractiveSidebar__backIconText"
            )
        );
        uiBackRow.onClick(() => {
            //trigger event to back to pinInfo
            this.editor.trigger("updateSidebarMode", ["LOCATION_INFO"]);
            this.editor.trigger("clearPathBetweenPins");
        });

        uiContainer.addChild(uiBackRow);

        // header row
        const uiHeaderRow = new UICol().addClass(
            "wsInteractiveSidebar__directionHeaderRow__headerCol"
        );
        uiHeaderRow.addChild(
            new UITextHeader("100%", "Directions").addClass(
                "wsInteractiveSidebar__directionHeaderRow__headerCol--header"
            )
        );
        uiHeaderRow.addChild(
            new UIText(
                "100%",
                "Select your departure location from the directory or select it on the map."
            ).addClass(
                "wsInteractiveSidebar__directionHeaderRow__headerCol--subHeader"
            )
        );
        uiContainer.addChild(uiHeaderRow);

        // navigation source searchbar
        const uiSearchContainer = new UIPanel().addClass(
            "wsInteractiveSidebar__directionSearchContainer"
        );

        const uiRouteIconCont = new UIPanel();
        uiRouteIconCont.addClass(
            "wsInteractiveSidebar__directionRouteIconCont"
        );
        const uiRouteIcon = new UIImage(RouteIcon, "24px", "100%");
        uiRouteIconCont.addChild(uiRouteIcon);
        uiSearchContainer.addChild(uiRouteIconCont);

        const uiSearchInputsCont = new UICol().addClass(
            "wsInteractiveSidebar__directionSearchInputsCont"
        );

        this.searchbarSource = new UISearchbar(
            "Choose Departure",
            "",
            false,
            {
                onfocus: () => this.onSearchFocus("source"),
            },
            "wsInteractiveFloatingbar__searchbar--bgWhite"
        ).onChange(() => this.handleSourceSearch());

        this.searchbarDestination = new UISearchbar(
            "Choose Destination",
            "",
            false,
            {
                onfocus: () => this.onSearchFocus("destination"),
            },
            "wsInteractiveFloatingbar__searchbar--bgWhite"
        ).onChange(() => this.handleDestinationSearch());

        uiSearchInputsCont.addChild(this.searchbarSource);
        uiSearchInputsCont.addChild(this.searchbarDestination);

        const uiSwapIconCont = new UICol().addClass(
            "wsInteractiveSidebar__directionSwapIconCont"
        );
        const uiSwapIcon = new UIImage(SwapIcon, "26px", "26px").onClick(() => {
            if (this.fetchingPath) return;
            const sourceValue = this.searchbarSource.getValue();
            const destinationValue = this.searchbarDestination.getValue();
            this.searchbarSource.setValue(destinationValue);
            this.searchbarDestination.setValue(sourceValue);
            //swap pins
            const temp = this.sourcePin;
            this.sourcePin = this.destinationPin;
            this.destinationPin = temp;

            this.searchbarSource.setIcon(this.sourcePin);
            this.searchbarDestination.setIcon(this.destinationPin);

            this.editor.trigger("swapPinsForNavigation");
            this.invokeNavigationData();
        });
        uiSwapIconCont.addChild(uiSwapIcon);

        uiSearchContainer.addChild(uiSearchInputsCont);
        uiSearchContainer.addChild(uiSwapIconCont);
        uiContainer.addChild(uiSearchContainer);

        this.uiLocationList = new UIRow().addClass(
            "wsInteractiveSidebar__directionLocationList"
        );
        uiContainer.addChild(this.uiLocationList);
        !detectMobile() && this.uiLocationList.setDisplay("none");

        this.uiStepsList = new UIRow().addClass(
            "wsInteractiveSidebar__directionStepsList"
        );
        uiContainer.addChild(this.uiStepsList);
        this.uiStepsList.setDisplay("none");

        this.dom = uiContainer;
    };

    emptyContent = () => {
        var child = this.uiLocationList.dom.lastChild;
        while (child) {
            this.uiLocationList.dom.removeChild(child);
            child = this.uiLocationList.dom.lastChild;
        }
    };

    renderLocationList = () => {
        this.emptyContent();

        if (this.locationPins.length === 0) {
            const uiNoDataCard = new UIPanel();
            uiNoDataCard.addClass("ws_uiNoDataCardInteractive");
            uiNoDataCard.setStyle("margin", "10px 0px");
            uiNoDataCard.setStyle("padding", "15px 20px");
            const uiSubText = document.createElement("div");
            uiSubText.classList.add("ws_uiNoDataCardInteractive--text");
            uiSubText.textContent = "You haven’t added any location pin.";
            uiNoDataCard.dom.appendChild(uiSubText);
            this.uiLocationList.addChild(uiNoDataCard);
        } else if (
            this.searchDestinationText.length > 0 ||
            this.searchSourceText.length > 0
        ) {
            if (this.searchedPins.length) {
                this.searchedPins.forEach((locationPin, index) => {
                    const uiContainer = new UILocationCard(
                        locationPin,
                        this.handlePinClick,
                        index,
                        has(locationPin, "mapId")
                            ? getFloorIdentifiers(
                                  this.editor.venueDetails.maps,
                                  locationPin.mapId
                              )
                            : null
                    );
                    this.uiLocationList.addChild(uiContainer);
                });
            } else {
                const uiNoDataCard = new UIPanel();
                uiNoDataCard.addClass("ws_uiNoDataCardInteractive");
                uiNoDataCard.setStyle("margin", "10px 0px");
                uiNoDataCard.setStyle("padding", "15px 20px");
                const uiSubText = document.createElement("div");
                uiSubText.classList.add("ws_uiNoDataCardInteractive--text");
                uiSubText.textContent = `Matching pins not found!`;
                uiNoDataCard.dom.appendChild(uiSubText);
                this.uiLocationList.addChild(uiNoDataCard);
            }
        } else {
            this.locationPins.forEach((locationPin, index) => {
                const uiContainer = new UILocationCard(
                    locationPin,
                    this.handlePinClick,
                    index,
                    null,
                    has(locationPin, "mapId")
                        ? getFloorIdentifiers(
                              this.editor.venueDetails.maps,
                              locationPin.mapId
                          )
                        : null
                );
                this.uiLocationList.addChild(uiContainer);
            });
        }
    };

    emptyDirectionContent = () => {
        if (this.uiDurationRow) {
            this.dom.dom.removeChild(this.uiDurationRow.dom);
            this.uiDurationRow = null;
        }

        if (this.stepsList) {
            let child = this.stepsList.dom.lastChild;
            while (child) {
                this.stepsList.dom.removeChild(child);
                child = this.stepsList.dom.lastChild;
            }
        }

        var child = this.uiStepsList.dom.lastChild;
        while (child) {
            this.uiStepsList.dom.removeChild(child);
            child = this.uiStepsList.dom.lastChild;
        }
    };

    renderStepsList = (list, duration, totalDistance) => {
        this.emptyDirectionContent();

        console.log("Direction List", list, duration, totalDistance);

        if (list.length === 0) {
            const uiNoDataCard = new UIPanel();
            uiNoDataCard.addClass("ws_uiNoDataCardInteractive");
            uiNoDataCard.setStyle("margin", "10px 0px");
            uiNoDataCard.setStyle("padding", "15px 20px");
            const uiSubText = document.createElement("div");
            uiSubText.classList.add("ws_uiNoDataCardInteractive--text");
            uiSubText.textContent =
                "Couldn't find path between the selected pins.";
            uiNoDataCard.dom.appendChild(uiSubText);
            this.uiStepsList.addChild(uiNoDataCard);
        } else {
            this.uiDurationRow = new UIRow().addClass(
                "wsInteractiveSidebar__directionListHeaderRow"
            );
            const uiDistanceCol = new UICol();
            uiDistanceCol.addChild(
                new UIText("100%", "Time to destination").addClass(
                    "wsInteractiveSidebar__directionListHeaderRow--headerText"
                )
            );
            const uiwheelCont = new UIRow().addClass(
                "wsInteractiveSidebar__directionListHeaderRow--headerIconRow"
            );
            uiwheelCont.addChild(
                new UIImage(WheelChairIcon, "24px", "24px").addClass(
                    "wsInteractiveSidebar__directionListHeaderRow--headerIcon"
                )
            );
            uiwheelCont.addChild(
                new UIText("80%", "Wheelchair Accessible").addClass(
                    "wsInteractiveSidebar__directionListHeaderRow--headerSubText"
                )
            );
            uiDistanceCol.addChild(uiwheelCont);
            this.uiDurationRow.addChild(uiDistanceCol);

            const uiDurationCol = new UICol().addClass(
                "wsInteractiveSidebar__directionListHeaderRow--headerDistCol"
            );
            uiDurationCol.addChild(
                new UIText("100%", `${totalDistance.toFixed(2)} m`).addClass(
                    "wsInteractiveSidebar__directionListHeaderRow--subText"
                )
            );
            uiDurationCol.addChild(
                new UIText("100%", duration).addClass(
                    "wsInteractiveSidebar__directionListHeaderRow--subText"
                )
            );
            this.uiDurationRow.addChild(uiDurationCol);

            this.dom.dom.insertBefore(
                this.uiDurationRow.dom,
                this.uiStepsList.dom
            );

            // steps content
            const uiStepsContainer = new UIPanel().addClass(
                "wsInteractiveSidebar__directionStepsContainer"
            );
            const uiStepsHeader = new UIRow().addClass(
                "wsInteractiveSidebar__directionStepsHeader"
            );
            const stepsIcon = new UIImage(StepsIcon, "24px", "24px").addClass(
                "wsInteractiveSidebar__directionStepsHeader--icon"
            );
            this.stepsStateText = new UIText("80%", "Show Steps").addClass(
                "wsInteractiveSidebar__directionStepsHeader--text"
            );
            this.stepsStateIcon = new UIImage(
                BackIcon,
                "26px",
                "26px"
            ).addClass(
                "wsInteractiveSidebar__directionStepsHeader--chevronIcon"
            );
            uiStepsHeader.addChild(stepsIcon);
            uiStepsHeader.addChild(this.stepsStateText);
            uiStepsHeader.addChild(this.stepsStateIcon);
            uiStepsHeader.onClick(() => {
                if (this.stepsList) {
                    this.stepsList.setDisplay(
                        this.stepsList.dom.style.display === "none"
                            ? ""
                            : "none"
                    );
                    this.stepsStateText.dom.textContent =
                        this.stepsList.dom.style.display === "none"
                            ? "Show Steps"
                            : "Hide Steps";
                    this.stepsStateIcon.dom.style.transform =
                        this.stepsList.dom.style.display === "none"
                            ? "rotate(0deg)"
                            : "rotate(180deg)";
                }
            });
            this.stepsList = new UIPanel();
            list.forEach((direction, index) => {
                const uiContainer = new UIDirectionCard(
                    direction,
                    this.handleDirectionClick,
                    index
                );
                this.stepsList.addChild(uiContainer);
            });

            // add destination pin
            const uiDestinationPin = new UIPanel().addClass(
                "wsInteractiveSidebar__directionStepsContainer--destinationPin"
            );
            const uiDestinationIcon = new UIPanel().addClass(
                "wsInteractiveSidebar__directionStepsContainer--destinationPinIcon"
            );
            const uiDestinationIconImg = new UIPanel().addChild(
                new UIImage(DestinationIcon, "26px", "26px")
            );
            uiDestinationIcon.addChild(uiDestinationIconImg);
            const uiDestinationText = new UIText(
                "80%",
                this.destinationPin.name
            ).addClass(
                "wsInteractiveSidebar__directionStepsContainer--destinationPinText"
            );
            uiDestinationPin.addChild(uiDestinationIcon);
            uiDestinationPin.addChild(uiDestinationText);
            this.stepsList.addChild(uiDestinationPin);

            this.stepsList.setDisplay("none");

            // ar button
            const uiArButton = new UIButton("Try AR Navigation!");
            uiArButton.onClick(() => {
                this.editor.invokeARNavigation(this.destinationPin);
            });

            uiArButton.setStyle("margin", "10px 0px");

            uiStepsContainer.addChild(uiStepsHeader);
            uiStepsContainer.addChild(this.stepsList);
            this.uiStepsList.addChild(uiStepsContainer);
            this.uiStepsList.addChild(uiArButton);
        }
    };

    addLoaderToUI = () => {
        this.emptyDirectionContent();
        const uiLoader = new UIPanel();
        uiLoader.addClass("ws_uiLoaderCard");
        uiLoader.setStyle("margin", "10px 0px");
        uiLoader.setStyle("padding", "15px 20px");
        const uiLoaderImage = new UIImage(LoaderSVG, "30px", "30px");
        uiLoader.dom.appendChild(uiLoaderImage.dom);
        const uiSubText = document.createElement("div");
        uiSubText.classList.add("ws_uiLoaderCard--text");
        uiSubText.textContent = "Finding the best route...";
        uiLoader.dom.appendChild(uiSubText);
        this.uiStepsList.addChild(uiLoader);
    };

    handleSourceSearch = () => {
        this.searchSourceText = this.searchbarSource.getValue();
        this.searchSourceText.length && this.searchbarSource.setIcon(null);
        this.searchedPins = this.locationPins.filter((pin) =>
            pin.name.toLowerCase().includes(this.searchSourceText.toLowerCase())
        );
        this.renderLocationList();
    };

    handleDestinationSearch = () => {
        this.searchDestinationText = this.searchbarDestination.getValue();
        this.searchDestinationText.length &&
            this.searchbarDestination.setIcon(null);
        this.searchedPins = this.locationPins.filter((pin) =>
            pin.name
                .toLowerCase()
                .includes(this.searchDestinationText.toLowerCase())
        );
        this.renderLocationList();
    };

    onSearchFocus = (onFocus) => {
        if (this.fetchingPath) return;
        this.searchOnFocus = onFocus;
        this.uiLocationList.setDisplay("");
        if (onFocus === "source") {
            this.searchbarSource.setValue("");
            this.searchbarSource.setIcon(null);
            this.sourcePin = null;
        } else {
            this.searchbarDestination.setValue("");
            this.searchbarDestination.setIcon(null);
            this.destinationPin = null;
        }
        if (this.uiDurationRow) {
            this.dom.dom.removeChild(this.uiDurationRow.dom);
            this.uiDurationRow = null;
        }
        this.uiStepsList.setDisplay("none");
    };

    handlePinClick = (locationPin) => {
        if (this.fetchingPath) return;
        if (
            locationPin === this.sourcePin ||
            locationPin === this.destinationPin
        )
            return;

        if (this.searchOnFocus === "source") {
            this.sourcePin = locationPin;
            this.searchbarSource.setValue(locationPin.name);
            this.searchbarSource.setIcon(this.sourcePin);
        } else {
            this.destinationPin = locationPin;
            this.searchbarDestination.setValue(locationPin.name);
            this.searchbarDestination.setIcon(this.destinationPin);
        }
        this.searchedPins = [];

        this.invokeNavigationData();

        this.locationPins = this.editor.jsonLocationPins;
        this.renderLocationList();
    };

    handleDirectionClick = () => {};

    refreshUI = (locationPin) => {
        this.locationPins = this.editor.jsonLocationPins;
        this.fetchingPath = false;
        this.startPin = locationPin;
        this.searchText = "";
        this.searchedPins = [];
        this.searchbarSource.setValue("");
        this.searchbarDestination.setValue(locationPin.name);
        this.searchbarDestination.setReadOnly(true);
        this.searchbarSource.setIcon(null);
        this.searchbarDestination.setIcon(locationPin);
        this.sourcePin = null;
        this.destinationPin = locationPin;
        detectMobile() && this.uiLocationList.setDisplay("");
        this.renderLocationList();
    };

    invokeNavigationData = () => {
        if (this.sourcePin && this.destinationPin) {
            this.editor.destinationPin = this.destinationPin;
            this.fetchingPath = true;
            this.addLoaderToUI();
            this.searchbarSource.setReadOnly(true);
            this.searchbarDestination.setReadOnly(true);
            this.uiLocationList.setDisplay("none");
            this.uiStepsList.setDisplay("");

            this.editor.trigger("searchDirectionBetweenPins", [
                this.sourcePin,
                this.destinationPin,
            ]);
        }
    };

    onPathBetweenPinsResponse = (path) => {
        if (this.sourcePin && this.destinationPin) {
            this.fetchingPath = false;
            this.searchbarSource.setReadOnly(false);
            this.searchbarDestination.setReadOnly(false);
            if (path) {
                this.setPathRoutes(path);
            } else {
                this.renderStepsList([]);
            }
        }
    };

    onClearPathBetweenPins = () => {
        this.fetchingPath = false;
        this.editor.destinationPin = null;
        this.searchbarSource.setReadOnly(false);
        this.searchbarDestination.setReadOnly(false);
        this.searchbarSource.setValue("");
        this.searchbarDestination.setValue("");
        this.sourcePin = null;
        this.destinationPin = null;
        this.searchbarSource.setIcon(null);
        this.searchbarDestination.setIcon(null);
        this.searchedPins = [];
        if (this.uiDurationRow) {
            this.dom.dom.removeChild(this.uiDurationRow.dom);
            this.uiDurationRow = null;
        }
        this.uiStepsList.setDisplay("none");
        this.uiLocationList.setDisplay("none");
        this.emptyDirectionContent();
        this.emptyContent();
    };

    // check nav type
    setPathRoutes = (path) => {
        if (this.editor.isVenue) {
            if (
                this.checkIfMultiLevel(path.allAccess) ||
                this.checkIfMultiLevel(path.wakable)
            ) {
                this.setMultiFloorPath(path);
            } else {
                this.setSingleFloorPath(path);
            }
        } else {
            this.setSingleFloorPath(path);
        }
    };

    checkIfMultiLevel = (path) => {
        return path && path.length > 1;
    };

    setSingleFloorPath = (path) => {
        // console.log("Single Floor Path", path);
        const allAccessPath = path.allAccess[0];
        const wakablePath = path.wakable[0];
        if (
            allAccessPath &&
            allAccessPath?.path?.length &&
            allAccessPath?.path?.length > 2
        ) {
            const directionObj = new ComputeDirections(allAccessPath.path);
            this.editor.trigger("drawLineBetweenPins", [allAccessPath.path]);
            this.renderStepsList(
                directionObj.directionList,
                directionObj.navigationDuration,
                directionObj.totalDistance
            );
        } else if (
            wakablePath &&
            wakablePath?.path?.length &&
            wakablePath?.path?.length > 2
        ) {
            const directionObj = new ComputeDirections(wakablePath.path);
            this.editor.trigger("drawLineBetweenPins", [wakablePath.path]);
            this.renderStepsList(
                directionObj.directionList,
                directionObj.navigationDuration,
                directionObj.totalDistance
            );
        } else {
            this.renderStepsList([]);
        }
    };

    checkIfValidMultiLevel = (path) => {
        if (!path.length) return false;

        let bFlag = true;

        path.forEach((floorPath) => {
            if (!floorPath.path || floorPath.path.length < 3) {
                bFlag = false;
            }
        });

        return bFlag;
    };

    setMultiFloorPath = (path) => {
        // console.log("Multi Floor Path", path);
        const allAccessPath = path.allAccess;
        const wakablePath = path.wakable;

        if (allAccessPath && this.checkIfValidMultiLevel(allAccessPath)) {
            const directions = new ComputeMultiDirections(allAccessPath, this.editor.sceneObjectData);
            this.editor.trigger("setMultiLevelNavigationData", [
                directions,
                0,
            ]);
            this.renderStepsList(
                directions.directionList,
                directions.navigationDuration,
                directions.totalDistance
            );
        } else if (wakablePath && this.checkIfValidMultiLevel(wakablePath)) {
            const directions = new ComputeMultiDirections(wakablePath, this.editor.sceneObjectData);
            this.editor.trigger("setMultiLevelNavigationData", [
                directions,
                0,
            ]);
            this.renderStepsList(
                directions.directionList,
                directions.navigationDuration,
                directions.totalDistance
            );
        } else {
            this.renderStepsList([]);
        }
    };
}

export { SidebarDirections };
