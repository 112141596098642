import React from "react";

import { Backdrop, Box, Modal, Fade } from "@mui/material";

import { XCircle } from "react-feather";

//Images
import Slide1 from "../../../assets/pngs/studio/interactiveMaps/ar_navigation.png";
import { detectMobile } from "../../_utils/detectBrowser";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: "max-content",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    border: "none !important",
    outline: "none !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center !important",
    alignItems: "center !important",
    borderRadius: "16px",
};

export default function ARModal({ openModal, onCloseModal, modalData }) {
    const handleClose = () => {
        onCloseModal();
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={onCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box
                        sx={{
                            ...style,
                            width: detectMobile() ? "90%" : style.width,
                        }}
                    >
                        <XCircle
                            color="#353E5A"
                            strokeWidth="1.5"
                            className="mapdetails__mapModalCloseIcon"
                            onClick={handleClose}
                        />
                        <div className="arNavModal">
                            <div className="mapdetails__mapModalHeader">
                                Download the APP
                            </div>
                            <div className="mapdetails__mapModalText">
                                Download the app and follow the instructions to
                                start the AR Navigation!
                            </div>
                            <img
                                src={Slide1}
                                alt={Slide1}
                                draggable="false"
                                className="arNavModal--img"
                            />
                            <div className="publishModalBtnsDiv">
                                <a
                                    className="arNavModal--button"
                                    href={modalData}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={handleClose}
                                >
                                    Download
                                </a>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
