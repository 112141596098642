import { has } from "lodash";
import { getFloorIdentifiers } from "../../threeUtils/LocationPins/PinUtils";
import EventEmitter from "../../utils/EventEmitter";
import { UIPanel, UIText, UIRow, UILocationCard } from "../ui.lib";

class FloatingbarLocationList extends EventEmitter {
    constructor(editor) {
        super();
        this.editor = editor;
        this.dom = null;

        this.locationPins = [];
        this.searchedPins = [];
        this.searchText = "";

        this.editor.resources.on("ready", this.updateUI);

        this.initUI();
    }

    initUI = () => {
        const uiContainer = new UIPanel().addClass(
            "wsInteractiveFloatingbar__locationListContainer"
        );

        const uiLocationTitle = new UIText("80%", "Location Pins").addClass(
            "wsInteractiveFloatingbar__topLocationTitle"
        );
        uiContainer.addChild(uiLocationTitle);

        this.uiLocationList = new UIRow().addClass(
            "wsInteractiveFloatingbar__locationList"
        );
        uiContainer.addChild(this.uiLocationList);

        this.dom = uiContainer;
    };

    emptyContent = () => {
        var child = this.uiLocationList.dom.lastChild;
        while (child) {
            this.uiLocationList.dom.removeChild(child);
            child = this.uiLocationList.dom.lastChild;
        }
    };

    renderLocationList = () => {
        this.emptyContent();

        if (this.locationPins.length === 0) {
            const uiNoDataCard = new UIPanel();
            uiNoDataCard.addClass("ws_uiNoDataCardInteractive");
            uiNoDataCard.setStyle("margin", "10px 0px");
            uiNoDataCard.setStyle("padding", "15px 20px");
            const uiSubText = document.createElement("div");
            uiSubText.classList.add("ws_uiNoDataCardInteractive--text");
            uiSubText.textContent = "You haven’t added any location pin.";
            uiNoDataCard.dom.appendChild(uiSubText);
            this.uiLocationList.addChild(uiNoDataCard);
        } else if (this.searchText.length > 0) {
            if (this.searchedPins.length) {
                this.searchedPins.forEach((locationPin, index) => {
                    const uiContainer = new UILocationCard(
                        locationPin,
                        this.handlePinClick.bind(this),
                        index,
                        null,
                        has(locationPin, "mapId")
                            ? getFloorIdentifiers(
                                    this.editor.venueDetails.maps,
                                    locationPin.mapId
                            )
                            : null
                    );
                    this.uiLocationList.addChild(uiContainer);
                });
            } else {
                const uiNoDataCard = new UIPanel();
                uiNoDataCard.addClass("ws_uiNoDataCardInteractive");
                uiNoDataCard.setStyle("margin", "10px 0px");
                uiNoDataCard.setStyle("padding", "15px 20px");
                const uiSubText = document.createElement("div");
                uiSubText.classList.add("ws_uiNoDataCardInteractive--text");
                uiSubText.textContent = `Matching pins not found!`;
                uiNoDataCard.dom.appendChild(uiSubText);
                this.uiLocationList.addChild(uiNoDataCard);
            }
        } else {
            this.locationPins.forEach((locationPin, index) => {
                const uiContainer = new UILocationCard(
                    locationPin,
                    this.handlePinClick.bind(this),
                    index,
                    null,
                    has(locationPin, "mapId")
                        ? getFloorIdentifiers(
                              this.editor.venueDetails.maps,
                              locationPin.mapId
                          )
                        : null
                );
                this.uiLocationList.addChild(uiContainer);
            });
        }
    };

    updateUI = () => {
        this.locationPins = this.editor.jsonLocationPins || [];
        this.renderLocationList();
    };

    updateSearchText = (searchText) => {
        this.searchText = searchText;
        this.searchedPins = this.locationPins?.filter((pin) =>
            pin.name.toLowerCase().includes(searchText.toLowerCase())
        );
        this.renderLocationList();
    };

    handlePinClick = (locationPin) => {
        if (
            this.editor.isVenue &&
            this.editor.activeMap !== locationPin.mapId
        ) {
            //Switch Scene
            this.editor.trigger("requestSceneSwitch", [locationPin.mapId]);
            this.editor.trigger("locationPinSelected", [locationPin]);
        } else {
            this.editor.trigger("locationPinSelected", [locationPin]);
        }
    };
}

export { FloatingbarLocationList };
